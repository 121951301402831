<div class="page-nav container" *ngIf="backButtonLink || (toolbarButtons && toolbarButtons.length)">
  <dx-toolbar #appPageToolbar [dataSource]="toolbarItems"></dx-toolbar>
</div>

<div class="container-fluid">
  <div class="page-header">
    <div class="container">
      <h2 *ngIf="pageTitle">{{pageTitle}}</h2>
      <h3 class="subtitle" *ngIf="subTitle">{{subTitle}}</h3>
    </div>
  </div>
  <div class="page-content">
    <div class="container">
      <ng-content *ngIf="!isLoading" ngTemplateOutletContext="tmplCtx"></ng-content>
      <div class="row" *ngIf="!isLoading">
        <div class="col-6">
          <ng-content select="[page-type=footer-prev]"></ng-content>
        </div>
        <div class="col-6 text-end">
          <ng-content select="[page-type=footer-next]"></ng-content>
        </div>
      </div>
      <dx-load-indicator class="item-loader" *ngIf="isLoading" hint="sdf" height="20" width="20"></dx-load-indicator>
    </div>
  </div>
</div>
