import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { ProfileModule } from './account/profile/profile.module';
import { AuthModule } from './auth/auth.module';
import { FooterComponent } from './layouts/footer.component';
import { FullLayoutComponent } from './layouts/full-layout.component';
import { SidebarLeftComponent } from './layouts/sidebar-left.component';
import { SidebarUserComponent } from './layouts/sidebar-user.component';
import { BottomNavsComponent } from './navigation/bottom-navs.component';
import { MobileFooterNavComponent } from './navigation/mobilefooternav/mobilefooternav.component';
import { AppAuthenticationResolver } from './resolver/app-authentication.resolver';
import { AppConfigResolver } from './resolver/app-config.resolver';
import { APIInterceptor } from './services/interceptor/APIInterceptor';
import { MessageService } from './services/message.service';

@NgModule({
  declarations: [
    FullLayoutComponent,
    FooterComponent,
    MobileFooterNavComponent,
    BottomNavsComponent,
    SidebarLeftComponent,
    SidebarUserComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    SharedModule,
    TranslateModule.forRoot(),
    AuthModule,
    ProfileModule,
  ],
  providers: [
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: APIInterceptor,
    },
    MessageService,
    AppAuthenticationResolver,
    AppConfigResolver,
  ],
})
export class CoreModule {}
