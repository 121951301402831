import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CloudinaryModule } from '@cloudinary/ng';
import { NappGuiLibModule } from '@napoleon/napp-gui-lib';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxStarsModule } from 'ngx-stars';
import { HtmlEditorDxComponent } from '~/app/shared/components/html-editor-dx.component';
import { BaseAccordionPanelComponent } from './components/accordion/base-accordion-panel.component';
import { AppButtonComponent } from './components/app-button.component';
import { AppLanguageSelectorComponent } from './components/app-language-selector.component';
import { AppPageComponent } from './components/app-page.component';
import { AppReportEntityComponent } from './components/app-report-entity-button';
import { AuthorImageComponent } from './components/author-image.component';
import { ContentReportComponent } from './components/content-report.component';
import { DamageReportComponent } from './components/damage-report.component';
import { ExternalFileChooserItemComponent } from './components/external-file/external-file-chooser-item.component';
import { ExternalFileChooserComponent } from './components/external-file/external-file-chooser.component';
import { ExternalFileImageCropperComponent } from './components/external-file/external-file-image-cropper.component';
import { ExternalFileManagerComponent } from './components/external-file/external-file-manager.component';
import { ExternalFileUploaderComponent } from './components/external-file/external-file-uploader.component';
import { ExternalFileService } from './components/external-file/external-file.service';
import { FeedbackComponent } from './components/feedback.component';
import { FileItemComponent } from './components/filemanager/components/file-item.component';
import { FileBrowserComponent } from './components/filemanager/file-browser.component';
import { FileManagerComponent } from './components/filemanager/file-manager.component';
import { FileUploadComponent } from './components/filemanager/file-upload.component';
import { HtmlEditorComponent } from './components/html-editor.component';
import { InnerHTMLRendererComponent } from './components/innerHTMLRenderer.component';
import { LoadingBarComponent } from './components/loading-bar.component';
import { PopupComponent } from './components/popup.component';
import { ScrollViewWithBottomBarComponent } from './components/scroll-view-with-bottom-bar.component';
import { SingleInputComponent } from './components/single-input.component';
import { DevextremeModule } from './devextreme.module';
import { LinkHandlingDirective } from './directives/link-handling.directive';
import { ReloadOnLangChangeDirective } from './directives/reload-on-lang-change.directive';
import { HighlightPipe } from './pipes/highlight.pipe';
import { StripTagsPipe } from './pipes/strip-tags.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';

@NgModule({
  declarations: [
    BaseAccordionPanelComponent,
    LoadingBarComponent,
    AppButtonComponent,
    AppPageComponent,
    AppLanguageSelectorComponent,
    SingleInputComponent,
    AuthorImageComponent,
    FeedbackComponent,
    TruncatePipe,
    StripTagsPipe,
    HighlightPipe,
    HtmlEditorComponent,
    HtmlEditorDxComponent,
    DamageReportComponent,
    LinkHandlingDirective,
    ReloadOnLangChangeDirective,
    InnerHTMLRendererComponent,
    FileBrowserComponent,
    FileItemComponent,
    FileManagerComponent,
    FileUploadComponent,
    PopupComponent,
    ScrollViewWithBottomBarComponent,
    AppReportEntityComponent,
    ContentReportComponent,
    ExternalFileManagerComponent,
    ExternalFileUploaderComponent,
    ExternalFileChooserComponent,
    ExternalFileImageCropperComponent,
    ExternalFileChooserItemComponent,
  ],
  imports: [
    CommonModule,
    DevextremeModule,
    NappGuiLibModule,
    TranslateModule,
    NgxStarsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB6CEBmKSrqK3johbyxFQEhscpQ66B8NtQ',
      libraries: ['places'],
    }),
    FormsModule,
    EditorModule,
    ImageCropperModule,
    CloudinaryModule,
  ],
  exports: [
    DevextremeModule,
    NappGuiLibModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    BaseAccordionPanelComponent,
    LoadingBarComponent,
    AppButtonComponent,
    AppPageComponent,
    AppLanguageSelectorComponent,
    SingleInputComponent,
    AuthorImageComponent,
    FeedbackComponent,
    TruncatePipe,
    StripTagsPipe,
    AgmCoreModule,
    HighlightPipe,
    HtmlEditorComponent,
    HtmlEditorDxComponent,
    DamageReportComponent,
    LinkHandlingDirective,
    ReloadOnLangChangeDirective,
    InnerHTMLRendererComponent,
    FileBrowserComponent,
    FileManagerComponent,
    ImageCropperModule,
    PopupComponent,
    ScrollViewWithBottomBarComponent,
    CloudinaryModule,
    AppReportEntityComponent,
    ContentReportComponent,
    ExternalFileManagerComponent,
  ],
  providers: [
    TranslateService,
    ExternalFileService,
    { provide: TINYMCE_SCRIPT_SRC, useValue: '/assets/vendors/tinymce/tinymce.min.js' },
  ],
})
export class SharedModule {}
