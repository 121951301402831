import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mergeMap } from 'rxjs/operators';
import { AppApiStrategy } from '../../../app-api-strategy';
import { ExternalFile, FilePurpose } from './types';

@Injectable()
export class ExternalFileService {
  private apiStrategy = AppApiStrategy;

  constructor(private http: HttpClient) {}

  public uploadExternalFile(file: Blob, purpose: FilePurpose) {
    const url = this.apiStrategy.getServiceUrl(['external-file']);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('purpose', purpose);

    return this.apiStrategy.getHeaders(undefined, true, true).pipe(
      mergeMap((headers) =>
        this.http.post<ExternalFile>(url, formData, {
          headers,
        })
      )
    );
  }

  public getExternalFileLibrary(purpose?: FilePurpose) {
    const url = this.apiStrategy.getServiceUrl(['external-file', 'library']);
    return this.apiStrategy.getHeaders(undefined, true, true).pipe(
      mergeMap((headers) =>
        this.http.get<ExternalFile[]>(url, {
          headers,
          params: purpose ? { purpose } : undefined,
        })
      )
    );
  }

  public deleteExternalFile(id: string) {
    const url = this.apiStrategy.getServiceUrl(['external-file', id]);
    return this.apiStrategy
      .getHeaders(undefined, true, true)
      .pipe(mergeMap((headers) => this.http.delete(url, { headers })));
  }
}
