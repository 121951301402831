<dx-popup
  [visible]="isOpen"
  (visibleChange)="onVisibleChange($event)"
  [hideOnOutsideClick]="true"
  title="File manager"
  [showCloseButton]="true"
>
  <dx-tab-panel *ngIf="show">
    <dxi-item [title]="'filemanager.uploadFile' | translate">
      <app-external-file-uploader
        [purpose]="purpose"
        [cropAspectRatio]="cropAspectRatio!"
        [enableCrop]="cropAspectRatio !== undefined"
        (fileUploaded)="onFileUploaded($event)"
      ></app-external-file-uploader>
    </dxi-item>
    <dxi-item *ngIf="showLibrary" [title]="'filemanager.chooseFile' | translate">
      <app-external-file-chooser [purpose]="purpose" (fileChosen)="onFileUploaded($event)"></app-external-file-chooser>
    </dxi-item>
  </dx-tab-panel>
</dx-popup>
