import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '~/app/core/services/base/base.service';
import { ProfileMetadata } from '../models/profile-metadata';

@Injectable({
  providedIn: 'root',
})
export class ProfileMetadataService extends BaseService<ProfileMetadata> {
  constructor(public override http: HttpClient) {
    super(http, ProfileMetadata);
  }

  protected getServiceUrl(): string {
    const url = this.generateServiceURL('profile/metadata');
    return this.apiStrategy.getServiceUrl(url);
  }
}
