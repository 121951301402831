import { BaseEntity } from '~/app/core/models/base-entity.model';

export class Context extends BaseEntity<Context> {
  constructor(
    public code?: string,
    public name?: string,
    public description?: string,
    public type?: string,
    public active?: number,
    public parent?: {
      id: string;
    }
  ) {
    super();
  }
}
