// https://stackoverflow.com/a/47138918

import { AppTranslationService } from './core/services/translation/app-translation.service';

export let AppTranslation: AppTranslationService;

/**
 * Helper to access the exported {@link AppTranslation}, needed as ES6 modules export
 * immutable bindings; see http://2ality.com/2015/07/es6-module-exports.html
 */
export function setAppTranslation(appTranslation: AppTranslationService) {
  if (AppTranslation) {
    // Should not happen
    console?.error('Programming error: AppTranslation was already set');
  }
  else {
    AppTranslation = appTranslation;
  }
}
