import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PositionConfig } from 'devextreme/animation/position';
import dxPopup from 'devextreme/ui/popup';
import { Subscription } from 'rxjs';
import { getNumericCssVariables } from '../helpers/cssVariables';

@Component({
  selector: 'app-popup',
  template: `
    <dx-popup
      [showTitle]="!!this.title"
      [title]="this.title"
      [dragEnabled]="false"
      [closeOnOutsideClick]="false"
      [(visible)]="this.visible"
      [showCloseButton]="true"
      [position]="this.popupPosition"
      [height]="this.popupHeight"
      [width]="this.popupWidth"
      (onInitialized)="this.onPopupInit($event)"
    >
      <div *dxTemplate="let data of 'content'">
        <ng-content></ng-content>
      </div>
    </dx-popup>
  `,
})
export class PopupComponent implements OnInit, OnDestroy {
  @Output() public visibleChange = new EventEmitter<boolean>();

  @Input() public title!: string;
  @Input() public get visible() {
    return this._visible;
  }
  public set visible(visible: boolean) {
    if (visible !== this._visible) {
      this._visible = visible;
      this.visibleChange.emit(this._visible);
    }
  }

  public popupHeight!: number;
  public popupWidth!: number;
  public popupPosition!: PositionConfig;

  private _visible = false;
  private visibleSubscription?: Subscription;
  private popupInstance?: dxPopup;
  private layoutChangeWidth = 0;
  private headerHeight = 0;
  private sat = 0;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  public ngOnInit() {
    this.visibleSubscription = this.visibleChange.subscribe((visible: boolean) => {
      if (visible) {
        this.repaint();
      }
    });
  }

  public ngOnDestroy() {
    this.visibleSubscription?.unsubscribe();
  }

  // tslint:disable-next-line: prefer-inline-decorator
  @HostListener('window:resize')
  @HostListener('window:orientationchange')
  public repaint() {
    this.updateCssVariables();
    this.popupInstance?.repaint();
  }

  public onPopupInit(e: { component: dxPopup }) {
    this.popupInstance = e.component;
  }

  private updateCssVariables() {
    const keys = ['napp-popup-fullscreen-threshold-size', 'napp-header-height', 'sat'] as const;
    const cssVariables = getNumericCssVariables(window, this.document, keys);
    this.layoutChangeWidth = cssVariables[keys[0]];
    this.headerHeight = cssVariables[keys[1]];
    this.sat = cssVariables[keys[2]];
    if (window.innerWidth < this.layoutChangeWidth) {
      this.popupHeight = window.innerHeight - this.headerHeight - this.sat;
      this.popupWidth = window.innerWidth;
      this.popupPosition = { my: 'bottom', at: 'bottom', of: window };
    } else {
      this.popupHeight = window.innerHeight * 0.8; // Dx default
      this.popupWidth = window.innerWidth * 0.8; // Dx default
      this.popupPosition = { my: 'center', at: 'center', of: window };
    }
  }
}
