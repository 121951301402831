import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { BehaviorSubject, from, Observable, ReplaySubject, Subscription } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { BaseService, FetchByIdOptions, IServiceUrlConfig } from '../../../../core/services/base/base.service';
import { MediaFile, MediaFileSearchInfo } from '../models/media-file';

export enum FILECATEGORYTYPES {
  PROFILEPICTURE = 'a92422b2-92b6-4953-8641-2c99cffbc43f',
  FEEDPOSTTEASER = 'fc5b9623-429d-4afc-9903-dc51bad0a676',
  MAGAZINE = '1c6e7ead-e23c-4145-91b2-5cd25d29d31b',
  CONTENT = '815a444d-7ea6-4e31-906a-21cc9c5dd9fb',
  _CLOUDINARY_ = '_CLOUDINARY_',
}

export enum FILESIZEVERSION {
  AVATAR_SMALL = 'AVATAR_SMALL',
  AVATAR_MEDIUM = 'AVATAR_MEDIUM',
  AVATAR = 'AVATAR',
  FEEDTEASER = 'FEEDTEASER',
  FEEDTEASER_DETAILED = 'FEEDTEASER_DETAILED',
  THUMBNAIL = 'THUMBNAIL',
  THUMBNAIL_SQUARE = 'THUMBNAIL_SQUARE',
  DEFAULT = 'DEFAULT',
}

export const FILEMANAGER_CACHE_LIFETIME_MS = new InjectionToken<number>('FILEMANAGER_CACHE_LIFETIME_MS');

export type FileTypes = 'application/pdf' | 'image/*' | 'video/*' | '*/*';

class DefaultMediaFile extends MediaFile {
  constructor() {
    super('');
  }
}

type FileCache = {
  [id: string]: {
    [version in FILESIZEVERSION]?: {
      pending?: ReplaySubject<Blob>;
      data?: Blob;
      cached?: Date;
    };
  };
};

interface FileManagerPreparationConfig {
  acceptedFileTypes: FileTypes | null;
  isMediaManagerShownRef: BehaviorSubject<boolean>;
  selected?: (data: Array<MediaFile>) => Observable<void>;
  fileUploaded?: (publicId: string) => Observable<void>;
  // videoUploaded?: (data: string) => Observable<void>;
  enableCrop?: boolean;
  cropAspectRatio?: number;
  updateFile?: boolean;
  fileIdToUpdate: string | null;
  fileDirectoryId: string | null;
  fileCategory: FILECATEGORYTYPES | null;
}

@Injectable({
  providedIn: 'root',
})
export class FileManagerService extends BaseService<MediaFile> {
  public mediaManagerRequestedAcceptedFileTypes = new BehaviorSubject<FileTypes | null>(null);
  public mediaManagerFileCategory = new BehaviorSubject<FILECATEGORYTYPES | null>(null);
  public mediaManagerEnableCrop = new BehaviorSubject<boolean>(false);
  public mediaManagerCropAspectRatio = new BehaviorSubject<number>(1 / 1);
  public mediaManagerUpdateFile = new BehaviorSubject<boolean>(false);
  public mediaManagerFileIdToUpdate = new BehaviorSubject<string | null>(null);
  public mediaManagerFileDirectoryId = new BehaviorSubject<string | null>(null);

  // TODO: Move cache to local db (ca. 100MB)
  private cache: FileCache = {};
  private queue: Array<(loadNext: () => void) => void> = [];
  private queueTask?: ReturnType<typeof setTimeout>;
  private mediaManagerCallback?: (publicId: string) => Observable<void>;
  // private mediaManagerVideoCallback?: (data: string) => Observable<void>;
  // private mediaManagerImageCallback?: (data: string) => Observable<void>;
  private isMediaManagerShownRef?: BehaviorSubject<boolean>;
  private closeSubscription?: Subscription;

  constructor(
    public override http: HttpClient,
    @Optional() @Inject(FILEMANAGER_CACHE_LIFETIME_MS) private cacheLifetimeMs: number
  ) {
    super(http, DefaultMediaFile);
    if (!this.cacheLifetimeMs || this.cacheLifetimeMs <= 0) {
      this.cacheLifetimeMs = 900_000; // 15 min
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public mediaManagerSelectedCallback(data: Array<MediaFile>): void {
    let done: Observable<void> = from([]);
    let handled = false;
    const cleanup = () => {
      if (!handled) {
        handled = true;
        this.closeSubscription?.unsubscribe();
        this.isMediaManagerShownRef?.next(false);
        // reset MediaManager
        this.resetMediaManager();
      }
    };
    if (typeof this.mediaManagerCallback === 'function') {
      done = this.mediaManagerCallback('');
      this.mediaManagerCallback = undefined;
    }
    done.subscribe({ next: cleanup, error: cleanup, complete: cleanup });
  }

  public mediaManagerUploadedCallback(publicId: string): void {
    let done: Observable<void> = from([]);
    let handled = false;
    const cleanup = () => {
      if (!handled) {
        handled = true;
        this.closeSubscription?.unsubscribe();
        this.isMediaManagerShownRef?.next(false);
        // reset MediaManager
        this.resetMediaManager();
      }
    };
    if (typeof this.mediaManagerCallback === 'function') {
      console.log('public id', publicId);
      done = this.mediaManagerCallback(publicId);
      this.mediaManagerCallback = undefined;
    }
    done.subscribe({ next: cleanup, error: cleanup, complete: cleanup });
  }

  // public mediaManagerVideoSelectedCallback(videoId: string): void {
  //   let done: Observable<void> = from([]);
  //   let handled = false;
  //   const cleanup = () => {
  //     if (!handled) {
  //       handled = true;
  //       this.closeSubscription?.unsubscribe();
  //       this.isMediaManagerShownRef?.next(false);
  //       // reset MediaManager
  //       this.resetMediaManager();
  //     }
  //   };
  //   if (typeof this.mediaManagerVideoCallback === 'function') {
  //     done = this.mediaManagerVideoCallback(videoId);
  //     this.mediaManagerVideoCallback = undefined;
  //   }
  //   done.subscribe({ next: cleanup, error: cleanup, complete: cleanup });
  // }

  // public mediaManagerImageSelectetCallback(imageId: string): void {
  //   let done: Observable<void> = from([]);
  //   let handled = false;
  //   const cleanup = () => {
  //     if (!handled) {
  //       handled = true;
  //       this.closeSubscription?.unsubscribe();
  //       this.isMediaManagerShownRef?.next(false);
  //       // reset MediaManager
  //       this.resetMediaManager();
  //     }
  //   };
  //   if (typeof this.mediaManagerImageCallback === 'function') {
  //     console.log('callback');
  //     done = this.mediaManagerImageCallback(imageId);
  //     this.mediaManagerImageCallback = undefined;
  //   }
  //   done.subscribe({ next: cleanup, error: cleanup, complete: cleanup });
  // }

  public showMediaManager({
    acceptedFileTypes,
    // selected,
    fileUploaded,
    isMediaManagerShownRef,
    // videoUploaded,
    // imageUploaded,
    enableCrop = false,
    cropAspectRatio = 1 / 1,
    updateFile = false,
    fileIdToUpdate,
    fileDirectoryId,
    fileCategory,
  }: FileManagerPreparationConfig) {
    this.mediaManagerEnableCrop.next(enableCrop);
    this.mediaManagerCropAspectRatio.next(cropAspectRatio);
    this.mediaManagerFileCategory.next(fileCategory);
    this.mediaManagerUpdateFile.next(updateFile);
    this.mediaManagerFileIdToUpdate.next(fileIdToUpdate);
    this.mediaManagerFileDirectoryId.next(fileDirectoryId);
    this.mediaManagerRequestedAcceptedFileTypes.next(acceptedFileTypes);
    this.mediaManagerCallback = fileUploaded;
    // this.mediaManagerVideoCallback = videoUploaded;
    // this.mediaManagerImageCallback = imageUploaded;
    this.isMediaManagerShownRef = isMediaManagerShownRef;
    this.isMediaManagerShownRef.next(true);
    this.closeSubscription = this.isMediaManagerShownRef
      .pipe(
        take(1),
        filter((v) => !v)
      )
      .subscribe(() => {
        this.resetMediaManager();
      });
  }

  public override getEntityById(options: FetchByIdOptions) {
    this.clearCacheForId(options.entityId);
    return super.getEntityById(options);
  }

  public getFileData(fileId: string, fileSizeVersion?: FILESIZEVERSION): Observable<Blob | null> {
    const cachedFile = this.cache[fileId];
    const fileSizeVersionKey = fileSizeVersion || FILESIZEVERSION.DEFAULT;

    if (cachedFile) {
      const cachedFileVersion = cachedFile[fileSizeVersionKey];
      if (cachedFileVersion) {
        if (cachedFileVersion?.pending) {
          return cachedFileVersion.pending;
        } else if (cachedFileVersion?.cached) {
          const now = new Date();
          if (now.getTime() - cachedFileVersion.cached.getTime() < this.cacheLifetimeMs) {
            return from([cachedFileVersion.data || null]);
          }
          delete this.cache[fileId][fileSizeVersionKey];
        }
      }
    } else {
      this.cache[fileId] = {};
    }
    this.cache[fileId][fileSizeVersionKey] = { pending: new ReplaySubject<Blob>(1, this.cacheLifetimeMs) };
    this.queueRequest(fileId, fileSizeVersion);
    return this.cache[fileId][fileSizeVersionKey]?.pending || from([null]);
  }

  public searchFilesByContent(searchString: string): Observable<MediaFile[]> {
    const url = this.generateServiceURL('media-file-search');
    const serviceUrl: string = this.apiStrategy.getServiceUrl(url);

    return this.apiStrategy.getHeaders().pipe(
      mergeMap((headers) => {
        return this.http
          .post<MediaFile>(
            serviceUrl,
            { searchTerm: searchString, 'category/id': FILECATEGORYTYPES.MAGAZINE },
            {
              headers,
              observe: 'response',
            }
          )
          .pipe(
            map((response) => {
              const data = response.body;
              const header = response.headers;

              this.entitiesTotal = Number(header.get('X-Pagination-Count'));
              const responseList: MediaFile[] = [];

              if (data instanceof Array) {
                data.forEach((item) => {
                  responseList.push(new this.parentModel().deserialize(item));
                });
              } else {
                responseList.push(new this.parentModel().deserialize(data ?? new DefaultMediaFile()));
              }
              return responseList;
            })
          );
      })
    );
  }

  public searchContentForSpecificFile(
    searchString: string,
    entityId: string,
    config: IServiceUrlConfig
  ): Observable<MediaFileSearchInfo | null> {
    const url = this.generateServiceURL('media-file', entityId, config);
    const serviceUrl: string = this.apiStrategy.getServiceUrl(url);

    return this.apiStrategy.getHeaders().pipe(
      mergeMap((headers) => {
        return this.http
          .post<MediaFileSearchInfo>(
            serviceUrl,
            { searchTerm: searchString, 'category/id': FILECATEGORYTYPES.MAGAZINE },
            {
              headers,
              observe: 'response',
            }
          )
          .pipe(
            map((response) => {
              this.entitiesTotal = Number(response.headers.get('X-Pagination-Count'));
              return response.body;
            })
          );
      })
    );
  }

  public getServiceUrl(entityId?: string, serviceUrlConfig?: IServiceUrlConfig): string {
    const url = this.generateServiceURL('media-file', entityId, serviceUrlConfig);
    return this.apiStrategy.getServiceUrl(url);
  }

  private resetMediaManager() {
    this.isMediaManagerShownRef = undefined;
    this.mediaManagerCallback = undefined;
    // this.mediaManagerVideoCallback = undefined;
    // this.mediaManagerImageCallback = undefined;
    this.mediaManagerRequestedAcceptedFileTypes.next(null);
    this.mediaManagerEnableCrop.next(false);
    this.mediaManagerCropAspectRatio.next(1 / 1);
    this.mediaManagerUpdateFile.next(false);
    this.mediaManagerFileIdToUpdate.next(null);
    this.mediaManagerFileDirectoryId.next(null);
    this.mediaManagerFileCategory.next(FILECATEGORYTYPES.MAGAZINE);
    this.closeSubscription?.unsubscribe();
    this.closeSubscription = undefined;
  }

  private loadFile(serviceUrl: string): Observable<Blob> {
    return this.apiStrategy.getHeaders('application/image').pipe(
      mergeMap((headers) => {
        return this.http
          .get<Blob>(serviceUrl, {
            headers,
            responseType: 'blob' as 'json',
          })
          .pipe(
            map((data: Blob) => {
              return data;
            })
          );
      })
    );
  }

  private queueRequest(fileId: string, fileSizeVersion?: FILESIZEVERSION): void {
    let serviceUrl: string = this.getServiceUrl(undefined, { path: [fileId, 'data'] });

    if (fileSizeVersion) {
      serviceUrl += '?version=' + fileSizeVersion;
    }

    const fileSizeVersionKey = fileSizeVersion || FILESIZEVERSION.DEFAULT;

    this.queue.push((loadNext: () => void) => {
      const sub = this.loadFile(serviceUrl).subscribe({
        next: (data) => {
          sub.unsubscribe();
          const notify = this.cache[fileId][fileSizeVersionKey]?.pending;
          const cached = new Date();
          this.cache[fileId][fileSizeVersionKey] = { cached, data };
          notify?.next(data);
          loadNext();
        },
        error: (err) => {
          sub.unsubscribe();
          const notify = this.cache[fileId][fileSizeVersionKey]?.pending;
          delete this.cache[fileId][fileSizeVersionKey];
          notify?.error(err);
          loadNext();
        },
      });
    });
    this.processQueue();
  }

  private processQueue() {
    if (!this.queueTask) {
      this._processQueue();
    }
  }

  private _processQueue() {
    if (this.queue.length > 0) {
      this.queueTask = setTimeout(() => {
        const nextTask = this.queue.shift();
        nextTask?.(() => {
          this._processQueue();
        });
      }, 0);
    } else {
      this.queueTask = undefined;
    }
  }

  private clearCacheForId(fileId: string | string[]) {
    if (Array.isArray(fileId)) {
      for (const id of fileId) {
        delete this.cache[id];
      }
    } else {
      delete this.cache[fileId];
    }
  }
}
