// https://stackoverflow.com/a/47138918

import { BaseModelServiceApiStrategy } from './core/services/backend/base-model-api.service.strategy';

export let AppApiStrategy: BaseModelServiceApiStrategy;

/**
 * Helper to access the exported {@link AppApiStrategy}, needed as ES6 modules export
 * immutable bindings; see http://2ality.com/2015/07/es6-module-exports.html
 */
export function setAppApiStrategy(apiStrategy: BaseModelServiceApiStrategy) {
  if (AppApiStrategy) {
    // Should not happen
    console?.error('Programming error: AppApiStrategy was already set');
  }
  else {
    AppApiStrategy = apiStrategy;
  }
}
