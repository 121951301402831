import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Cloudinary } from '@cloudinary/url-gen';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { ENVIRONMENT_CONFIGURATION } from '@softwarehaus/util-configuration';
import { Observable } from 'rxjs';
import { exhaustMap, mergeMap } from 'rxjs/operators';
import { v4 as uuid_v4 } from 'uuid';
import { NappEnvironmentConfiguration } from '~/app/config/environments-configuration';
import { ImageVersion, imageVersionSizes } from '~/app/shared/components/filemanager/image-versions';
import { AppApiStrategy } from '../../../../app-api-strategy';
import { LayoutService } from '../../../../core/services/store/layout.service';

export interface CloudinaryResponse {
  public_id: string;
}

interface SignatureOptions {
  public_id: string;
  upload_preset?: string;
  transformation?: string;
  eager?: string;
  eager_async?: boolean;
}

interface SignatureResponse {
  signature: string;
  apiKey: string;
  timestamp: number;
}

@Injectable({
  providedIn: 'root',
})
export class CloudinaryService {
  private apiStrategy = AppApiStrategy;
  private uploadPreset: string = this.config.cloudinary?.uploadPreset ?? '';
  private cloudName: string = this.config.cloudinary?.cloudName ?? '';
  private _cloudinary?: Cloudinary;

  private readonly CHUNK_SIZE = 20_000_000;
  private readonly CLOUDINARY_BASE_URL = `https://api.cloudinary.com/v1_1/${this.cloudName}`;

  constructor(
    private readonly http: HttpClient,
    private readonly layoutService: LayoutService,
    @Inject(ENVIRONMENT_CONFIGURATION) private config: NappEnvironmentConfiguration
  ) {}

  public getVideo(publicId: string) {
    return this.getCloudinary().video(publicId);
  }

  public getImage(publicId: string, format?: ImageVersion) {
    const image = this.getCloudinary().image(publicId).setAssetType('image');

    if (format) {
      const { width, height } = imageVersionSizes[format];
      return image.resize(fill(width, height));
    }

    return image;
  }

  public getVideoThumbnail(publicId: string) {
    return this.getCloudinary().image(`${publicId}.png`).setAssetType('video');
  }

  public getYoutubeVideoThumbnail(videoId: string) {
    return this.getCloudinary().image(`${videoId}.png`).setAssetType('image').setDeliveryType('youtube');
  }

  public uploadImageToCloudinary(file: File): Observable<CloudinaryResponse> {
    const signatureOptions: SignatureOptions = {
      public_id: `${uuid_v4()}_${new Date().getTime()}`,
      upload_preset: this.uploadPreset,
    };

    return this.createCloudinarySignature(signatureOptions).pipe(
      exhaustMap(async (signatureResponse) => {
        let cloudinaryResponse: CloudinaryResponse = { public_id: '' };

        try {
          this.layoutService.setLoadingLocked(true);

          const uniqueUploadId = Date.now().toString(10);

          const formData: FormData = new FormData();

          formData.append('public_id', signatureOptions.public_id);
          formData.append('upload_preset', signatureOptions.upload_preset as string);
          formData.append('api_key', signatureResponse.apiKey);
          formData.set('file', file);

          const headers = new HttpHeaders({
            'X-Unique-Upload-Id': uniqueUploadId,
          });

          cloudinaryResponse = await this.http
            .post<CloudinaryResponse>(`${this.CLOUDINARY_BASE_URL}/image/upload`, formData, { headers })
            .toPromise();
        } finally {
          this.layoutService.setLoadingLocked(false);
        }

        return cloudinaryResponse;
      })
    );
  }

  /**
   * In order to upload large video files of more than 100MB in size to Cloudinary, the files need to be
   * chunked into smaller pieces. The video file is then uploaded in multiple requests until all pieces have
   * successfully been uploaded. The response of the last upload request contains the information to access
   * the uploaded video file.
   *
   * @param file The video file to upload to Cloudinary.
   * @returns The last Cloudinary response containing the public id to access the video file.
   */
  public uploadVideoToCloudinary(file: File): Observable<CloudinaryResponse> {
    const signatureOptions: SignatureOptions = {
      public_id: `${uuid_v4()}_${new Date().getTime()}`,
      eager: 'sp_full_hd/m3u8',
      eager_async: true,
      upload_preset: this.uploadPreset,
    };

    return this.createCloudinarySignature(signatureOptions).pipe(
      exhaustMap(async (signatureResponse) => {
        let cloudinaryResponse: CloudinaryResponse = { public_id: '' };

        try {
          this.layoutService.setLoadingLocked(true);

          const uniqueUploadId = Date.now().toString(10);
          const chunkBoundaries = this.calculateChunkBoundaries(file.size);
          const formData = this.createUploadFormData(signatureOptions, signatureResponse);

          for (const chunkBoundary of chunkBoundaries) {
            formData.set('file', this.getUploadChunk(file, chunkBoundary.start, chunkBoundary.end));
            cloudinaryResponse = await this.uploadChunk(
              formData,
              uniqueUploadId,
              chunkBoundary.start,
              chunkBoundary.end - 1,
              file.size
            );
          }
        } finally {
          this.layoutService.setLoadingLocked(false);
        }

        return cloudinaryResponse;
      })
    );
  }

  public deleteImageFromCloudinary(publicId: string): Observable<void> {
    return this.deleteFromCloudinary('image', publicId);
  }

  public deleteVideoFromCloudinary(publicId: string): Observable<void> {
    return this.deleteFromCloudinary('video', publicId);
  }

  private deleteFromCloudinary(type: 'image' | 'video', publicId: string): Observable<void> {
    const formData: FormData = new FormData();
    return this.createCloudinarySignature({ public_id: publicId }).pipe(
      mergeMap(({ signature, timestamp, apiKey }) => {
        formData.append('public_id', publicId);
        formData.append('timestamp', timestamp.toString());
        formData.append('api_key', apiKey);
        formData.append('signature', signature);
        return this.http.post<void>(`${this.CLOUDINARY_BASE_URL}/${type}/destroy`, formData);
      })
    );
  }

  private createUploadFormData(signatureOptions: SignatureOptions, signatureResponse: SignatureResponse) {
    const formData: FormData = new FormData();

    formData.append('public_id', signatureOptions.public_id);
    formData.append('upload_preset', signatureOptions.upload_preset as string);
    formData.append('timestamp', signatureResponse.timestamp.toString());
    formData.append('api_key', signatureResponse.apiKey);
    formData.append('signature', signatureResponse.signature);
    formData.append('eager', signatureOptions.eager as string);
    formData.append('eager_async', signatureOptions.eager_async ? 'true' : 'false');

    return formData;
  }

  private calculateChunkBoundaries(fileSize: number) {
    const chunkBoundaries: Array<{ start: number; end: number }> = [];
    let chunkStart = 0;
    let chunkEnd = 0;

    while (chunkEnd < fileSize) {
      chunkEnd = chunkStart + this.CHUNK_SIZE;
      if (chunkEnd > fileSize) {
        chunkEnd = fileSize;
      }

      chunkBoundaries.push({ start: chunkStart, end: chunkEnd });

      if (chunkEnd < fileSize) {
        chunkStart += this.CHUNK_SIZE;
      }
    }

    return chunkBoundaries;
  }

  private getUploadChunk(file: FileWithSlices, chunkStart: number, chunkEnd: number): Blob {
    const slice = file['mozSlice']
      ? file['mozSlice']
      : file['webkitSlice']
      ? file['webkitSlice']
      : file.slice
      ? file.slice
      : () => {};

    return slice.bind(file)(chunkStart, chunkEnd) ?? new Blob();
  }

  private async uploadChunk(
    formData: FormData,
    uniqueUploadId: string,
    chunkStart: number,
    chunkEnd: number,
    fileSize: number
  ) {
    const headers = new HttpHeaders({
      'X-Unique-Upload-Id': uniqueUploadId,
      'Content-Range': `bytes ${chunkStart}-${chunkEnd}/${fileSize}`,
    });

    return await this.http
      .post<CloudinaryResponse>(`${this.CLOUDINARY_BASE_URL}/video/upload`, formData, { headers })
      .toPromise();
  }

  private createCloudinarySignature(signatureOptions: SignatureOptions): Observable<SignatureResponse> {
    return this.apiStrategy.getHeaders(undefined, true, true).pipe(
      mergeMap((headers) => {
        return this.http.post<SignatureResponse>(
          this.apiStrategy.getServiceUrl(['cloudinary', 'sign']),
          signatureOptions,
          { headers }
        );
      })
    );
  }

  private getCloudinary(): Cloudinary {
    if (typeof this._cloudinary === 'undefined') {
      this._cloudinary = new Cloudinary({
        cloud: {
          cloudName: this.cloudName,
        },
      });
    }
    return this._cloudinary;
  }
}

interface FileWithSlices extends File {
  mozSlice?: (start: number, end: number) => Blob;
  webkitSlice?: (start: number, end: number) => Blob;
}
