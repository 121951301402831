import { nanoid } from 'nanoid';
import { User } from '../../features/mgmt/user/models/user';

export const assignUniqueIdToDOMElement = (link?: Element): string | undefined => {
  if (!link) {
    return undefined;
  }
  const currentId = link.id;
  if (typeof currentId === 'string' && currentId.length > 0) {
    return currentId;
  }
  let uniqueId: string;
  do {
    uniqueId = nanoid();
    if (document.getElementById(uniqueId)) {
      uniqueId = '';
    }
  } while (!uniqueId);
  link.id = uniqueId;
  return uniqueId;
};

export const convertMentionHTML = (userList: Array<User>, data: string): string => {
  return data?.replace(
    // REGEX to find mentions in HTML
    /<span[^>]+data-mention-value="([^"]+)"\s+data-id="([^"]{36})"[^>]*>(.*\1[^<]*<\/span>)[^<]*<\/span>/gimu,
    (match, name, id) => {
      let fullName = userList.find((user) => user.id === id)?.fullName;
      if (typeof fullName !== 'string' || fullName.length === 0) {
        fullName = name;
      }
      return `<a href="/feed/user-created/${id}" class="dx-mention">@${fullName}</a>`;
    }
  );
};

export const stripCommentPTag = (text: string): string => {
  return `<span>${text
    .trim()
    .replace(/<p><br><\/p>$/imu, '')
    .replace(/^<p>/imu, '')
    .replace(/<\/p>$/imu, '')
    .replace(/^(&nbsp; ?)+/imu, '')
    .replace(/( ?&nbsp;)+$/imu, '')}</span>`;
};

export const stripAllTags = (text: string): string => {
  return text.replace(/(<([^>]+)>)/gi, '').trim();
};

export const extractMentionedUsers = (text: string): Array<User> => {
  const startIdx = 'data-id="'.length;
  const extractLength = 36; // length of UUID
  const matches = text.match(/data-id="([^"]{36})"/gimu);
  if (null === matches) {
    return [];
  } else {
    return matches
      .map((match) => match.substring(startIdx, startIdx + extractLength))
      .reduce<Array<string>>((uniqueUserIds, userId) => {
        if (!uniqueUserIds.includes(userId)) {
          uniqueUserIds.push(userId);
        }
        return uniqueUserIds;
      }, [])
      .map((id) => ({ id } as User));
  }
};
