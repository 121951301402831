<app-loading-bar class="app-loading-bar"></app-loading-bar>

<div class="page-wrapper">
  <!-- Sidebar -->
  <app-sidebar-left></app-sidebar-left>

  <!--- Main Content --->
  <div
    id="content"
    class="active"
    [ngClass]="{ closed: this.sidebarsClosed, left: this.sidebarLeftOpen, right: this.sidebarUserOpen }"
  >
    <header class="header">
      <div class="navbar">
        <div class="container header-container d-flex justify-content-between">
          <div class="d-flex align-items-center">
            <dx-button
              (click)="layoutService.toggleLeftSidebar()"
              icon="fas fa-bars"
              class="float-start"
              style="margin-left: -6px"
            ></dx-button>
          </div>
          <div class="logo-header-container">
            <a routerLink="/feed" [queryParams]="logoQueryParams" class="navbar-brand d-flex">
              <i class="logo-header"></i>
            </a>
          </div>
          <dx-button
            (click)="layoutService.toggleUserSidebar()"
            icon="fas fa-user"
            class="user-icon float-end"
            [template]="'buttonTemplate'"
          >
            <div *dxTemplate="let buttonData of 'buttonTemplate'">
              <i class="fas fa-user" style="font-size: 24px"></i>
            </div>
          </dx-button>
        </div>
      </div>
    </header>

    <main>
      <div id="main-page-wrapper">
        <div id="main-page">
          <router-outlet></router-outlet>
        </div>
      </div>
    </main>

    <app-footer></app-footer>
    <app-bottom-navs></app-bottom-navs>
  </div>

  <!-- Sidebar -->
  <app-sidebar-user></app-sidebar-user>
</div>

<!--<dx-speed-dial-action
  icon="chevronup"
  *ngIf="showUpBtn()"
  (onClick)="toTop()">
</dx-speed-dial-action>-->

<div class="sidebar-overlay" [ngClass]="{ active: this.anySidebarOpen }" (click)="onOverlayClick()"></div>

<app-file-manager
  *ngIf="this.layoutService.isMediaManagerShown | async"
  [enableCrop]="(this.fileManagerService.mediaManagerEnableCrop | async) ?? false"
  [cropAspectRatio]="(this.fileManagerService.mediaManagerCropAspectRatio | async) ?? 0"
  [updateFile]="(this.fileManagerService.mediaManagerUpdateFile | async) ?? false"
  [fileIdToUpdate]="(this.fileManagerService.mediaManagerFileIdToUpdate | async) ?? ''"
  [fileDirectoryId]="(this.fileManagerService.mediaManagerFileDirectoryId | async) ?? ''"
  [fileCategoryType]="(this.fileManagerService.mediaManagerFileCategory | async)!"
  [acceptedFileTypes]="this.fileManagerService.mediaManagerRequestedAcceptedFileTypes | async"
  (selected)="this.fileManagerService.mediaManagerSelectedCallback($event)"
  (fileUploaded)="this.fileManagerService.mediaManagerUploadedCallback($event)"
  [visible]="true"
  (visibleChange)="this.layoutService.isMediaManagerShown.next($event)"
></app-file-manager>

<dx-action-sheet
  *ngIf="this.layoutService.isActionSheetShown | async"
  [width]="
    (this.feedCreateService.target | async) && (this.layoutService.actionSheetUsePopover | async)
      ? (this.layoutService.actionSheetPopoverWidth | async)!
      : undefined
  "
  [title]="(this.feedCreateService.titleText | async)!"
  [cancelText]="(this.feedCreateService.cancelText | async)!"
  [items]="(this.feedCreateService.commands | async)!"
  [target]="(this.feedCreateService.target | async)!"
  [usePopover]="((this.feedCreateService.target | async) && (this.layoutService.actionSheetUsePopover | async))!"
  [visible]="true"
  [showTitle]="true"
  [showCancelButton]="true"
  (onCancelClick)="this.feedCreateService.actionSheetSelectedCallback(0)"
  (visibleChange)="!$event && this.feedCreateService.actionSheetSelectedCallback(0)"
  (onItemClick)="this.feedCreateService.actionSheetSelectedCallback($event.itemData.id)"
  itemTemplate="item"
>
  <div *dxTemplate="let item of 'item'">
    <div *ngIf="item.text">{{ item.text }}</div>
    <dx-button
      [type]="item.default ? 'default' : 'normal'"
      stylingMode="contained"
      class="action-sheet-button"
      *ngIf="item.id"
    >
      {{ item.name }}
    </dx-button>
  </div>
</dx-action-sheet>

<app-popup
  [title]="'reportContent.title' | translate"
  [visible]="(this.layoutService.isReportDialogShown | async)!"
  (visibleChange)="this.layoutService.isReportDialogShown.next($event)"
>
  <app-content-report (closeContentReport)="this.layoutService.isReportDialogShown.next(false)"></app-content-report>
</app-popup>
