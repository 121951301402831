<div id="bottombar" class="mobileonly bottombar">
  <a [routerLink]="['/feed']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
    <i class="fas fa-user"></i>
    <p>Mein Feed</p>
  </a>
  <a
    [routerLink]="['/home']"
    [queryParams]="{ title: 'Feed' }"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <i class="fas fa-user"></i>
    <p>Feed</p>
  </a>
  <a
    [routerLink]="['/home']"
    [queryParams]="{ title: 'Archiv' }"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <i class="fas fa-archive"></i>
    <p>Archiv</p>
  </a>
</div>
