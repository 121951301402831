export enum Platform {
  Browser = 0,
  iOS = 1,
  Android = 2,
}

export enum BridgeNativeErrors {
  NoInternetConnection = 0,
  NoResponseFromServer = 1,
  CouldNotParseResponse = 2,
  InvalidResponse = 4,
  UnknownError = 5,
  NoLoginDataFound = 6,
  MissingConfiguration = 7,
  InvalidRequest = 8,
  InvalidURL = 9,
}

export enum BridgeMethod {
  getToken = 'getToken',
  setUserCredentials = 'setUserCredentials',
  pushGetStatus = 'pushGetStatus',
  pushEnable = 'pushEnable',
  pushDisable = 'pushDisable',
  logout = 'logout',
  openUrl = 'openUrl',
  chooseFile = 'chooseFile',
  sideBarStatus = 'sideBarStatus',
  webReady = 'webReady',
  didGoBack = 'didGoBack',
  shareText = 'shareText',
  showSelection = 'showSelection',
  playVideo = 'playVideo',
  showToS = 'showToS',
  tosEnabled = 'tosEnabled',
}

export enum BridgeCallbackMethod {
  updateToken = 'updateToken',
  loginPending = 'loginPending',
  loggedIn = 'loggedIn',
  loggedOut = 'loggedOut',
  pushStatusCallback = 'pushStatusCallback',
  pushChangeCallback = 'pushChangeCallback',
  openUrlCallback = 'openUrlCallback',
  shareTextCallback = 'shareTextCallback',
  chooseFileCallback = 'chooseFileCallback',
  sideBarStatusCallback = 'sideBarStatusCallback',
  goBack = 'goBack',
  createPost = 'createPost',
  showSelectionCallback = 'showSelectionCallback',
  playVideoCallback = 'playVideoCallback',
  tosEnabledCallback = 'tosEnabledCallback',
  error = 'error',
}

export interface BridgeMethodParameterWithoutCallback {
  params?: { [key: string]: unknown };
  requestId: string;
}

export interface BridgeMethodParameterWithCallback extends BridgeMethodParameterWithoutCallback {
  callbackName: BridgeCallbackMethod;
}

export type BridgeMethodParameter = BridgeMethodParameterWithoutCallback | BridgeMethodParameterWithCallback;

export interface BridgeError {
  message: string;
  code: number;
  restApiError?: {
    message: string;
    code: string;
    requestId: string;
    detail: unknown;
  };
  nativeError?: unknown;
}

export interface BridgeMethodResponse {
  params?: { [key: string]: boolean | string };
  error?: BridgeError;
  requestId?: string;
}

export type DefaultSubscriber<T> = (_?: T, error?: BridgeError) => void;

export interface SelectionInfo {
  id: number;
  default: boolean;
  name: string;
}

export enum VideoType {
  YOUTUBE = 'youtube',
  CLOUDINARY = 'cloudinary',
}
