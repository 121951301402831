import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { confirm } from 'devextreme/ui/dialog';
import { Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MediaFile } from '../models/media-file';
import { FILESIZEVERSION, FileManagerService } from '../services/filemanager.service';

@Component({
  selector: 'app-file-item',
  templateUrl: './file-item.component.html',
})
export class FileItemComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Input() public mediaFile?: MediaFile;
  @Input() public selectedFiles?: MediaFile[];
  @Input() public editMode?: boolean;

  @Output() public itemRemoved = new EventEmitter<MediaFile>();
  @Output() public itemClick = new EventEmitter<{ mediaFile: MediaFile; active: boolean }>();

  public imageData?: ImageData;
  public active = false;

  private fileManagerSubscription?: Subscription;

  constructor(
    private fileManagerService: FileManagerService,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private translate: TranslateService
  ) {}

  public ngOnInit() {
    this.fileManagerSubscription = this.fileManagerService
      .getFileData(this.mediaFile?.id ?? '', FILESIZEVERSION.THUMBNAIL)
      .pipe(catchError(() => this.fileManagerService.getFileData(this.mediaFile?.id ?? '', FILESIZEVERSION.AVATAR)))
      .subscribe({
        next: (result) => {
          const objectURL = URL.createObjectURL(result as Blob);
          (<SafeUrl>this.imageData) = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          if (!this.mediaFile?.imageData) return;
          this.mediaFile.imageData = result as Blob;
        },
      });
  }

  public ngOnDestroy() {
    this.fileManagerSubscription?.unsubscribe();
  }

  public ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  public isActive(currentSelection: MediaFile[]) {
    this.active = currentSelection.filter((selection) => selection.id === this.mediaFile?.id).length > 0;
    return this.active;
  }

  public onClick() {
    if (this.editMode) {
      const confirmation = confirm(
        this.translate.instant('general.confirm_text'),
        this.translate.instant('general.confirm_title')
      );
      confirmation.then((dialogResult) => {
        if (dialogResult) {
          if (this.mediaFile?.id) {
            this.fileManagerService.delete(this.mediaFile.id).subscribe(() => {
              this.itemRemoved.emit(this.mediaFile);
            });
          }
        }
      });
    } else {
      this.active = !this.active;
      if (this.mediaFile) {
        this.itemClick.emit({ mediaFile: this.mediaFile, active: this.active });
      }
    }
  }
}
