<app-base-accordion-panel [panel]="this">
  <div class="row">
    <div class="col-lg-4">
      <div *ngIf="this.entity">
        <dx-form
          *appReloadOnLangChange
          [readOnly]="panelMode === modeType.Read"
          [showColonAfterLabel]="true"
          [(formData)]="this.formData"
          (onFieldDataChanged)="onFieldChange($event)"
          (onEditorEnterKey)="save(safeType, $event)"
        >
          <dxi-item
            editorType="dxSelectBox"
            dataField="language.id"
            [editorOptions]="{
              items: languages,
              displayExpr: 'iso',
              valueExpr: 'id',
              selectionMode: 'single',
              showSelectionControls: true,
              showTitle: false,
              placeholder: 'general.select' | translate
            }"
          >
            <dxo-label [text]="'labels.language' | translate"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'error.input_required' | translate }}">
            </dxi-validation-rule>
          </dxi-item>
        </dx-form>
      </div>
    </div>
  </div>
</app-base-accordion-panel>
