import { NappEnvironmentConfiguration } from '~/app/config/environments-configuration';

export const DEMO_NAPLEON_ENVIRONMENT_CONFIGURATION: NappEnvironmentConfiguration = {
  environmentName: 'demo',
  appBaseUrl: 'demo.napp.ch',
  title: 'SOFTWAREHAUS 08EINS',
  theme: 'default',
  defaultLangISO: 'de',
  api: {
    clientId: 'AulX7u8l4M',
    clientSecret: 'd9dc852e6cf8726c77e0e91d3015d5b760aab6a2bf39a519e8ea11145feb5411',
    baseServiceUrl: 'https://demo.napp.ch/api',
  },
  captchaSiteKey: '6LcBwwAaAAAAACH1ps_5VTejkI6peVVDVu_8efUz',
  analyticsUrl: 'https://console.firebase.google.com/project/napoleon-c6fd5/overview',
  appUrl: 'https://demo.napp.ch',
  footer: {
    customer: '08EINS AG SOFTWAREHAUS',
    phoneNr: '+41 81 252 30 30',
    website: 'www.softwarehaus.net',
    email: 'info@softwarehaus.net',
    instagram: 'https://www.instagram.com/softwarehaus_08eins/',
    facebook: 'https://www.facebook.com/08eins/',
    linkedIn: 'https://www.linkedin.com/company/softwarehaus/',
    type: 'default',
  },
  availableModules: {
    feed: true,
    document: true,
    shortener: true,
    magazine: false,
    event: false,
    agenda: true,
    employees: true,
  },
  availableFeatures: {
    feed: {
      location: true,
      showPostType: true,
      approve: true,
      allowVideoUpload: true,
      mention: true,
      report: false,
    },
    header: {
      logoRedirectMyFeed: true,
    },
    push: {
      autoEnable: true,
      unregisteredDevices: true,
    },
    employee: {
      showFirstname: true,
      showLastname: true,
      showLocation: true,
      showDepartment: true,
      showFunctionality: true,
    },
  },
  cloudinary: {
    cloudName: 'cloud-08eins',
    uploadPreset: 'qxds6qrd',
    prefix: 'softwarehaus-demo',
  },
  appleAppID: '1506784322',
};
