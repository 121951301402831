import { DOCUMENT, Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ENVIRONMENT_CONFIGURATION } from '@softwarehaus/util-configuration';
import config from 'devextreme/core/config';
import repaintFloatingActionButton from 'devextreme/ui/speed_dial_action/repaint_floating_action_button';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { NappEnvironmentConfiguration } from '~/app/config/environments-configuration';
import { getNumericCssVariables } from '~/app/shared/helpers/cssVariables';
import { AppAngularBridge } from '../../app-bridge';
import { FileManagerService } from '../../shared/components/filemanager/services/filemanager.service';
import { FeedCreateService } from '../../shared/services/feed.create.service';
import { AuthService } from '../auth/authentication.service';
import { LayoutService } from '../services/store/layout.service';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',
})
export class FullLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('logoIcon', { static: false }) public logoIcon?: ElementRef;

  public anySidebarOpen = false;
  public sidebarsClosed = true;
  public sidebarLeftOpen = false;
  public sidebarUserOpen = false;
  public logoQueryParams: { feedtype?: 'subscribed' } = {};

  private authSubscription?: Subscription;
  private leftSubscription?: Subscription;
  private userSubscription?: Subscription;
  private updateFloatingActionButtonTimeout?: ReturnType<typeof setTimeout>;
  private sab = 0;
  private sar = 0;

  constructor(
    public layoutService: LayoutService,
    public fileManagerService: FileManagerService,
    public feedCreateService: FeedCreateService,
    private authService: AuthService,
    private router: Router,
    private location: Location,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    @Inject(ENVIRONMENT_CONFIGURATION) private config: NappEnvironmentConfiguration
  ) {}

  public ngOnInit() {
    this.authSubscription = this.authService
      .isLoggedIn()
      .pipe(distinctUntilChanged())
      .subscribe((isLoggedIn) => {
        this.scheduleFloatingActionButtonUpdate();
        if (isLoggedIn && this.config.availableFeatures.header.logoRedirectMyFeed) {
          this.logoQueryParams = { feedtype: 'subscribed' };
        } else {
          this.logoQueryParams = {};
        }
      });
    this.leftSubscription = this.layoutService.showLeftSidebarSubject.subscribe((next) => {
      this.sidebarLeftOpen = next;
      this.setSidebarStatus();
    });
    this.userSubscription = this.layoutService.showUserSidebarSubject.subscribe((next) => {
      this.sidebarUserOpen = next;
      this.setSidebarStatus();
    });

    this.updateFloatingActionButton(true);
    this.layoutService.onDxLayoutChanged.subscribe(() => {
      this.updateFloatingActionButton();
    });
  }

  public ngAfterViewInit() {
    setTimeout(() => {
      this.layoutService.setLoadingLocked(false);
      AppAngularBridge.webReady();
    }, 0);
  }

  @HostListener('window:click', ['$event']) public onWindowClick(event: MouseEvent) {
    const element = event.target as Element;
    // needed for context menu in the left side navigation
    // as soon as the user clicks outside the context menu
    // close the context menu (if there's one open of course)
    if (!(element.classList.contains('mmenu-site-actions') || element.classList.contains('mm-more-icons'))) {
      const menuListItems = document.querySelectorAll('li.mm-listitem');
      // Array.prototype.forEach.call needed for IE 11, see: https://developer.mozilla.org/en-US/docs/Web/API/NodeList#Example
      Array.prototype.forEach.call(menuListItems, (menuItem) => {
        const actionDivs = menuItem.querySelectorAll('.mmenu-site-actions');
        Array.prototype.forEach.call(actionDivs, (div) => {
          div.parentNode.removeChild(div);
        });
      });
    }
  }

  public showUpBtn() {
    return window.pageYOffset > 50;
  }

  public toTop() {
    window.scrollTo(0, 0);
  }

  public goBack() {
    this.location.back();
  }

  public goToHome() {
    this.layoutService.triggerLeftMenuReload.next(true);
    this.router.navigate(['/']);
  }

  public onOverlayClick() {
    if (this.sidebarLeftOpen) {
      this.layoutService.toggleLeftSidebar(false);
      this.renderer.removeClass(this.logoIcon?.nativeElement, 'animate');
    } else if (this.sidebarUserOpen) {
      this.layoutService.toggleUserSidebar(false);
    }
    this.layoutService.scrollHandling();
  }

  public ngOnDestroy() {
    this.authSubscription?.unsubscribe();
    this.leftSubscription?.unsubscribe();
    this.userSubscription?.unsubscribe();
  }

  private setSidebarStatus() {
    this.anySidebarOpen = this.sidebarLeftOpen || this.sidebarUserOpen;
    this.sidebarsClosed = !this.anySidebarOpen;
    this.scheduleFloatingActionButtonUpdate();
  }

  private scheduleFloatingActionButtonUpdate() {
    if (this.updateFloatingActionButtonTimeout) {
      clearTimeout(this.updateFloatingActionButtonTimeout);
    }
    this.updateFloatingActionButtonTimeout = setTimeout(this.updateFloatingActionButton.bind(this, true), 200);
  }

  private updateFloatingActionButton(init = false) {
    const keys = ['sab', 'sar'] as const;
    const safeArea: { [x in (typeof keys)[number]]: number } = getNumericCssVariables(window, this.document, keys);
    if (init || safeArea.sar !== this.sar || safeArea.sab !== this.sab) {
      this.sar = safeArea.sar;
      this.sab = safeArea.sab;
      config({
        floatingActionButtonConfig: {
          icon: 'more',
          position: {
            my: 'right bottom',
            at: 'right bottom',
            offset: {
              x: -1 * (20 + this.sar),
              y: -1 * (100 + this.sab),
            },
          },
        },
      });
      repaintFloatingActionButton();
    }
  }
}
