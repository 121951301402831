import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DxValidationGroupComponent } from 'devextreme-angular';
import { User } from '../../features/mgmt/user/models/user';

@Component({
  selector: 'app-single-input',
  template: `
    <dx-validation-group #singleInputVG>
      <div class="dx-field mb-2">
        <div class="dx-field-value">
          <app-html-editor-dx
            *ngIf="mentionUsers"
            [data]="fieldValue"
            (dataChange)="fieldValue = $event"
            [inline]="true"
            [placeholder]="'feed.writeCommentPlaceholder' | translate"
            [mentionUsers]="mentionUsers"
            style="display: block; width: 100%;"
          ></app-html-editor-dx>
          <dx-text-box [(value)]="fieldValue" [ngStyle]="mentionUsers ? { visibility: 'hidden' } : {}">
            <dx-validator>
              <dxi-validation-rule type="required"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
      </div>
      <div class="dx-field button-bar">
        <app-button class="small" [type]="'default'" [stylingMode]="'contained'" (buttonClick)="onSave()">{{
          'general.buttons.save' | translate
        }}</app-button>
        <dx-button class="small" [type]="'normal'" [stylingMode]="'text'" (onClick)="onCancel()">{{
          'general.buttons.cancel' | translate
        }}</dx-button>
      </div>
    </dx-validation-group>
  `,
})
export class SingleInputComponent {
  @Input() public fieldValue!: string;
  @Input() public mentionUsers?: Array<User>;

  @Output() public cancel = new EventEmitter();
  @Output() public save = new EventEmitter();

  @ViewChild('singleInputVG', { static: false }) public singleInputVG?: DxValidationGroupComponent;

  constructor() {}

  public onSave() {
    if (!this.singleInputVG) return;
    if (this.singleInputVG.instance.validate().isValid) {
      this.save.emit(this.fieldValue);
    }
  }

  public onCancel() {
    this.cancel.emit();
  }
}
