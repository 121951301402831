import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DxFormComponent } from 'devextreme-angular';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subscription } from 'rxjs';
import { AppAngularBridge } from '~/app/app-bridge';
import { AuthService } from '~/app/core/auth/authentication.service';
import { MessageService, TOASTTYPE } from '~/app/core/services/message.service';
import { LayoutService } from '~/app/core/services/store/layout.service';
import { getTimezoneOffsetInValidPattern } from '~/app/shared/helpers/timezone';

@Component({
  selector: 'app-domain-login',
  templateUrl: './domain-login.component.html',
})
export class DomainLoginComponent implements OnInit, OnDestroy {
  @Input() public isVisible = false;
  @ViewChild(DxFormComponent) public form?: DxFormComponent;

  // @HostBinding('class.right-menu') public addClass = false;

  public success = false;
  public pending = false;
  public captchaToken: string | null = null;
  public disableKeyBoardFix = false;
  public caption: string | null = null;

  private loginPendingSubscription?: Subscription;
  private layoutSubscription?: Subscription;
  private recaptchaSubscription?: Subscription;

  constructor(
    public authService: AuthService,
    private router: Router,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private layoutService: LayoutService
  ) {}

  public ngOnInit() {
    this.translate.get('login.caption').subscribe((r) => {
      this.caption = r;
    });

    // if confirmation link exist
    const confirmationSession = this.route.snapshot.queryParamMap.get('s');
    if (confirmationSession !== null && !this.authService.confirmed) {
      this.authService.domainConfirmation(confirmationSession).subscribe(() => {
        this.messageService.showToast(TOASTTYPE.SUCCESS, this.translate.instant('register.accountActivated'));
      });
      this.authService.confirmed = true;
      this.router.navigate(['/']);
    }

    this.loginPendingSubscription = this.authService.loginPending.subscribe((pending) => (this.pending = pending));

    if (AppAngularBridge.isNative()) {
      this.layoutSubscription = this.layoutService.showUserSidebarSubject.subscribe((isOpen) => {
        if (this.isVisible) {
          if (isOpen) {
            this.disableKeyBoardFix = true;
          }
          AppAngularBridge.sideBarStatus(isOpen).subscribe({
            next: () => {
              if (isOpen) {
                this.disableKeyBoardFix = false;
              }
            },
            error: () => {
              this.disableKeyBoardFix = false;
            },
          });
        }
      });
    }
  }

  public ngOnDestroy() {
    this.loginPendingSubscription?.unsubscribe();
    this.layoutSubscription?.unsubscribe();
    this.recaptchaSubscription?.unsubscribe();
  }

  public resolved(captchaResponse: string) {
    this.captchaToken = captchaResponse;
  }

  public login() {
    if (!this.form) return;

    if (this.form.instance.validate().isValid) {
      if (AppAngularBridge.isNative()) {
        this.execLogin();
      } else {
        this.recaptchaSubscription = this.recaptchaV3Service.execute('login').subscribe({
          next: (token) => {
            this.captchaToken = token;
            this.execLogin();
          },
          error: (error) => {
            this.captchaToken = null;
            this.messageService.showToast(
              TOASTTYPE.ERROR,
              this.translate.instant('error.get_captcha_token_error', { error })
            );
          },
        });
      }
    } else {
      this.messageService.showToast(TOASTTYPE.ERROR, this.translate.instant('message.error.missingFormFields'));
    }
  }

  private execLogin() {
    if (this.recaptchaSubscription) {
      this.recaptchaSubscription.unsubscribe();
    }
    const params = {
      username: this.form?.instance.option('formData').email,
      captchaToken: AppAngularBridge.isNative() ? 'null' : this.captchaToken,
      timezone: getTimezoneOffsetInValidPattern(),
      isApp: AppAngularBridge.getPlatform(),
    };
    this.authService.domainLogin(params).subscribe(() => {
      this.success = true;
    });
  }
}
