import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ENVIRONMENT_CONFIGURATION } from '@softwarehaus/util-configuration';
import { ValueChangedEvent } from 'devextreme/ui/select_box';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import { NappEnvironmentConfiguration } from '~/app/config/environments-configuration';
import { JSON } from '~/app/core/JSON.interface';
import { getTimezoneOffsetInValidPattern } from '~/app/shared/helpers/timezone';
// import { environment } from '../../../environments/environment';
import { AppAngularBridge } from '../../app-bridge';
import { StorageConfig } from '../../config/storage.config';
import { FeedService, FeedTypes } from '../../features/feed/services/feed.service';
import { ProfileConfig } from '../account/profile/models/profile-config';
import { ProfileConfigService } from '../account/profile/services/profile-config.service';
import { AuthService } from '../auth/authentication.service';
import { PermissionService } from '../auth/permission.service';
import { ReportContentService } from '../services/backend/report-content.service';
import { MessageService, TOASTTYPE } from '../services/message.service';
import { LayoutService } from '../services/store/layout.service';
import { AppTranslationService } from '../services/translation/app-translation.service';

@Component({
  selector: 'app-sidebar-user',
  templateUrl: './sidebar-user.component.html',
})
export class SidebarUserComponent implements OnInit, OnDestroy {
  public languages: string[] = [];
  public selectedLanguage = this.languages[0];
  public langMenuOpenend = false;
  public feedTypes = FeedTypes;
  public isLoggedIn = false;
  public open = false;
  public notApprovedFeedPostCount = 0;
  public contentReportsCount = 0;
  public analyticsUrl?: string;
  public showApprovedEntry: boolean;
  public canViewNonApprovedEntries = false;
  public showContentReportEntry: boolean;
  public canViewContentReports = false;

  private layoutSubscription?: Subscription;
  private authSubscription?: Subscription;
  private configSubscription?: Subscription;
  private langStoredSubscription?: Subscription;
  private translationSubscription?: Subscription;

  constructor(
    public authService: AuthService,
    public layoutService: LayoutService,
    public permissionService: PermissionService,
    public appTranslationService: AppTranslationService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private profileConfigService: ProfileConfigService,
    private feedService: FeedService,
    private reportContentService: ReportContentService,
    @Inject(ENVIRONMENT_CONFIGURATION) config: NappEnvironmentConfiguration
  ) {
    this.analyticsUrl = config.analyticsUrl;
    this.showApprovedEntry = config.availableFeatures.feed.approve;
    this.showContentReportEntry = config.availableFeatures.feed.report;
  }

  public ngOnInit() {
    this.langStoredSubscription = this.appTranslationService.hasLanguagesStored
      .pipe(filter((value) => value))
      .subscribe(() => {
        this.setUpLangs();
      });
    if (this.appTranslationService.hasStoredLanguages()) {
      this.setUpLangs();
    }

    this.updateCapabilities();

    this.layoutSubscription = this.layoutService.showUserSidebarSubject.subscribe((next) => {
      this.open = next;
      if (this.open) {
        setTimeout(() => {
          this.authService
            .isLoggedIn()
            .pipe(take(1))
            .subscribe({
              next: (isLoggedIn) => {
                if (isLoggedIn) {
                  this.getNotApprovedFeedPostCount();
                  this.getContentReportsCount();
                }
              },
            });
        }, 0);
      } else {
        this.langMenuOpenend = this.open;
      }
    });
    this.authSubscription = this.authService
      .isLoggedIn()
      .pipe(distinctUntilChanged())
      .subscribe((isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
        this.adjustLoginStatus();
      });
  }

  public ngOnDestroy() {
    this.layoutSubscription?.unsubscribe();
    this.authSubscription?.unsubscribe();
    this.translationSubscription?.unsubscribe();
    this.langStoredSubscription?.unsubscribe();
    this.configSubscription?.unsubscribe();
  }

  public logout() {
    this.authService.logout().subscribe({
      next: () => {
        this.messageService.showToast(TOASTTYPE.SUCCESS, this.translateService.instant('logout.success'));
        this.close();
      },
    });
  }

  public openScanner() {
    this.layoutService.toggleUserSidebar(false);
    window.location.href = 'app://qrscanner';
    this.layoutService.scrollHandling();
  }

  public close() {
    this.authService.resetPasswordUser = false;
    this.authService.registrateUser = false;
    this.layoutService.toggleUserSidebar(false);
    this.layoutService.scrollHandling();
  }

  public changePushSetting(status: boolean) {
    let pushChangeObservable: Observable<boolean>;
    if (status) {
      pushChangeObservable = (AppAngularBridge.enablePush() as Observable<boolean>) ?? false;
    } else {
      pushChangeObservable = (AppAngularBridge.disablePush() as Observable<boolean>) ?? false;
    }
    if (pushChangeObservable) {
      pushChangeObservable.subscribe((value) => {
        this.authService.pushStatus = value;
      });
    }
  }

  public feedback() {
    this.layoutService.isFeedbackShown.next(true);
  }

  public damageReport() {
    this.layoutService.isDamageReportShown.next(true);
  }

  public onLanguageSelect(event: ValueChangedEvent) {
    this.appTranslationService.setAppTranslation(event.value.toLowerCase()).subscribe(() => {
      this.messageService.showToast(TOASTTYPE.SUCCESS, this.translateService.instant('general.langs.changed'));
      this.layoutService.triggerLeftMenuReload.next(true);
    });
  }

  public displayUser(): string {
    if (
      localStorage.getItem(StorageConfig.USER_FIRSTNAME) === 'undefined' &&
      localStorage.getItem(StorageConfig.USER_FIRSTNAME) !== null
    ) {
      return '';
    }
    return localStorage.getItem(StorageConfig.USER_FIRSTNAME) + ' ' + localStorage.getItem(StorageConfig.USER_LASTNAME);
  }

  public isNative() {
    return AppAngularBridge.isNative();
  }

  private checkPushStatus() {
    const pushStatusObservable = AppAngularBridge.getPushStatus();
    if (pushStatusObservable) {
      pushStatusObservable.subscribe((value) => (this.authService.pushStatus = value ?? false));
    }
  }

  private setUpLangs() {
    this.languages = [];
    this.appTranslationService.getStoredLanguages().forEach((items) => {
      if (items.active) {
        this.languages.push(items.iso.toUpperCase());
      }
    });

    const lang = this.appTranslationService.getCurrentLanguage();
    if (lang) {
      this.selectedLanguage = lang.iso.toUpperCase();
    } else {
      this.selectedLanguage = this.languages[0];
    }

    if (!this.translationSubscription) {
      this.translationSubscription = this.appTranslationService.languageChanged.subscribe(() => {
        this.selectedLanguage = this.appTranslationService.getCurrentLanguage().iso.toUpperCase();
      });
    }
  }

  private adjustLoginStatus() {
    this.updateCapabilities();

    if (this.isLoggedIn) {
      this.getNotApprovedFeedPostCount();
      this.getContentReportsCount();

      if (AppAngularBridge.isNative()) {
        this.checkPushStatus();
      }
      this.layoutService.toggleUserSidebar(false);
      this.layoutService.toggleLeftSidebar(false);

      const profileConfigLoaded = (userConfig: ProfileConfig[]) => {
        this.configSubscription?.unsubscribe();
        if (userConfig.length > 0) {
          const userSetLangId = userConfig[0].language?.id;
          if (userSetLangId) {
            const storedLang = this.appTranslationService.getLanguageById(userSetLangId);
            let source: Observable<JSON>;
            if (storedLang) {
              source = this.appTranslationService.setAppTranslation(storedLang.iso);
            } else {
              source = this.appTranslationService.setAppTranslationUserPreferredLang();
            }
            source.pipe(take(1)).subscribe();
          }
        }
      };

      this.configSubscription = this.profileConfigService.fetchAll().subscribe({
        next: profileConfigLoaded,
        error: (error) => {
          this.configSubscription?.unsubscribe();
          if (error?.error?.code === 'userconfig_not_found') {
            const profileConfig = new ProfileConfig(
              getTimezoneOffsetInValidPattern(),
              this.appTranslationService.getCurrentLanguage()
            );
            this.configSubscription = this.profileConfigService
              .post(profileConfig)
              .subscribe((result) => profileConfigLoaded([result]));
          }
        },
      });
    }
  }

  private updateCapabilities() {
    this.canViewNonApprovedEntries =
      this.isLoggedIn &&
      this.showApprovedEntry &&
      this.permissionService.checkScopes(['mgmt.feedpost.approve']) &&
      this.permissionService.checkScopes(['mgmt.feedpost.update']);

    this.canViewContentReports =
      this.isLoggedIn &&
      this.showContentReportEntry &&
      this.permissionService.checkScopes(['mgmt.reportcontent.read']) &&
      this.permissionService.checkScopes(['mgmt.reportcontent.update']);
  }

  private getNotApprovedFeedPostCount() {
    if (!this.canViewNonApprovedEntries) {
      return;
    }

    this.feedService.getNotApprovedCount().subscribe((data) => {
      this.notApprovedFeedPostCount = data.count;
    });
  }

  private getContentReportsCount() {
    if (!this.canViewContentReports) {
      return;
    }

    this.reportContentService.getContentReportCount().subscribe((data) => {
      this.contentReportsCount = data.count;
    });
  }
}
