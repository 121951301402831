import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { CardBodyDirective } from './card-body/card-body.directive';
import { CardFooterDirective } from './card-footer/card-footer.directive';
import { CardHeaderDirective } from './card-header/card-header.directive';

@Component({
  selector: 'napp-card',
  template: `
    <div class="card">
      <napp-card-header
        [headerImgPath]="headerImgPath"
        [headerStickerText]="headerStickerText"
        [headerTemplate]="cardHeaderTemplate"
      >
      </napp-card-header>
      <napp-card-body
        [showUserInfo]="showUserInfo"
        [userImg]="userImgPath"
        [publicationDate]="publicationDate"
        [datePipe]="datePipe"
        [userName]="userName"
        [cardText]="cardText"
        [cardTitle]="cardTitle"
        [bodyTemplate]="cardBodyTemplate"
        [isBookmarkActive]="isBookmarkActive"
        [showBookmark]="showBookmark"
        [showShare]="showShare"
        (share)="share.emit($event)"
        (bookmark)="bookmark.emit($event)"
      >
      </napp-card-body>
      <napp-card-footer
        [btnText]="btnText"
        [btnType]="btnType"
        [btnMode]="btnMode"
        [footerTemplate]="cardFooterTemplate"
        (openFeed)="openFeed.emit($event)"
      >
      </napp-card-footer>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./card.component.scss'],
  host: {
    class: 'napp-card',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent implements OnInit {
  /**
   * Text to display in card body.
   */
  @Input() cardText: string = '';

  /**
   * Title for the card body.
   */
  @Input() cardTitle: string = '';

  /**
   * Url for image in card header
   */
  @Input() headerImgPath: string = '';

  /**
   * Url for image in body (small user image)
   */
  @Input() userImgPath: string = '';

  /**
   * Text for the sticker (top left) in the card header
   */
  @Input() headerStickerText: string = '';

  /**
   * Username/Creator of the ontent of the card
   */
  @Input() userName: string = '';

  /**
   * When the news/feed or other is created/published
   */
  @Input() publicationDate: Date | undefined;

  /**
   * Date pipe how the @publicationDate needs to be displayed
   */
  @Input() datePipe: string = '';

  /**
   * Button mode, see documentation of DevExpress for more detailed information
   *
   * Example: 'contained', 'outlined', 'text'
   */
  @Input() btnMode: string = '';

  /**
   * Button type, see documentation of DevExpress for more detailed information
   *
   * Example: 'normal', 'success', 'default', 'danger'
   */
  @Input() btnType: string = '';

  /**
   * Text for the button to be displayed
   */
  @Input() btnText: string = '';

  /**
   * Boolean value, if user info should be displayed. <div> in center with user image, username and publication date
   */
  @Input() showUserInfo: boolean = true;

  /**
   * Boolean value, if bookmark icon is active (change color)
   */
  @Input() isBookmarkActive: boolean = false;

  /**
   * Boolean value, if bookmark icon should be displayed.
   */
  @Input() showBookmark: boolean = true;

  /**
   * Boolean value, if share icon should be displayed.
   */
  @Input() showShare: boolean = true;

  /**
   * Output event when user want's to bookmark the content of the card
   */
  @Output() bookmark: EventEmitter<any> = new EventEmitter();

  /**
   * Output event when user want's to share the content of the card
   */
  @Output() share: EventEmitter<any> = new EventEmitter();

  /**
   * Output event when user want's to open the content of the card
   */
  @Output() openFeed: EventEmitter<any> = new EventEmitter();

  @ContentChild(CardFooterDirective)
  cardFooterTemplate!: CardFooterDirective;

  @ContentChild(CardHeaderDirective)
  cardHeaderTemplate!: CardHeaderDirective;

  @ContentChild(CardBodyDirective)
  cardBodyTemplate!: CardBodyDirective;

  constructor() {}

  ngOnInit() {}
}
