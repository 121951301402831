import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService, IServiceUrlConfig } from '../../../core/services/base/base.service';
import { MediaDirectory } from '../models/media-directory';

@Injectable({
  providedIn: 'root',
})
export class MediaDirectoryService extends BaseService<MediaDirectory> {
  constructor(public override http: HttpClient) {
    super(http, MediaDirectory);
  }

  protected getServiceUrl(entityId?: string, serviceUrlConfig?: IServiceUrlConfig): string {
    const url = this.generateServiceURL('media-directory', entityId, serviceUrlConfig);
    return this.apiStrategy.getServiceUrl(url);
  }
}
