import { assign } from 'lodash';

export abstract class BaseEntity<T> {
  constructor(public id?: string) {}

  /**
   *  deserializes an generic object into the given class object
   */
  public deserialize(object: T): this {
    assign(this, object);
    return this;
  }
}
