<div id="sidebar-right" class="closed" [ngClass]="{ closed: !this.open }">
  <div class="top-bar">
    <dx-button
      id="close-icon"
      [type]="'text'"
      [stylingMode]="'text'"
      (click)="close()"
      icon="fas fa-times"
      class="float-start"
    ></dx-button>
    <div class="logo-bg">
      <app-profile-image [class.animate]="this.open"></app-profile-image>
    </div>
    <div class="profile-container" *ngIf="this.isLoggedIn && !authService.registrateUser">
      <div class="profile">
        {{ displayUser() }}
      </div>
    </div>
    <div class="language-selector-container">
      <dx-select-box
        *ngIf="languages.length > 1"
        id="custom-templates"
        stylingMode="underlined"
        [items]="languages"
        [(value)]="selectedLanguage"
        (onValueChanged)="onLanguageSelect($event)"
        [(opened)]="langMenuOpenend"
      ></dx-select-box>
    </div>
  </div>

  <!--  <div class="right-menu" *ngIf="!this.isLoggedIn && !authService.registrateUser && !authService.resetPasswordUser">-->
  <!--    <dx-tab-panel #authTabPanel-->
  <!--                  class="sidebar-right-content-no-scroll"-->
  <!--                  [height]="'100vh'"-->
  <!--                  [dataSource]="['employee', 'administrator']"-->
  <!--                  [selectedIndex]="0"-->
  <!--                  [loop]="false"-->
  <!--                  [animationEnabled]="true"-->
  <!--                  [showNavButtons]="true"-->
  <!--                  [swipeEnabled]="false">-->
  <!--      <div *dxTemplate="let item of 'title'">-->
  <!--        <span>{{'login.types.'+item | translate}}</span>-->
  <!--      </div>-->
  <!--      <div *dxTemplate="let item of 'item'">-->
  <!--        <dx-scroll-view [useNative]="true">-->
  <!--          <div class="tabpanel-item">-->
  <!--            <ng-container *ngIf="item === 'employee'">-->
  <!--              <app-domain-login [isVisible]="authTabPanel.selectedItem === item"></app-domain-login>-->
  <!--            </ng-container>-->
  <!--            <ng-container *ngIf="item === 'administrator'">-->
  <!--              <app-login [isVisible]="authTabPanel.selectedItem === item"></app-login>-->
  <!--            </ng-container>-->
  <!--          </div>-->
  <!--        </dx-scroll-view>-->
  <!--      </div>-->
  <!--    </dx-tab-panel>-->
  <!--  </div>-->
  <app-login *ngIf="!this.isLoggedIn && !authService.registrateUser && !authService.resetPasswordUser"></app-login>
  <app-registration *ngIf="authService.registrateUser"></app-registration>
  <app-reset-password *ngIf="authService.resetPasswordUser"></app-reset-password>

  <div *ngIf="this.isLoggedIn && !authService.registrateUser" class="right-menu">
    <div class="sidebar-right-content">
      <ul class="list-view">
        <li class="list-item">
          <a class="text" (click)="close()" [routerLink]="['/account', 'profile']">{{
            'general.navigation.profile' | translate
          }}</a>
        </li>
        <li class="list-item">
          <a class="text" (click)="close()" [routerLink]="['/feed', 'subscriptions']">{{
            'general.navigation.abo' | translate
          }}</a>
        </li>
        <li class="list-item">
          <a
            class="text"
            (click)="close()"
            [routerLink]="['/feed']"
            [queryParams]="{ feedtype: feedTypes.BOOKMARKED }"
            routerLinkActive="active"
            >{{ 'general.navigation.favourit' | translate }}</a
          >
        </li>
        <li class="list-item">
          <a
            class="text"
            (click)="close()"
            [routerLink]="['/feed']"
            [queryParams]="{ feedtype: feedTypes.CREATED }"
            routerLinkActive="active"
            *ngIf="this.permissionService.checkScopes(['mgmt.feedpost.create', 'feedpost.create'])"
            >{{ 'general.navigation.own' | translate }}</a
          >
        </li>
        <li class="list-item" *ngIf="isNative() && authService.pushStatus === false">
          <a class="text" (click)="changePushSetting(true)">{{ 'general.navigation.push.activate' | translate }}</a>
        </li>
        <li class="list-item" *ngIf="isNative() && authService.pushStatus === true">
          <a class="text" (click)="changePushSetting(false)">{{ 'general.navigation.push.deactivate' | translate }}</a>
        </li>
      </ul>
      <ul class="list-view separator" *ngIf="canViewNonApprovedEntries || canViewContentReports">
        <li class="list-item" *ngIf="canViewNonApprovedEntries">
          <a
            class="text"
            (click)="close()"
            [routerLink]="['/feed']"
            [queryParams]="{ feedtype: feedTypes.NOT_APPROVED }"
            routerLinkActive="active"
          >
            {{ 'general.navigation.not_approved_posts' | translate }}
            <span class="badge">{{ notApprovedFeedPostCount }}</span>
          </a>
        </li>
        <li class="list-item" *ngIf="canViewContentReports">
          <a class="text" (click)="close()" [routerLink]="['/mgmt', 'report-content']" routerLinkActive="active">
            {{ 'general.navigation.content_reports' | translate }} <span class="badge">{{ contentReportsCount }}</span>
          </a>
        </li>
      </ul>
      <ul
        class="list-view separator"
        *ngIf="
          this.permissionService.checkScopes([
            'mgmt.tag.read',
            'mgmt.account.read',
            'mgmt.user.read',
            'mgmt.event.read',
            'mgmt.role.read',
            'mgmt.scope.read',
            'mgmt.context.read',
            'mgmt.attribute.read',
            'damagereport.create.hide_at_the_moment',
            'mgmt.analytics.read'
          ])
        "
      >
        <li class="list-item">
          <a
            class="text"
            [routerLink]="['/mgmt/tag']"
            (click)="close()"
            *ngIf="this.permissionService.checkScopes(['mgmt.tag.read'])"
            ><i class="fa fa-tags"></i> {{ 'tag.mgmt.navLabel' | translate }}</a
          >
        </li>
        <li class="list-item">
          <a
            class="text"
            [routerLink]="['/mgmt/account']"
            (click)="close()"
            *ngIf="this.permissionService.checkScopes(['mgmt.account.read'])"
            ><i class="fa fa-cube"></i> {{ 'account.navLabel' | translate }}</a
          >
        </li>
        <li class="list-item">
          <a
            class="text"
            [routerLink]="['/mgmt/user']"
            (click)="close()"
            *ngIf="this.permissionService.checkScopes(['mgmt.user.read'])"
            ><i class="fa fa-users"></i> {{ 'user.mgmt.navLabel' | translate }}</a
          >
        </li>
        <!--- <li class="list-item"><a class="text" [routerLink]="['/mgmt/event']" (click)="close()" *ngIf="this.permissionService.checkScopes(['mgmt.event.read'])"><i class="fa fa-users"></i> Events</a></li> --->
        <li class="list-item">
          <a
            class="text"
            [routerLink]="['/mgmt/client']"
            (click)="close()"
            *ngIf="this.permissionService.checkScopes(['mgmt.client.read'])"
            ><i class="fa fa-id-badge"></i> {{ 'client.navLabel' | translate }}</a
          >
        </li>
        <li class="list-item">
          <a
            class="text"
            [routerLink]="['/mgmt/role']"
            (click)="close()"
            *ngIf="this.permissionService.checkScopes(['mgmt.role.read'])"
            ><i class="fa fa-user-lock"></i> {{ 'role.navLabel' | translate }}</a
          >
        </li>
        <li class="list-item">
          <a
            class="text"
            [routerLink]="['/mgmt/scope']"
            (click)="close()"
            *ngIf="this.permissionService.checkScopes(['mgmt.scope.read'])"
            ><i class="fas fa-box"></i> {{ 'scope.navLabel' | translate }}</a
          >
        </li>
        <li class="list-item">
          <a
            class="text"
            [routerLink]="['/mgmt/context']"
            (click)="close()"
            *ngIf="this.permissionService.checkScopes(['mgmt.context.read'])"
            ><i class="fas fa-border-style"></i> {{ 'context.navLabel' | translate }}</a
          >
        </li>
        <li class="list-item">
          <a
            class="text"
            [routerLink]="['/mgmt/language']"
            (click)="close()"
            *ngIf="this.permissionService.checkScopes(['mgmt.lang.read'])"
            ><i class="fas fa-language"></i> {{ 'language.navLabel' | translate }}</a
          >
        </li>
        <li class="list-item">
          <a
            class="text"
            [routerLink]="['/mgmt/translation']"
            (click)="close()"
            *ngIf="this.permissionService.checkScopes(['mgmt.translation.read'])"
            ><i class="fas fa-language"></i> {{ 'translation.navLabel' | translate }}</a
          >
        </li>
        <li class="list-item">
          <a
            class="text"
            [routerLink]="['/mgmt/attribute']"
            (click)="close()"
            *ngIf="this.permissionService.checkScopes(['mgmt.attribute.read'])"
            ><i class="fab fa-buffer"></i> {{ 'attribute.navLabel' | translate }}</a
          >
        </li>
        <li class="list-item">
          <a
            class="text"
            [routerLink]="['/mgmt/backend-setting']"
            (click)="close()"
            *ngIf="this.permissionService.checkScopes(['mgmt.config.read'])"
            ><i class="fas fa-cogs"></i> {{ 'backendSetting.navLabel' | translate }}</a
          >
        </li>
        <li class="list-item">
          <a
            class="text cursor-pointer"
            href="javascript: void(0)"
            (click)="damageReport()"
            *ngIf="this.permissionService.checkScopes(['damagereport.create.hide_at_the_moment'])"
            ><i class="fas fa-paper-plane"></i> {{ 'report.href' | translate }}</a
          >
        </li>
        <li *ngIf="!!this.analyticsUrl" class="list-item">
          <a
            class="text"
            [href]="this.analyticsUrl"
            target="_blank"
            *ngIf="this.permissionService.checkScopes(['mgmt.feedpost.read', 'mgmt.event.read'])"
            ><i class="fa fa-chart-line"></i> {{ 'general.navigation.googleAnalytics' | translate }}
            <i class="fa fa-external-link-alt upper-icon"></i
          ></a>
        </li>
      </ul>
      <ul class="list-view separator">
        <li class="list-item">
          <a class="text cursor-pointer" href="javascript: void(0)" (click)="logout()"
            ><i class="fa fa-sign-out-alt"></i> {{ 'general.buttons.logout' | translate }}</a
          >
        </li>
        <li class="list-item">
          <a
            class="text cursor-pointer"
            href="javascript: void(0)"
            *ngIf="this.permissionService.checkScopes(['feedback.create'])"
            (click)="feedback()"
            ><i class="fa fa-bullhorn"></i> {{ 'general.navigation.feedback' | translate }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</div>

<app-popup
  [title]="'general.navigation.feedback' | translate"
  [visible]="(this.layoutService.isFeedbackShown | async) ?? false"
  (visibleChange)="this.layoutService.isFeedbackShown.next($event)"
>
  <app-feedback (closeFeedback)="this.layoutService.isFeedbackShown.next(false)"></app-feedback>
</app-popup>

<app-popup
  [title]="'report.href' | translate"
  [visible]="(this.layoutService.isDamageReportShown | async) ?? false"
  (visibleChange)="this.layoutService.isDamageReportShown.next($event)"
>
  <app-damage-report (closeDamageReport)="this.layoutService.isDamageReportShown.next(false)"></app-damage-report>
</app-popup>
