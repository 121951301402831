<div class="toolbar">
  <ng-container *ngIf="panel?.panelMode === panel?.modeType?.Read">
    <dx-button (onClick)="panel.setWriteMode()" icon="edit" class="flex-right"> </dx-button>
  </ng-container>
</div>
<div class="row">
  <div class="col-12 accordion-panel-body">
    <ng-content></ng-content>
  </div>
</div>
<div class="row">
  <div class="col-lg-12 accordion-panel-footer-buttons">
    <ng-container *ngIf="panel.panelMode === panel.modeType?.Write">
      <dx-button [type]="'normal'" [stylingMode]="'text'" (onClick)="panel.cancel()">{{
        'general.buttons.cancel' | translate
      }}</dx-button>
      <dx-button [type]="'default'" [stylingMode]="'text'" (onClick)="panel.save(panel.safeType)">{{
        'general.buttons.refresh' | translate
      }}</dx-button>
    </ng-container>
  </div>
</div>
