import { BaseEntity } from '~/app/core/models/base-entity.model';

export class ProfileMetadata extends BaseEntity<ProfileMetadata> {
  constructor(
    private field1?: string,
    private field2?: string,
    private field3?: string,
    private field4?: string,
    private field5?: string,
    private field6?: string,
    private field7?: string,
    private field8?: string,
    private field9?: string,
    private field10?: string,
    private field11?: string,
    private field12?: string,
    private field13?: string,
    private field14?: string,
    private field15?: string,
    private field16?: string,
    private field17?: string,
    private field18?: string,
    private field19?: string,
    private field20?: string,
    private field21?: string,
    private field22?: string,
    private field23?: string,
    private field24?: string,
    private field25?: string,
    private field26?: string,
    private field27?: string,
    private field28?: string,
    private field29?: string,
    private field30?: string
  ) {
    super();
  }

  public get blockedUsers() {
    let blockedUsers: Array<string> = [];
    try {
      blockedUsers = JSON.parse(this.field1 ?? '');
      if (!Array.isArray(blockedUsers)) {
        blockedUsers = [];
      }
    } catch (e) {
      if (e instanceof SyntaxError) {
        blockedUsers = [];
      } else {
        throw e;
      }
    }
    return blockedUsers;
  }

  public set blockedUsers(blockedUsers: Array<string>) {
    this.field1 = JSON.stringify(blockedUsers);
  }
}
