import { Directive } from '@angular/core';
import Editor from 'devextreme/ui/editor/editor';
import dxForm, { EditorEnterKeyEvent, GroupItem, Item, SimpleItem } from 'devextreme/ui/form';

@Directive()
export abstract class KeyboardNavigableComponent {
  constructor() {}

  /**
   * Call this function with an event from a DxForm's (onEditorEnterKey) event handler
   * and the corresponding form data.
   * When another field can be focused and the current field has data in it,
   * the focus is moved to the next field.
   *
   * @param event the event from  a DxForm's (onEditorEnterKey) event handler
   * @param formInstance the DxForm instance
   */

  // eslint-disable-next-line @typescript-eslint/ban-types
  public focusNextField(event?: EditorEnterKeyEvent, formInstance?: dxForm) {
    if (event?.['dataField'] && formInstance) {
      const comp: dxForm = event.component;
      if (comp) {
        // check if we have data in the current field, only then advance to next field
        if (!formInstance.option('formData')[event['dataField']]) {
          // IE11 updates on blur, not change, so we need to blur and focus the field
          const eventItemEditor = comp.getEditor(event['dataField']);
          if (eventItemEditor) {
            (eventItemEditor as Editor).focus();
            setTimeout(() => {
              eventItemEditor.focus();
              setTimeout(() => {
                if (event.dataField && formInstance.option('formData')[event.dataField]) {
                  // only call us again, if the check succeeds, thus prevents an infinite setTimeout loop
                  this.focusNextField(event, formInstance);
                }
              }, 0);
            }, 0);
          }
        } else {
          const items = comp.option('items');

          if (!Array.isArray(items)) return;

          const flatItems = this.flattenItems(items);

          let match: SimpleItem | null = null;

          for (const item of flatItems) {
            if (match) {
              comp.getEditor(item.dataField ?? '')?.focus();
              return;
            } else if (item.dataField === event['dataField']) {
              match = item;
            }
          }
        }
      }
    }
  }

  /**
   * Call this function with an event from a DxForm's (onEditorEnterKey) event handler.
   * Call blur on all fields to get rid of the KeyBoard when saving form.
   *
   * @param event the event from  a DxForm's (onEditorEnterKey) event handler
   */
  public blurAllFields(event?: EditorEnterKeyEvent) {
    if (!event) return;

    const comp: dxForm = event.component;

    if (!comp) return;

    const items = comp.option('items');

    if (!Array.isArray(items)) return;

    this.flattenItems(items)
      .filter((item) => Boolean(item.dataField))
      .forEach((item) =>
        comp
          .getEditor(item.dataField ?? '')
          ?.element()
          .blur()
      );
  }

  private flattenItems(items: Array<Item>): Array<SimpleItem> {
    const reduceItems = (allItem: Array<Item>, itemArray: Array<Item>) => allItem.concat(itemArray);
    const mapItems = (item: Item): Item[] => {
      switch (item.itemType) {
        case 'group': {
          const groupItem = item as GroupItem;
          return groupItem.items?.map(mapItems).reduce(reduceItems, []) ?? [];
        }
        case 'simple': {
          const simpleItem = item as SimpleItem;
          return [simpleItem];
        }
        default:
          return [];
      }
    };
    return items.map(mapItems).reduce(reduceItems, []);
  }
}
