import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DxValidationGroupComponent } from 'devextreme-angular';
import { DamageReport } from '../../core/models/damagereport';
import { DamageReportService } from '../../core/services/backend/damage-report.service';

@Component({
  selector: 'app-damage-report',
  templateUrl: './damage-report.component.html',
})
export class DamageReportComponent implements OnInit {
  @ViewChild('damageReportVG', { static: false }) public damageReportVG?: DxValidationGroupComponent;

  @Output() public closeDamageReport = new EventEmitter();
  public formData: DamageReport = new DamageReport();

  public availableLocations = [
    {
      value: 'arbeitsplatz',
    },
    {
      value: 'autowaschanlage',
    },
    {
      value: 'demoraum1ug',
    },
    {
      value: 'fitnessumkleideruheraum',
    },
    {
      value: 'garage',
    },
    {
      value: 'reinholdwuerthsaal',
    },
    {
      value: 'restaurant',
    },
    {
      value: 'sitzungszimmer',
    },
    {
      value: 'wcanlagen',
    },
  ];

  constructor(public translateService: TranslateService, public damageReportService: DamageReportService) {}

  public ngOnInit() {
    this.reset();
  }

  public send() {
    if (this.damageReportVG) {
      if (this.damageReportVG.instance.validate().isValid) {
        this.damageReportService.post(this.formData).subscribe(() => {
          this.closeDamageReport.emit();
          this.reset();
        });
      }
    }
  }

  public close() {
    this.reset();
    this.closeDamageReport.emit();
  }

  public reset() {
    this.formData = new DamageReport();
  }
}
