import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Action } from '@cloudinary/url-gen/internal/Action';
import { TranslateService } from '@ngx-translate/core';
import { DxValidationGroupComponent } from 'devextreme-angular';
import { ItemClickEvent } from 'devextreme/ui/box';
import { ClickEvent } from 'devextreme/ui/button';
import { confirm } from 'devextreme/ui/dialog';
import { ButtonAction } from '~/app/features/mgmt/translation/translation-list.component';
import { StorageConfig } from '../../config/storage.config';
import { ProfileMetadata } from '../../core/account/profile/models/profile-metadata';
import { ProfileMetadataService } from '../../core/account/profile/services/profile-metadata.service';
import { PermissionService } from '../../core/auth/permission.service';
import { ReportContent } from '../../core/models/report-content';
import { LayoutService } from '../../core/services/store/layout.service';
import { PostComment } from '../../features/feed/feed-post/comment/models/post-comment';
import { Author } from '../../features/feed/models/author';
import { FeedPost } from '../../features/feed/models/feed-post';
import { deleteAppDataUserId } from '../helpers/users';

@Component({
  selector: 'app-report-entity-button',
  template: `
    <dx-drop-down-button
      *ngIf="buttonActions.length > 0"
      [dropDownOptions]="{ width: 230 }"
      [useSelectMode]="false"
      icon="more"
      [items]="buttonActions"
      displayExpr="name"
      (onItemClick)="onItemClick($event)"
      [showArrowIcon]="false"
    ></dx-drop-down-button>
  `,
})
export class AppReportEntityComponent implements OnInit {
  @Input() public post?: FeedPost;
  @Input() public comment?: PostComment;
  @Input() public user?: Author;

  @Input() public actions: ButtonAction[] = [];
  @Output() public actionClick = new EventEmitter<ClickEvent>();

  @ViewChild('reportContentVG', { static: false }) public reportContentVG?: DxValidationGroupComponent;

  public report?: ReportContent;
  public buttonActions: (Action | { value: string; name: string; icon: string })[] = [];

  private buttonActionsPrefix = '__report__content__';

  constructor(
    private translate: TranslateService,
    public layoutService: LayoutService,
    public permissionService: PermissionService,
    private profileMetadataService: ProfileMetadataService
  ) {}

  public ngOnInit() {
    const user = this.user ?? this.post?.author ?? this.comment?.author;
    const currentUserId = localStorage.getItem(StorageConfig.USER_ID);
    const isCurrentUser = currentUserId === user?.id;
    const isDeletedUser = deleteAppDataUserId === user?.id;
    this.buttonActions = [
      ...this.actions,
      ...(isCurrentUser || isDeletedUser
        ? []
        : [
            ...(this.post
              ? [
                  {
                    value: `${this.buttonActionsPrefix}reportContent`,
                    name: this.translate.instant('general.buttons.reportContent') as string,
                    icon: 'fas fa-flag',
                  } as ButtonAction,
                ]
              : []),
            {
              value: `${this.buttonActionsPrefix}reportUser`,
              name: this.translate.instant('general.buttons.reportUser') as string,
              icon: 'fas fa-flag',
            } as ButtonAction,
            ...(this.permissionService.checkScopes(['mgmt.reportcontent.read'])
              ? []
              : [
                  {
                    value: `${this.buttonActionsPrefix}block`,
                    name: this.translate.instant('general.buttons.blockUser') as string,
                    icon: 'fas fa-ban',
                  },
                ]),
          ]),
    ];
  }

  public onItemClick($event: ItemClickEvent) {
    if ($event.itemData.value.slice(0, this.buttonActionsPrefix.length) === this.buttonActionsPrefix) {
      if ($event.itemData.value === `${this.buttonActionsPrefix}reportContent`) {
        this.openReportPopup();
      } else if ($event.itemData.value === `${this.buttonActionsPrefix}reportUser`) {
        this.openReportPopup(true);
      } else if ($event.itemData.value === `${this.buttonActionsPrefix}block`) {
        const user = this.user ?? this.post?.author ?? this.comment?.author;
        if (user) {
          this.openBlockPopup(user);
        }
      }
    } else {
      if (typeof !$event === 'undefined') return;
      this.actionClick.emit($event as ClickEvent);
    }
  }

  public openBlockPopup(user: Author) {
    const confirmation = confirm(
      `<p>${this.translate.instant('reportContent.blockUserConfirm', {
        username: `${user.firstname} ${user.lastname}`,
      })}</p><p>${this.translate.instant('reportContent.blockUserInfoBlock')}</p><p><small>${this.translate.instant(
        'reportContent.blockUserInfoManage'
      )}</small></p>`,
      this.translate.instant('general.confirm_title')
    );

    confirmation.then((dialogResult) => {
      if (dialogResult) {
        this.profileMetadataService
          .fetchAll({
            serviceUrlConfig: { showMessage: false },
          })
          .subscribe({
            next: (userMeta: Array<ProfileMetadata>) => {
              if (userMeta.length > 0) {
                const blockedUsers = userMeta[0].blockedUsers;
                blockedUsers.push(user.id || '');
                userMeta[0].blockedUsers = blockedUsers;
                this.profileMetadataService.patch(userMeta[0]).subscribe(() => {
                  window.location.reload();
                });
              }
            },
            error: (error) => {
              if (error?.error?.code === 'usermetadata_not_found') {
                const profileConfig = new ProfileMetadata();
                profileConfig.blockedUsers = [user.id || ''];
                this.profileMetadataService.post(profileConfig).subscribe(() => {
                  window.location.reload();
                });
              }
            },
          });
      }
    });
  }

  public openReportPopup(user?: boolean) {
    this.report = new ReportContent();
    this.report.comment = this.comment;
    this.report.user = user && this.post ? this.post.author : this.user;
    this.report.post = !user ? this.post : undefined;

    this.layoutService.reportDialogContentReport.next(this.report);
    this.layoutService.isReportDialogShown.next(true);
  }
}
