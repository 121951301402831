import { Component } from '@angular/core';
@Component({
  selector: 'app-scroll-view-with-bottom-bar',
  template: `
    <dx-scroll-view width="100%" class="scroll-view-footer-bar-after">
      <ng-content></ng-content>
    </dx-scroll-view>
    <div class="scroll-view-footer-bar button-bar">
      <ng-content select="div.bottom-bar"></ng-content>
    </div>
  `,
})
export class ScrollViewWithBottomBarComponent {
  constructor() {}
}
