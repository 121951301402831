import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClickEvent } from 'devextreme/ui/button';
import { endsWith } from 'lodash';
import { HttpStateService } from '../../core/services/backend/httpstate.service';
import { HttpProgressState } from '../../core/services/base/base.service';

@Component({
  selector: 'app-button',
  template: `
    <dx-button
      class="app-button"
      [disabled]="disabled"
      [width]="width"
      [type]="type"
      [stylingMode]="stylingMode"
      (onClick)="clickHandler($event)"
    >
      <div *dxTemplate="let data of 'content'">
        <dx-load-indicator class="button-indicator" [visible]="loading" [class.indicator-only]="hideLabelDuringLoading">
        </dx-load-indicator>
        <span class="dx-button-text" [class.invisible]="loading && hideLabelDuringLoading"
          ><ng-content></ng-content
        ></span>
      </div>
    </dx-button>
  `,
})
export class AppButtonComponent implements OnInit {
  @Input() public label?: string;
  @Input() public disabled = false;
  @Input() public type = 'default';
  @Input() public stylingMode = 'contained';
  @Input() public hideLabelDuringLoading = false;
  @Input() public filterBy: string | null = null;
  @Input() public filterEndsWith = false;
  @Input() public width?: string;

  @Output() public buttonClick = new EventEmitter();

  public loading = false;

  constructor(private httpStateService: HttpStateService) {}

  public ngOnInit() {
    this.httpStateService.state.subscribe((progress) => {
      if (progress && progress.url) {
        if (!this.filterBy) {
          setTimeout(() => (this.loading = progress.state === HttpProgressState.start));
        } else if (progress.url.indexOf(this.filterBy) !== -1) {
          if (this.filterEndsWith && endsWith(progress.url, this.filterBy)) {
            setTimeout(() => (this.loading = progress.state === HttpProgressState.start));
          } else if (!this.filterEndsWith && progress.url.indexOf(this.filterBy) !== -1) {
            setTimeout(() => (this.loading = progress.state === HttpProgressState.start));
          }
        }
      }
    });
  }

  public clickHandler(event: ClickEvent) {
    if (!this.disabled) {
      this.buttonClick.emit(event);
    }
  }
}
