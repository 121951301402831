<app-base-accordion-panel [panel]="this">
  <div class="row">
    <div class="col-lg-8">
      <div *ngIf="this.entity">
        <dx-form
          *appReloadOnLangChange
          [readOnly]="panelMode === modeType.Read"
          [showColonAfterLabel]="true"
          [(formData)]="this.formData"
          (onFieldDataChanged)="onFieldChange($event)"
          (onEditorEnterKey)="save(safeType, $event)"
        >
          <dxi-item itemType="group" caption="{{ 'profile.blockedUsers' | translate }}" cssClass="item-group">
            <dxi-item
              *ngIf="usersLoaded && users.length > 0"
              dataField="blockedUsers"
              editorType="dxTagBox"
              [editorOptions]="{
                items: users,
                displayExpr: 'fullName',
                valueExpr: 'id',
                showClearButton: false
              }"
            >
              <dxo-label text="{{ 'profile.blockedUsers' | translate }}"></dxo-label>
            </dxi-item>
            <dxi-item *ngIf="!usersLoaded && (!users || users?.length === 0)">
              <dxo-label text="{{ 'profile.blockedUsers' | translate }}"></dxo-label>
              <p style="margin-left: 16px">{{ 'profile.noBlockedUsers' | translate }}</p>
            </dxi-item>
          </dxi-item>
        </dx-form>
      </div>
    </div>
  </div>
</app-base-accordion-panel>
