import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { flatten } from 'lodash';
import { Feedback } from '../../models/feedback';
import { BaseService, IServiceUrlConfig } from '../base/base.service';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService extends BaseService<Feedback> {
  constructor(public override http: HttpClient) {
    super(http, Feedback);
  }

  protected getServiceUrl(entityId?: string, serviceUrlConfig?: IServiceUrlConfig): string {
    let url = ['feedback'];
    if (serviceUrlConfig && serviceUrlConfig.path) {
      url = flatten([url, serviceUrlConfig.path]);
    }
    return this.apiStrategy.getServiceUrl(url);
  }
}
