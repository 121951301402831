import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { clone } from 'lodash';
import { BaseAccordionPanel, SafeType } from '~/app/shared/components/accordion/base-accordion-panel';
import { ProfileAddress } from '../models/profile-address';
import { ProfileAddressService } from '../services/profile-address.service';

@Component({
  selector: 'app-profile-address',
  templateUrl: './profile-address.component.html',
})
export class ProfileAddressComponent
  extends BaseAccordionPanel<ProfileAddress, ProfileAddressService>
  implements OnInit
{
  public formData: ProfileAddress = new ProfileAddress();

  constructor(
    protected override translateService: TranslateService,
    protected profileAddressService: ProfileAddressService
  ) {
    super(profileAddressService, translateService);
  }

  public override ngOnInit() {
    this.profileAddressService.fetchAll().subscribe({
      next: (result: ProfileAddress[]) => {
        this.entity = result[0];
        if (this.entity.id) {
          this.safeType = SafeType.Patch;
        }
        this.createForm();
      },
      error: (error: HttpErrorResponse) => {
        if (error.status === 404) {
          // it's ok if user has no address
          this.entity = new ProfileAddress();
          this.createForm();
        } else {
          throw error;
        }
      },
    });
  }

  public createForm() {
    const clonedEntity = clone(this.entity);
    if (!clonedEntity) return;
    this.formData = clonedEntity;
  }
}
