import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EditorEnterKeyEvent } from 'devextreme/ui/form';
import { clone } from 'lodash';
import { BaseAccordionPanel, SafeType } from '~/app/shared/components/accordion/base-accordion-panel';
import { Profile } from '../models/profile';
import { ProfileService } from '../services/profile.service';

@Component({
  selector: 'app-profile-general',
  templateUrl: './profile-general.component.html',
})
export class ProfileGeneralComponent extends BaseAccordionPanel<Profile, ProfileService> implements OnInit {
  public formData: Profile = new Profile();

  constructor(protected override translateService: TranslateService, protected profileService: ProfileService) {
    super(profileService, translateService);
  }

  public override ngOnInit() {
    if (this.entity?.id) {
      this.safeType = SafeType.Patch;
    }
    this.createForm();
  }

  public createForm() {
    if (!this.entity) return;
    this.formData = clone(this.entity);
  }

  public override save(saveType = SafeType.Patch, event?: EditorEnterKeyEvent): void {
    // Makes sure if the email address gets changed also the username gets updated
    this.formData.username = this.formData.email;
    super.save(saveType, event);
  }
}
