export type ImageVersion =
  | 'teaser-detailed'
  | 'teaser-thumbnail'
  | 'teaser-preview'
  | 'avatar'
  | 'avatar-medium'
  | 'avatar-small';

export type ImageVersionSizes = {
  [key in ImageVersion]: {
    width: number;
    height: number;
  };
};

export const imageVersionSizes: ImageVersionSizes = {
  'teaser-detailed': {
    width: 750,
    height: 350,
  },
  'teaser-thumbnail': {
    width: 550,
    height: 300,
  },
  'teaser-preview': {
    width: 600,
    height: 280,
  },
  avatar: {
    width: 180,
    height: 180,
  },
  'avatar-medium': {
    width: 110,
    height: 110,
  },
  'avatar-small': {
    width: 80,
    height: 80,
  },
};
