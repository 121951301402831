import { BaseEntity } from '../../../core/models/base-entity.model';
import { ExternalFile } from '../../../shared/components/external-file/types';

export class Author extends BaseEntity<Author> {
  constructor(
    public email: string = '',
    public firstname: string = '',
    public lastname: string = '',
    public username: string = '',
    public picture?: {
      id: string;
    },
    public profilePicture?: ExternalFile
  ) {
    super();
  }
}
