import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '../../auth/auth.guard';
import { ProfileComponent } from './profile.component';

const routes: Routes = [
  {
    canActivate: [AuthenticationGuard],
    component: ProfileComponent,
    path: '',
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
})
export class ProfileRoutingModule {}
