// from https://github.com/semanticbits/previous-url-example/blob/master/src/app/services/router-history.service.ts

import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, map, scan } from 'rxjs/operators';
import { AngularBridgeService } from './angular-bridge.service';
import { BridgeCallbackMethod, DefaultSubscriber } from './angular-bridge.types';
import { LayoutService } from './store/layout.service';

interface RouterHistory {
  event: NavigationStart | NavigationEnd | null;
  history: Array<{ id: number; url: string }>;
  trigger?: 'imperative' | 'popstate' | 'hashchange';
  id: number;
  idToRestore?: number;
  currentIndex: number;
}

@Injectable({
  providedIn: 'root',
})
export class RouterHistoryService {
  public previousUrl$ = new BehaviorSubject<string | null>(null);
  public currentUrl$ = new BehaviorSubject<string | null>(null);

  constructor(private router: Router, private bridge: AngularBridgeService, private layoutService: LayoutService) {
    this.router.events
      .pipe(
        // only include NavigationStart and NavigationEnd events
        filter((event) => event instanceof NavigationStart || event instanceof NavigationEnd),
        map((event) => event as NavigationStart | NavigationEnd),
        scan<NavigationStart | NavigationEnd, RouterHistory>(
          (acc, event) => {
            if (event instanceof NavigationStart) {
              // We need to track the trigger, id, and idToRestore from the NavigationStart events
              return {
                ...acc,
                event,
                trigger: event.navigationTrigger,
                id: event.id,
                idToRestore: (event.restoredState && event.restoredState.navigationId) || undefined,
              };
            }

            // NavigationEnd events
            const history = [...acc.history];
            let currentIndex = acc.currentIndex;

            // router events are imperative (router.navigate or routerLink)
            if (acc.trigger === 'imperative') {
              // remove all events in history that come after the current index
              history.splice(currentIndex + 1);

              // add the new event to the end of the history and set that as our current index
              history.push({ id: acc.id, url: event.urlAfterRedirects });
              currentIndex = history.length - 1;
            }

            // browser events (back/forward) are popstate events
            if (acc.trigger === 'popstate') {
              // get the history item that references the idToRestore
              const idx = history.findIndex((x) => x.id === acc.idToRestore);

              // if found, set the current index to that history item and update the id
              if (idx > -1) {
                currentIndex = idx;
                history[idx].id = acc.id;
              } else {
                currentIndex = 0;
              }
            }

            return {
              ...acc,
              event,
              history,
              currentIndex,
            };
          },
          {
            event: null,
            history: [],
            trigger: undefined,
            id: 0,
            idToRestore: 0,
            currentIndex: 0,
          }
        ),
        // filter out so we only act when navigation is done
        filter(({ event, trigger }) => event instanceof NavigationEnd && !!trigger)
      )
      .subscribe(({ history, currentIndex }) => {
        const previous = history[currentIndex - 1];
        const current = history[currentIndex];

        // update current and previous urls
        this.previousUrl$.next(previous ? previous.url : '');
        this.currentUrl$.next(current.url);
      });

    this.bridge.setDefaultReceiver(BridgeCallbackMethod.goBack, this.canGoBack as unknown as DefaultSubscriber<string>);
  }

  private canGoBack(requestId: string, error: Error) {
    if (typeof error === 'undefined') {
      let canGoBack = true;
      if (this.layoutService.isMediaManagerShown.getValue()) {
        this.layoutService.isMediaManagerShown.next(false);
      } else if (this.layoutService.isReportDialogShown.getValue()) {
        this.layoutService.isReportDialogShown.next(false);
      } else if (this.layoutService.isFeedbackShown.getValue()) {
        this.layoutService.isFeedbackShown.next(false);
      } else if (this.layoutService.isDamageReportShown.getValue()) {
        this.layoutService.isDamageReportShown.next(false);
      } else if (this.layoutService.showLeftSidebarSubject.getValue()) {
        this.layoutService.toggleLeftSidebar();
      } else if (this.layoutService.showUserSidebarSubject.getValue()) {
        this.layoutService.toggleUserSidebar();
      } else {
        const url = this.previousUrl$.getValue();
        canGoBack = typeof url === 'string' && url.length > 0;
        if (canGoBack && url) {
          if (window && window.history && typeof window.history['back'] === 'function') {
            window.history.back();
          } else {
            this.router.navigateByUrl(url);
          }
        }
      }
      this.bridge.didGoBack(requestId, canGoBack);
    }
  }
}
