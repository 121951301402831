import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CardBodyDirective } from './card-body.directive';

@Component({
  selector: 'napp-card-body',
  template: `
    <div class="card-body">
      <ng-template *ngIf="bodyTemplate" [ngTemplateOutlet]="bodyTemplate.template"> </ng-template>
      <ng-container *ngIf="!bodyTemplate">
        <div *ngIf="showUserInfo" class="user-info">
          <img src="{{ userImg }}" class="card-user-img" alt="some image" />
          <div class="">
            <strong
              ><p class="text-muted">{{ userName }}</p></strong
            >
          </div>
          <div class="">
            <p class="text-muted">{{ publicationDate | date : datePipe }}</p>
          </div>
        </div>
        <div *ngIf="showBookmark" class="feed-bookmark">
          <i
            [ngClass]="isBookmarkActive ? 'fas fa-bookmark active' : 'fas fa-bookmark'"
            (click)="bookmark.emit($event)"
          ></i>
        </div>
        <h5 class="card-title">{{ cardTitle }}</h5>
        <p class="card-text" [innerHTML]="cardText | safe : 'html'"></p>
        <i *ngIf="showShare" class="fas fa-share-alt btn-right" (click)="share.emit($event)"></i>
      </ng-container>
    </div>
  `,
  styleUrls: ['./card-body.component.scss'],
  host: {
    class: 'napp-card-body',
  },
})
export class CardBodyComponent implements OnInit {
  @Input() userImg: string = '';
  @Input() userName: string = '';
  @Input() publicationDate: Date | undefined;
  @Input() datePipe: string = '';
  @Input() cardText: string = '';
  @Input() cardTitle: string = '';
  @Input() showUserInfo: boolean = true;
  @Input() isBookmarkActive: boolean = false;
  @Input() showBookmark: boolean = true;
  @Input() showShare: boolean = true;
  @Input() bodyTemplate: CardBodyDirective | undefined;

  @Output() bookmark: EventEmitter<any> = new EventEmitter();
  @Output() share: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
