import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { DefaultLangChangeEvent, LangChangeEvent, TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { Observable, Subscription, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularBridgeService } from '../../core/services/angular-bridge.service';
import { DxLayoutEvent, LayoutService } from '../../core/services/store/layout.service';

type ReloadType = 'language' | 'translation' | 'both';

@Directive({
  selector: '[appReloadOnLangChange]',
})
export class ReloadOnLangChangeDirective implements OnInit, OnDestroy {
  private _type: ReloadType = 'language';
  private _timeout = 100; // ms
  private _timeoutRef?: ReturnType<typeof setTimeout>;
  private _subscription?: Subscription;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private translate: TranslateService,
    private layoutService: LayoutService,
    private bridge: AngularBridgeService
  ) {}

  public ngOnInit() {
    this.createView();
    const isNative = this.bridge.isNative();
    const events: Array<Observable<LangChangeEvent | DefaultLangChangeEvent | TranslationChangeEvent | DxLayoutEvent>> =
      [];
    if (!isNative) {
      events.push(this.layoutService.onDxLayoutChanged.pipe(map((event) => event)));
    }
    if (this._type === 'language' || this._type === 'both') {
      events.push(this.translate.onLangChange.pipe(map((event) => event)));
      events.push(this.translate.onDefaultLangChange.pipe(map((event) => event)));
    }
    if (this._type === 'translation' || this._type === 'both') {
      events.push(this.translate.onTranslationChange.pipe(map((event) => event)));
    }
    this._subscription = merge(...events).subscribe(() => {
      if (!this._timeoutRef) {
        this.viewContainer.clear();
      } else {
        clearTimeout(this._timeoutRef);
      }
      this._timeoutRef = setTimeout(() => {
        this._timeoutRef = undefined;
        this.createView();
      }, this._timeout);
    });
  }

  public ngOnDestroy() {
    this._subscription?.unsubscribe();

    if (this._timeoutRef) {
      clearTimeout(this._timeoutRef);
    }
  }

  /**
   * handles type
   */
  @Input() set appReloadOnLangChange(type: ReloadType | '' | undefined) {
    if (typeof type !== 'undefined' && type !== '') {
      this._type = type;
    }
  }

  /**
   * handles timeout
   */
  @Input() set appReloadOnLangChangeTimeout(timeout: number) {
    if (typeof timeout !== 'number' || Number.isNaN(timeout) || timeout < 0) {
      throw new Error('Invalid timeout. Expecting number greater or equal to 0.');
    }
    this._timeout = timeout;
  }

  private createView() {
    this.viewContainer.createEmbeddedView(this.templateRef);
  }
}
