import { BaseEntity } from '~/app/core/models/base-entity.model';
import { AttributeValue } from './attribute-value';

export class Attribute extends BaseEntity<Attribute> {
  constructor(
    public name: string = '',
    public editable: boolean = false,
    public key: string = '',
    public values?: AttributeValue[]
  ) {
    super();
  }

  public override deserialize(object: this): this {
    if (typeof object.values !== 'undefined') {
      object.values = object.values.map((value) => new AttributeValue().deserialize(value));
    }
    return super.deserialize(object);
  }
}
