import {
  Component,
  ElementRef,
  Inject,
  InjectionToken,
  isDevMode,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ENVIRONMENT_CONFIGURATION } from '@softwarehaus/util-configuration';
import { confirm } from 'devextreme/ui/dialog';
import Mmenu from 'mmenu-js/dist/core/oncanvas/mmenu.oncanvas';
import { BehaviorSubject, merge, Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { NappEnvironmentConfiguration } from '~/app/config/environments-configuration';
// eslint-disable-next-line @nx/enforce-module-boundaries
import packageJson from '../../../../../../package.json';
import { MediaDirectory } from '../../features/document/models/media-directory';
import { MediaDirectoryService } from '../../features/document/services/mediadirectory.service';
import { CMSSite } from '../../features/site/models/cms-site';
import { CMSService } from '../../features/site/services/cms.service';
import { AuthService } from '../auth/authentication.service';
import { PermissionService } from '../auth/permission.service';
import { MessageService, TOASTTYPE } from '../services/message.service';
import { LayoutService } from '../services/store/layout.service';

enum NavigationItemType {
  Title = 'Title',
}

export interface IModulePath {
  path: string;
  queryParams?: Params;
}

export interface IModuleActions {
  value: 'add' | 'edit' | 'delete' | 'open' | 'orderUp' | 'orderDown';
  name: string;
  icon: string;
  scopes?: string[];
  message?: string;
  params?: Params;
}

type IModuleActionsFactory = (item: MenuItem) => IModuleActions;

export class MenuItem {
  public title = '';
  public moduleEnabled = false;
  public id?: string;
  public type?: NavigationItemType;
  public childs?: MenuItem[];
  public modulePath?: IModulePath;
  public requiredScopes?: string[];
  public siteActions?: IModuleActions[];
  public classNames?: string;
}

export const SIDEBAR_LEFT_REDRAW_TIMEOUT_MS = new InjectionToken<number>('SIDEBAR_LEFT_REDRAW_TIMEOUT_MS');
export const SIDEBAR_LEFT_REDRAW_TIMEOUT_ALLOW_FAIL_NUM_TIMES = new InjectionToken<number>(
  'SIDEBAR_LEFT_REDRAW_TIMEOUT_ALLOW_FAIL_NUM_TIMES'
);

@Component({
  selector: 'app-sidebar-left',
  templateUrl: './sidebar-left.component.html',
})
export class SidebarLeftComponent implements OnInit, OnDestroy {
  public mmenu?: Mmenu;
  public mmenuApi?: object;
  public prevHref?: Element;

  public version = (isDevMode() ? 'DEV' : packageJson.version).split('+')[0];
  public open = false;

  public pages?: MenuItem;
  public modules?: MenuItem;
  public documentModule?: MenuItem;
  public dividerMenuItem: MenuItem = {
    title: '',
    classNames: 'menu-divider',
    moduleEnabled: this.config.availableModules.document,
  };

  public isLoading = new BehaviorSubject<boolean>(false);

  @ViewChild('leftMenu2', { static: true }) private leftMenu?: ElementRef<HTMLDivElement>;
  private showLeftSidebarSubscription?: Subscription;
  private reloadSubscription?: Subscription;
  private isLoadingSubscription: Subscription;
  private mediaDirectorySubscription?: Subscription;
  private cmsSubscription?: Subscription;
  private refreshTimeout?: ReturnType<typeof setTimeout>;
  private refreshTimeoutCount = 0;

  constructor(
    public layoutService: LayoutService,
    public authService: AuthService,
    private renderer: Renderer2,
    private router: Router,
    private cmsService: CMSService,
    private messageService: MessageService,
    private permissionService: PermissionService,
    private elem: ElementRef,
    private mediaDirectoryService: MediaDirectoryService,
    private translate: TranslateService,
    @Optional() @Inject(SIDEBAR_LEFT_REDRAW_TIMEOUT_MS) private reloadTimeout: number,
    @Optional() @Inject(SIDEBAR_LEFT_REDRAW_TIMEOUT_ALLOW_FAIL_NUM_TIMES) private reloadNumAttempts: number,
    @Inject(ENVIRONMENT_CONFIGURATION) private config: NappEnvironmentConfiguration
  ) {
    if (!this.reloadTimeout || this.reloadTimeout <= 0) {
      this.reloadTimeout = 15_000;
    }
    if (!this.reloadNumAttempts || this.reloadNumAttempts <= 0) {
      this.reloadNumAttempts = 7;
    }

    this.isLoadingSubscription = this.isLoading.subscribe((next) => {
      if (this.refreshTimeout) {
        clearTimeout(this.refreshTimeout);
        this.refreshTimeout = undefined;
      }
      if (next && this.refreshTimeoutCount < this.reloadNumAttempts) {
        if (this.mediaDirectorySubscription) {
          this.mediaDirectorySubscription.unsubscribe();
          this.mediaDirectorySubscription = undefined;
        }
        if (this.cmsSubscription) {
          this.cmsSubscription.unsubscribe();
          this.cmsSubscription = undefined;
        }

        this.refreshTimeout = setTimeout(() => {
          this.refreshTimeoutCount++;
          this.layoutService.triggerLeftMenuReload.next(true);
        }, this.reloadTimeout);
      } else {
        this.refreshTimeoutCount = 0;
      }
    });
  }

  public ngOnInit() {
    this.showLeftSidebarSubscription = this.layoutService.showLeftSidebarSubject.subscribe((next) => {
      this.open = next;
      if (this.open) {
        setTimeout(() => this.authService.refreshLoginStatus(), 100);
      } else {
        this.removeActiveActionDivs();
      }
    });

    this.reInitDefaults();
    this.loadCMSSites();

    this.reloadSubscription = merge(
      this.authService.isLoggedIn().pipe(distinctUntilChanged()), // reload on any status change
      this.layoutService.reloadLeftMenu.pipe(filter((v) => v)) // only reload on true-ish values
    ).subscribe(() => {
      this.reInitDefaults();
      this.removeMenuNodes();
      this.loadCMSSites();
    });
  }

  public reInitDefaults() {
    this.pages = {
      childs: [],
      title: this.translate.instant('site.navLabel'),
      type: NavigationItemType.Title,
      moduleEnabled: true,
    };

    this.modules = {
      childs: [
        {
          modulePath: {
            path: '/feed',
          },
          title: this.translate.instant('feed.navLabel'),
          moduleEnabled: this.config.availableModules.feed,
        },
        {
          modulePath: { path: '/feed', queryParams: { feedtype: 'subscribed' } },
          title: this.translate.instant('feed.myFeed'),
          requiredScopes: ['feedtag.subscribe.create'],
          moduleEnabled: this.config.availableModules.feed,
        },
        // {
        //   modulePath: {
        //     path: '/shortener',
        //   },
        //   requiredScopes: ['mgmt.shortener.read'],
        //   title: this.translate.instant('shortener.navLabel'),
        //   moduleEnabled: this.config.availableModules.shortener,
        // },
        {
          modulePath: {
            path: '/magazine',
          },
          requiredScopes: ['mgmt.magazine.read', 'magazine.read'],
          title: this.translate.instant('magazine.navLabel'),
          moduleEnabled: this.config.availableModules.magazine,
        },
        {
          modulePath: {
            path: '/agenda',
          },
          requiredScopes: ['agenda.read', 'mgmt.agenda.read'],
          title: this.translate.instant('agenda.singular'),
          moduleEnabled: this.config.availableModules.agenda,
        },
        {
          modulePath: {
            path: '/employee',
          },
          requiredScopes: ['employee.read'],
          title: this.translate.instant('employee.singular'),
          moduleEnabled: this.config.availableModules.employees,
        },
        // {
        //   childs: [
        //     {
        //       title: 'Übersicht',
        //       modulePath: { path: '/events', queryParams: {events: 'all'} },
        //       moduleEnabled: this.config.availableModules.event,
        //     },
        //     {
        //       title: 'Meine Events',
        //       modulePath: { path: '/events', queryParams: {events: 'my'} },
        //       moduleEnabled: this.config.availableModules.event,
        //     }
        //   ],
        //   title: 'Events',
        //   moduleEnabled: this.config.availableModules.event,
        // },
        // {
        //   modulePath: {
        //     path: 'app://fotobooth',
        //   },
        //   title: 'AR Photo Camera',
        //   moduleEnabled: this.config.availableModules.event,
        // }
      ],
      title: this.translate.instant('general.modules'),
      type: NavigationItemType.Title,
      moduleEnabled: true,
    };

    this.documentModule = {
      childs: [],
      modulePath: {
        path: '/mediadirectories',
      },
      requiredScopes: ['mgmt.mediadirectory.read', 'mediadirectory.read'],
      siteActions: [
        {
          icon: 'fas fa-plus',
          name: this.translate.instant('directory.newDirectory'),
          scopes: ['mgmt.mediadirectory.create'],
          value: 'add',
        },
      ],
      title: this.translate.instant('documents.plural'),
      moduleEnabled: this.config.availableModules.document,
    };
  }

  public ngOnDestroy(): void {
    this.showLeftSidebarSubscription?.unsubscribe();
    this.isLoadingSubscription.unsubscribe();
    this.reloadSubscription?.unsubscribe();
  }

  public close() {
    this.removeActiveActionDivs();
    this.layoutService.toggleLeftSidebar(false);
  }

  public onClick() {
    this.layoutService.toggleLeftSidebar(false);
  }

  private removeMenuNodes() {
    this.renderer.removeClass(this.leftMenu?.nativeElement, 'mm-menu');
    this.leftMenu?.nativeElement.childNodes.forEach((element) => {
      this.leftMenu?.nativeElement.removeChild(element);
    });
  }

  private loadCMSSites() {
    this.isLoading.next(true);
    this.cmsSubscription = this.cmsService
      .fetchAll({ serviceUrlConfig: { customPath: ['cms-site-navigation'] } })
      .subscribe((result: CMSSite[]) => {
        if (!this.pages) return;
        this.pages.childs = this.createMenuTree(result, 'site', true);

        const actions: IModuleActions[] = [
          {
            icon: 'fas fa-sort-numeric-up',
            name: this.translate.instant('general.buttons.orderUp'),
            scopes: ['mgmt.cmssite.update'],
            value: 'orderUp',
          },
          {
            icon: 'fas fa-sort-numeric-down',
            name: this.translate.instant('general.buttons.orderDown'),
            scopes: ['mgmt.cmssite.update'],
            value: 'orderDown',
          },
          {
            icon: 'fas fa-pen',
            name: this.translate.instant('general.buttons.edit'),
            scopes: ['mgmt.cmssite.update', 'cmssite.update'],
            value: 'edit',
          },
          {
            icon: 'fas fa-trash',
            message: this.translate.instant('site.confirmDeletion'),
            name: this.translate.instant('general.buttons.delete'),
            scopes: ['mgmt.cmssite.delete', 'cmssite.delete'],
            value: 'delete',
          },
        ];
        this.pages.childs = this.addChildSiteActions(this.pages.childs, actions);

        // add possibility to easy add new CMS site
        this.pages.childs.push({
          modulePath: {
            path: '/site/add',
          },
          title: this.translate.instant('site.addSite'),
          requiredScopes: ['mgmt.cmssite.create', 'cmssite.create'],
          moduleEnabled: true,
        });

        this.loadDirectoryChildren();

        this.cmsSubscription?.unsubscribe();
        this.cmsSubscription = undefined;
      });
  }

  private loadDirectoryChildren() {
    if (this.permissionService.checkScopes(['mgmt.mediadirectory.read', 'mediadirectory.read'])) {
      this.mediaDirectorySubscription = this.mediaDirectoryService.fetchAll().subscribe((results: MediaDirectory[]) => {
        if ((results && results.length > 0) || this.permissionService.checkScopes(['mgmt.mediadirectory.create'])) {
          if (!this.documentModule) return;
          this.documentModule.childs = this.createMenuTree(
            results,
            'mediadirectories',
            this.config.availableModules.document
          );
          const actions: Array<IModuleActions | IModuleActionsFactory> = [
            {
              icon: 'far fa-eye',
              name: this.translate.instant('documents.seeDocuments'),
              scopes: ['mgmt.mediadirectory.read', 'mediadirectory.read'],
              value: 'open',
            },
            (item: MenuItem) => ({
              icon: 'fas fa-plus',
              name: this.translate.instant('directory.newDirectory'),
              scopes: ['mgmt.mediadirectory.create'],
              value: 'add',
              params: {
                parentId: item.id,
              },
            }),
            {
              icon: 'fas fa-pen',
              name: this.translate.instant('directory.editDirectory'),
              scopes: ['mgmt.mediadirectory.update'],
              value: 'edit',
            },
            {
              icon: 'fas fa-trash',
              message: this.translate.instant('directory.confirmDeletion'),
              name: this.translate.instant('directory.deleteDirectory'),
              scopes: ['mgmt.mediadirectory.delete'],
              value: 'delete',
            },
          ];

          this.documentModule.childs = this.addChildSiteActions(this.documentModule.childs, actions);
        }

        if (!this.modules?.childs?.find((x) => x.modulePath?.path === '/mediadirectories') && this.documentModule) {
          this.modules?.childs?.splice(3, 0, this.dividerMenuItem);
          this.modules?.childs?.splice(3, 0, this.documentModule);
          this.modules?.childs?.splice(3, 0, this.dividerMenuItem);
        }

        this.initMenu();

        this.mediaDirectorySubscription?.unsubscribe();
        this.mediaDirectorySubscription = undefined;
      });
    } else {
      this.initMenu();
    }
  }

  private addChildSiteActions(children: MenuItem[], siteActions: Array<IModuleActions | IModuleActionsFactory>) {
    for (const child of children) {
      for (const actionOrFactory of siteActions) {
        let action: IModuleActions;
        if (typeof actionOrFactory === 'function') {
          action = actionOrFactory(child);
        } else {
          action = actionOrFactory;
        }
        if (action.scopes && this.permissionService.checkScopes(action.scopes)) {
          if (!child.siteActions) {
            child.siteActions = [];
          }
          child.siteActions.push(action);
        }
      }

      if (child.childs && child.childs.length > 0) {
        child.childs = this.addChildSiteActions(child.childs, siteActions);
      }
    }

    return children;
  }

  private initMenu(): void {
    if (!this.leftMenu) return;
    const navigation: Array<MenuItem> = [...(this.modules?.childs ?? []), ...(this.pages?.childs ?? [])];
    this.isLoading.next(false);
    this.createMenuDom(this.leftMenu.nativeElement, navigation);
    this.renderMenu();
  }

  private createMenuTree(
    menuObjects: CMSSite[] | MediaDirectory[],
    menuPath: string,
    moduleEnabled: boolean
  ): MenuItem[] {
    const menuData: MenuItem[] = [];

    for (const menuObject of menuObjects) {
      // loop over all parents
      if (!menuObject.parent || menuObject.parent.id == undefined) {
        const item: MenuItem = new MenuItem();
        item.title = menuObject.navtitleTranslated || menuObject.id || '';
        item.id = menuObject.id;

        // pages should be enabled by default
        item.moduleEnabled = moduleEnabled;

        if (menuObject.key) {
          item.modulePath = { path: `/${menuPath}`, queryParams: { key: encodeURI(menuObject.key) } };
        } else {
          item.modulePath = { path: `/${menuPath}` };
        }
        if (menuObject.hasChildren) {
          item.childs = this.getMyChilds(menuObjects, menuObject.id ?? '', menuPath, moduleEnabled);
        }
        menuData.push(item);
      }
    }

    return menuData;
  }

  private getMyChilds(
    menuObjects: CMSSite[] | MediaDirectory[],
    parentId: string,
    menuPath: string,
    moduleEnabled: boolean
  ): MenuItem[] {
    const childs: MenuItem[] = [];
    for (const menuObject of menuObjects) {
      if (menuObject.parent && menuObject.parent.id === parentId) {
        const item: MenuItem = new MenuItem();
        item.title = menuObject.navtitleTranslated || menuObject.id || '';
        item.id = menuObject.id;
        item.moduleEnabled = moduleEnabled;

        if (menuObject.key) {
          item.modulePath = { path: `/${menuPath}`, queryParams: { key: encodeURI(menuObject.key) } };
        } else {
          item.modulePath = { path: `/${menuPath}` };
        }

        if (menuObject.hasChildren) {
          item.childs = this.getMyChilds(menuObjects, menuObject.id || '', menuPath, moduleEnabled);
        }
        childs.push(item);
      }
    }
    return childs;
  }

  /**
   * Recursively generates and appends HTML elements to represent a menu structure based on the provided data.
   *
   * @param parent The parent HTML element to which the generated menu structure will be appended.
   * @param levelData An array of MenuItem objects representing the menu items at the current level.
   * @param levelDom The HTML element representing the current level of the menu (optional, created if not provided).
   */
  private createMenuDom(parent: HTMLElement, levelData: Array<MenuItem>, levelDom: object | null = null) {
    if (levelDom === null) levelDom = this.renderer.createElement('ul');

    for (const item of levelData) {
      if (typeof item.type !== 'undefined' && item.type === NavigationItemType.Title) {
        // Item Type: Title
        const span = this.renderer.createElement('span');
        const title = this.renderer.createText(item.title);
        this.renderer.addClass(span, 'title');
        this.renderer.appendChild(levelDom, span);
        this.renderer.appendChild(span, title);
        this.createMenuDom(parent, item.childs ?? [], levelDom);
      } else {
        const list = this.renderer.createElement('li') as HTMLLIElement;

        // add custom classname for menu item
        if (item.classNames) {
          this.renderer.addClass(list, item.classNames);
        }

        let moreActionsIcon = null;

        // id of menu item indicates that the item is a site and not a module
        if (item.siteActions && item.siteActions.length > 0 && this.hasRightForLeastOneAction(item.siteActions)) {
          // create icon which shows, that there can be executed some actions on this menu item
          moreActionsIcon = this.renderer.createElement('i');
          this.renderer.setAttribute(moreActionsIcon, 'class', 'fas fa-ellipsis-v mm-more-icons');

          // listen on click action for that icon to display div with possible actions
          this.renderer.listen(moreActionsIcon, 'click', () => {
            // check if one div is already opened

            const openActionDiv = list.querySelector('.mmenu-site-actions');
            if (openActionDiv) {
              this.renderer.removeChild(list, openActionDiv); // close opened div on selected list element
            } else {
              // close already opened divs in other list elements
              this.removeActiveActionDivs();

              // create new div
              const actionDiv = this.renderer.createElement('div');
              this.renderer.addClass(actionDiv, 'mmenu-site-actions');

              // add possible actions to opened div
              for (const action of item.siteActions ?? []) {
                if (action.scopes && this.permissionService.checkScopes(action.scopes)) {
                  const liAction = this.renderer.createElement('li');
                  this.renderer.addClass(liAction, 'site-action');
                  this.renderer.setAttribute(liAction, 'data-action', action.value);
                  this.renderer.setAttribute(liAction, 'data-message', action.message ?? '');
                  this.renderer.setAttribute(liAction, 'data-params', JSON.stringify(action.params ?? {}));
                  const liActionIcon = this.renderer.createElement('i');
                  this.renderer.setAttribute(liActionIcon, 'class', action.icon);
                  const liText = this.renderer.createText(action.name);

                  // listen on click on one of the actions and execute
                  this.renderer.listen(liAction, 'click', (liEvent) => {
                    const actionValue = liEvent.target.getAttribute('data-action');
                    const actionMessage = liEvent.target.getAttribute('data-message');
                    let actionParams = liEvent.target.getAttribute('data-params');
                    try {
                      actionParams = JSON.parse(actionParams);
                    } catch (e) {
                      actionParams = {};
                    }

                    if (!item.id || !item?.modulePath?.path) return;

                    if (actionValue === 'delete') {
                      const confirmation = confirm(actionMessage, this.translate.instant('general.confirm_title'));

                      confirmation.then((dialogResult) => {
                        if (dialogResult) {
                          if (item?.modulePath?.path && item.id) {
                            if (item.modulePath.path.indexOf('site') >= 0) {
                              this.cmsService.delete(item.id).subscribe(() => {
                                // trigger reload of menu data
                                this.layoutService.triggerLeftMenuReload.next(true);
                                this.navigateForSiteAction(['/'], list, actionParams);
                              });
                            } else if (item.modulePath.path.indexOf('mediadirectories') >= 0) {
                              this.mediaDirectoryService.delete(item.id, { mgmt: true }).subscribe(() => {
                                // trigger reload of menu data
                                this.layoutService.triggerLeftMenuReload.next(true);
                                this.navigateForSiteAction(['/'], list, actionParams);
                              });
                            }
                          }
                        }
                      });
                    } else if (actionValue === 'edit') {
                      this.navigateForSiteAction([item.modulePath.path, item.id, 'edit'], list, actionParams);
                    } else if (actionValue === 'add') {
                      this.navigateForSiteAction([item.modulePath.path, 'add'], list, actionParams);
                    } else if (actionValue === 'open') {
                      this.navigateForSiteAction([item.modulePath.path, item.id], list, actionParams);
                    } else if (actionValue === 'orderUp' || actionValue === 'orderDown') {
                      const up = actionValue === 'orderUp';

                      if (this.pages && Array.isArray(this.pages.childs)) {
                        const order = this.findAllOnSameLevel(this.pages.childs, item);
                        if (order.length > 0) {
                          const currentIndex = order.findIndex((id) => id === item.id);
                          const newIndex = currentIndex + (up ? -1 : 1);
                          if (newIndex >= 0 && newIndex < order.length) {
                            order[currentIndex] = order[newIndex];
                            order[newIndex] = item.id;
                            const definedOrder = order.filter(Boolean) as string[];
                            this.cmsService.arrange(definedOrder).subscribe(() => {
                              this.reInitDefaults();
                              this.removeMenuNodes();
                              this.loadCMSSites();
                              this.messageService.showToast(
                                TOASTTYPE.SUCCESS,
                                this.translate.instant('site.order.success')
                              );
                            });
                          } else {
                            this.messageService.showToast(
                              TOASTTYPE.ERROR,
                              this.translate.instant('site.order.canNotMove')
                            );
                          }
                        }
                      }
                    }
                  });

                  this.renderer.appendChild(liAction, liActionIcon);
                  this.renderer.appendChild(liAction, liText);
                  this.renderer.appendChild(actionDiv, liAction);
                }
              }

              this.renderer.appendChild(list, actionDiv);
            }
          });
        }

        if (item.moduleEnabled && (!item.requiredScopes || this.permissionService.checkScopes(item.requiredScopes))) {
          if (typeof item.childs !== 'undefined' && item.childs.length > 0) {
            // Item Type: Parent with childs
            const span = this.renderer.createElement('span');
            const title = this.renderer.createText(item.title);
            this.renderer.appendChild(levelDom, list);
            this.renderer.appendChild(list, span);
            this.renderer.appendChild(span, title);

            if (moreActionsIcon) {
              this.renderer.appendChild(list, moreActionsIcon);
            }

            this.createMenuDom(list, item.childs);
          } else {
            // Item Type: Childs with link
            const href = this.renderer.createElement('a');
            this.renderer.setAttribute(href, 'href', '#');

            // add on click handler
            if (item.modulePath) {
              this.renderer.listen(href, 'click', () => {
                if (!item.modulePath?.path) return;
                const path = [item.modulePath.path];
                if (item.id) {
                  path.push(item.id);
                }
                if (typeof this.prevHref !== 'undefined') {
                  this.renderer.removeClass(this.prevHref, 'active');
                }
                this.prevHref = href;
                this.renderer.addClass(href, 'active');
                this.itemClickHandler(path, item.modulePath);
              });
            }
            const title = this.renderer.createText(item.title);
            this.renderer.appendChild(levelDom, list);
            this.renderer.appendChild(list, href);

            if (moreActionsIcon) {
              this.renderer.appendChild(list, moreActionsIcon);
            }

            this.renderer.appendChild(href, title);
          }
        }
      }
    }

    this.renderer.appendChild(parent, levelDom);
  }

  private findAllOnSameLevel(levelItems: Array<MenuItem>, searchedItem: MenuItem): Array<MenuItem['id']> {
    for (const child of levelItems) {
      if (child.id === searchedItem.id) {
        return levelItems.map((i) => i.id).filter((i) => typeof i === 'string');
      }
    }
    for (const child of levelItems) {
      if (Array.isArray(child.childs)) {
        const sub = this.findAllOnSameLevel(child.childs, searchedItem);
        if (sub.length > 0) {
          return sub;
        }
      }
    }
    return [];
  }

  private hasRightForLeastOneAction(actions: IModuleActions[]) {
    for (const action of actions) {
      if (!action.scopes) continue;
      if (!this.permissionService.checkScopes(action.scopes)) {
        return false;
      }
    }
    return true;
  }

  private navigateForSiteAction(route: string[], list: HTMLLIElement, params: Params = {}) {
    this.router.navigate(route, { queryParams: params });
    this.renderer.removeChild(list, list.querySelector('.mmenu-site-actions'));
    this.layoutService.toggleLeftSidebar(false);
    this.removeActiveActionDivs();
  }

  private removeActiveActionDivs() {
    const menuLists = this.elem.nativeElement.querySelectorAll('li.mm-listitem');
    for (const listItem of menuLists) {
      const actionDivElements = listItem.querySelectorAll('.mmenu-site-actions');
      for (const actionElement of actionDivElements) {
        this.renderer.removeChild(listItem, actionElement);
      }
    }
  }

  private itemClickHandler(path: string[], modulePath: IModulePath): void {
    if (modulePath.queryParams) {
      this.router.navigate(path, { queryParams: modulePath.queryParams });
    } else if (path.length === 1 && path[0].includes('app')) {
      window.location.href = path[0];
    } else {
      this.router.navigate(path);
    }

    this.close();
  }

  private renderMenu() {
    this.mmenu = new Mmenu('#left-menu', {
      slidingSubmenus: true,
    });
    this.mmenuApi = this.mmenu.API;
  }
}
