import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CardFooterDirective } from './card-footer.directive';

@Component({
  selector: 'napp-card-footer',
  template: `
    <div class="card-footer">
      <ng-template *ngIf="footerTemplate" [ngTemplateOutlet]="footerTemplate.template"> </ng-template>
      <ng-container *ngIf="!footerTemplate">
        <dx-button
          *ngIf="btnMode !== '' && btnType !== ''"
          [stylingMode]="btnMode"
          [type]="btnType"
          [text]="btnText"
          (click)="openFeed.emit($event)"
        ></dx-button>
      </ng-container>
    </div>
  `,
  styleUrls: ['./card-footer.component.scss'],
  host: {
    class: 'napp-card-footer',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardFooterComponent implements OnInit {
  @Input() btnMode: string = '';
  @Input() btnType: string = '';
  @Input() btnText: string = '';
  @Input() footerTemplate: CardFooterDirective | undefined;

  @Output() openFeed: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
