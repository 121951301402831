<app-page [parent]="this">
  <div class="row">
    <div class="col-12 text-center">
      <div class="profile-img-container" *ngIf="profile">
        <app-profile-image
          [profile]="profile"
          [profileImage]="profileImage | async"
          width="100%"
          height="100%"
        ></app-profile-image>
        <dx-drop-down-button
          [dropDownOptions]="{ width: 230 }"
          [useSelectMode]="false"
          icon="more"
          [items]="buttonActions"
          displayExpr="name"
          (onItemClick)="onItemClick($event)"
          [showArrowIcon]="false"
        ></dx-drop-down-button>
        <app-external-file-manager
          purpose="PROFILE_PICTURE"
          [isOpen]="showPictureUpload"
          [cropAspectRatio]="1 / 1"
          (isOpenChange)="showPictureUpload = $event"
          (fileUploaded)="onFileUploaded($event)"
        >
        </app-external-file-manager>
      </div>
    </div>
  </div>
  <dx-accordion
    #accordion
    [items]="tabs"
    [collapsible]="true"
    [multiple]="false"
    [animationDuration]="300"
    *ngIf="profile && userConfig && userMetadata"
  >
    <div *dxTemplate="let item of 'title'">
      {{ 'profile.' + item | translate }}
    </div>
    <div *dxTemplate="let item of 'item'">
      <app-profile-general
        *ngIf="item === 'general'"
        [entity]="profile"
        (saved)="reloadData(item)"
      ></app-profile-general>
      <!-- <app-profile-address *ngIf="item === 'address'" [entity]="profile.address" (saved)="reloadData(item)"></app-profile-address>
      <app-profile-employee-info *ngIf="item === 'employeeinfo'" [entity]="profile.employeeInformation" (saved)="reloadData(item)"></app-profile-employee-info> -->
      <app-profile-password
        *ngIf="item === 'password'"
        [entity]="profile.password"
        (saved)="reloadData(item)"
      ></app-profile-password>
      <app-profile-settings
        *ngIf="item === 'settings'"
        [entity]="userConfig"
        (saved)="reloadData(item)"
      ></app-profile-settings>
      <app-profile-metadata
        *ngIf="item === 'metadata'"
        [entity]="userMetadata"
        (saved)="reloadData(item)"
      ></app-profile-metadata>
    </div>
  </dx-accordion>
  <div class="row">
    <div class="col-12 text-end mt-3">
      <app-button [type]="'default'" [stylingMode]="'contained'" (buttonClick)="deleteAccount()">{{
        'general.buttons.delete_account' | translate
      }}</app-button>
    </div>
  </div>
</app-page>
