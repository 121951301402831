import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DxToolbarComponent } from 'devextreme-angular';
import { DxoButtonOptions } from 'devextreme-angular/ui/nested/base/button-options';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { dxGanttToolbarItem } from 'devextreme/ui/gantt';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-page',
  templateUrl: 'app-page.component.html',
})
export class AppPageComponent implements OnInit, OnDestroy {
  /**
   * @Input `backButton` is a shorthand to create a back button
   */
  @Input() public backButtonLink?: Array<string>;

  /**
   * @Input `toolbarButtons` can specify any `dxi-item` in the toolbar
   */
  @Input() public toolbarButtons?: Array<dxGanttToolbarItem>;

  /**
   * @Input `toolbarButtonsStylingMode` will set the button type of all buttons (including the back button).
   * Defaults to 'text';
   * Do not use 'outlined' with a menu, since the background of the menu is white and the font-color is also white.
   */
  @Input() public toolbarButtonsStylingMode: 'contained' | 'outlined' | 'text' = 'text';

  @Input() public isLoading = false;
  @Input() public pageTitle?: string;
  @Input() public subTitle?: string;
  @Input() public parent!: unknown;
  @Input() public pageURL = '';
  @Input() @HostBinding('class.child-layer') public childLayer = false;
  @Input() @HostBinding('class.no-navbar-gap') public noNavbarGap = false;
  @Input() @HostBinding('class.mobile-full-width') public mobileFullWidth = false;

  @Output() public activeStatusChange = new EventEmitter();
  @Output() public toolbarDataSource: EventEmitter<DataSource> = new EventEmitter();

  @ViewChild('appPageToolbar', { static: true }) public appPageToolbar?: DxToolbarComponent;

  @HostBinding('class.active') public isActive = false;

  public backButtonOptions: Partial<DxoButtonOptions>;
  public tmplCtx = {};
  public toolbarItems: DataSource;

  private subscriptions: Array<Subscription> = [];
  private prevActive = true;

  constructor(
    private router: Router,
    private currentActivatedRoute: ActivatedRoute,
    private translate: TranslateService
  ) {
    this.backButtonOptions = {
      text: this.translate.instant('general.buttons.previous'),
      icon: 'chevronleft',
      stylingMode: this.toolbarButtonsStylingMode,
      onClick: () => {
        this.navigate();
      },
    };
    this.toolbarItems = new DataSource({
      store: new CustomStore({
        key: 'id',
        load: () => {
          return new Promise((resolve) => {
            const toolbarItems = [];
            if (this.backButtonLink) {
              toolbarItems.push({
                id: this.backButtonOptions.text,
                location: 'before',
                widget: 'dxButton',
                options: this.backButtonOptions,
              });
            }
            if (Array.isArray(this.toolbarButtons)) {
              toolbarItems.push(...this.toolbarButtons);
            }
            resolve(
              toolbarItems.map((tbi) => {
                if ((tbi as typeof tbi & { widget: 'dxButton' }).widget === 'dxButton') {
                  (
                    tbi as typeof tbi & { options: { stylingMode: 'text' | 'contained' | 'outlined' } }
                  ).options.stylingMode = this.toolbarButtonsStylingMode;
                }
                (tbi as typeof tbi & { id: string }).id = (
                  tbi as typeof tbi & { options: { text: string } }
                ).options.text;
                return tbi;
              })
            );
          });
        },
      }),
    });
  }

  public ngOnInit() {
    this.checkInputs();
    this.checkActive();
    this.subscriptions.push(
      this.router.events.pipe(filter((evt) => evt instanceof NavigationEnd)).subscribe(() => {
        this.checkActive();
      })
    );
    this.toolbarDataSource.emit(this.toolbarItems);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public navigate() {
    if (typeof this.backButtonLink !== 'undefined') {
      this.router.navigate(this.backButtonLink);
    }
  }

  private checkActive() {
    const charPos = this.router.url.indexOf('?');
    const cleanUrl = charPos !== -1 ? this.router.url.slice(0, charPos) : this.router.url;
    this.isActive = cleanUrl === this.pageURL;

    if (this.prevActive !== this.isActive) {
      this.prevActive = this.isActive;
      this.callActiveStatusChange();
    }
  }

  private callActiveStatusChange() {
    this.activeStatusChange.emit(this.isActive);
  }

  private checkInputs() {
    if (!this.parent) {
      throw new Error('Specify a parent, to build the template context.');
    }
    this.tmplCtx = { $implicit: this.parent };
  }
}
