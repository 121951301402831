import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { TagService } from '~/app/features/feed/feed-tag/service/tag.service';
import { AuthService } from '../auth/authentication.service';
import { LayoutService } from '../services/store/layout.service';

@Component({
  selector: 'app-bottom-navs',
  templateUrl: './bottom-navs.component.html',
})
export class BottomNavsComponent implements OnInit, OnDestroy {
  public isLoggedIn = false;
  public hasAnySubscriptions = false;

  private authSubscription?: Subscription;
  public subscriptionsUpdatedSubscription?: Subscription;

  constructor(public authService: AuthService, private tagService: TagService, private layoutService: LayoutService) {}

  public ngOnInit() {
    this.subscriptionsUpdatedSubscription = this.layoutService.subscriptionsUpdated.subscribe(() => {
      this.checkSubscriptions();
    });
  }

  private checkSubscriptions() {
    this.authSubscription = this.authService
      .isLoggedIn()
      .pipe(distinctUntilChanged())
      .subscribe((isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;

        if (this.isLoggedIn) {
          this.tagService.getFeedSubscription().subscribe((result) => {
            this.hasAnySubscriptions = result.length > 0;
          });
        } else {
          this.hasAnySubscriptions = false;
        }
      });
  }

  public ngOnDestroy(): void {
    this.authSubscription?.unsubscribe();
    this.subscriptionsUpdatedSubscription?.unsubscribe();
  }
}
