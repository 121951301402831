// https://stackoverflow.com/a/47138918

import { RouterHistoryService } from './core/services/router-history.service';

export let AppRouterHistory: RouterHistoryService;

/**
 * Helper to access the exported {@link AppRouterHistoryService}, needed as ES6 modules export
 * immutable bindings; see http://2ality.com/2015/07/es6-module-exports.html
 */
export function setAppRouterHistory(routerHistory: RouterHistoryService) {
  if (AppRouterHistory) {
    // Should not happen
    console?.error('Programming error: AppRouterHistoryService was already set');
  }
  else {
    AppRouterHistory = routerHistory;
  }
}
