import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { of } from 'rxjs';
import { catchError, concatMap, take } from 'rxjs/operators';
import { StorageConfig } from '../../config/storage.config';
import { AuthService } from '../auth/authentication.service';
import { ITokenResult } from '../auth/interfaces/ITokenResult';
import { MessageService } from '../services/message.service';
import { LayoutService } from '../services/store/layout.service';

@Injectable()
export class AppAuthenticationResolver implements Resolve<string | ITokenResult> {
  constructor(
    private authService: AuthService,
    private layoutService: LayoutService,
    private messageService: MessageService
  ) {}

  public resolve() {
    this.layoutService.setLoadingLocked(true);
    const loginType = localStorage.getItem(StorageConfig.LOGGEDIN_TYPE);

    if (loginType && loginType !== '') {
      this.layoutService.enableAnalytics();
      return of('logged in');
    }

    return this.authService.authenticateApp()?.pipe(
      take(1),
      catchError(() => {
        this.layoutService.setLoadingLocked(false);
        this.messageService.showErrorDialog();
        return of('No data');
      }),
      concatMap((value) => {
        return of(typeof value === 'object' && typeof value.access_token === 'string' ? 'login' : value);
      })
    );
  }
}
