import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Feedback } from '../../core/models/feedback';
import { FeedbackService } from '../../core/services/backend/feedback.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
})
export class FeedbackComponent implements OnInit {
  public formData: Feedback = new Feedback();

  public availableReasons = [
    {
      label: 'general',
      value: 'GENERAL',
    },
    {
      label: 'improvement',
      value: 'IMPROVEMENT',
    },
    {
      label: 'error',
      value: 'ERROR',
    },
  ];

  @Output() public closeFeedback = new EventEmitter();

  constructor(public translateService: TranslateService, public feedbackService: FeedbackService) {}

  public ngOnInit() {
    this.reset();
  }

  public rated(val: number) {
    this.formData.rating = val;
  }

  public send() {
    this.feedbackService.post(this.formData).subscribe(() => {
      this.closeFeedback.emit();
      this.reset();
    });
  }

  public close() {
    this.reset();
    this.closeFeedback.emit();
  }

  public reset() {
    this.formData = new Feedback();
  }
}
