import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { NappEnvironmentConfiguration } from '~/app/config/environments-configuration';
import { AuthRoutingModule } from '~/app/core/auth/auth.routing.module';
import { DomainLoginComponent } from '~/app/core/auth/login/domain-login.component';
import { LoginComponent } from '~/app/core/auth/login/login.component';
import { RegistrationComponent } from '~/app/core/auth/registration/registration.component';
import { ResetPasswordComponent } from '~/app/core/auth/reset-password/reset-password.component';
import { SharedModule } from '~/app/shared/shared.module';

@NgModule({
  declarations: [LoginComponent, DomainLoginComponent, RegistrationComponent, ResetPasswordComponent],
  exports: [LoginComponent, DomainLoginComponent, RegistrationComponent, ResetPasswordComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SharedModule, AuthRoutingModule, RecaptchaV3Module],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useFactory: (config: NappEnvironmentConfiguration) => config.captchaSiteKey,
    },
  ],
})
export class AuthModule {}
