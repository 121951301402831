import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CloudinaryImage } from '@cloudinary/url-gen';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { FileManagerService } from '~/app/shared/components/filemanager/services/filemanager.service';
import { CloudinaryService } from '../../../shared/components/filemanager/services/cloudinary.service';
import { AuthService } from '../../auth/authentication.service';
import { Profile } from './models/profile';
import { ProfileService } from './services/profile.service';

@Component({
  selector: 'app-profile-image',
  template: `<div class="author-image-container">
    <advanced-image *ngIf="profileImage" [cldImg]="profileImage" width="100%" height="100%"></advanced-image>
    <img [src]="profileImageSrc" *ngIf="profileImageSrc" width="100%" height="100%" />
    <img class="animate default-img" width="25" height="25" *ngIf="!profileImageSrc && !isLoading && !profileImage" />
    <dx-load-indicator class="item-loader" *ngIf="isLoading" height="20" width="20"></dx-load-indicator>
  </div>`,
})
export class ProfileImageComponent implements OnInit, OnDestroy {
  @Input() public profile: Profile = new Profile();
  @Input() public profileImageSrc: string | null = null;
  @Input() public profileImage: CloudinaryImage | null = null;

  public isLoading = false;
  private subscription?: Subscription;
  private subscriptionAuth?: Subscription;
  private loggedIn = false;

  constructor(
    private fileManagerService: FileManagerService,
    private sanitizer: DomSanitizer,
    private profileService: ProfileService,
    private authService: AuthService,
    private cloudinaryService: CloudinaryService
  ) {}

  public ngOnInit() {
    // this.isLoading = true;
    this.subscriptionAuth = this.authService
      .isLoggedIn()
      .pipe(distinctUntilChanged())
      .subscribe((loggedIn) => {
        this.loggedIn = loggedIn;
      });

    // this.subscription = this.profileService.profileImage.subscribe((result) => {
    //   if (this.loggedIn) {
    //     if (!result) {
    //       if (this.profile && this.profile.picture && this.profile.picture.id) {
    //         this.fileManagerService
    //           .getFileData(this.profile.picture.id, FILESIZEVERSION.AVATAR_MEDIUM)
    //           .subscribe((imageData) => {
    //             this.profileService.refreshProfileImage(imageData);
    //           });
    //       } else {
    //         this.profileService.fetchAll().subscribe((profileResult: Profile[]) => {
    //           if (profileResult[0].picture && profileResult[0].picture.id) {
    //             this.fileManagerService
    //               .getFileData(profileResult[0].picture.id, FILESIZEVERSION.AVATAR_MEDIUM)
    //               .subscribe((imageData) => {
    //                 this.profileService.refreshProfileImage(imageData);
    //               });
    //           } else {
    //             this.isLoading = false;
    //             this.profileImageSrc = null;
    //           }
    //         });
    //       }
    //     } else if (result) {
    //       const objectURL = URL.createObjectURL(result);
    //       this.profileImageSrc = this.sanitizer.bypassSecurityTrustUrl(objectURL) as string;
    //       this.isLoading = false;
    //     } else {
    //       this.isLoading = false;
    //     }
    //   } else {
    //     this.profileImageSrc = null;
    //     this.isLoading = false;
    //   }
    // });
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.subscriptionAuth?.unsubscribe();
  }
}
