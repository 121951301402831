import { TranslatableEntity } from '~/app/core/models/translatable-entity';
import { Tag } from '../../feed/feed-tag/models/tag';
import { Author } from '../../feed/models/author';
import { Context } from '../../mgmt/context/models/context';
import { CMSSiteContents } from './cms-site-contents';
import { CMSSiteLanguage } from './cms-site-language';

export class CMSSite extends TranslatableEntity<CMSSite, CMSSiteLanguage> {
  public navtitleTranslated?: string;
  public titleTranslated?: string;

  constructor(
    public to?: string,
    public from?: string,
    public searchable: boolean = false,
    public contents: CMSSiteContents[] = [],
    public parent: {
      id?: string;
    } = {},
    public key?: string,
    /* public */ languages?: CMSSiteLanguage[],
    public sort?: number,
    public hasChildren?: boolean,
    public author?: Author,
    public contexts: Context[] = [],
    public tags: Tag[] = []
  ) {
    super(languages ?? []);
  }

  public updateTranslations(): void {
    const translation = this.getPropertyTranslation(this.translatedEntities, 'title', 'navtitle');
    this.titleTranslated = translation?.title?.toString() ?? this.id;
    this.navtitleTranslated = translation?.navtitle?.toString() ?? this.id;
  }

  public override deserialize(object: this): this {
    if (typeof object.contents !== 'undefined') {
      object.contents = object.contents.map((item) => new CMSSiteContents().deserialize(item));
    }
    object.tags = object.tags ? object.tags.map((item) => new Tag().deserialize(item)) : [];
    return super.deserialize(object);
  }
}
