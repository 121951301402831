import { Directive, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DxFormComponent } from 'devextreme-angular';
import { EventInfo } from 'devextreme/events';
import dxForm, { EditorEnterKeyEvent } from 'devextreme/ui/form';
import { BaseEntity } from '../../../core/models/base-entity.model';
import { BaseService } from '../../../core/services/base/base.service';
import { KeyboardNavigableComponent } from '../keyboard-navigable.component';

export enum Mode {
  Read = 'read',
  Write = 'write',
}

export enum SafeType {
  Put = 'put',
  Patch = 'patch',
  Post = 'post',
}

@Directive()
export abstract class BaseAccordionPanel<T1 extends BaseEntity<T1>, T2 extends BaseService<T1>>
  extends KeyboardNavigableComponent
  implements OnInit
{
  @Input() public entity?: T1;
  @Output() public saved = new EventEmitter<T1>();
  @Input() public openPanelOnInit = false;
  @Input() public forceWriteMode = false;
  @Input() public isEditable = true;
  @Input() public showCancelButton = true;
  @ViewChild(DxFormComponent) public form?: DxFormComponent;

  public isPanelOpen: boolean;
  public panelMode: Mode;
  public modeType = Mode;
  public safeType = SafeType.Post;
  public abstract formData: T1;

  constructor(protected panelService: T2, protected translateService: TranslateService) {
    super();
    this.panelMode = Mode.Read;
    this.isPanelOpen = false;
  }

  public ngOnInit(): void {
    if (this.openPanelOnInit) {
      this.isPanelOpen = true;
    }
    if (this.forceWriteMode) {
      this.panelMode = Mode.Write;
    }
  }

  public abstract createForm(): void;

  public isValid(): boolean {
    if (this.form?.instance?.validate()?.isValid) {
      return true;
    } else {
      return false;
    }
  }

  public setWriteMode(): void {
    this.panelMode = Mode.Write;
  }

  public setReadMode(): void {
    this.panelMode = Mode.Read;
  }

  public save(saveType = SafeType.Patch, event?: EventInfo<dxForm>): void {
    if (this.isValid()) {
      this.blurAllFields(event);
      this.panelService[saveType](this.formData, this.entity?.id).subscribe((savedEntity) => {
        this.saved.emit(savedEntity);
        this.panelMode = Mode.Read;
      });
      if (this.safeType === SafeType.Post) {
        this.safeType = SafeType.Patch;
      }
    } else {
      if (!this.form) return;
      this.focusNextField(event as EditorEnterKeyEvent, this.form.instance);
    }
  }

  public cancel(): void {
    this.setReadMode();
    this.createForm();
  }
}
