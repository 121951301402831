<router-outlet></router-outlet>
<dx-load-panel
    #loadPanel
    shadingColor="rgba(0,0,0,0.4)"
    [visible]="layoutService.getLoadingLocked()"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="true"
    [closeOnOutsideClick]="false"
    style="height: 100vh; width: 100%; margin-top: calc(-1 * var(--sat));">
</dx-load-panel>
