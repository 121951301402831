import { BaseEntity } from '../../../../../core/models/base-entity.model';
import { User } from '../../../../mgmt/user/models/user';
import { Author } from '../../../models/author';

export class PostComment extends BaseEntity<PostComment> {
  constructor(
    public date?: Date,
    public comment?: string,
    public author?: Author,
    public mentionedUsers?: Array<User>
  ) {
    super();
  }

  public override deserialize(object: typeof this): this {
    object.mentionedUsers = object.mentionedUsers
      ? object.mentionedUsers.map((item) => new User().deserialize(item))
      : [];
    return super.deserialize(object);
  }
}
