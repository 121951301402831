import { BaseEntity } from '~/app/core/models/base-entity.model';
import { TranslatableEntity } from '~/app/core/models/translatable-entity';
import { MediaDirectory } from '~/app/features/document/models/media-directory';
import { PostLanguage } from '~/app/features/feed/models/post-language';
import { User } from '~/app/features/mgmt/user/models/user';
import { FILECATEGORYTYPES } from '../services/filemanager.service';

export class MediaFileSearchInfo extends BaseEntity<MediaFile> {
  constructor(public highlights: string[] = [], public hitCount: string = '') {
    super();
  }
}

export type MediaFileExtension = {
  name: string;
  mimetype: string;
};

export class MediaFile extends TranslatableEntity<MediaFile, PostLanguage> {
  public nameTranslated?: string;
  public descriptionTranslated?: string;

  constructor(
    public name = '',
    public file?: File,
    public category?: FILECATEGORYTYPES,
    /* public */ languages: PostLanguage[] = [],
    public metadata?: string,
    public imageData?: Blob,
    public publicHash?: string,
    public directory?: MediaDirectory,
    public user?: User,
    public searchInfo?: {
      highlight: string;
      hitCount: number;
    },
    public extension?: MediaFileExtension
  ) {
    super(languages);
  }

  public updateTranslations(): void {
    const translation = this.getPropertyTranslation(this.translatedEntities, 'title', 'description');
    if (translation) {
      this.nameTranslated = translation.title as string;
      this.descriptionTranslated = translation.description as string;
    }
  }

  public evaluateFileIcon(fileName: string) {
    const charPos = fileName.indexOf('.');
    const fileExtension = fileName.substring(charPos + 1);
    let fileTypeIcon: string;

    switch (fileExtension) {
      case 'pdf':
        fileTypeIcon = '-pdf';
        break;
      case 'docx':
      case 'doc':
        fileTypeIcon = '-word';
        break;
      case 'xlsx':
      case 'xls':
        fileTypeIcon = '-excel';
        break;
      case 'pptx':
        fileTypeIcon = '-powerpoint';
        break;
      case 'txt':
        fileTypeIcon = '-alt';
        break;
      case 'csv':
        fileTypeIcon = '-csv';
        break;
      case 'jpeg':
      case 'jpg':
      case 'png':
      case 'gif':
      case 'svg':
        fileTypeIcon = '-image';
        break;
      case 'zip':
        fileTypeIcon = '-archive';
        break;
      default:
        fileTypeIcon = '';
        break;
    }

    return fileTypeIcon;
  }
}
