import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, mergeMap, retry } from 'rxjs/operators';
import { AuthService } from '~/app/core/auth/authentication.service';
import { BaseService, FetchAllOptions } from '~/app/core/services/base/base.service';

import { Profile } from '../models/profile';

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends BaseService<Profile> {
  public profileImageSubject = new BehaviorSubject<Blob | null>(null);
  public profileImage = this.profileImageSubject.asObservable();

  constructor(public override http: HttpClient, private authService: AuthService) {
    super(http, Profile);
    this.authService.refreshProfileImage.subscribe(this.refreshProfileImage.bind(this, null));
  }

  public refreshProfileImage(imageData: Blob | null) {
    this.profileImageSubject.next(imageData);
  }

  public deleteProfile(password: string): Observable<Profile> {
    return this.apiStrategy.getHeaders().pipe(
      mergeMap((headers) => {
        const headersWithPassword = headers.append('password', password);
        return this.http.delete<Profile>(this.getServiceUrl(), {
          headers: headersWithPassword,
        });
      })
    );
  }

  public loadAllProfiles({ httpParameters = [] }: FetchAllOptions = {}): Observable<Profile[]> {
    let params = new HttpParams();
    params = params.set(
      'expand',
      'address,employeeInformation/location,employeeInformation/department,employeeInformation/functionality'
    );

    httpParameters.forEach((item) => {
      params = params.set(item.key, String(item.value));
    });

    return this.apiStrategy.getHeaders('application/json', true, false).pipe(
      mergeMap((headers) => {
        return this.http
          .get(this.apiStrategy.getServiceUrl(['profiles']), {
            headers,
            observe: 'response',
            params,
          })
          .pipe(
            retry(2),
            map((response) => {
              const data = response.body;
              const header = response.headers;

              this.entitiesTotal = Number(header.get('X-Pagination-Count'));
              const responseList: Profile[] = [];

              if (data instanceof Array) {
                data.forEach((item) => {
                  responseList.push(new Profile().deserialize(item));
                });
                this.entities = responseList;
              } else {
                responseList.push(new Profile().deserialize(data as Profile));
                this.entities = responseList;
              }
              return this.entities;
            })
          );
      })
    );
  }

  protected getServiceUrl(): string {
    return this.apiStrategy.getServiceUrl(['profile']);
  }
}
