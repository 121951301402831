import { Directive, ElementRef, HostBinding, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppAngularBridge } from '../../app-bridge';
import { BridgeNativeErrors } from '../../core/services/angular-bridge.types';
import { MessageService, TOASTTYPE } from '../../core/services/message.service';

const TARGET_BLANK = '_blank';
const REL_NOOPENER = 'noopener';

@Directive({
  // tslint:disable-next-line:directive-selector
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'a[href]',
})
export class LinkHandlingDirective implements OnChanges {
  @HostBinding('attr.rel') public relAttr?: string;
  @HostBinding('attr.target') public targetAttr?: string;
  @HostBinding('attr.href') public hrefAttr?: string;
  @Input() public rel?: string;
  @Input() public target?: string;
  @Input() public href?: string;

  private nativeElement?: HTMLAnchorElement;

  public static handleClick(
    event: MouseEvent,
    target: HTMLAnchorElement,
    messageService: MessageService,
    translate: TranslateService
  ) {
    if (
      target.getAttribute('target') === TARGET_BLANK ||
      target.getAttribute('rel') === REL_NOOPENER ||
      target.className.indexOf('is-pdf') !== -1 ||
      LinkHandlingDirective._isLinkExternal(target.href)
    ) {
      if (AppAngularBridge.isNative()) {
        event.preventDefault();
        LinkHandlingDirective.openBridge(target.href, messageService, translate);
      }
    }
  }

  public static openLinkInNewWindow(href: string, messageService: MessageService, translate: TranslateService) {
    if (AppAngularBridge.isNative()) {
      LinkHandlingDirective.openBridge(href, messageService, translate);
    } else {
      window.open(href, TARGET_BLANK, REL_NOOPENER);
    }
  }

  private static openBridge(href: string, messageService: MessageService, translate: TranslateService) {
    const openURLObservable = AppAngularBridge.openURL(href);
    if (openURLObservable) {
      openURLObservable.subscribe({
        next: () => {},
        error: (err) => {
          if ('code' in err && err.code === BridgeNativeErrors.InvalidURL) {
            messageService.showToast(TOASTTYPE.ERROR, translate.instant('message.error.invalidURL'));
          }
        },
      });
    }
  }

  private static _isLinkExternal(href: string) {
    return String(href).indexOf(location.hostname) === -1;
  }

  constructor(
    private link: ElementRef<HTMLAnchorElement>,
    private messageService: MessageService,
    private translate: TranslateService
  ) {
    if (!/^(javascript|mailto|tel):/.test(this.link.nativeElement.href)) {
      this.nativeElement = this.link.nativeElement;
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    // target and rel first, so that href could overwrite them
    if (typeof changes['target'] === 'object') {
      this.targetAttr = this.target;
    }
    if (typeof changes['rel'] === 'object') {
      this.relAttr = this.rel;
    }
    if (typeof changes['href'] === 'object') {
      this.hrefAttr = this.href;

      if (/^(javascript|mailto|tel):/.test(this.href ?? '')) {
        this.nativeElement = undefined;
      } else {
        if (!this.nativeElement) {
          this.nativeElement = this.link.nativeElement;
        }
        if (LinkHandlingDirective._isLinkExternal(this.href ?? '')) {
          // ensure to open in new window
          this.relAttr = REL_NOOPENER;
          this.targetAttr = TARGET_BLANK;
        }
      }
    }
  }

  @HostListener('click', ['$event']) public onClick(e: MouseEvent) {
    if (this.nativeElement) {
      LinkHandlingDirective.handleClick(e, this.nativeElement, this.messageService, this.translate);
    }
  }
}
