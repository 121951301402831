import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ExternalFileService } from './external-file.service';
import { ExternalFile, FilePurpose } from './types';

@Component({
  selector: 'app-external-file-manager',
  templateUrl: './external-file-manager.component.html',
})
export class ExternalFileManagerComponent {
  @Input() public isOpen!: boolean;
  @Input() public purpose!: FilePurpose;
  @Input() public cropAspectRatio?: number;
  @Input() public showLibrary = true;

  @Output() public isOpenChange = new EventEmitter<boolean>();
  @Output() public fileUploaded = new EventEmitter<ExternalFile>();

  public show = false;

  constructor(private externalFileService: ExternalFileService) {}

  public onVisibleChange(visible: boolean) {
    this.isOpenChange.emit(visible);
    if (!visible) {
      // wait for animation to finish
      // then remove the component to reset the state
      setTimeout(() => {
        this.show = false;
      }, 100);
    } else {
      this.show = true;
    }
  }

  onFileUploaded(result: ExternalFile) {
    this.isOpen = false;
    this.isOpenChange.emit(this.isOpen);
    this.fileUploaded.emit(result);
  }
}
