<app-popup #popup [title]="'filemanager.title' | translate" [(visible)]="this.visible">
  <div class="popup-container file-manager">
    <dx-tab-panel
      [selectedIndex]="0"
      [loop]="false"
      [animationEnabled]="false"
      [swipeEnabled]="false"
      [focusStateEnabled]="false"
      [deferRendering]="true"
      (onTitleClick)="changeTab($event)"
      [dataSource]="tabs"
      #fileManagerTabPanel
      *ngIf="tabs.length > 1"
    >
      <div *dxTemplate="let tab of 'title'">
        <span>{{ tab === tabType.FILEBROWSER ? tabTitles.filebrowser : tabTitles.fileupload }}</span>
      </div>
      <div *dxTemplate="let tab of 'item'">
        <app-file-upload
          [enableCrop]="enableCrop"
          [cropAspectRatio]="cropAspectRatio"
          [fileCategoryType]="fileCategoryType"
          [acceptedFileTypes]="acceptedFileTypes || null"
          [updateFile]="updateFile"
          [fileIdToUpdate]="fileIdToUpdate || ''"
          [fileDirectoryId]="fileDirectoryId || ''"
          (apply)="onSelect($event)"
          (videoUploaded)="onVideoSelect($event)"
          (imageUploaded)="onImageUploaded($event)"
          (fileUploaded)="onFileUploaded($event)"
          [resetInput]="this.resetInput"
          *ngIf="this.visible && tab === tabType.FILEUPLOAD"
        ></app-file-upload>
        <app-file-browser
          [activeTab]="activeTab"
          #fileBrowser
          [fileCategoryType]="fileCategoryType"
          [acceptedFileTypes]="acceptedFileTypes || null"
          (apply)="onSelect($event)"
          *ngIf="this.visible && tab === tabType.FILEBROWSER"
        ></app-file-browser>
      </div>
    </dx-tab-panel>
    <app-file-upload
      [enableCrop]="enableCrop"
      [cropAspectRatio]="cropAspectRatio"
      [fileCategoryType]="fileCategoryType"
      [acceptedFileTypes]="acceptedFileTypes ?? null"
      [updateFile]="updateFile"
      [fileIdToUpdate]="fileIdToUpdate ?? ''"
      [fileDirectoryId]="fileDirectoryId ?? ''"
      (apply)="onSelect($event)"
      (videoUploaded)="onVideoSelect($event)"
      [resetInput]="this.resetInput"
      *ngIf="this.visible && tabs.length === 1 && tabs[0] === tabType.FILEUPLOAD"
    ></app-file-upload>
    <app-file-browser
      [activeTab]="activeTab"
      #fileBrowser
      [fileCategoryType]="fileCategoryType"
      [acceptedFileTypes]="acceptedFileTypes ?? ''"
      (apply)="onSelect($event)"
      *ngIf="this.visible && tabs.length === 1 && tabs[0] === tabType.FILEBROWSER"
    ></app-file-browser>
  </div>
</app-popup>
