import { Component, Inject, OnInit } from '@angular/core';
import { ENVIRONMENT_CONFIGURATION } from '@softwarehaus/util-configuration';
import config from 'devextreme/core/config';
import { NappEnvironmentConfiguration } from '~/app/config/environments-configuration';
import { ThemeService } from '~/app/core/services/theme.service';
import { LayoutService } from './core/services/store/layout.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    public layoutService: LayoutService,
    @Inject(ENVIRONMENT_CONFIGURATION) private environmentConfig: NappEnvironmentConfiguration,
    private themeService: ThemeService
  ) {
    config({ defaultCurrency: 'EUR' });
  }

  ngOnInit() {
    const theme = this.environmentConfig.theme;
    this.themeService.loadTheme(theme);
  }
}
