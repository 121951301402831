<app-scroll-view-with-bottom-bar>
  <div class="row">
    <div class="col-12">
      <ul class="file-browser">
        <li *ngFor="let file of files">
          <app-file-item [selectedFiles]="selectedFiles"
                         [mediaFile]="file"
                         [editMode]="editMode"
                         (itemClick)="setSelection($event)"
                         (itemRemoved)="removeItemFromList($event)"
          ></app-file-item>
        </li>
      </ul>
    </div>
  </div>
  <div class="bottom-bar">
    <dx-button class="small"
               [type]="'default'"
               [stylingMode]="'text'"
               (onClick)="toggleEditMode()"
               [text]="'general.buttons.' + (editMode ? 'done' : 'edit') | translate"
    ></dx-button>
    <app-button [disabled]='selectedFiles.length === 0 || editMode' (buttonClick)="onApply()">{{'filemanager.apply' | translate}}</app-button>
  </div>
</app-scroll-view-with-bottom-bar>
