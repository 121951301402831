export function pseudoRandomBytes(length: number) {
  const buf = [];
  let bufLen = 0;
  while (bufLen < length) {
    const add = Math.random().toString(16).slice(2);
    buf.push(add);
    bufLen += add.length;
  }
  return buf.join('').slice(0, length);
}
