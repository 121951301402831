import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '~/app/core/auth/authentication.service';
import { LayoutService } from '~/app/core/services/store/layout.service';

/**
 * Description: Checks if user want's to access auth part of application
 */
@Injectable({
  providedIn: 'root',
})
export class RootGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private layoutService: LayoutService) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    next.queryParams['s']
      ? this.router.navigate(['/'], { queryParams: next.queryParams })
      : this.router.navigate(['/']);
    this.layoutService.toggleUserSidebar(true);
    const nextUrl = state.url.indexOf('?') >= 0 ? state.url.substr(0, state.url.indexOf('?')) : state.url;
    switch (nextUrl) {
      case '/auth/login':
      case '/auth/domain-login':
      case '/auth/confirm-registration':
        return this.authService.logoutUserIfNeeded();
      case '/auth/register':
        this.authService.registrateUser = true;
        return true;
      case '/auth/reset-password':
        this.authService.resetPasswordUser = true;
        return true;
    }
    return false;
  }
}
