import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FieldDataChangedEvent } from 'devextreme/ui/form';
import { clone } from 'lodash';
import { forkJoin } from 'rxjs';
import { User } from '~/app/features/mgmt/user/models/user';
import { UserService } from '~/app/features/mgmt/user/services/user.service';
import { BaseAccordionPanel, SafeType } from '~/app/shared/components/accordion/base-accordion-panel';
import { ProfileMetadata } from '../models/profile-metadata';
import { ProfileMetadataService } from '../services/profile-metadata.service';

@Component({
  selector: 'app-profile-metadata',
  templateUrl: './profile-metadata.component.html',
})
export class ProfileMetadataComponent
  extends BaseAccordionPanel<ProfileMetadata, ProfileMetadataService>
  implements OnInit
{
  public formData: ProfileMetadata = new ProfileMetadata();
  public loadedUsers: Array<User> = [];
  public users: Array<User> = [];
  public usersLoaded = false;

  constructor(
    protected override translateService: TranslateService,
    protected userMetadataService: ProfileMetadataService,
    public translate: TranslateService,
    private userService: UserService
  ) {
    super(userMetadataService, translateService);
  }

  public override ngOnInit() {
    if (this.entity?.id) {
      this.safeType = SafeType.Patch;
    }
    this.createForm();
  }

  public createForm() {
    if (!this.entity) return;
    this.formData = clone(this.entity);
    this.loadUsers();
  }

  public onFieldChange(event: FieldDataChangedEvent): void {
    if (event.dataField === 'blockedUsers') {
      this.users = this.users.filter((u) => event.value.includes(u.id));
    }
  }

  private loadUsers() {
    this.usersLoaded = false;
    forkJoin(this.formData.blockedUsers.map((userId) => this.userService.getUserInfo(userId))).subscribe((users) => {
      this.loadedUsers = users;
      this.users = this.loadedUsers.slice();
      this.usersLoaded = true;
    });
  }
}
