import { Component } from '@angular/core';
import { FeedTypes } from '../../../features/feed/services/feed.service';

@Component({
  selector: 'app-mobilefooternav',
  templateUrl: './mobilefooternav.component.html',
})
export class MobileFooterNavComponent {
  public menuBtnPositionClass = '';
  public menuBtnIcon = 'fas fa-user'; // set default
  public feedTypes = FeedTypes;

  constructor() {}

  public changePositionClass(position: string, icon: string): void {
    this.menuBtnPositionClass = position;
    this.menuBtnIcon = icon;
  }
}
