import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Resolve } from '@angular/router';
import { of } from 'rxjs';
import { catchError, filter, flatMap, take, timeout as timeoutPipe } from 'rxjs/operators';
import { JSON } from '~/app/core/JSON.interface';
import { MessageService } from '../services/message.service';
import { LayoutService } from '../services/store/layout.service';
import { AppTranslationService } from '../services/translation/app-translation.service';

export const APP_CONFIG_RESOLVER_TIMEOUT = new InjectionToken<number>('APP_CONFIG_RESOLVER_TIMEOUT');

@Injectable()
export class AppConfigResolver implements Resolve<JSON> {
  constructor(
    public appTranslationService: AppTranslationService,
    private layoutService: LayoutService,
    private messageService: MessageService,
    @Optional() @Inject(APP_CONFIG_RESOLVER_TIMEOUT) private timeout: number
  ) {
    if (!this.timeout || this.timeout <= 0) {
      this.timeout = 30_000;
    }
  }

  public resolve() {
    if (this.appTranslationService.hasStoredLanguages()) {
      return this.appTranslationService.setAppTranslationUserPreferredLang();
    } else {
      return this.appTranslationService.hasLanguagesStored.pipe(
        filter((value) => value),
        take(1),
        flatMap(() => this.appTranslationService.setAppTranslationUserPreferredLang()),
        timeoutPipe(this.timeout),
        catchError(() => {
          this.layoutService.setLoadingLocked(false);
          this.messageService.showErrorDialog();
          return of('No data');
        })
      );
    }
  }
}
