import { BaseEntity } from '~/app/core/models/base-entity.model';
import { Context } from '~/app/features/mgmt/context/models/context';
import { ExternalFile } from '../../../../shared/components/external-file/types';
import { ProfileAddress } from './profile-address';
import { ProfileEmployeeInfo } from './profile-employee-info';
import { ProfilePassword } from './profile-password';

export class Profile extends BaseEntity<Profile> {
  constructor(
    public address: ProfileAddress = new ProfileAddress(),
    public employeeInformation: ProfileEmployeeInfo = new ProfileEmployeeInfo(),
    public password: ProfilePassword = new ProfilePassword(),
    public firstname?: string,
    public lastname?: string,
    public email?: string,
    public username?: string,
    public phone?: string,
    public picture?: {
      id?: string;
    },
    public profilePicture?: ExternalFile,
    public contexts: Array<Context> = []
  ) {
    super();
  }

  public override deserialize(object: this): this {
    object.address = object.address ? new ProfileAddress().deserialize(object.address) : object.address;
    object.employeeInformation = object.employeeInformation
      ? new ProfileEmployeeInfo().deserialize(object.employeeInformation)
      : object.employeeInformation;
    return super.deserialize(object);
  }
}
