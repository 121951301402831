import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { flatten } from 'lodash';
import { flatMap } from 'rxjs/operators';
import { Author } from '~/app/features/feed/models/author';
import { FeedPostType } from '~/app/features/feed/models/feed-post-type';
import { BaseService, IServiceUrlConfig } from '../../../core/services/base/base.service';
import { FeedPost } from '../models/feed-post';

export enum FeedTypes {
  DEFAULT = 'default',
  SUBSCRIBED = 'subscribed',
  CREATED = 'created',
  BOOKMARKED = 'bookmarked',
  NOT_APPROVED = 'not-approved',
}

export enum FilterFeedTypes {
  ALL = 'all',
  // EVENT = 'event',
  NEWS = 'news',
  // JOBS = 'job',
}

// must be > 12, since 12 posts are displayed at once on a big screen
export const defaultInfiniteFeedLimit = 18;
export const defaultInfiniteFeedSkip = 0;

class DefaultFeedPost extends FeedPost {
  constructor() {
    super(
      '',
      '',
      new Author('', '', '', '', { id: '' }),
      [],
      [],
      new FeedPostType('', ''),
      false,
      0,
      false,
      false,
      false,
      ''
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class FeedService extends BaseService<FeedPost> {
  constructor(public override http: HttpClient) {
    super(http, DefaultFeedPost);
  }

  public getNotApprovedCount() {
    return this.apiStrategy.getHeaders('application/json', true, false).pipe(
      flatMap((headers) => {
        return this.http.get<{ count: number }>(this.apiStrategy.getServiceUrl(['feed', 'not-approved-count']), {
          headers,
        });
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected getServiceUrl(entityId: string = '', serviceUrlConfig: IServiceUrlConfig = {}): string {
    let url = ['feed'];
    if (serviceUrlConfig && serviceUrlConfig.path) {
      url = flatten([url, serviceUrlConfig.path]);
    }
    return this.apiStrategy.getServiceUrl(url);
  }
}
