<app-scroll-view-with-bottom-bar class="file-upload">
  <div class="upload-zone" [class.crop-mode]="cropMode">
    <div [hidden]="this.cropMode" class="col file-input">
      <h3>{{ 'filemanager.uploadtext' | translate }}</h3>
      <p>{{ 'filemanager.uploadtext_or' | translate }}</p>
      <input
        id="fileChooser"
        *ngIf="!resetInput"
        type="file"
        [accept]="getAcceptedFileTypes()"
        multiple="false"
        (change)="fileChangeEvent($event)"
      />
    </div>
    <app-external-file-image-cropper
      *ngIf="cropMode"
      [aspectRatio]="cropAspectRatio"
      [imageFile]="file"
      (cropperReady)="isLoading = false"
      (imageCropped)="imageCropped($event)"
    >
    </app-external-file-image-cropper>
    <dx-load-indicator *ngIf="isLoading" class="item-loader" height="20" width="20"></dx-load-indicator>
  </div>
  <div class="bottom-bar" *ngIf="this.cropMode">
    <dx-button class="small" type="normal" stylingMode="text" (onClick)="cancel()">{{
      'general.buttons.cancel' | translate
    }}</dx-button>
    <dx-button (onClick)="uploadFile(croppedImage)" type="default">{{ 'general.buttons.save' | translate }}</dx-button>
  </div>
</app-scroll-view-with-bottom-bar>
