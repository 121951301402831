import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { BaseService, IServiceUrlConfig } from '../../../../core/services/base/base.service';
import { Tag } from '../models/tag';

@Injectable({
  providedIn: 'root',
})
export class TagService extends BaseService<Tag> {
  constructor(public override http: HttpClient) {
    super(http, Tag);
  }

  public getFeedSubscription(): Observable<Tag[]> {
    return this.apiStrategy.getHeaders().pipe(
      flatMap((headers) => {
        return this.http
          .get<Tag[]>(this.apiStrategy.getServiceUrl(['tag', 'subscription']), {
            headers,
          })
          .pipe(
            map((response: Tag[]) => {
              const responseList: Tag[] = [];
              response.forEach((item) => {
                responseList.push(new this.parentModel().deserialize(item));
              });
              return responseList;
            })
          );
      })
    );
  }

  public subscribeTag(tagId: string) {
    return this.apiStrategy.getHeaders().pipe(
      flatMap((headers) => {
        return this.http.post<Tag>(this.apiStrategy.getServiceUrl(['tag', tagId, 'subscribe']), null, {
          headers,
        });
      })
    );
  }

  public unsubscribeTag(tagId: string) {
    return this.apiStrategy.getHeaders().pipe(
      flatMap((headers) => {
        return this.http.delete<Tag>(this.apiStrategy.getServiceUrl(['tag', tagId, 'subscribe']), {
          headers,
        });
      })
    );
  }

  protected getServiceUrl(entityId?: string, serviceUrlConfig?: IServiceUrlConfig): string {
    const url = this.generateServiceURL('tag', entityId, serviceUrlConfig);
    return this.apiStrategy.getServiceUrl(url);
  }
}
