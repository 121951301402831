import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExternalFileSelection } from './external-file-chooser-item.component';
import { ExternalFileService } from './external-file.service';
import { ExternalFile, FilePurpose } from './types';

@Component({
  selector: 'app-external-file-chooser',
  templateUrl: './external-file-chooser.component.html',
})
export class ExternalFileChooserComponent implements OnInit {
  @Input() public purpose?: FilePurpose;

  @Output() public fileChosen = new EventEmitter<ExternalFile>();

  public files: ExternalFile[] = [];
  public editMode = false;
  public selectedFiles: ExternalFile[] = [];

  constructor(private externalFileService: ExternalFileService) {}

  ngOnInit(): void {
    this.externalFileService.getExternalFileLibrary(this.purpose).subscribe((result) => {
      this.files = result;
    });
  }

  public toggleEditMode() {
    this.selectedFiles = [];

    this.editMode = !this.editMode;
  }

  setSelection({ file, active }: ExternalFileSelection) {
    this.selectedFiles = [];

    if (active) {
      this.selectedFiles.push(file);
    }

    // TODO: multiple selection
  }

  public removeItemFromList(file: ExternalFile) {
    this.selectedFiles = [];

    this.files = this.files?.filter((f) => f.id !== file.id);
  }

  apply() {
    if (!this.selectedFiles[0]) return;
    this.fileChosen.emit(this.selectedFiles[0]);
  }
}
