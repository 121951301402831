<app-scroll-view-with-bottom-bar>
  <dx-validation-group #contentReportVG>
    <div class="row" *ngIf="report">
      <div class="col my-3 pe-4 pr-md-3">
        <div class="dx-field">
          <div class="dx-field-label">{{ 'reportContent.reportedItem' | translate }}</div>
          <div class="dx-field-value">
            <span *ngIf="report.post">
              Feed Post: {{ report.post.titleTranslated }} <br />
              {{ report.post.author.firstname }} {{ report.post.author.lastname }}
            </span>
            <span *ngIf="report.comment">
              {{ 'fields.comment' | translate }}: {{ stripAllTags(report.comment.comment ?? '') }} <br />
              {{ report.comment.author?.firstname }} {{ report.comment.author?.lastname }}
            </span>
            <span *ngIf="report.user">
              {{ 'user.mgmt.navLabel' | translate }}: {{ report.user.firstname }} {{ report.user.lastname }}
            </span>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">{{ 'reportContent.message' | translate }}</div>
          <div class="dx-field-value">
            <dx-text-area [(value)]="report.message" height="150">
              <dx-validator>
                <dxi-validation-rule type="required"></dxi-validation-rule>
              </dx-validator>
            </dx-text-area>
          </div>
        </div>
        <div class="dx-field" *ngIf="!this.isLoggedIn">
          <div class="dx-field-label">{{ 'reportContent.yourName' | translate }}</div>
          <div class="dx-field-value">
            <dx-text-box [(value)]="report.authorName">
              <dx-validator>
                <dxi-validation-rule type="required"></dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
          </div>
        </div>
        <div class="dx-field" *ngIf="!this.isLoggedIn">
          <div class="dx-field-label">{{ 'reportContent.yourEmail' | translate }}</div>
          <div class="dx-field-value">
            <dx-text-box [(value)]="report.authorEmail">
              <dx-validator>
                <dxi-validation-rule type="required"></dxi-validation-rule>
                <dxi-validation-rule type="email"></dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-bar text-end">
      <dx-button class="small me-2" [type]="'normal'" [stylingMode]="'text'" (onClick)="close()">{{
        'general.buttons.cancel' | translate
      }}</dx-button>
      <app-button (buttonClick)="send()">{{ 'general.buttons.send' | translate }}</app-button>
    </div>
  </dx-validation-group>
</app-scroll-view-with-bottom-bar>
