import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DomainLoginComponent } from '~/app/core/auth/login/domain-login.component';
import { LoginComponent } from '~/app/core/auth/login/login.component';
import { RegistrationComponent } from '~/app/core/auth/registration/registration.component';
import { ResetPasswordComponent } from '~/app/core/auth/reset-password/reset-password.component';

const routes: Routes = [
  {
    component: LoginComponent,
    path: 'login',
  },
  {
    component: LoginComponent,
    path: 'confirm-registration',
  },
  {
    component: DomainLoginComponent,
    path: 'domain-login',
  },
  {
    component: DomainLoginComponent,
    path: 'confirm-domain-registration',
  },
  {
    component: RegistrationComponent,
    path: 'register',
  },
  {
    component: ResetPasswordComponent,
    path: 'reset-password',
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
})
export class AuthRoutingModule {}
