<div class="attachment-preview image-preview" [class.active]="isActive(this.selectedFiles ?? [])" (click)="onClick()">
  <div class="thumbnail">
    <div class="centered" [class.file-selection]="editMode">
      <img [class.loaded]="imageData" [class.active]="active" [src]="imageData" width="100%" height="100%" />
      <div *ngIf="editMode" class="post-teaser-image-overlay">
        <span class="icon-overlay">
          <i class="fas fa-times"></i>
        </span>
      </div>
    </div>
  </div>
  <dx-load-indicator class="item-loader" *ngIf="!imageData" height="20" width="20"></dx-load-indicator>
  <i *ngIf="!editMode && active" class="checked dx-icon-check"></i>
</div>
