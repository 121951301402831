import { Component, OnInit } from '@angular/core';
import { CloudinaryImage } from '@cloudinary/url-gen';
import { TranslateService } from '@ngx-translate/core';
import { confirm, custom } from 'devextreme/ui/dialog';
import { ItemClickEvent } from 'devextreme/ui/drop_down_button';
import { BehaviorSubject, forkJoin, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import {
  FILECATEGORYTYPES,
  FileManagerService,
} from '~/app/shared/components/filemanager/services/filemanager.service';
import { ExternalFile } from '../../../shared/components/external-file/types';
import { CloudinaryService } from '../../../shared/components/filemanager/services/cloudinary.service';
import { AuthService } from '../../auth/authentication.service';
import { LayoutService } from '../../services/store/layout.service';
import { Profile } from './models/profile';
import { ProfileConfig } from './models/profile-config';
import { ProfileMetadata } from './models/profile-metadata';
import { ProfileConfigService } from './services/profile-config.service';
import { ProfileMetadataService } from './services/profile-metadata.service';
import { ProfileService } from './services/profile.service';

const tabs = ['general', 'password', 'settings', 'metadata'] as const;

type ButtonAction = {
  value: string;
  name: string;
  icon: string;
};

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
})
export class ProfileComponent implements OnInit {
  public profile?: Profile;
  public userConfig: ProfileConfig = new ProfileConfig();
  public userMetadata: ProfileMetadata = new ProfileMetadata();
  public fileCategoryTypes = FILECATEGORYTYPES;
  public tabs: Array<string> = Array.from(tabs);
  public profileImage = new BehaviorSubject<CloudinaryImage | null>(null);

  public showPictureUpload = false;

  public buttonActions: ButtonAction[] = [
    { value: 'edit', name: this.translate.instant('general.buttons.edit'), icon: 'edit' },
    { value: 'delete', name: this.translate.instant('general.buttons.delete'), icon: 'trash' },
  ];

  constructor(
    private profileService: ProfileService,
    private translate: TranslateService,
    private profileConfigService: ProfileConfigService,
    private profileMetadataService: ProfileMetadataService,
    private authService: AuthService,
    private fileManagerService: FileManagerService,
    public layoutService: LayoutService,
    private cloudinaryService: CloudinaryService
  ) {}

  public ngOnInit() {
    forkJoin([
      this.profileService.fetchAll(),
      this.profileConfigService.fetchAll(),
      this.profileMetadataService.fetchAll({ serviceUrlConfig: { showMessage: false } }).pipe(
        catchError((error) => {
          if (error?.error?.code === 'usermetadata_not_found') {
            const profileConfig = new ProfileMetadata();
            return this.profileMetadataService
              .post(profileConfig, undefined, false, { showMessage: false })
              .pipe(mergeMap((meta) => of([profileConfig.deserialize(meta)])));
          } else {
            throw error;
          }
        })
      ),
    ]).subscribe((results) => {
      if (results[0] && results[0].length === 1) {
        this.profile = results[0][0];
        if (!this.profile.profilePicture) return;
        const image = this.cloudinaryService.getImage(this.profile.profilePicture.key, 'avatar-medium');
        this.profileImage.next(image);
      }
      if (results[1] && results[1].length === 1) {
        this.userConfig = results[1][0];
      }
      if (results[2] && results[2].length === 1) {
        this.userMetadata = results[2][0];
      }
    });
  }

  public onItemClick(e: ItemClickEvent) {
    if (e.itemData.value === 'edit') {
      // this.fileManagerService.showMediaManager({
      //   acceptedFileTypes: 'image/*',
      //   selected: (data: MediaFile[]) => this.changeProfileImage(data),
      //   isMediaManagerShownRef: this.layoutService.isMediaManagerShown,
      //   enableCrop: true,
      //   fileCategory: FILECATEGORYTYPES.PROFILEPICTURE,
      //   fileIdToUpdate: null,
      //   fileDirectoryId: null,
      // });
      this.showPictureUpload = true;
    } else if (e.itemData.value === 'delete') {
      this.removeProfileImage();
    }
  }

  public onFileUploaded(file: ExternalFile) {
    if (!this.profile) return;
    const updatedProfile = this.profile;
    updatedProfile.profilePicture = file;
    this.profileService.patch(updatedProfile, this.profile.id).subscribe(() => {
      const image = this.cloudinaryService.getImage(file.key, 'avatar-medium');
      this.profileImage.next(image);
    });
  }

  public reloadData(tab: (typeof tabs)[number]) {
    if (tab === 'metadata') {
      this.profileMetadataService.fetchAll().subscribe((metadata) => {
        this.userMetadata = metadata[0];
      });
    } else if (tab === 'settings') {
      this.profileConfigService.fetchAll().subscribe((config) => {
        this.userConfig = config[0];
      });
    } else {
      this.profileService.fetchAll().subscribe((profile) => {
        this.profile = profile[0];
      });
    }
  }

  // public changeProfileImage(selection: MediaFile[]) {
  //   const updatedProfile = this.profile;
  //   updatedProfile.picture = { id: selection[0].id };
  //   this.profileService.patch(updatedProfile, this.profile.id).subscribe();
  //   this.profileService.refreshProfileImage(selection[0].imageData as Blob);
  //   return from([]);
  // }

  public removeProfileImage() {
    const confirmation = confirm(
      this.translate.instant('general.confirm_text'),
      this.translate.instant('general.confirm_title')
    );
    confirmation.then((dialogResult) => {
      if (dialogResult && this.profile) {
        const updatedProfile = this.profile;
        // @ts-expect-error to delete the profile picture, it has to be null
        // updatedProfile.picture = null;
        updatedProfile.profilePicture = null;
        // this.profile.picture = undefined;
        this.profileService.patch(updatedProfile, this.profile.id).subscribe(() => {
          // this.profileService.refreshProfileImage(null);
          this.profileImage.next(null);
        });
      }
    });
  }

  public deleteAccount() {
    const passwordFieldID = 'password_delete_entry';
    const profileDeletionDxDialog = custom({
      title: this.translate.instant('general.confirm_title'),
      messageHtml: this.translate.instant('general.confirm_account_deletion', {
        newLine: '<br />',
        input: `<div class="dx-textbox dx-texteditor dx-editor-filled dx-widget mt-2">
                  <div class="dx-texteditor-container">
                    <div class="dx-texteditor-input-container">
                      <input autocomplete="off" placeholder="${this.translate.instant(
                        'labels.password'
                      )}" type="password" id="${passwordFieldID}" name="${passwordFieldID}" class="dx-texteditor-input" spellcheck="false" role="textbox"/>
                      <div data-dx_placeholder="" class="dx-placeholder dx-state-invisible"></div>
                    </div>
                    <div class="dx-texteditor-buttons-container"><div></div></div>
                  </div>
                </div>`,
      }),
      buttons: [
        {
          text: this.translate.instant('general.buttons.cancel'),
          type: 'default',
          stylingMode: 'text',
          onClick: (): false | string => {
            return false;
          },
          onInitialized: (e) => {
            let count = 0;
            const document = e.element?.ownerDocument;
            const interval = setInterval(() => {
              const element = document?.getElementById(passwordFieldID);
              if (element instanceof HTMLInputElement) {
                element.focus();
                clearInterval(interval);
              } else {
                if (++count > 20) {
                  clearInterval(interval);
                }
              }
            }, 500);
          },
        },
        {
          text: this.translate.instant('general.buttons.delete_account'),
          type: 'danger',
          stylingMode: 'contained',
          onClick: (e): false | string => {
            const element = e.element?.ownerDocument?.getElementById(passwordFieldID);
            if (element instanceof HTMLInputElement) {
              const password = element.value;
              if (typeof password === 'string' && password.length > 0) {
                return password;
              }
            }
            return false;
          },
        },
      ],
    });
    profileDeletionDxDialog.show().then((passwordOrCancelIfFalse: false | string) => {
      if (passwordOrCancelIfFalse && this.profile?.id) {
        this.profileService.deleteProfile(this.profile.id).subscribe({
          next: () => {
            this.authService.logout().subscribe();
          },
        });
      }
    });
  }
}
