import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { StorageConfig } from '~/app/config/storage.config';
import { AuthService } from '~/app/core/auth/authentication.service';
import { MessageService, TOASTTYPE } from '~/app/core/services/message.service';
import { LayoutService } from '~/app/core/services/store/layout.service';

/**
 * Description: Checks if user has a token in session storage, if not redirect to /login
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate, CanActivateChild {
  private static setRedirectInformation(url: string): void {
    localStorage.setItem(StorageConfig.REDIRECT, url);
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService,
    private layoutService: LayoutService,
    private translate: TranslateService
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    AuthenticationGuard.setRedirectInformation(state.url);
    return this.canActivateCheck();
  }

  public canActivateChild(): Observable<boolean> {
    return this.canActivateCheck();
  }

  private canActivateCheck(): Observable<boolean> {
    const errorHandler = () => {
      this.layoutService.toggleUserSidebar(true);
      this.router.navigate(['/']);
      setTimeout(() => {
        this.messageService.showToast(TOASTTYPE.ERROR, this.translate.instant('general.noPermissionForPage'));
      }, 500);
    };
    return this.authService.getToken().pipe(
      mergeMap((token) => {
        if (!token) return of(false);
        return this.authService.getTokenExpiration(token).pipe(
          map((authTokenExpiration) => {
            const now = Date.now() / 1000; // converts millis to secs
            return Boolean(token) && now <= authTokenExpiration;
          })
        );
      }),
      tap((value) => {
        if (!value) {
          errorHandler();
        }
      })
    );
  }
}
