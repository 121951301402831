<app-scroll-view-with-bottom-bar>
  <div class="row" *ngIf="this.formData">
    <div class="col-lg-12 mt-3" style="margin-bottom: 60px">
      <dx-validation-group #damageReportVG>
        <div class="dx-field">
          <div class="dx-field-label">{{ 'report.firstname' | translate }}</div>
          <div class="dx-field-value">
            <dx-text-box *ngIf="formData.firstname" [(value)]="formData.firstname">
              <dx-validator>
                <dxi-validation-rule type="required"></dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">{{ 'report.lastname' | translate }}</div>
          <div class="dx-field-value">
            <dx-text-box *ngIf="formData.lastname" [(value)]="formData.lastname">
              <dx-validator>
                <dxi-validation-rule type="required"></dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">{{ 'report.location' | translate }}</div>
          <div class="dx-field-value">
            <dx-select-box
              [text]="'report.location' | translate"
              [items]="this.availableLocations"
              valueExpr="value"
              displayExpr="value"
              [(value)]="formData.location"
              fieldTemplate="field"
            >
              <div *dxTemplate="let data of 'field'">
                <div class="custom-item">
                  <dx-text-box
                    [value]="translateService.instant('report.' + data.value)"
                    [readOnly]="true"
                  ></dx-text-box>
                </div>
              </div>
              <div *dxTemplate="let item of 'item'">
                {{ 'report.' + item.value | translate }}
              </div>
            </dx-select-box>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">{{ 'report.locationExtension' | translate }}</div>
          <div class="dx-field-value">
            <dx-text-box *ngIf="formData.locationExtension" [(value)]="formData.locationExtension"></dx-text-box>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">{{ 'report.description' | translate }}</div>
          <div class="dx-field-value">
            <dx-text-area *ngIf="formData.description" [(value)]="formData.description" height="150"></dx-text-area>
          </div>
        </div>
      </dx-validation-group>
    </div>
  </div>
  <div class="bottom-bar">
    <dx-button class="small" [type]="'normal'" [stylingMode]="'text'" (onClick)="close()">{{
      'general.buttons.cancel' | translate
    }}</dx-button>
    <app-button (buttonClick)="send()">Senden</app-button>
  </div>
</app-scroll-view-with-bottom-bar>
