import { Component } from '@angular/core';
import { AppTranslationService } from '../../core/services/translation/app-translation.service';

@Component({
  selector: 'app-language-selector',
  template: `
    <dx-drop-down-button
      [text]="'languages.add' | translate"
      icon="add"
      [items]="this.appTranslation.getStoredLanguages()"
      keyExpr="iso"
      displayExpr="iso"
      (onItemClick)="onItemClick()"
      [splitButton]="true"
      (onButtonClick)="onButtonClick()"
    >
    </dx-drop-down-button>
  `,
})
export class AppLanguageSelectorComponent {
  onItemClick() {}
  onButtonClick() {}

  constructor(public appTranslation: AppTranslationService) {}
}
