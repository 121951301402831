import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Observable, Subject } from 'rxjs';
import { AppAngularBridge } from '../../../app-bridge';

@Component({
  selector: 'app-external-file-image-cropper',
  templateUrl: './external-file-image-cropper.component.html',
})
export class ExternalFileImageCropperComponent {
  // @Input() public enableCrop = false;
  // @Input() public resetInput = false;
  // public isLoading = false;
  // public imageChangedEvent?: ImageCroppedEvent | Event;
  // public imageFile?: File;
  // public croppedImage?: Blob;
  // public cropMode = false;
  // public canvasRotation = 0;

  @Input() public aspectRatio = 1 / 1;
  @Input() public updateFile = false;
  @Input() public resetInput = false;
  @Input() public imageFile?: File;

  @Output() public imageLoaded = new EventEmitter();
  @Output() public cropperReady = new EventEmitter();
  @Output() public imageCropped = new EventEmitter<Blob>();

  public isLoading = false;
  public imageChangedEvent?: ImageCroppedEvent;
  public canvasRotation = 0;

  constructor(private cdRef: ChangeDetectorRef) {}

  public isNative() {
    return AppAngularBridge.isNative();
  }

  public onImageCropped(event: ImageCroppedEvent) {
    this.isLoading = false;

    const base64URL = event.base64 as string;
    const base64String = base64URL.split(',')[1];
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/jpeg' });

    this.imageCropped.emit(blob);
  }

  public rotateLeft() {
    this.canvasRotation--;
  }

  public rotateRight() {
    this.canvasRotation++;
  }

  public triggerResetInput(): Observable<void> {
    return this._triggerResetInput(true);
  }

  private _triggerResetInput(reset: boolean, obs?: Subject<void>): Observable<void> {
    this.resetInput = reset;
    this.cdRef.detectChanges();
    if (reset) {
      obs = new Subject<void>();
      setTimeout(() => this._triggerResetInput(false, obs), 0);
    } else {
      if (obs) {
        obs.next();
        obs.complete();
      }
    }
    if (!obs) return new Observable<void>();
    return obs.asObservable();
  }
}
