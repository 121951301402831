<dx-html-editor
  [height]="inline ? 51 : 500"
  [value]="data"
  (valueChange)="dataChange.emit($event)"
  (onInitialized)="editorDidInit.emit()"
  [placeholder]="placeholder ?? ''"
>
  <dxo-toolbar *ngIf="!inline" [multiline]="false">
    <dxi-item name="header" [acceptedValues]="[false, 1, 2, 3, 4, 5]"></dxi-item>

    <dxi-item name="separator"></dxi-item>

    <dxi-item name="bold"></dxi-item>
    <dxi-item name="italic"></dxi-item>
    <dxi-item name="underline"></dxi-item>
    <dxi-item name="color"></dxi-item>
    <dxi-item name="clear"></dxi-item>

    <dxi-item name="separator"></dxi-item>

    <dxi-item name="alignLeft"></dxi-item>
    <dxi-item name="alignCenter"></dxi-item>
    <dxi-item name="alignRight"></dxi-item>
    <dxi-item name="alignJustify"></dxi-item>

    <dxi-item name="separator"></dxi-item>

    <dxi-item name="orderedList"></dxi-item>
    <dxi-item name="bulletList"></dxi-item>

    <dxi-item name="separator"></dxi-item>

    <!-- <dxi-item name="image"></dxi-item> -->
    <dxi-item widget="dxButton" [options]="uploadButtonOptions"></dxi-item>
    <dxi-item name="link"></dxi-item>

    <dxi-item name="separator"></dxi-item>

    <dxi-item name="undo"></dxi-item>
    <dxi-item name="redo"></dxi-item>
  </dxo-toolbar>

  <dxo-media-resizing [enabled]="true"></dxo-media-resizing>
  <dxo-image-upload [tabs]="['file']" fileUploadMode="base64"></dxo-image-upload>

  <dxi-mention valueExpr="id" displayExpr="fullname" searchExpr="fullname" [dataSource]="mentionUsers"></dxi-mention>
</dx-html-editor>
<app-external-file-manager
  [isOpen]="showUploader"
  purpose="CONTENT_PICTURE"
  (isOpenChange)="showUploader = $event"
  (fileUploaded)="onFileUploaded($event)"
>
</app-external-file-manager>
