import {NgModule} from '@angular/core';
import {
  DxDataGridModule,
  DxPieChartModule,
  DxChartModule,
  DxAccordionModule,
  DxButtonModule,
  DxDateBoxModule,
  DxDrawerModule,
  DxGalleryModule,
  DxDropDownBoxModule,
  DxDropDownButtonModule,
  DxFormModule,
  DxTabsModule,
  DxTabPanelModule,
  DxListModule,
  DxLoadIndicatorModule,
  DxLoadPanelModule,
  DxPopupModule,
  DxProgressBarModule,
  DxRadioGroupModule,
  DxSelectBoxModule,
  DxSpeedDialActionModule,
  DxSwitchModule,
  DxTagBoxModule,
  DxTemplateModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxToastModule,
  DxValidationGroupModule,
  DxValidatorModule, DxFileUploaderModule, DxTileViewModule,
  DxToolbarModule,
  DxActionSheetModule, DxScrollViewModule, DxHtmlEditorModule, DxPopoverModule,
  DxTooltipModule, DxAutocompleteModule, DxCheckBoxModule,
} from 'devextreme-angular';

@NgModule({
  declarations: [],
  imports: [
    DxButtonModule,
    DxDrawerModule,
    DxDropDownBoxModule,
    DxListModule,
    DxFormModule,
    DxToastModule,
    DxTagBoxModule,
    DxLoadPanelModule,
    DxAccordionModule,
    DxDataGridModule,
    DxPieChartModule,
    DxChartModule,
    DxValidatorModule,
    DxTextBoxModule,
    DxTextAreaModule,
    DxLoadIndicatorModule,
    DxProgressBarModule,
    DxSpeedDialActionModule,
    DxDateBoxModule,
    DxDropDownButtonModule,
    DxTemplateModule,
    DxPopupModule,
    DxValidationGroupModule,
    DxSelectBoxModule,
    DxTabsModule,
    DxTabPanelModule,
    DxSwitchModule,
    DxRadioGroupModule,
    DxToolbarModule,
    DxFileUploaderModule,
    DxTileViewModule,
    DxActionSheetModule,
    DxScrollViewModule,
    DxHtmlEditorModule,
    DxPopoverModule,
    DxTooltipModule,
    DxAutocompleteModule,
    DxCheckBoxModule,
    DxGalleryModule,
  ],
  exports: [
    DxButtonModule,
    DxDrawerModule,
    DxDropDownBoxModule,
    DxListModule,
    DxFormModule,
    DxToastModule,
    DxTagBoxModule,
    DxLoadPanelModule,
    DxAccordionModule,
    DxDataGridModule,
    DxPieChartModule,
    DxChartModule,
    DxValidatorModule,
    DxTextBoxModule,
    DxLoadIndicatorModule,
    DxProgressBarModule,
    DxTextAreaModule,
    DxSpeedDialActionModule,
    DxDateBoxModule,
    DxDropDownButtonModule,
    DxTemplateModule,
    DxPopupModule,
    DxValidationGroupModule,
    DxSelectBoxModule,
    DxTabsModule,
    DxTabPanelModule,
    DxSwitchModule,
    DxRadioGroupModule,
    DxToolbarModule,
    DxFileUploaderModule,
    DxTileViewModule,
    DxActionSheetModule,
    DxScrollViewModule,
    DxHtmlEditorModule,
    DxPopoverModule,
    DxTooltipModule,
    DxAutocompleteModule,
    DxCheckBoxModule,
    DxGalleryModule,
  ]
})
export class DevextremeModule {
}
