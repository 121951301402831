import { EnvironmentConfiguration } from '@softwarehaus/util-configuration';
import { DEMO_NAPLEON_ENVIRONMENT_CONFIGURATION } from '~/app/config/demo-environment-configuration';
import { DOCKERDEMO_NAPLEON_ENVIRONMENT_CONFIGURATION } from '~/app/config/docker-demo-environment-configuration';
import { GOTTHILFT_PROD_NAPLEON_ENVIRONMENT_CONFIGURATION } from '~/app/config/gotthilft-prod-environment-configuration';
import { GOTTHILFT_STAGE_NAPLEON_ENVIRONMENT_CONFIGURATION } from '~/app/config/gotthilft-stage-environment-configuration';
import { KSGR_PROD_NAPLEON_ENVIRONMENT_CONFIGURATION } from '~/app/config/ksgr-prod-environment-configuration';
import { KSGR_STAGE_NAPLEON_ENVIRONMENT_CONFIGURATION } from '~/app/config/ksgr-stage-environment-configuration';
import { LOCAL_NAPLEON_ENVIRONMENT_CONFIGURATION } from '~/app/config/local-environment-configuration';
import { SEESPITAL_PROD_NAPLEON_ENVIRONMENT_CONFIGURATION } from '~/app/config/see-spital-prod-environment-configuration';
import { SEESPITAL_STAGE_NAPLEON_ENVIRONMENT_CONFIGURATION } from '~/app/config/see-spital-stage-environment-configuration';
import { NULL8EINS_NAPLEON_ENVIRONMENT_CONFIGURATION } from './08eins-environment-configuration';

export type NappTheme = 'default' | '08eins' | 'ksgr' | 'gotthilft' | 'seespital';

export interface NappEnvironmentConfiguration extends EnvironmentConfiguration {
  title: string;
  theme: NappTheme;
  defaultLangISO: string;
  api: {
    clientId: string;
    clientSecret: string;
    baseServiceUrl: string;
  };
  captchaSiteKey: string;
  analyticsUrl?: string;
  appUrl: string;
  footer: {
    customer: string;
    phoneNr: string;
    website: string;
    email: string;
    instagram?: string;
    facebook?: string;
    linkedIn: string;
    type: 'default' | 'sponsor';
    sponsorLogos?: {
      name: string;
      width: number;
    }[];
  };
  availableModules: {
    feed: boolean;
    document: boolean;
    shortener: boolean;
    magazine: boolean;
    event: boolean;
    agenda: boolean;
    employees: boolean;
  };
  availableFeatures: {
    feed: {
      location: boolean;
      showPostType: boolean;
      approve: boolean;
      allowVideoUpload: boolean;
      mention: boolean;
      report: boolean;
    };
    header: {
      logoRedirectMyFeed: boolean;
    };
    push: {
      autoEnable: boolean;
      unregisteredDevices: boolean;
    };
    employee: {
      showFirstname: boolean;
      showLastname: boolean;
      showLocation: boolean;
      showDepartment: boolean;
      showFunctionality: boolean;
    };
  };
  cloudinary?: {
    uploadPreset: string;
  } & (
    | {
        cloudName: 'ksgr-08eins';
      }
    | {
        cloudName: 'cloud-08eins';
        prefix: string;
      }
  );
  appleAppID?: string;
}

export const NAPOLEON_ENVIRONMENTS_CONFIGURATION = [
  LOCAL_NAPLEON_ENVIRONMENT_CONFIGURATION,
  DEMO_NAPLEON_ENVIRONMENT_CONFIGURATION,
  NULL8EINS_NAPLEON_ENVIRONMENT_CONFIGURATION,
  DOCKERDEMO_NAPLEON_ENVIRONMENT_CONFIGURATION,
  GOTTHILFT_PROD_NAPLEON_ENVIRONMENT_CONFIGURATION,
  GOTTHILFT_STAGE_NAPLEON_ENVIRONMENT_CONFIGURATION,
  KSGR_PROD_NAPLEON_ENVIRONMENT_CONFIGURATION,
  KSGR_STAGE_NAPLEON_ENVIRONMENT_CONFIGURATION,
  SEESPITAL_PROD_NAPLEON_ENVIRONMENT_CONFIGURATION,
  SEESPITAL_STAGE_NAPLEON_ENVIRONMENT_CONFIGURATION,
];
