import { BaseEntity } from '~/app/core/models/base-entity.model';
import { AttributeValue } from '~/app/features/mgmt/attribute/models/attribute-value';

export class ProfileEmployeeInfo extends BaseEntity<ProfileEmployeeInfo> {
  constructor(
    public location?: AttributeValue,
    public department?: AttributeValue,
    public functionality?: AttributeValue
  ) {
    super();
  }

  public override deserialize(object: this): this {
    object.location = object.location ? new AttributeValue().deserialize(object.location) : object.location;
    object.department = object.department ? new AttributeValue().deserialize(object.department) : object.department;
    object.functionality = object.functionality
      ? new AttributeValue().deserialize(object.functionality)
      : object.functionality;
    return super.deserialize(object);
  }
}
