import { PostComment } from '../../features/feed/feed-post/comment/models/post-comment';
import { Author } from '../../features/feed/models/author';
import { FeedPost } from '../../features/feed/models/feed-post';
import { BaseEntity } from './base-entity.model';

export class ReportContent extends BaseEntity<ReportContent> {
  constructor(
    public message: string = '',
    public resolved: boolean = false,
    public author?: Author,
    public authorName: string = '',
    public authorEmail: string = '',
    public comment?: PostComment,
    public post?: FeedPost,
    public user?: Author
  ) {
    super();
  }

  /**
   *  deserializes an generic object into the given class object
   */
  public override deserialize(object: ReportContent): this {
    object.post = object.post ? new FeedPost().deserialize(object.post) : object.post;
    object.comment = object.comment ? new PostComment().deserialize(object.comment) : object.comment;
    object.user = object.user ? new Author().deserialize(object.user) : object.user;
    object.author = object.author ? new Author().deserialize(object.author) : object.author;
    return super.deserialize(object);
  }
}
