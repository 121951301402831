import { TranslatableEntity } from '~/app/core/models/translatable-entity';
import { PostLanguage } from '../../feed/models/post-language';
import { Context } from '../../mgmt/context/models/context';

export class MediaDirectory extends TranslatableEntity<MediaDirectory, PostLanguage> {
  public navtitleTranslated?: string;
  public nameTranslated?: string;

  constructor(
    public name: string = '',
    /* public */ languages: PostLanguage[] = [],
    public parent?: MediaDirectory,
    public hasChildren?: boolean,
    public key?: string,
    public contexts: Context[] = []
  ) {
    super(languages);
  }

  public updateTranslations(): void {
    const translation = this.getPropertyTranslation(this.translatedEntities, 'name');
    this.nameTranslated = this.navtitleTranslated = translation?.name.toString() ?? '';
  }
}
