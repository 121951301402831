import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { clone } from 'lodash';
import { BaseAccordionPanel, SafeType } from '~/app/shared/components/accordion/base-accordion-panel';
import { ProfilePassword } from '../models/profile-password';
import { ProfilePasswordService } from '../services/profile-password.service';

@Component({
  selector: 'app-profile-password',
  templateUrl: './profile-password.component.html',
})
export class ProfilePasswordComponent
  extends BaseAccordionPanel<ProfilePassword, ProfilePasswordService>
  implements OnInit
{
  public formData: ProfilePassword = new ProfilePassword();

  constructor(
    protected override translateService: TranslateService,
    protected profilePasswordService: ProfilePasswordService
  ) {
    super(profilePasswordService, translateService);
  }

  public override ngOnInit() {
    this.safeType = SafeType.Put;
  }

  public createForm() {
    if (!this.entity) return;
    this.formData = clone(this.entity);
  }

  public passwordComparison = () => {
    return this.form?.instance.option('formData').newpassword;
  };
}
