import { HttpClient, HttpParams } from '@angular/common/http';
import { LoadOptions } from 'devextreme/data';
import { BehaviorSubject, Observable } from 'rxjs';
import { flatMap, map, mergeMap, retry, tap } from 'rxjs/operators';
import { AppApiStrategy } from '../../../app-api-strategy';
import { BaseEntity } from '../../models/base-entity.model';

export enum HttpProgressState {
  start,
  end,
}

export interface IHttpParameters {
  key: string;
  value: string | number;
}

export interface IServiceUrlConfig {
  path?: string[];
  authenticate?: boolean;
  mgmt?: boolean;
  system?: boolean;
  customPath?: string[];
  showMessage?: boolean;
}

interface BaseOptions {
  serviceUrlConfig?: IServiceUrlConfig;
  selection?: string[];
  expansions?: string[];
}

export interface FetchAllOptions extends BaseOptions {
  httpParameters?: IHttpParameters[];
  appendResult?: boolean;
}

export interface FetchByIdOptions extends BaseOptions {
  entityId: string | string[];
}

export abstract class BaseService<T extends BaseEntity<T>> {
  // tslint:disable
  /** One BehaviorSubject per store entity */
  private readonly _entities = new BehaviorSubject<T[]>([]);

  /** Expose the observable$ part of the _news subject (read only stream) */
  readonly entities$ = this._entities.asObservable();

  /** Trigger Watcher to reload entities if needed */
  private triggerEntitiesReload = new BehaviorSubject<boolean>(false);
  public reloadEntities = this.triggerEntitiesReload.asObservable();
  public entitiesTotal = 0;
  // tslint:enable

  protected apiStrategy = AppApiStrategy;

  constructor(protected http: HttpClient, protected parentModel: new () => T) {}

  get entities(): T[] {
    return this._entities.getValue();
  }

  set entities(val: T[]) {
    this._entities.next(val);
  }

  public fetchAll({
    httpParameters = [],
    serviceUrlConfig,
    appendResult = false,
    selection = [],
    expansions = [],
  }: FetchAllOptions = {}): Observable<T[]> {
    let params = new HttpParams();
    let authenticate = true;
    const showMessage =
      serviceUrlConfig && typeof serviceUrlConfig.showMessage !== 'undefined' && !serviceUrlConfig.showMessage
        ? serviceUrlConfig.showMessage
        : true;
    const hasSelection = selection.length > 0;

    httpParameters.forEach((item) => {
      params = params.set(item.key, String(item.value));
    });

    if (expansions.length > 0) {
      params = params.set('expand', expansions.join(','));
      if (hasSelection) {
        selection = selection.concat(expansions);
      }
    }
    if (hasSelection) {
      params = params.set('select', selection.join(','));
    }

    if (serviceUrlConfig && typeof serviceUrlConfig.authenticate !== 'undefined' && !serviceUrlConfig.authenticate) {
      authenticate = false;
    }

    return this.apiStrategy.getHeaders(undefined, authenticate, showMessage).pipe(
      mergeMap((headers) => {
        return this.http
          .get<T[] | T>(this.getServiceUrl(undefined, serviceUrlConfig), {
            headers,
            observe: 'response',
            params,
          })
          .pipe(
            retry(2),
            map((response) => {
              const data = response.body;
              const header = response.headers;

              this.entitiesTotal = Number(header.get('X-Pagination-Count'));
              const responseList: T[] = [];

              if (!data) return this.entities;

              if (data instanceof Array) {
                data.forEach((item) => {
                  responseList.push(new this.parentModel().deserialize(item));
                });
                if (appendResult) {
                  this.entities = this.entities.concat(responseList);
                } else {
                  this.entities = responseList;
                }
              } else {
                responseList.push(new this.parentModel().deserialize(data));
                this.entities = responseList;
              }
              return this.entities;
            })
          );
      })
    );
  }

  public getEntityById({
    entityId,
    selection = [],
    expansions = [],
    serviceUrlConfig,
  }: FetchByIdOptions): Observable<T> {
    let params = new HttpParams();
    const hasSelection = selection.length > 0;

    if (expansions.length > 0) {
      params = params.set('expand', expansions.join(','));
      if (hasSelection) {
        selection = selection.concat(expansions);
      }
    }
    if (hasSelection) {
      params = params.set('select', selection.join(','));
    }

    return this.apiStrategy.getHeaders().pipe(
      flatMap((headers) => {
        return this.http
          .get<T>(this.getServiceUrl(entityId, serviceUrlConfig), {
            headers,
            params,
          })
          .pipe(
            retry(2),
            map((result: T) => {
              return new this.parentModel().deserialize(result);
            })
          );
      })
    );
  }

  public post(
    entity: T,
    entityId?: string | string[],
    triggerReload?: boolean,
    serviceUrlConfig?: IServiceUrlConfig
  ): Observable<T> {
    const showMessage = serviceUrlConfig && !serviceUrlConfig.showMessage ? serviceUrlConfig.showMessage : true;

    return this.apiStrategy.getHeaders(undefined, true, showMessage).pipe(
      flatMap((headers) => {
        return this.http
          .post<T>(this.getServiceUrl(entityId || entity.id, serviceUrlConfig), entity, {
            headers,
          })
          .pipe(
            tap(() => {
              if (triggerReload) {
                this.triggerReload();
              }
            })
          );
      })
    );
  }

  public put(
    entity: T,
    entityId?: string | string[],
    reloadEntities?: boolean,
    serviceUrlConfig?: IServiceUrlConfig
  ): Observable<T> {
    return this.apiStrategy.getHeaders(undefined).pipe(
      flatMap((headers) => {
        return this.http.put<T>(this.getServiceUrl(entityId || entity.id, serviceUrlConfig), entity, {
          headers,
        });
      })
    );
  }

  public patch(
    entity: T,
    entityId?: string | string[],
    reloadEntities?: boolean,
    serviceUrlConfig?: IServiceUrlConfig
  ): Observable<T> {
    return this.apiStrategy.getHeaders().pipe(
      flatMap((headers) => {
        return this.http.patch<T>(this.getServiceUrl(entityId || entity.id, serviceUrlConfig), entity, {
          headers,
        });
      })
    );
  }

  public delete(entity: T | string | string[], serviceUrlConfig?: IServiceUrlConfig): Observable<T> {
    const entityId = typeof entity === 'string' || Array.isArray(entity) ? entity : entity.id;
    return this.apiStrategy.getHeaders().pipe(
      flatMap((headers) => {
        return this.http.delete<T>(this.getServiceUrl(entityId, serviceUrlConfig), {
          headers,
        });
      })
    );
  }

  /**
   * GENERAL HELPER FUNCTIONS
   */

  public totalEntityLoaded(skip: number, limit: number, entityTotal = null): boolean {
    const total = entityTotal ? entityTotal : this.entitiesTotal;

    return skip + limit >= total;
  }

  public triggerReload(value = true) {
    this.triggerEntitiesReload.next(value);
  }

  public generateServiceURL(resource: string, entityId?: string, serviceUrlConfig?: IServiceUrlConfig): string[] {
    let url = [
      ...(serviceUrlConfig
        ? [...(serviceUrlConfig.mgmt ? ['mgmt'] : []), ...(serviceUrlConfig.system ? ['system'] : [])]
        : []),
      resource,
    ];

    url = serviceUrlConfig && serviceUrlConfig.customPath ? serviceUrlConfig.customPath : url;

    if (entityId) {
      url.push(entityId);
    }

    if (serviceUrlConfig && serviceUrlConfig.path) {
      url.push(...serviceUrlConfig.path);
    }
    return url;
  }

  public getParametersForDropdownFilter(
    loadOptions: LoadOptions<T>,
    keyValue: string,
    loadAllLimit: number = 9999
  ): IHttpParameters[] {
    let params: IHttpParameters[] = this.getSkipAndLimitParameter(loadOptions).concat(
      this.getSortParameter(loadOptions)
    );
    const lk = Object.keys(loadOptions);
    const filterSetButValueUndefined = lk.indexOf('filter') !== -1 && !loadOptions.filter;

    if (filterSetButValueUndefined && lk.indexOf('take') === -1) {
      // see comment on getMissingValuesForList for condition
      params = params.concat(this.getMissingValuesForList(loadOptions.filter, loadAllLimit));
    } else if (loadOptions.filter) {
      if (!loadOptions.filter['filterValue']) {
        params = params.concat(this.getMissingValuesForList(loadOptions.filter, loadAllLimit));
      } else {
        params = params.concat([{ key: keyValue, value: `like:%${loadOptions.filter['filterValue']}%` }]);
      }
    } else {
      const paramIndex = params.findIndex((x) => x.key === keyValue);
      if (paramIndex !== -1) {
        params.splice(paramIndex, 1);
      }
    }
    return params;
  }

  public processLoadOptions(loadOptions: LoadOptions<T>): IHttpParameters[] {
    return this.parseSingleItemFilter(loadOptions).concat(
      this.getSkipAndLimitParameter(loadOptions),
      this.getSortParameter(loadOptions)
    );
  }

  public getSkipAndLimitParameter(loadOptions: LoadOptions<T>): IHttpParameters[] {
    let params: IHttpParameters[] = [];
    if (!('isLoadingAll' in loadOptions)) {
      params = params.concat([
        {
          key: 'limit',
          value: JSON.stringify(loadOptions.take) || 20,
        },
        {
          key: 'skip',
          value: JSON.stringify(loadOptions.skip) || 0,
        },
      ]);
    }

    return params;
  }

  public getSortParameter(loadOptions: LoadOptions<T>): IHttpParameters[] {
    let params: IHttpParameters[] = [];

    if (loadOptions.sort && Array.isArray(loadOptions.sort) && loadOptions.sort.length > 0) {
      const sortParam = loadOptions.sort
        .filter((item): item is { desc?: boolean; selector: string } => {
          return typeof item === 'object' && 'selector' in item && typeof item.selector === 'string';
        })
        .map((item) => {
          // replace dot with slash for filtering on subobjects
          return `${item.desc ? '-' : ''}${item.selector.replace('.', '/')}`;
        });

      params = params.concat([
        {
          key: 'sort',
          value: sortParam.join(','),
        },
      ]);
    }

    return params;
  }

  protected abstract getServiceUrl(entityId?: string | string[], serviceUrlConfig?: IServiceUrlConfig): string;

  private parseSingleItemFilter(loadOptions: LoadOptions<T>): IHttpParameters[] {
    let params: IHttpParameters[] = [];

    if (loadOptions.filter && !loadOptions.filter['filterValue'] && loadOptions.take === 1 && loadOptions.skip === 0) {
      params = params.concat(this.getMissingValuesForList([loadOptions.filter], loadOptions.take));
    }
    return params;
  }

  /**
   * If the selected values are not in the loaded list,
   * Devextreme triggers load()-Method of the CustomStore again with the filter property of type array in loadOptions.
   * The filter array contains the ID's of the selected values. With these, we need to call the API again
   * and filter all the values with the corresponding ID.
   *
   * See Ticket from Devextreme Support:
   * https://supportcenter.devexpress.com/Ticket/Details/T556095/
   *
   * If the filter is too long however, then the non-documented selection.maxFilterLengthInRequest option kicks in and tries
   * to load all data. So if filterLoadOptions is undefined and no 'take' parameter is specified, load all data.
   *
   * https://supportcenter.devexpress.com/ticket/details/t720516/
   *
   * @param filterLoadOptions loadOptions['filter'] from Devextreme DataGrid
   * @param loadAllLimit the limit is different for different API calls, so a value must be provided.
   */
  private getMissingValuesForList(filterLoadOptions: LoadOptions['filter'], loadAllLimit: number): IHttpParameters[] {
    if (filterLoadOptions) {
      const ids: string[] = this.getFilterIdsFormLoadOptions(filterLoadOptions, []);

      return [
        {
          key: 'id',
          value: `in:${ids.join(',')}`,
        },
        {
          key: 'skip',
          value: 0,
        },
        {
          key: 'limit',
          value: ids.length,
        },
      ];
    } else {
      return [
        {
          key: 'skip',
          value: 0,
        },
        {
          key: 'limit',
          value: loadAllLimit,
        },
      ];
    }
  }

  private getFilterIdsFormLoadOptions(filterLoadOptions: LoadOptions['filter'], ids: string[]) {
    for (const filter of filterLoadOptions) {
      if (Array.isArray(filter)) {
        if (filter[2]) {
          ids.push(filter[2]);
        } else {
          ids = this.getFilterIdsFormLoadOptions(filter, ids);
        }
      }
    }
    return ids;
  }
}
