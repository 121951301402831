import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ENVIRONMENT_CONFIGURATION } from '@softwarehaus/util-configuration';
import { BehaviorSubject, from, Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Editor, RawEditorSettings, TinyMCE } from 'tinymce';
import { NappEnvironmentConfiguration } from '~/app/config/environments-configuration';
import { AppTranslationService } from '~/app/core/services/translation/app-translation.service';
import { AppAngularBridge } from '../../app-bridge';
import { LayoutService } from '../../core/services/store/layout.service';
import { User } from '../../features/mgmt/user/models/user';
import { pseudoRandomBytes } from '../helpers/random';
import { MediaFile } from './filemanager/models/media-file';
import { FILECATEGORYTYPES, FileManagerService, FileTypes } from './filemanager/services/filemanager.service';
type TinyEditor = Editor;

declare global {
  interface Window {
    tinymce?: TinyMCE;
  }
}

@Component({
  selector: 'app-html-editor',
  template: `
    <editor
      *ngIf="this.pluginLoaded"
      [disabled]="this.beforePluginLoad"
      [init]="tinyMceSettings"
      [ngModel]="this.data"
      (ngModelChange)="this.dataChange.emit($event)"
      class="tinymce-editor"
    ></editor>
  `,
})
export class HtmlEditorComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public data?: string;
  @Input() public maxLength?: number;
  @Input() public inline? = false;
  @Input() public placeholder?: string;
  @Input() public mentionUsers?: Array<User>;

  @Output() public dataChange: EventEmitter<string> = new EventEmitter<string>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onEnterKey: EventEmitter<void> = new EventEmitter<void>();
  @Output() public editorDidInit: EventEmitter<void> = new EventEmitter<void>();

  public tinyMceSettings: RawEditorSettings = {};
  public beforePluginLoad = true;
  public pluginLoaded = true;

  private removePlugin?: () => void;
  private pluginName?: string;
  private imageButtonName?: string;
  private mediaButtonName?: string;
  private languageSubscription?: Subscription;
  private addNappUploadPluginSubscription?: Subscription;
  private hasMaxLength?: boolean;
  private imageBaseURL = `${this.config.api.baseServiceUrl}/media-file/publication`;

  constructor(
    private fileManagerService: FileManagerService,
    @Inject(AppTranslationService) private appTranslationService: AppTranslationService,
    private cdRef: ChangeDetectorRef,
    public layoutService: LayoutService,
    private zone: NgZone,
    @Inject(ENVIRONMENT_CONFIGURATION) private config: NappEnvironmentConfiguration
  ) {
    this.updateHasMaxLength();
  }

  public ngOnInit(): void {
    this.updateHasMaxLength();
    this.languageSubscription = this.appTranslationService.languageChanged.subscribe(() => {
      this.pluginLoaded = false;
      this.initEditor();
    });
    if (this.inline) {
      this.initEditor();
    } else {
      this.addNappUploadPluginSubscription = this.addNappUploadPlugin().subscribe({
        next: () => this.initEditor(),
        error: () => {
          console?.error('tinyMCE plugin could not be loaded');
          this.pluginName = this.imageButtonName = this.mediaButtonName = undefined;
          this.removePlugin = undefined;
          this.initEditor();
        },
      });
    }
  }

  public ngOnDestroy(): void {
    this.languageSubscription?.unsubscribe();
    this.addNappUploadPluginSubscription?.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (Object.keys(changes).indexOf('maxLength') !== -1) {
      this.updateHasMaxLength();
    }
  }

  private updateHasMaxLength() {
    this.hasMaxLength = typeof this.maxLength === 'number' && !Number.isNaN(this.maxLength) && this.maxLength > 0;
  }

  private initEditor() {
    let editorInstance: TinyEditor | undefined;
    this.tinyMceSettings = {
      selector: '.tinymce-editor',
      height: this.inline ? 50 : AppAngularBridge.isNative() ? 750 : 500,
      ...(this.inline
        ? {
            width: '100%',
          }
        : {}),
      ...(this.placeholder
        ? {
            placeholder: this.placeholder,
          }
        : {}),
      inline: false, // inline is not supported on mobile devices
      statusbar: this.hasMaxLength,
      elementpath: false,
      resize: false,
      branding: false,
      // toolbar_mode: 'scrolling',
      language: this.getLangForEditor(),
      base_url: '/assets/vendors/tinymce',
      toolbar: this.inline
        ? false
        : `formatselect | bold italic underline forecolor backcolor hr | alignleft aligncenter alignright alignjustify | bullist numlist | table ${this.imageButtonName} link | undo redo`, // media ${this.mediaButtonName}
      plugins: `${this.inline ? '' : `paste table lists advlist link hr ${this.pluginName ?? ''}`} ${
        this.config.availableFeatures.feed.mention ? 'mention noneditable' : ''
      }`, // media
      ...(this.config.availableFeatures.feed.mention
        ? {
            mentions: {
              delimiter: ['@'],
              source: (query: string, process: (data: Array<object>) => void) => {
                process(this.mentionUsers ?? []);
              },
              queryBy: 'fullname',
              insert: (item: Pick<User, 'id' | 'firstname' | 'lastname'>): string => {
                return `<um data-id="${item.id}" class="mceNonEditable">${`${item.firstname ?? ''} ${
                  item.lastname ?? ''
                }`.trim()}</um> `;
              },
              render: function (item: User) {
                return `<li class="dropdown-item"><a href="javascript:;"><span>${item.fullname}</span></a></li>`;
              },
            },
            extended_valid_elements: 'um[data-id,class]',
            custom_elements: '~um',
          }
        : {}),
      menubar: false,
      paste_block_drop: true,
      paste_as_text: true,
      mobile: {
        toolbar_mode: 'scrolling',
        toolbar: this.inline ? false : `formatselect | bold italic underline | ${this.imageButtonName} | undo redo`,
        // toolbar_sticky: true,
        // toolbar_sticky_offset: 60,
      },
      content_style: `
      @media (max-width: 575.98px) {
        img[data-mce-imagemaxsize] {
          max-width: 200px;
          max-height: 300px;
        }
      }
      @media (min-width: 576px) and (max-width: 991.98px) {
        img[data-mce-imagemaxsize] {
          max-width: 400px;
          max-height: 600px;
        }
      }
      @media (min-width: 992px) {
        img[data-mce-imagemaxsize] {
          max-width: 800px;
          max-height: 1200px;
        }
      }
      ${
        this.config.availableFeatures.feed.mention
          ? `
      um {
        border: 1px solid lightgrey;
        border-radius: 0.5em;
        background-color: lightgrey;
        padding-left: 0.5em;
        padding-right: 0.5em;
        font-weight: 500;
        white-space: nowrap;
      }
      um::before {
        content: '@';
        display: inline-block;
        padding-right: 0.25em;
        font-weight: 700;
      }
      `
          : ''
      }
      ${
        this.inline
          ? `
      body {
        margin: 15px 16px 14px;
        background-color: rgba(0,0,0,.04);
        font-size: 14px;
        font-family: "Corporate S Napp","Helvetica Neue","Segoe UI",Helvetica,Verdana,sans-serif;
        line-height: 1.2857;
      }
      p {
        margin: 0;
      }
      `
          : ''
      }`,
      link_class_list: [
        { title: 'None', value: '' },
        { title: 'PDF', value: 'is-pdf' },
      ],
      default_link_target: '_blank',
      setup: (editor: TinyEditor) => {
        editorInstance = editor;
        editor.on('blur', () => {
          this.zone.run(() => {
            this.dataChange.emit(editor.getContent());
          });
        });
        editor.on('remove', () => {
          this.zone.run(() => {
            if (typeof this.removePlugin === 'function') {
              this.removePlugin();
            }
            editorInstance = undefined;
          });
        });
        const keyUpFn = () => {
          this.zone.run(() => {
            if (this.hasMaxLength) {
              // grabbing the length of the curent editors content
              const tinylen = editor.getContent().length;

              let htmlcount = `${tinylen} / ${this.maxLength}`;
              if (this.maxLength && tinylen >= this.maxLength) {
                htmlcount = `<span style="font-weight:bold; color: #f00;">${htmlcount}</span>`;
              }

              // write the html count into the path row of the active editor
              const statusBars: HTMLCollection = editor.editorContainer.getElementsByClassName('tox-statusbar');
              Array.prototype.slice.apply(statusBars).forEach((statusBar: HTMLDivElement) => {
                statusBar.innerHTML = `<span id="${editor.id}_character_count">${htmlcount}</span>`;
              });
            }
          });
        };
        editor.on('keyup', keyUpFn);
        editor.on('init', keyUpFn);
        editor.on('init', () => {
          this.zone.run(() => {
            this.editorDidInit.emit();
          });
        });
        if (this.inline) {
          editor.on('keydown', (event: KeyboardEvent) => {
            this.zone.run(() => {
              const match = editor.dom.select('span#autocomplete-searchtext');
              if (match.length === 0 && (event.key === 'Enter' || event.keyCode === 13 || event.which === 13)) {
                editor.fire('blur', { focusedEditor: editor });
                this.onEnterKey.emit();
                event.stopImmediatePropagation();
                event.preventDefault();
                return false;
              }
              return true; // Add this line to return a value for all code paths
            });
          });
        }
        if (this.config.availableFeatures.feed.mention) {
          editor.on('input', (event: InputEvent) => {
            this.zone.run(() => {
              if (event.inputType === 'deleteContentBackward') {
                const range: Range = editor.selection.getRng();
                if (range.collapsed) {
                  let endNode: Node = editor.selection.getEnd();
                  if (endNode.nodeName !== 'UM' && range.startOffset === 0) {
                    const previousNode = range.startContainer.previousSibling;
                    endNode = previousNode !== null ? previousNode : endNode;
                  }
                  if (endNode.nodeName === 'UM') {
                    const catchEvent = (e: InputEvent) => {
                      if (e.inputType === 'deleteContentBackward') {
                        if (typeof e.stopImmediatePropagation === 'function') {
                          e.stopImmediatePropagation();
                        } else {
                          e.stopPropagation();
                        }
                        return false;
                      }
                      return true; // Add this line to return a value for all code paths
                    };
                    // delete Node, since its not editable and on Android keyboard disappears
                    let collapseStart = false;
                    let previous: Node | null = endNode.previousSibling as Node;
                    if (null === previous) {
                      collapseStart = true;
                      previous = endNode.nextSibling;
                      if (null === previous) {
                        previous = endNode.parentNode;
                      }
                    }
                    editor.dom.removeClass(endNode, 'mceNonEditable');
                    editor.on('input', catchEvent, true);
                    editor.dom.remove(endNode);
                    editor.off('input', catchEvent);
                    editor.focus();
                    if (previous) {
                      editor.selection.select(previous);
                    }
                    editor.selection.collapse(collapseStart);
                  }
                }
              }
            });
          });
        }
      },
      file_picker_types: 'file',
      file_picker_callback: (
        // eslint-disable-next-line @typescript-eslint/ban-types
        callback: Function,
        _value: string,
        meta: Record<string, string>
      ) => {
        if (meta['filetype'] === 'file' && editorInstance) {
          this.insertPDF(editorInstance, callback as (value: string, meta: object) => void)();
        }
      },
      relative_urls: false,
      remove_script_host: true,
      document_base_url: this.config.appUrl,
    };
    this.pluginLoaded = true;
    this.beforePluginLoad = false;
    this.cdRef.detectChanges();
  }

  private getLangForEditor() {
    let lang = this.appTranslationService.getCurrentLanguage()?.iso;
    if (!lang) {
      lang = this.config.defaultLangISO;
    }
    switch (lang) {
      case 'zh':
        lang += '_CN';
        break;
      case 'en':
        lang += '_US';
        break;
    }
    return lang;
  }

  private buildPublicURL(mediaFile: MediaFile): string {
    return `${this.imageBaseURL}/${mediaFile.publicHash}`;
  }

  private getPublicURLForMediaFile(data: MediaFile): Observable<[string, MediaFile]> {
    if (data.publicHash) {
      return of([this.buildPublicURL(data), data]);
    } else {
      return this.fileManagerService.post({} as MediaFile, data.id, false, { path: ['publication'] }).pipe(
        map((mediaFile: MediaFile) => {
          return [this.buildPublicURL(mediaFile), mediaFile];
        })
      );
    }
  }

  private processImageForEditor(): (editor: TinyEditor, data: MediaFile) => Observable<void> {
    return (editor: TinyEditor, data: MediaFile) => {
      return this.getPublicURLForMediaFile(data).pipe(
        map(([src]: [string, MediaFile]) => {
          editor.undoManager.transact(() => {
            let image = editor.selection.getNode() as HTMLElement;

            if (image && image.nodeName !== 'IMG') {
              image = {} as HTMLElement;
            }

            if (image) {
              editor.dom.setAttrib(image, 'src', src);
              editor.dom.setAttrib(image, 'data-mce-imagemaxsize', 'true');

              editor.selection.select(image);
              image.onload = image.onerror = () => {
                image.onload = image.onerror = null;

                if (editor.selection) {
                  if (!AppAngularBridge.isNative()) {
                    editor.selection.select(image);
                  }
                  editor.nodeChanged();
                }
              };
              // Trigger reload
              editor.dom.setAttrib(image, 'src', image.getAttribute('src'));
            } else {
              const elm = document.createElement('img');

              editor.dom.setAttrib(elm, 'src', src);
              editor.dom.setAttrib(elm, 'data-mce-id', '__mcenew');
              editor.dom.setAttrib(elm, 'data-mce-imagemaxsize', 'true');

              editor.focus();
              editor.selection.setContent(elm.outerHTML);

              const insertedElm = editor.dom.select('*[data-mce-id="__mcenew"]')[0];
              editor.dom.setAttrib(insertedElm, 'data-mce-id', null);

              if (!AppAngularBridge.isNative()) {
                editor.selection.select(insertedElm);
              }
            }
          });
        })
      );
    };
  }

  private processPdfForEditor(
    callback: (src: string, data: object) => void
  ): (editor: TinyEditor, data: MediaFile) => Observable<void> {
    return (editor: TinyEditor, data: MediaFile) => {
      return this.getPublicURLForMediaFile(data).pipe(
        map(([src, mediaFile]: [string, MediaFile]) => {
          if (typeof callback === 'function') {
            callback(src, { text: mediaFile.name });
          }
        })
      );
    };
  }

  private processMediaForEditor(): () => Observable<void> {
    return () => {
      return from([]);
    };
  }

  private insertImage(editor: TinyEditor) {
    return this.insertData(editor, 'image/*', this.processImageForEditor());
  }

  private insertPDF(editor: TinyEditor, callback: (src: string, data: object) => void) {
    return this.insertData(editor, 'application/pdf', this.processPdfForEditor(callback));
  }

  private insertMedia(editor: TinyEditor) {
    return this.insertData(editor, 'video/*', this.processMediaForEditor());
  }

  private insertData(
    editor: TinyEditor,
    mime: FileTypes,
    callback: (editor: TinyEditor, data: MediaFile) => Observable<void>
  ) {
    return () => {
      this.fileManagerService.showMediaManager({
        fileCategory: FILECATEGORYTYPES.CONTENT,
        acceptedFileTypes: mime,
        selected: (data: MediaFile[]) => callback(editor, data[0]),
        isMediaManagerShownRef: this.layoutService.isMediaManagerShown as BehaviorSubject<boolean>,
        fileIdToUpdate: null,
        fileDirectoryId: null,
      });
    };
  }

  private addNappUploadPlugin(): Observable<boolean> {
    // see: https://github.com/tinymce/tinymce-angular/blob/master/tinymce-angular-component/src/main/ts/TinyMCE.ts
    const getTinymce = (): TinyMCE | null => {
      const w: Window = typeof window !== 'undefined' ? window : ({} as Window);
      return w && w.tinymce ? w.tinymce : null;
    };
    const tinyMCELoadedAndReady = (tMCE: TinyMCE) => {
      return tMCE && tMCE.PluginManager && typeof tMCE.PluginManager.add === 'function';
    };

    this.pluginName = `nappupload${pseudoRandomBytes(32)}`;
    this.imageButtonName = `${this.pluginName}image`;
    this.mediaButtonName = `${this.pluginName}pdf`;

    let tinyMCE = getTinymce();
    if (tinyMCE && tinyMCELoadedAndReady(tinyMCE)) {
      // TinyMCE is loaded, setup directly
      this.addPluginToTinyMCE(tinyMCE);
      return from([true]);
    } else {
      return new Observable((subscriber) => {
        let count = 0;
        const interval = setInterval(() => {
          tinyMCE = getTinymce();
          if (tinyMCE && tinyMCELoadedAndReady(tinyMCE)) {
            clearInterval(interval);
            this.addPluginToTinyMCE(tinyMCE);
            subscriber.next(true);
            subscriber.complete();
          } else if (++count > 5) {
            clearInterval(interval);
            subscriber.error(count);
          }
        }, 100);
        return {
          unsubscribe: () => {
            clearInterval(interval);
          },
        };
      });
    }
  }

  private addPluginToTinyMCE(tinyMCE: TinyMCE) {
    this.pluginLoaded = false;
    this.cdRef.detectChanges();
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    const Plugin = function (editor: TinyEditor) {
      editor.ui.registry.addButton(that.imageButtonName ?? '', {
        icon: 'gallery',
        tooltip: 'Insert/edit image',
        onAction: that.insertImage(editor),
      });

      editor.ui.registry.addButton(that.mediaButtonName ?? '', {
        icon: 'gallery',
        tooltip: 'Insert/edit media',
        onAction: that.insertMedia(editor),
      });

      return {
        getMetadata: () => {
          return {
            name: `Napoleon upload plugin instance (${that.pluginName})`,
            url: 'https://08eins.com',
          };
        },
      };
    };

    tinyMCE.PluginManager.add(this.pluginName ?? '', Plugin);
    this.removePlugin = () => {
      tinyMCE.PluginManager.remove(this.pluginName ?? '');
    };
  }
}
