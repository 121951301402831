import { BaseEntity } from './base-entity.model';

export class DamageReport extends BaseEntity<DamageReport> {
  constructor(
    public firstname?: string,
    public lastname?: string,
    public location: string = 'arbeitsplatz',
    public locationExtension?: string,
    public description?: string
  ) {
    super();
  }
}
