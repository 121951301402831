import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { CloudinaryImage } from '@cloudinary/url-gen';
import { Author } from '../../features/feed/models/author';
import { CloudinaryService } from './filemanager/services/cloudinary.service';

@Component({
  selector: 'app-author-image',
  template: `
    <div class="author-image-container">
      <advanced-image *ngIf="authorImage" [cldImg]="authorImage" width="25" height="25"></advanced-image>
      <img [src]="imageSrc" *ngIf="imageSrc" />
      <img class="animate default-img" width="25" height="25" *ngIf="!imageSrc && !isLoading && !authorImage" />
      <dx-load-indicator class="item-loader" *ngIf="isLoading" height="20" width="20"></dx-load-indicator>
    </div>
  `,
  styles: [],
})
export class AuthorImageComponent implements OnInit, OnChanges {
  @Input() public parent?: Author;

  public imageSrc?: SafeUrl | null;
  public authorImage?: CloudinaryImage;
  public isLoading = false;

  constructor(private cloudinaryService: CloudinaryService) {}

  public ngOnInit() {
    this.isLoading = true;

    this.loadPicture();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (Object.keys(changes).indexOf('parent') !== -1) {
      this.loadPicture();
    }
  }

  private loadPicture() {
    // if (this.parent?.picture) {
    //   this.fileManagerService.getFileData(this.parent.picture.id, FILESIZEVERSION.AVATAR_MEDIUM).subscribe(
    //     (imageData) => {
    //       const objectURL = URL.createObjectURL(imageData as Blob) ?? null;
    //       this.imageSrc = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    //       this.isLoading = false;
    //     },
    //     // eslint-disable-next-line @typescript-eslint/no-unused-vars
    //     (error) => {
    //       this.isLoading = false;
    //     }
    //   );
    // } else {
    //   this.imageSrc = null;
    //   this.isLoading = false;
    // }
    const externalKey = this.parent?.profilePicture?.key;
    if (externalKey) {
      console.log('lkjlfsdlfjslfkjl');
      this.authorImage = this.cloudinaryService.getImage(externalKey, 'avatar-medium');
    }

    this.isLoading = false;
  }
}
