import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RootGuard} from './core/auth/root.guard';
import {FullLayoutComponent} from './core/layouts/full-layout.component';
import {AppAuthenticationResolver} from './core/resolver/app-authentication.resolver';
import {ErrorPageComponent} from './error-page.component';
import {AppConfigResolver} from './core/resolver/app-config.resolver';
import {AuthenticationGuard} from './core/auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'feed',
    pathMatch: 'full',
  },
  {
    path: '.well-known/change-password',
    redirectTo: 'account/profile',
    pathMatch: 'full',
  },
  {
    children: [
      {
        canActivate: [RootGuard],
        loadChildren: () => import('./core/auth/auth.module').then((m) => m.AuthModule),
        path: 'auth',
      },
      {
        loadChildren: () => import('./core/account/profile/profile.module').then((m) => m.ProfileModule),
        path: 'account/profile',
      },
      {
        loadChildren: () => import('./features/home/home.module').then((m) => m.HomeModule),
        path: 'home',
      },
      {
        loadChildren: () => import('./features/site/cms-site.module').then((m) => m.CMSSiteModule),
        path: 'site',
      },
      {
        loadChildren: () => import('./features/feed/feed.module').then((m) => m.FeedModule),
        path: 'feed',
      },
      {
        canActivate: [AuthenticationGuard],
        loadChildren: () => import('./features/shortener/shortener.module').then((m) => m.ShortenerModule),
        path: 'shortener',
      },
      {
        canActivate: [AuthenticationGuard],
        loadChildren: () => import('./features/mgmt/mgmt.module').then((m) => m.MgmtModule),
        path: 'mgmt',
      },
      {
        canActivate: [AuthenticationGuard],
        loadChildren: () => import('./features/shortener/redirect/shortener-redirect.module').then((m) => m.ShortenerRedirectModule),
        path: 's',
      },
      {
        canActivate: [AuthenticationGuard],
        loadChildren: () => import('./features/magazine/magazine.module').then((m) => m.MagazineModule),
        path: 'magazine',
      },
      {
        canActivate: [AuthenticationGuard],
        loadChildren: () => import('./features/document/document.module').then((m) => m.DocumentModule),
        path: 'mediadirectories',
      },
      {
        canActivate: [AuthenticationGuard],
        loadChildren: () => import('./features/agenda/agenda.module').then((m) => m.AgendaModule),
        path: 'agenda',
      },
      {
        canActivate: [AuthenticationGuard],
        loadChildren: () => import('./features/employee/employee.module').then((m) => m.EmployeeModule),
        path: 'employee',
      },
      {
        path: 'home',
        loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'events',
        loadChildren: () => import('./features/event/event.module').then(m => m.EventModule)
      },
    ],
    component: FullLayoutComponent,
    path: '',
    resolve: [AppAuthenticationResolver, AppConfigResolver],
  },
  {
    component: ErrorPageComponent,
    path: 'error',
  },
  {
    component: ErrorPageComponent,
    path: '**',
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
})
export class AppRoutingModule {
}
