import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '~/app/core/services/base/base.service';
import { Profile } from '../models/profile';
import { ProfilePassword } from '../models/profile-password';

@Injectable({
  providedIn: 'root',
})
export class ProfilePasswordService extends BaseService<ProfilePassword> {
  constructor(public override http: HttpClient) {
    super(http, Profile);
  }

  protected getServiceUrl(): string {
    return this.apiStrategy.getServiceUrl(['profile', 'password']);
  }
}
