<div class="sidebar-right-content">
  <ng-container *ngIf="!success; else completed">
    <h3 class="title">{{ 'register.title' | translate }}</h3>
    <dx-form *appReloadOnLangChange (onEditorEnterKey)="registrateUser($event)">
      <dxi-item dataField="firstname" [editorOptions]="{ disabled: disableKeyBoardFix }">
        <dxo-label text="{{ 'labels.firstname' | translate }}"></dxo-label>
        <dxi-validation-rule type="required" message="{{ 'error.input_required' | translate }}"> </dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="lastname" [editorOptions]="{ disabled: disableKeyBoardFix }">
        <dxo-label text="{{ 'labels.lastname' | translate }}"></dxo-label>
        <dxi-validation-rule type="required" message="{{ 'error.input_required' | translate }}"> </dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="email" [editorOptions]="{ mode: 'email', disabled: disableKeyBoardFix }">
        <dxo-label text="{{ 'labels.email' | translate }}"></dxo-label>
        <dxi-validation-rule type="required" message="{{ 'error.input_required' | translate }}"> </dxi-validation-rule>
        <dxi-validation-rule type="email" message="{{ 'error.input_required' | translate }}"> </dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="password" [editorOptions]="{ mode: 'password', disabled: disableKeyBoardFix }">
        <dxo-label text="{{ 'labels.password' | translate }}"></dxo-label>
        <dxi-validation-rule type="required" message="{{ 'error.input_required' | translate }}"> </dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="passwordRepeat" [editorOptions]="{ mode: 'password', disabled: disableKeyBoardFix }">
        <dxo-label text="{{ 'labels.passwordRepeat' | translate }}"></dxo-label>
        <dxi-validation-rule type="required" message="{{ 'error.input_required' | translate }}"> </dxi-validation-rule>
        <dxi-validation-rule
          type="compare"
          [comparisonTarget]="passwordComparison"
          message="{{ 'error.password_missmatch' | translate }}"
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item
        *ngIf="showToS"
        editorType="dxCheckBox"
        [editorOptions]="{
          value: acceptToS,
          onValueChanged: acceptToSValueChanged,
          disabled: disableKeyBoardFix,
          addClass: 'w-100'
        }"
      >
        <dxo-label text="{{ 'register.acceptTos' | translate }}" location="left"></dxo-label>
        <dxi-validation-rule type="required" message="{{ 'error.input_required' | translate }}"> </dxi-validation-rule>
      </dxi-item>
      <dxi-item *ngIf="showToS" cssClass="pt-0">
        <dx-button
          *appReloadOnLangChange="'both'; timeout: 200"
          [type]="'normal'"
          [stylingMode]="'text'"
          class="dx-auto-width"
          (onClick)="readToS()"
          >{{ 'register.readTos' | translate }}</dx-button
        >
      </dxi-item>
    </dx-form>
    <dx-button [type]="'default'" [stylingMode]="'contained'" class="dx-auto-width" (onClick)="registrateUser()">{{
      'register.button' | translate
    }}</dx-button>
  </ng-container>
  <ng-template #completed>
    <div class="registration-completed">
      <p>{{ 'login.success.title' | translate }}</p>
      <p>{{ 'login.success.mailSend' | translate }}</p>
    </div>
  </ng-template>
</div>
