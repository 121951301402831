import { Component, Inject } from '@angular/core';
import { ENVIRONMENT_CONFIGURATION } from '@softwarehaus/util-configuration';
import { NappEnvironmentConfiguration } from '~/app/config/environments-configuration';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent {
  public footerConfig = {
    ...this.config.footer,
    phoneNrLink: this.config.footer.phoneNr.replace(/\s/g, ''),
    showSponsors: this.config.footer.type === 'sponsor' && Array.isArray(this.config.footer.sponsorLogos),
  };
  constructor(@Inject(ENVIRONMENT_CONFIGURATION) private config: NappEnvironmentConfiguration) {}

  public getLogoCSSLink(logo: string) {
    return `url('assets/themes/${this.config.theme}/images/sponsors/${logo}.svg')`;
  }

  public getLogoCSSWidth(width: number) {
    return `${width}px`;
  }
}
