import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { encodeHtml } from 'devextreme/core/utils/string';
import { custom } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { AppAngularBridge } from '../../app-bridge';
import { IToastPositionConfig } from '../models/toastpositionconfig.interface';

export enum TOASTTYPE {
  ERROR = 'error',
  SUCCESS = 'success',
}

@Injectable()
export class MessageService {
  private config: IToastPositionConfig = {
    at: 'top',
    my: 'top',
    of: 'body',
  };

  constructor(private translate: TranslateService) {}

  public showToast(
    type: string,
    message: string,
    displayTime: number = type === TOASTTYPE.SUCCESS ? 400 : 3000,
    position: IToastPositionConfig = this.config,
    shading: boolean = false,
    closeOnClick: boolean = true,
    width: string = '100%'
  ) {
    notify({
      message,
      type,
      width,
      // tslint:disable-next-line:object-literal-sort-keys
      displayTime,
      closeOnClick,
      position,
      shading,
    });
  }

  public showErrorDialog() {
    const keys = ['message', 'reloadPage', 'title'];
    const translation: {
      [x in (typeof keys)[number]]: { translationKey: string; default: string; translated?: string };
    } = {
      message: { translationKey: 'error.unknown', default: 'An unknown error has occurred. Please try again later.' },
      reloadPage: { translationKey: 'message.error.reloadPage', default: 'Refresh site' },
      title: { translationKey: 'message.error.title', default: 'Error' },
    };
    for (const key of keys) {
      translation[key].translated = this.translate.instant(translation[key].translationKey);
      if (translation[key].translated === translation[key].translationKey) {
        translation[key].translated = translation[key].default;
      }
    }
    const encodedMessage = encodeHtml(translation['message'].translated ?? '');
    const myDialog = custom({
      buttons: [
        {
          onClick: (e) => {
            document.location.href = '/';
            return { buttonText: e.component.option('text') };
          },
          text: translation['reloadPage'].translated,
        },
      ],
      messageHtml: encodedMessage,
      title: translation['title'].translated,
    });
    myDialog.show().then(() => {
      AppAngularBridge.webReady();
    });
  }
}
