import { ProfileAddress } from '~/app/core/account/profile/models/profile-address';
import { ProfileEmployeeInfo } from '~/app/core/account/profile/models/profile-employee-info';
import { BaseEntity } from '~/app/core/models/base-entity.model';
import { Context } from '../../context/models/context';
import { Role } from '../../role/models/role';

export class User extends BaseEntity<User> {
  constructor(
    public firstname?: string,
    public lastname?: string,
    public email?: string,
    public phone?: string,
    public username?: string,
    public password?: string,
    public roles: Role[] = [],
    public contexts: Context[] = [],
    public address?: ProfileAddress,
    public employeeInfo?: ProfileEmployeeInfo,
    public picture?: {
      id: string;
    }
  ) {
    super();
  }

  public get fullName() {
    return `${this.firstname ?? ''} ${this.lastname ?? ''}`.trim();
  }

  public get fullname() {
    return this.fullName;
  }
}
