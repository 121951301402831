import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService, IServiceUrlConfig } from '~/app/core/services/base/base.service';
import { Attribute } from '../models/attribute';

@Injectable({
  providedIn: 'root',
})
export class AttributeService extends BaseService<Attribute> {
  constructor(public override http: HttpClient) {
    super(http, Attribute);
  }

  protected getServiceUrl(entityId?: string, serviceUrlConfig?: IServiceUrlConfig): string {
    const url = this.generateServiceURL('attribute', entityId, serviceUrlConfig);
    return this.apiStrategy.getServiceUrl(url);
  }
}
