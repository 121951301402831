<footer class="footer" [ngClass]="{ sponsors: footerConfig.showSponsors }">
  <div class="sponsors-container container" id="sponsors-container">
    <div class="text-center">
      <div *ngIf="footerConfig.showSponsors" class="adressbox logos-sponsors">
        <span class="sponsors-caption">{{ 'footer.sponsorsCaption' | translate }}</span>
        <ng-container *ngFor="let logo of footerConfig.sponsorLogos">
          <span
            class="logo"
            [ngStyle]="{ backgroundImage: getLogoCSSLink(logo.name), width: getLogoCSSWidth(logo.width) }"
          ></span>
        </ng-container>
      </div>
      <div class="adressbox icon-only" *ngIf="footerConfig.linkedIn || footerConfig.instagram || footerConfig.facebook">
        <span *ngIf="footerConfig.linkedIn"
          ><a href="{{ footerConfig.linkedIn }}" target="_blank"><i class="fa-brands fa-linkedin"></i></a
        ></span>
        <span *ngIf="footerConfig.instagram"
          ><a href="{{ footerConfig.instagram }}" target="_blank"><i class="fab fa-instagram"></i></a
        ></span>
        <span *ngIf="footerConfig.facebook"
          ><a href="{{ footerConfig.facebook }}" target="_blank"><i class="fab fa-facebook"></i></a
        ></span>
      </div>
      <div class="adressbox">
        <span><i class="fa fa-map-marker"></i>{{ footerConfig.customer }}</span>
        <span
          ><i class="fa fa-phone"></i>{{ 'footer.phone' | translate }}
          <a href="tel:{{ footerConfig.phoneNrLink }}">{{ footerConfig.phoneNr }}</a></span
        >
        <span
          ><i class="fa fa-globe"></i>
          <a href="https://{{ footerConfig.website }}" target="_blank">{{ footerConfig.website }}</a></span
        >
        <span
          ><i class="fa fa-envelope"></i> <a href="mailto:{{ footerConfig.email }}">{{ footerConfig.email }}</a></span
        >
      </div>
    </div>
  </div>
</footer>
