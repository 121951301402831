<app-scroll-view-with-bottom-bar class="file-upload">
  <div class="upload-zone" [class.crop-mode]="cropMode">
    <div [hidden]="this.cropMode" class="col file-input">
      <h3>{{ 'filemanager.uploadtext' | translate }}</h3>
      <p>{{ 'filemanager.uploadtext_or' | translate }}</p>
      <input
        id="fileChooser"
        *ngIf="!resetInput && !isNative()"
        type="file"
        [accept]="getAcceptedFileTypes()"
        multiple="false"
        (change)="fileChangeEvent($event)"
      />
      <button id="fileChooserBtn" *ngIf="isNative()" (click)="chooseFileViaBridge()">
        {{ 'filemanager.chooseFile' | translate }}
      </button>
    </div>
    <div [hidden]="!this.cropMode">
      <div class="image-rotation">
        <dx-button icon="undo" (onClick)="rotateLeft()"></dx-button>
        <dx-button icon="redo" (onClick)="rotateRight()"></dx-button>
      </div>

      <image-cropper
        *ngIf="!isNative()"
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="cropAspectRatio"
        [format]="outputFormat"
        [canvasRotation]="canvasRotation"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
      <image-cropper
        *ngIf="isNative()"
        [imageFile]="imageFile"
        [maintainAspectRatio]="true"
        [aspectRatio]="cropAspectRatio"
        [format]="outputFormat"
        [canvasRotation]="canvasRotation"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
    </div>
    <dx-load-indicator *ngIf="isLoading" class="item-loader" height="20" width="20"></dx-load-indicator>
  </div>
  <div class="bottom-bar" *ngIf="this.cropMode">
    <dx-button class="small" [type]="'normal'" [stylingMode]="'text'" (onClick)="cancel()">{{
      'general.buttons.cancel' | translate
    }}</dx-button>
    <app-button (buttonClick)="uploadCroppedImage()">{{ 'general.buttons.save' | translate }}</app-button>
  </div>
</app-scroll-view-with-bottom-bar>
