import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CloudinaryImage } from '@cloudinary/url-gen';
import { TranslateService } from '@ngx-translate/core';
import { confirm } from 'devextreme/ui/dialog';
import { Subscription } from 'rxjs';
import { CloudinaryService } from '../filemanager/services/cloudinary.service';
import { ExternalFileService } from './external-file.service';
import { ExternalFile } from './types';

export type ExternalFileSelection = { file: ExternalFile; active: boolean };

@Component({
  selector: 'app-external-file-chooser-item',
  templateUrl: './external-file-chooser-item.component.html',
})
export class ExternalFileChooserItemComponent implements OnInit, OnDestroy {
  @Input() public file?: ExternalFile;
  @Input() public editMode?: boolean;
  @Input() public selectedFiles?: ExternalFile[];

  @Output() public itemRemoved = new EventEmitter<ExternalFile>();
  @Output() public itemClick = new EventEmitter<ExternalFileSelection>();

  public previewImage?: CloudinaryImage;
  public active = false;

  private fileManagerSubscription?: Subscription;

  constructor(
    private translate: TranslateService,
    private cloudinaryService: CloudinaryService,
    private externalFileService: ExternalFileService
  ) {}

  public ngOnInit() {
    if (!this.file) return;
    this.previewImage = this.cloudinaryService.getImage(this.file.key);
  }

  public ngOnDestroy() {
    this.fileManagerSubscription?.unsubscribe();
  }

  public isActive(currentSelection: ExternalFile[]) {
    this.active = currentSelection.filter((selection) => selection.id === this.file?.id).length > 0;
    return this.active;
  }

  public onClick() {
    if (this.editMode) {
      const confirmation = confirm(
        this.translate.instant('general.confirm_text'),
        this.translate.instant('general.confirm_title')
      );
      confirmation.then((dialogResult) => {
        if (!dialogResult || !this.file) return;

        this.externalFileService.deleteExternalFile(this.file.id).subscribe(() => {
          this.itemRemoved.emit(this.file);
        });
      });
    } else {
      this.active = !this.active;
      if (this.file) {
        this.itemClick.emit({ file: this.file, active: this.active });
      }
    }
  }
}
