import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DxButtonModule } from 'devextreme-angular';
import { CardBodyTemplateDirective } from './card/card-body/card-body-template.directive';
import { CardBodyComponent } from './card/card-body/card-body.component';
import { CardBodyDirective } from './card/card-body/card-body.directive';
import { CardFooterTemplateDirective } from './card/card-footer/card-footer-template.directive';
import { CardFooterComponent } from './card/card-footer/card-footer.component';
import { CardFooterDirective } from './card/card-footer/card-footer.directive';
import { CardHeaderTemplateDirective } from './card/card-header/card-header-template.directive';
import { CardHeaderComponent } from './card/card-header/card-header.component';
import { CardHeaderDirective } from './card/card-header/card-header.directive';
import { CardComponent } from './card/card.component';
import { DatatableComponent } from './datatable/datatable.component';
import { SafePipe } from './pipes/safe.pipe';

@NgModule({
  declarations: [
    DatatableComponent,
    CardComponent,
    CardHeaderComponent,
    CardHeaderDirective,
    CardHeaderTemplateDirective,
    CardBodyComponent,
    CardBodyDirective,
    CardBodyTemplateDirective,
    CardFooterComponent,
    CardFooterTemplateDirective,
    CardFooterDirective,
    SafePipe,
  ],
  imports: [DxButtonModule, CommonModule],
  exports: [
    DatatableComponent,
    CardComponent,
    CardHeaderComponent,
    CardHeaderDirective,
    CardHeaderTemplateDirective,
    CardBodyComponent,
    CardBodyDirective,
    CardBodyTemplateDirective,
    CardFooterComponent,
    CardFooterTemplateDirective,
    CardFooterDirective,
    SafePipe,
  ],
})
export class NappGuiLibModule {}
