import { TranslatableEntity } from '~/app/core/models/translatable-entity';
import { Context } from '../../../mgmt/context/models/context';
import { FeedPostType } from '../../models/feed-post-type';
import { TagLanguage } from './tag-language';

export class Tag extends TranslatableEntity<Tag, TagLanguage> {
  public name?: string;

  constructor(
    /* public */ languages: TagLanguage[] = [],
    public type: FeedPostType = {} as FeedPostType,
    public contexts: Context[] = []
  ) {
    super(languages);
  }

  public updateTranslations(): void {
    const translation = this.getPropertyTranslation(this.translatedEntities, 'name');
    this.name = translation?.name.toString() ?? '';
  }

  public override deserialize(object: this): this {
    object.contexts = object.contexts ? object.contexts.map((item) => new Context().deserialize(item)) : [];
    return super.deserialize(object);
  }
}
