<div class="page-wrapper">
  <!--- Main Content --->
  <div id="content" class="active">
    <main>
      <div class="container" id="main-page-wrapper">
        <div class="row">
          <div class="col-12 text-center">
            <img src="assets/images/napoleon_gross.png" />
          </div>
          <div class="col-12 text-center">
            <h1>:-(</h1>
            <p>
              {{ 'message.error.somethingWentWrong' | translate }}
            </p>
            <p>
              <dx-button icon="refresh" [text]="'general.buttons.reload' | translate" (onClick)="reload()"></dx-button>
            </p>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>
