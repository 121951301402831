import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap, retry } from 'rxjs/operators';
import { BaseService } from '~/app/core/services/base/base.service';
import { ProfileEmployeeInfo } from '../models/profile-employee-info';

@Injectable({
  providedIn: 'root',
})
export class ProfileEmployeeInfoService extends BaseService<ProfileEmployeeInfo> {
  constructor(public override http: HttpClient) {
    super(http, ProfileEmployeeInfo);
  }

  protected getServiceUrl(): string {
    return this.apiStrategy.getServiceUrl(['profile', 'employeeinfo']);
  }

  public getByUserId(userId: string): Observable<ProfileEmployeeInfo> {
    return this.apiStrategy.getHeaders().pipe(
      mergeMap((headers) => {
        return this.http
          .get<ProfileEmployeeInfo>(this.apiStrategy.getServiceUrl(['mgmt', 'user', userId, 'employeeinfo']), {
            headers,
          })
          .pipe(
            retry(2),
            map((result: ProfileEmployeeInfo) => {
              return new this.parentModel().deserialize(result);
            })
          );
      })
    );
  }

  public updateByUserId(entity: ProfileEmployeeInfo, userId: string): Observable<ProfileEmployeeInfo> {
    return this.apiStrategy.getHeaders().pipe(
      mergeMap((headers) => {
        return this.http.patch<ProfileEmployeeInfo>(
          this.apiStrategy.getServiceUrl(['mgmt', 'user', userId, 'employeeinfo']),
          entity,
          {
            headers,
          }
        );
      })
    );
  }
}
