import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mergeMap } from 'rxjs/operators';
import { AppApiStrategy } from '~/app/app-api-strategy';

@Injectable({
  providedIn: 'root',
})
export class ResetPasswordService {
  constructor(public http: HttpClient) {}

  public getServiceUrl(endpoint: string): string {
    return AppApiStrategy.getServiceUrl(['user', endpoint]);
  }

  public requestSession(email: string) {
    return AppApiStrategy.getHeaders().pipe(
      mergeMap((headers) => {
        return this.http.post(
          this.getServiceUrl('reset-password'),
          { username: email },
          {
            headers,
          }
        );
      })
    );
  }

  public finalizeSession(password: string, session: string) {
    return AppApiStrategy.getHeaders().pipe(
      mergeMap((headers) => {
        return this.http.post(
          this.getServiceUrl('reset-password-confirmation'),
          { password, session },
          {
            headers,
          }
        );
      })
    );
  }
}
