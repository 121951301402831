export enum LOGINTYPE {
  USER = 'user',
  APP = 'app',
}

export class StorageConfig {
  public static readonly ACCESS_TOKEN_KEY = 'access_token';
  public static readonly ACCESS_TOKEN_EXPIRATION = 'access_token_exp';
  public static readonly ACCOUNT_ID_KEY = 'account_id';
  public static readonly ACCOUNT_CHOOSEN = 'account_choosen';
  public static readonly SCOPES_KEY = 'scopes';
  public static readonly USER_IDENTIFICATION = 'ro_identification';
  public static readonly USER_ID = 'ro_id';
  public static readonly USER_NAME = 'ro_name';
  public static readonly COMPANY_NAME = 'company_name';
  public static readonly COMPANY_TYPE = 'company_type';
  public static readonly REDIRECT = 'redirect';
  public static readonly LOGGEDIN_TYPE = 'login_type';
  public static readonly LANGUAGES = 'languages';
  public static readonly CURRENT_LANGUAGE = 'current_language';
  public static readonly CLIENT = 'access_token';
  public static readonly USER_FIRSTNAME = 'user_firstname';
  public static readonly USER_LASTNAME = 'user_lastname';
  public static readonly GOOGLE_ANALYTICS = 'google_analytics';
  public static readonly FIREBASE_ANALYTICS = 'firebase_analytics';
  public static readonly PUSH_STATUS = 'push_status';

  public static clearStorage() {
    localStorage.clear();
  }
}
