import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ENVIRONMENT_CONFIGURATION } from '@softwarehaus/util-configuration';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Observable, Subject } from 'rxjs';
import { NappEnvironmentConfiguration } from '~/app/config/environments-configuration';
import { AppAngularBridge } from '../../../app-bridge';
import { MessageService, TOASTTYPE } from '../../../core/services/message.service';
import { MediaFile } from './models/media-file';
import { CloudinaryResponse, CloudinaryService } from './services/cloudinary.service';
import { FILECATEGORYTYPES, FileManagerService, FILESIZEVERSION } from './services/filemanager.service';

class NameExtendedBlob extends Blob {
  public constructor(blobParts?: BlobPart[], public name?: string, options?: BlobPropertyBag) {
    super(blobParts, options);
  }
}

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
})
export class FileUploadComponent {
  @Input() public enableCrop = false;
  @Input() public cropAspectRatio = 1 / 1;
  @Input() public fileCategoryType?: FILECATEGORYTYPES;
  @Input() public set acceptedFileTypes(types: string | null) {
    if (types !== this._acceptedFileTypes) {
      this._acceptedFileTypes = types;
      this.triggerResetInput().subscribe();
    }
  }
  public get acceptedFileTypes(): string | null {
    return this._acceptedFileTypes ?? null;
  }
  @Input() public updateFile = false;
  @Input() public fileIdToUpdate!: string;
  @Input() public fileDirectoryId!: string;
  @Input() public resetInput = false;

  @Output() public apply = new EventEmitter<MediaFile[]>();
  @Output() public videoUploaded = new EventEmitter<string>();
  @Output() public imageUploaded = new EventEmitter<string>();
  @Output() public fileUploaded = new EventEmitter<string>();

  public isLoading = false;
  public imageChangedEvent?: ImageCroppedEvent | Event;
  public imageFile?: File;
  public croppedImage?: Blob;
  public cropMode = false;
  public canvasRotation = 0;
  public outputFormat: 'png' | 'jpeg' = 'jpeg';

  private fileData?: MediaFile;
  private _acceptedFileTypes?: string | null;

  constructor(
    private fileManagerService: FileManagerService,
    private cdRef: ChangeDetectorRef,
    private cloudinaryService: CloudinaryService,
    private messageService: MessageService,
    private translate: TranslateService,
    @Inject(ENVIRONMENT_CONFIGURATION) private config: NappEnvironmentConfiguration
  ) {}

  public getAcceptedFileTypes() {
    if (this.acceptedFileTypes) {
      if (Array.isArray(this.acceptedFileTypes)) {
        // multiple types must be a comma separated list
        return this.acceptedFileTypes.join(',');
      } else {
        // single filetype
        return this.acceptedFileTypes;
      }
    } else {
      // allow all filetypes to be picked
      return '*/*';
    }
  }

  public chooseFileViaBridge(): void {
    const chooseFileObservable = AppAngularBridge.chooseFile(
      this.getAcceptedFileTypes(),
      this.fileManagerService.getServiceUrl(this.fileIdToUpdate).replace(this.config.api.baseServiceUrl, ''),
      this.updateFile ? this.fileIdToUpdate : undefined,
      this.fileCategoryType,
      this.fileDirectoryId
    );
    if (chooseFileObservable) {
      chooseFileObservable.subscribe({
        next: (fileData) => {
          if (this.acceptedFileTypes === 'video/*') {
            this.videoUploaded.emit(fileData?.id);
          } else if (this.enableCrop) {
            this.isLoading = true;
            this.fileManagerService.getFileData(fileData?.id ?? '', FILESIZEVERSION.AVATAR).subscribe((imageData) => {
              if (fileData !== null) fileData.imageData = imageData ?? (null as unknown as Blob);
              if (fileData !== null) this.fileData = fileData;
              if (this.fileData?.imageData !== undefined) {
                this.imageFile = this.fileData.imageData as File;
              }
              if (!this.updateFile) {
                // overwrite created file with cropped version, so setup data here
                this.updateFile = true;
                this.fileIdToUpdate = fileData?.id ?? '';
              }
              this.cropMode = true;
            });
          } else {
            this.loadServerFile(fileData ?? (null as unknown as MediaFile));
          }
        },
        error: this.uploadError.bind(this),
      });
    } else {
      this.uploadError();
    }
  }

  public isNative() {
    return AppAngularBridge.isNative();
  }

  public fileChangeEvent(event: Event): void {
    const target = event.target as HTMLInputElement;
    const file = target.files ? target.files[0] : null;

    if (file) {
      if (file.type === 'image/png') {
        this.outputFormat = 'png';
      } else {
        this.outputFormat = 'jpeg';
      }

      if (this.enableCrop) {
        this.imageChangedEvent = event;
        this.isLoading = true;
        this.cropMode = true;
      } else {
        if (this.acceptedFileTypes === 'video/*') {
          this.uploadVideoToCloudinary(file);
        } else {
          this.uploadFile(file);
        }
      }
    }
  }

  public imageCropped(event: ImageCroppedEvent) {
    this.isLoading = false;

    const base64URL = event.base64 as string;
    const base64String = base64URL.split(',')[1];
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/jpeg' });
    this.croppedImage = blob;
  }

  public async uploadCroppedImage() {
    const native = AppAngularBridge.isNative();
    if (!this.imageChangedEvent) return;
    const files = (<HTMLInputElement>(this.imageChangedEvent as Event).target)?.files;
    const type = native ? this.fileData?.extension?.mimetype : files?.[0]?.type;
    const name = native ? this.fileData?.name : files?.[0]?.name;

    const blob = this.croppedImage;

    if (!blob) return;

    // let file: File | NameExtendedBlob;
    // try {
    //   file = new File([blob], name as string, { type });
    //   console.log('normal file');
    // } catch (err) {
    //   file = new NameExtendedBlob([blob], name, { type });
    // }

    // this.uploadFile(file);

    const file = new File([blob], name as string, { type });
    this.uploadFileCloudinary(file);
  }

  public imageLoaded() {
    // show cropper
  }

  public cropperReady() {
    // cropper ready
  }

  public loadImageFailed() {
    // show message
  }

  public cancel() {
    this.triggerResetInput().subscribe({
      next: () => {
        this.imageChangedEvent = undefined;
        this.cropMode = false;
      },
    });
  }

  public rotateLeft() {
    this.canvasRotation--;
  }

  public rotateRight() {
    this.canvasRotation++;
  }

  public triggerResetInput(): Observable<void> {
    return this._triggerResetInput(true);
  }

  private uploadFileCloudinary(file: File) {
    this.cloudinaryService.uploadImageToCloudinary(file).subscribe({
      next: (result: CloudinaryResponse) => {
        console.log('result from uploadFileCloudinary', result);
        this.fileUploaded.emit(result.public_id);
        // this.imageUploaded.emit(result.public_id);
      },
      error: (err) => {
        let errData = 'Unknown Error';
        try {
          errData = JSON.stringify(err);
        } catch (e) {
          // ignore
        }
        this.messageService.showToast(
          TOASTTYPE.ERROR,
          `${this.translate.instant('message.error.cloudinaryError')} ${errData}`
        );
        this.triggerResetInput();
      },
    });
  }

  private uploadFile(file: File | NameExtendedBlob) {
    this.isLoading = true;
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('name', file.name as string);

    if (this.fileCategoryType && this.fileCategoryType !== FILECATEGORYTYPES._CLOUDINARY_) {
      formData.append('category[id]', this.fileCategoryType);
    }

    if (this.fileDirectoryId) {
      formData.append('directory[id]', this.fileDirectoryId);
    }

    if (!this.updateFile) {
      this.fileManagerService.post(formData as unknown as MediaFile).subscribe({
        next: (result) => {
          this.loadServerFile(result);
        },
        error: this.uploadError.bind(this),
      });
    } else {
      // first update data of file
      this.fileManagerService
        .put(formData as unknown as MediaFile, this.fileIdToUpdate, false, { path: ['data'] })
        .subscribe({
          next: () => {
            this.fileManagerService
              .getEntityById({ entityId: this.fileIdToUpdate })
              .subscribe((updatedFile: MediaFile) => {
                this.loadServerFile(updatedFile);
              });
          },
          error: this.uploadError.bind(this),
        });
    }
  }

  private uploadError() {
    this.triggerResetInput().subscribe({
      next: () => {
        this.isLoading = false;
        this.imageChangedEvent = undefined;
      },
    });
  }

  private loadServerFile(file: MediaFile) {
    if (!file.id) return;
    this.fileManagerService.getFileData(file.id).subscribe((imageData) => {
      file.imageData = imageData || undefined;
      this.isLoading = false;
      this.apply.emit([file]);
      this.cropMode = false;
      this.imageChangedEvent = undefined;
    });
  }

  private uploadVideoToCloudinary(file: File) {
    this.cloudinaryService.uploadVideoToCloudinary(file).subscribe({
      next: (result: CloudinaryResponse) => {
        this.fileUploaded.emit(result.public_id);
        // this.videoUploaded.emit(result.public_id);
      },
      error: (err) => {
        let errData = 'Unknown Error';
        try {
          errData = JSON.stringify(err);
        } catch (e) {
          // ignore
        }
        this.messageService.showToast(
          TOASTTYPE.ERROR,
          `${this.translate.instant('message.error.cloudinaryError')} ${errData}`
        );
        this.triggerResetInput();
      },
    });
  }

  private _triggerResetInput(reset: boolean, obs?: Subject<void>): Observable<void> {
    this.resetInput = reset;
    this.cdRef.detectChanges();
    if (reset) {
      obs = new Subject<void>();
      setTimeout(() => this._triggerResetInput(false, obs), 0);
    } else {
      if (obs) {
        obs.next();
        obs.complete();
      }
    }
    if (!obs) return new Observable<void>();
    return obs.asObservable();
  }
}
