<div id="sidebar-left" class="closed" [ngClass]="{ closed: !this.open }">
  <div class="top-bar">
    <div class="logo-bg">
      <i class="napp-logo napp-logo-brand" [class.animate]="this.open"></i>
      <!--      <svg height="30" class="logo"  [class.animate]="this.open" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.69 85.04">-->
      <!--        <g id="Layer_2" data-name="Layer 2">-->
      <!--          <g id="Typo">-->
      <!--            <path d="M58.741 25.177H0V0h24.447v9.9h9.849V0h24.445v25.177zM34.296 54.425v9.857c14.06-2.64 24.445-14.886 24.445-28.638v-.519H0v.519c0 13.752 10.393 25.998 24.447 28.638v-9.857h9.849z" fill="#E21F26"></path>-->
      <!--          </g>-->
      <!--        </g>-->
      <!-- </svg> -->
    </div>
  </div>
  <dx-button (click)="close()" icon="fas fa-times" id="close-icon"></dx-button>
  <dx-load-indicator class="item-loader" *ngIf="isLoading | async" height="20" width="20"></dx-load-indicator>
  <nav #leftMenu2 id="left-menu"></nav>
  <div class="version-container">
    <small class="version">version:&nbsp;{{ version }}</small>
  </div>
</div>
