import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpProgressState } from '~/app/core/services/base/base.service';

interface Progress {
  url: string;
  state: HttpProgressState;
}

@Injectable({
  providedIn: 'root',
})
export class HttpStateService {
  public state = new BehaviorSubject<Progress | null>(null);

  constructor() {}
}
