import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DxFormComponent } from 'devextreme-angular';
import { EditorEnterKeyEvent } from 'devextreme/ui/form';
import { Subscription } from 'rxjs';
import { AppAngularBridge } from '~/app/app-bridge';
import { AuthService } from '~/app/core/auth/authentication.service';
import { ResetPasswordService } from '~/app/core/auth/reset-password/reset-password.service';
import { MessageService, TOASTTYPE } from '~/app/core/services/message.service';
import { LayoutService } from '~/app/core/services/store/layout.service';
import { KeyboardNavigableComponent } from '~/app/shared/components/keyboard-navigable.component';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent extends KeyboardNavigableComponent implements OnInit, OnDestroy {
  @ViewChild(DxFormComponent) public form?: DxFormComponent;

  @HostBinding('class.right-menu') public addClass = false;

  public session: string | null = null;
  public disableKeyBoardFix = false;

  private layoutSubscription?: Subscription;

  constructor(
    public authService: AuthService,
    private resetPasswordService: ResetPasswordService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private layoutService: LayoutService
  ) {
    super();
  }

  public ngOnInit() {
    this.addClass = true;
    this.getSession();

    if (AppAngularBridge.isNative()) {
      this.layoutSubscription = this.layoutService.showUserSidebarSubject.subscribe((isOpen) => {
        if (isOpen) {
          this.disableKeyBoardFix = true;
        }
        AppAngularBridge.sideBarStatus(isOpen).subscribe({
          next: () => {
            if (isOpen) {
              this.disableKeyBoardFix = false;
            }
          },
          error: () => {
            this.disableKeyBoardFix = false;
          },
        });
      });
    }
  }

  public ngOnDestroy() {
    this.layoutSubscription?.unsubscribe();
  }

  public passwordComparison = () => {
    return this.form?.instance.option('formData').newPassword;
  };

  public requestSession(event?: EditorEnterKeyEvent) {
    if (!this.form) return;

    if (this.form.instance.validate().isValid) {
      this.blurAllFields(event);
      this.resetPasswordService.requestSession(this.form.instance.option('formData').email).subscribe(() => {
        this.messageService.showToast(TOASTTYPE.SUCCESS, this.translate.instant('passwordReset.mailSend'));
        this.form?.instance.resetValues();
      });
    } else {
      if (!event) return;
      this.focusNextField(event, this.form.instance);
    }
  }

  public finalizeSession(event?: EditorEnterKeyEvent) {
    if (!this.form) return;

    if (this.form.instance.validate().isValid && this.session) {
      this.blurAllFields(event);
      this.resetPasswordService
        .finalizeSession(this.form.instance.option('formData').newPassword, this.session)
        .subscribe(() => {
          this.messageService.showToast(TOASTTYPE.SUCCESS, this.translate.instant('passwordReset.newPwSet'));
          this.form?.instance.resetValues();
          this.authService.confirmed = true;
          this.authService.resetPasswordUser = false;
        });
    } else {
      if (!event) return;
      this.focusNextField(event, this.form.instance);
    }
  }

  private getSession() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.session = params['s'];
    });
  }
}
