import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, enableProdMode, Inject, Injectable, Injector, NgModule } from '@angular/core';
import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG, Meta } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { ENVIRONMENT_CONFIGURATION, provideEnvironmentConfiguration } from '@softwarehaus/util-configuration';
import {
  NAPOLEON_ENVIRONMENTS_CONFIGURATION,
  NappEnvironmentConfiguration,
} from '~/app/config/environments-configuration';
import { ThemeService } from '~/app/core/services/theme.service';
import { setAppApiStrategy } from './app-api-strategy';
import { setAppTranslation } from './app-app-translation';
import { setAppAngularBridge } from './app-bridge';
import { setAppInjector } from './app-injector';
import { setAppRouterHistory } from './app-router-history';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AngularBridgeService } from './core/services/angular-bridge.service';
import { BaseModelServiceApiStrategy } from './core/services/backend/base-model-api.service.strategy';
import { RouterHistoryService } from './core/services/router-history.service';
import { AppTranslationService } from './core/services/translation/app-translation.service';
import { ErrorPageComponent } from './error-page.component';
import { SharedModule } from './shared/shared.module';

@Injectable()
export class CustomHammerConfig extends HammerGestureConfig {
  public override overrides: { [key: string]: object } = {
    pinch: { enable: false },
    rotate: { enable: false },
  };
}

export function initializeApp(appInitService: AngularBridgeService) {
  return (): Promise<void> => {
    return appInitService.init();
  };
}

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent, ErrorPageComponent],
  imports: [
    BrowserModule,
    LayoutModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    CoreModule,
    SharedModule,
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AngularBridgeService], multi: true },
    { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig },
    provideEnvironmentConfiguration(NAPOLEON_ENVIRONMENTS_CONFIGURATION),
    ThemeService,
  ],
})
export class AppModule {
  constructor(
    injector: Injector,
    apiStrategy: BaseModelServiceApiStrategy,
    routerHistory: RouterHistoryService,
    appTranslation: AppTranslationService,
    angularBridge: AngularBridgeService,
    meta: Meta,
    @Inject(ENVIRONMENT_CONFIGURATION) config: NappEnvironmentConfiguration
  ) {
    if (config.environmentName !== 'local') enableProdMode();

    setAppInjector(injector);
    setAppApiStrategy(apiStrategy);
    setAppRouterHistory(routerHistory);
    setAppTranslation(appTranslation);
    setAppAngularBridge(angularBridge);

    const appleAppID = config.appleAppID;
    const baseUrl = config.appUrl;
    if (appleAppID) {
      const appleAppLinkMetaTagName = 'apple-itunes-app';
      const appleAppLinkMetaTagKey = `name=${appleAppLinkMetaTagName}`;
      routerHistory.currentUrl$.subscribe((url) => {
        const appArgument = `${baseUrl}${
          null === url || typeof url === 'undefined' ? '' : '/' === url.charAt(0) ? url : `/${url}`
        }`;
        meta.updateTag(
          { name: appleAppLinkMetaTagName, content: `app-id=${appleAppID}, app-argument=${appArgument}` },
          appleAppLinkMetaTagKey
        );
      });
    }
  }
}
