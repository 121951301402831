export type HTTPSuccessCode = keyof typeof httpStatusCodes.success;

export type HTTPErrorCode = keyof Omit<typeof httpStatusCodes.error, 'code' | 'field'>;

export type RESTApiErrorCode = keyof typeof httpStatusCodes.error.code;

export type RESTApiErrorField = keyof typeof httpStatusCodes.error.field;

export const httpStatusCodes = {
  success: {
    '200': {
      reason: 'Ok',
      showToast: false,
      showModal: false,
      log: false,
      message: 'message.200',
    },
    '201': {
      reason: 'Created',
      showToast: true,
      showModal: false,
      log: false,
      message: 'message.201',
    },
    '204': {
      reason: 'No Content',
      showToast: true,
      showModal: false,
      log: false,
      message: 'message.204',
    },
    '250': {
      reason: 'Mutation',
      showToast: true,
      showModal: false,
      log: false,
      message: 'message.250',
      displayTime: 3000,
    },
    '251': {
      reason: 'Mutation',
      showToast: true,
      showModal: false,
      log: false,
      message: 'message.251',
      displayTime: 3000,
    },
  },
  error: {
    '0': {
      reason: 'Unknown Error',
      showToast: true,
      showModal: false,
      log: false,
      message: 'message.error.general',
    },
    '400': {
      reason: 'Bad Request',
      showToast: true,
      showModal: false,
      log: false,
      message: 'message.400',
    },
    '401': {
      reason: 'Unauthorized',
      showToast: true,
      showModal: false,
      log: false,
      message: 'message.401',
    },
    '403': {
      reason: 'Forbidden',
      showToast: true,
      showModal: false,
      log: false,
      message: 'message.403',
    },
    '404': {
      reason: 'Not Found',
      showToast: true,
      showModal: false,
      log: false,
      message: 'message.404',
    },
    '405': {
      reason: 'Not allowed',
      showToast: true,
      showModal: false,
      log: false,
      message: 'message.405',
    },
    '422': {
      reason: 'Not Found',
      showToast: true,
      showModal: false,
      log: false,
      message: 'message.422',
    },
    '500': {
      reason: 'Server Error',
      showToast: true,
      showModal: false,
      log: true,
      message: 'message.500',
    },
    code: {
      file_not_found: {
        showToast: false,
        showModal: false,
        log: false,
        message: 'message.error.general',
        logout: false,
      },
      invalid_route: {
        showToast: true,
        showModal: false,
        log: false,
        message: 'message.error.general',
        logout: true,
      },
      invalid_token: {
        showToast: true,
        showModal: false,
        log: false,
        message: 'message.error.invalid_token',
        logout: true,
      },
      invalid_request: {
        showToast: true,
        showModal: false,
        log: false,
        message: 'message.error.general',
        logout: true,
      },
      introspection_failed: {
        showToast: true,
        showModal: false,
        log: false,
        message: 'message.error.general',
        logout: true,
      },
      invalid_credentials: {
        showToast: true,
        showModal: false,
        log: false,
        message: 'message.error.invalid_credential',
        logout: true,
      },
      invalid_client: {
        showToast: true,
        showModal: false,
        log: false,
        message: 'message.error.invalid_credential',
        logout: true,
      },
      invalid_grant: {
        showToast: true,
        showModal: false,
        log: false,
        message: 'message.error.invalid_credential',
        logout: true,
      },
      profile_address_not_found: {
        reason: 'profile_address_not_found',
        showToast: false,
        showModal: false,
        log: false,
        message: 'message.404',
      },
      address_not_found: {
        reason: 'address_not_found',
        showToast: false,
        showModal: false,
        log: false,
        message: 'message.404',
      },
      invalid_mail_domain: {
        reason: 'invalid_mail_domain',
        showToast: true,
        showModal: false,
        log: false,
        message: 'message.error.invalid_mail_domain',
      },
    },
    field: {
      unique_media_file_name_directory: {
        reason: 'unique_media_file_name_directory',
        showToast: true,
        showModal: false,
        log: false,
        message: 'message.error.unique_media_file_name_directory',
      },
      file_mime_type_invalid: {
        reason: 'file_mime_type_invalid',
        showToast: true,
        showModal: false,
        log: false,
        message: 'message.error.file_mime_type_invalid',
      },
      profile_password_missmatch: {
        reason: 'profile_password_missmatch',
        showToast: true,
        showModal: false,
        log: false,
        message: 'message.error.profile_password_missmatch',
      },
    },
  },
};
