import { TranslatedEntity } from '../../../core/models/translated-entity';

export class PostLanguage extends TranslatedEntity<PostLanguage> {
  constructor(
    public title: string = '',
    public name: string = '',
    public teaser: string = '',
    public description: string = ''
  ) {
    super();
  }
}
