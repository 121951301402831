<div class="sidebar-right-content">
  <!---<dx-button [type]="'text'" [stylingMode]="'text'"
             (click)="authService.resetPasswordUser = !authService.resetPasswordUser"
             icon="fas fa-chevron-left" class="icon-small float-start arrow-left-menu"></dx-button>--->
  <ng-container *ngIf="!session; else sessionAvailable">
    <h3 class="title">{{ 'passwordReset.title' | translate }}</h3>
    <dx-form *appReloadOnLangChange (onEditorEnterKey)="requestSession($event)">
      <dxi-item dataField="email" [editorOptions]="{ mode: 'email', disabled: disableKeyBoardFix }">
        <dxo-label text="{{ 'labels.email' | translate }}"></dxo-label>
        <dxi-validation-rule type="required" message="{{ 'error.input_required' | translate }}"> </dxi-validation-rule>
        <dxi-validation-rule type="email" message="{{ 'error.email_format' | translate }}"> </dxi-validation-rule>
      </dxi-item>
    </dx-form>
    <app-button
      *ngIf="!session"
      class="dx-auto-width"
      [type]="'default'"
      [stylingMode]="'contained'"
      (buttonClick)="requestSession()"
      >{{ 'passwordReset.request' | translate }}</app-button
    >
  </ng-container>
  <ng-template #sessionAvailable>
    <h3 class="title">{{ 'passwordReset.title' | translate }}</h3>
    <dx-form *appReloadOnLangChange (onEditorEnterKey)="finalizeSession($event)">
      <dxi-item dataField="newPassword" [editorOptions]="{ mode: 'password', disabled: disableKeyBoardFix }">
        <dxo-label text="{{ 'labels.password' | translate }}"></dxo-label>
        <dxi-validation-rule type="required" message="{{ 'error.input_required' }}"> </dxi-validation-rule>
      </dxi-item>
      <dxi-item
        dataField="newPasswordConfirm"
        editorType="dxTextBox"
        [editorOptions]="{ mode: 'password', disabled: disableKeyBoardFix }"
      >
        <dxo-label text="{{ 'labels.passwordRepeat' | translate }}"></dxo-label>
        <dxi-validation-rule type="required" message="{{ 'error.input_required' }}"> </dxi-validation-rule>
        <dxi-validation-rule
          type="compare"
          [comparisonTarget]="passwordComparison"
          message="{{ 'error.password_missmatch' | translate }}"
        ></dxi-validation-rule>
      </dxi-item>
    </dx-form>
    <app-button
      *ngIf="session"
      [type]="'default'"
      [stylingMode]="'contained'"
      class="dx-auto-width"
      (buttonClick)="finalizeSession()"
      >{{ 'passwordReset.finalize' | translate }}</app-button
    >
  </ng-template>
</div>
