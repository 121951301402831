<app-base-accordion-panel [panel]="this">
  <div class="row">
    <div class="col-lg-8">
      <div *ngIf="this.entity">
        <dx-form
          *appReloadOnLangChange
          [readOnly]="panelMode === modeType.Read"
          [showColonAfterLabel]="true"
          [(formData)]="this.formData"
          (onEditorEnterKey)="save(safeType, $event)"
        >
          <dxi-item dataField="firstname">
            <dxo-label [text]="'labels.firstname' | translate"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'error.input_required' | translate }}">
            </dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="lastname">
            <dxo-label [text]="'labels.lastname' | translate"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'error.input_required' | translate }}">
            </dxi-validation-rule>
          </dxi-item>
        </dx-form>
      </div>
    </div>
  </div>
</app-base-accordion-panel>
