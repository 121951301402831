<!-- <image-cropper
  [imageChangedEvent]="imageChangedEvent"
  [maintainAspectRatio]="true"
  [aspectRatio]="15 / 7"
  format="jpeg"
  [canvasRotation]="canvasRotation"
  (imageCropped)="imageCropped($event)"
  (imageLoaded)="imageLoaded()"
  (cropperReady)="cropperReady()"
  (loadImageFailed)="loadImageFailed()"
></image-cropper> -->
<div class="image-rotation">
  <dx-button icon="undo" (onClick)="rotateLeft()"></dx-button>
  <dx-button icon="redo" (onClick)="rotateRight()"></dx-button>
</div>

<image-cropper
  *ngIf="!isNative()"
  [imageFile]="imageFile"
  [maintainAspectRatio]="true"
  [aspectRatio]="aspectRatio"
  format="jpeg"
  [canvasRotation]="canvasRotation"
  (imageCropped)="onImageCropped($event)"
  (imageLoaded)="imageLoaded.emit()"
  (cropperReady)="cropperReady.emit()"
></image-cropper>
<!-- <image-cropper
  *ngIf="isNative()"
  [imageFile]="imageFile"
  [maintainAspectRatio]="true"
  [aspectRatio]="cropAspectRatio"
  format="jpeg"
  [canvasRotation]="canvasRotation"
  (imageCropped)="imageCropped($event)"
  (imageLoaded)="imageLoaded()"
  (cropperReady)="cropperReady()"
  (loadImageFailed)="loadImageFailed()"
></image-cropper> -->
<!-- <dx-load-indicator *ngIf="isLoading" class="item-loader" height="20" width="20"></dx-load-indicator> -->
