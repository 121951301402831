import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT_CONFIGURATION } from '@softwarehaus/util-configuration';
import { Observable, from, throwError } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { NappEnvironmentConfiguration } from '~/app/config/environments-configuration';
import { AuthService } from '../../auth/authentication.service';

interface HeaderData {
  [name: string]: string | string[];
}

@Injectable({
  providedIn: 'root',
})
export class BaseModelServiceApiStrategy {
  constructor(
    private authService: AuthService,
    @Inject(ENVIRONMENT_CONFIGURATION) private config: NappEnvironmentConfiguration
  ) {}

  /**
   * Returns all basic headers for the application
   */
  public getHeaders(contentType?: string, auth = true, showMessage = true): Observable<HttpHeaders> {
    const headersData: HeaderData = {
      'Cache-Control': 'max-age=0', // do not set to 'no-cache' (disables caching completely in Chrome, works in Firefox [which checks if the resource has changed]), but to 'max-age=0', which triggers a revalidation on each load using the 'If-None-Match' Header with the 'Etag' from the backend
      /* Pragma: 'no-cache', // disable Header, since only for HTTP/1.0 and completely disables caching in Chrome even for HTTP/1.1 or HTTP/2 connections. Works in Firefox (Header is ignored for non HTTP/1.0 connections). */
    };

    if (contentType !== undefined) {
      headersData['Content-Type'] = contentType;
    }

    headersData['app_showMessage'] = showMessage.toString();

    return from([headersData]).pipe(
      flatMap(this.getAuthTokenIfNeeded(auth, showMessage)),
      map((hd) => new HttpHeaders(hd))
    );
  }

  public headersUrlEncoded(auth = true): Observable<HttpHeaders> {
    const headersData: HeaderData = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };

    return from([headersData]).pipe(
      flatMap(this.getAuthTokenIfNeeded(auth, true)),
      map((hd) => new HttpHeaders(hd))
    );
  }

  public getServiceUrl(resourcePath: string[]): string {
    const environmentBaseResourceUrl = this.config.api.baseServiceUrl;
    return environmentBaseResourceUrl + '/' + resourcePath.join('/');
  }

  private getAuthTokenIfNeeded(
    auth: boolean,
    showMessage: boolean
  ): (value: HeaderData, index: number) => Observable<HeaderData> {
    const errMsg = `No token received, but requested. Abort.`;
    const getAppToken = (hd: HeaderData): Observable<HeaderData> => {
      return this.authService.getAppToken(showMessage).pipe(
        flatMap((token) => {
          if (token) {
            hd['Authorization'] = `Bearer ${token}`;
            return from([hd]);
          } else {
            return throwError(errMsg);
          }
        })
      );
    };
    return (hd: HeaderData) => (auth ? getAppToken(hd) : from([hd]));
  }
}
