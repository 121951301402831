import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import dxTabs from 'devextreme/ui/tabs';
import { Subscription } from 'rxjs';
import { IHttpParameters } from '~/app/core/services/base/base.service';
import { MediaFile } from './models/media-file';
import { FILECATEGORYTYPES, FileManagerService } from './services/filemanager.service';

@Component({
  selector: 'app-file-browser',
  templateUrl: './file-browser.component.html',
})
export class FileBrowserComponent implements OnInit, OnDestroy {
  @Input() public multiple = false;
  @Input() public fileCategoryType?: FILECATEGORYTYPES;
  @Input() public activeTab?: EventEmitter<dxTabs>;
  @Input() public set acceptedFileTypes(types: string | null) {
    // Update the type to 'string | null'
    if (types !== this._acceptedFileTypes) {
      this._acceptedFileTypes = types;
      this.visibilityChanged();
    }
  }
  public get acceptedFileTypes(): string | null {
    return String(this._acceptedFileTypes);
  }

  @Output() public apply = new EventEmitter<MediaFile[]>();

  public files?: MediaFile[];
  public selectedFiles: MediaFile[] = [];
  public editMode = false;

  private subscription?: Subscription;
  private visible = false;
  private _acceptedFileTypes?: string | null;

  constructor(private fileManagerService: FileManagerService) {}

  public ngOnInit() {
    this.subscription = this.activeTab?.subscribe((result) => {
      this.visible = result.toString() === 'filebrowser';
      this.visibilityChanged();
    });
  }

  public refresh() {
    const httpParameters: Array<IHttpParameters> = [
      {
        key: 'limit',
        value: 10,
      },
    ];
    if (this.fileCategoryType !== FILECATEGORYTYPES._CLOUDINARY_) {
      httpParameters.push({
        key: 'category/id',
        value: this.fileCategoryType ?? '',
      });
    }
    if (this.acceptedFileTypes && this.acceptedFileTypes !== '*/*') {
      let value = this.acceptedFileTypes;
      if (value.indexOf('/*') !== -1) {
        value = `like:${value.replace('/*', '/%')}`;
      }
      httpParameters.push({
        key: 'extension/mimetype',
        value,
      });
    }

    this.clear(true);
    this.fileManagerService
      .fetchAll({
        httpParameters,
        serviceUrlConfig: {
          path: ['category'],
        },
        expansions: ['category'],
      })
      .subscribe((result) => {
        this.files = result;
      });
  }

  public setSelection(param: { mediaFile: MediaFile; active: boolean }) {
    this.selectedFiles = [];

    if (param.active) {
      this.selectedFiles.push(param.mediaFile);
    }

    // todo: multiple selection
  }

  public removeItemFromList(mediaFile: MediaFile) {
    this.selectedFiles = [];

    this.files = this.files?.filter((f) => f.id !== mediaFile.id);
  }

  public toggleEditMode() {
    this.selectedFiles = [];

    this.editMode = !this.editMode;
  }

  public onApply() {
    const selectedFile = this.selectedFiles[0];
    this.fileManagerService.getFileData(selectedFile.id as string).subscribe((imageData) => {
      selectedFile.imageData = imageData as Blob;
      this.apply.emit([selectedFile]);
      // use setTimeout(...) to prevent user seeing 'deselection' of <app-file-item />
      // according to https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxPopup/Configuration/animation/
      // the DxPopup needs 400ms to hide, so lets use 500ms
      setTimeout(() => this.clear(), 500);
    });
  }

  public clear(alsoClearLoadedFiles = false) {
    this.selectedFiles = [];
    if (alsoClearLoadedFiles) {
      this.files = [];
    }
  }

  public ngOnDestroy(): void {
    this.clear(true);
    this.subscription?.unsubscribe();
  }

  private visibilityChanged() {
    if (this.visible) {
      this.refresh();
    } else {
      this.clear(true);
    }
  }
}
