import { Component, Input, OnInit } from '@angular/core';
import { CardHeaderDirective } from './card-header.directive';

@Component({
  selector: 'napp-card-header',
  template: `
    <div class="card-header card-header-image">
      <ng-template *ngIf="headerTemplate" [ngTemplateOutlet]="headerTemplate.template"> </ng-template>
      <ng-container *ngIf="!headerTemplate">
        <img src="{{ headerImgPath }}" class="card-img-top" alt="Image could not be loaded" />
        <div *ngIf="headerStickerText !== ''" class="card-header-sticker">
          <strong
            ><p>{{ headerStickerText }}</p></strong
          >
        </div>
      </ng-container>
    </div>
  `,
  styleUrls: ['./card-header.component.scss'],
  host: {
    class: 'napp-card-header',
  },
})
export class CardHeaderComponent implements OnInit {
  @Input() headerImgPath: string = '';
  @Input() headerStickerText: string = '';
  @Input() headerTemplate: CardHeaderDirective | undefined;

  constructor() {}

  ngOnInit() {}
}
