import { Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { ENVIRONMENT_CONFIGURATION } from '@softwarehaus/util-configuration';
import { DxHtmlEditorComponent } from 'devextreme-angular';
import { Properties } from 'devextreme/ui/button';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { NappEnvironmentConfiguration } from '~/app/config/environments-configuration';
import { LayoutService } from '~/app/core/services/store/layout.service';
import { User } from '~/app/features/mgmt/user/models/user';
import { MediaFile } from '~/app/shared/components/filemanager/models/media-file';
import {
  FILECATEGORYTYPES,
  FileManagerService,
} from '~/app/shared/components/filemanager/services/filemanager.service';
import { ExternalFile } from './external-file/types';

@Component({
  selector: 'app-html-editor-dx',
  templateUrl: './html-editor-dx.component.html',
})
export class HtmlEditorDxComponent {
  @Input() public data?: string;
  @Input() public placeholder?: string;
  @Input() public inline?: boolean = false;
  @Input() public mentionUsers?: User[];
  @Output() public dataChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() public editorDidInit: EventEmitter<void> = new EventEmitter<void>();
  @Output() public enterKeyPressed: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild(DxHtmlEditorComponent, { static: false }) htmlEditor?: DxHtmlEditorComponent;

  public showUploader = false;

  public uploadButtonOptions: Properties = {
    icon: 'image',
    stylingMode: 'contained',
    onClick: () => (this.showUploader = true),
  };

  private imageBaseURL = `${this.config.api.baseServiceUrl}/media-file/publication`;

  constructor(
    private fileManagerService: FileManagerService,
    public layoutService: LayoutService,
    @Inject(ENVIRONMENT_CONFIGURATION) private config: NappEnvironmentConfiguration
  ) {}

  /**
   * Shows the media manager to select an image
   */
  private addImage() {
    this.fileManagerService.showMediaManager({
      fileCategory: FILECATEGORYTYPES.CONTENT,
      acceptedFileTypes: 'image/*',
      selected: (data) => {
        this.imageSelected(data);
        return of();
      },
      isMediaManagerShownRef: this.layoutService.isMediaManagerShown,
      fileIdToUpdate: null,
      fileDirectoryId: null,
    });
  }

  public onFileUploaded(file: ExternalFile) {
    const index = this.htmlEditor?.instance.getSelection(true).index;
    this.htmlEditor?.instance.insertEmbed(index, 'image', file.url);
  }

  /**
   * Called when an image is selected from the media manager
   * @param data The selected images
   */
  private imageSelected(data: MediaFile[]) {
    // if no data, return empty observable
    if (data.length <= 0) return;

    // get public url for media file
    this.getPublicURLForMediaFile(data[0]).subscribe(([imageUrl]) => {
      // insert image at cursor position
      const index = this.htmlEditor?.instance.getSelection(true).index;
      this.htmlEditor?.instance.insertEmbed(index, 'image', imageUrl);
    });
  }

  /**
   * Builds the public url for a media file
   * @param mediaFile The media file
   * @returns The public url
   */
  private buildPublicURL(mediaFile: MediaFile): string {
    return `${this.imageBaseURL}/${mediaFile.publicHash}`;
  }

  /**
   * Gets the public url for a media file. If the media file has no public hash, it will be fetched from the server
   * @param data The media file
   * @returns The public url and the media file
   */
  private getPublicURLForMediaFile(data: MediaFile): Observable<[string, MediaFile]> {
    // if media file has public hash, return public url
    if (data.publicHash) return of([this.buildPublicURL(data), data]);

    // if no public hash, fetch media file from server
    return this.fileManagerService.post({} as MediaFile, data.id, false, { path: ['publication'] }).pipe(
      map((mediaFile: MediaFile) => {
        return [this.buildPublicURL(mediaFile), mediaFile];
      })
    );
  }
}
