import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '~/app/shared/shared.module';
import { ProfileAddressComponent } from './panel/profile-address.component';
import { ProfileEmployeeInfoComponent } from './panel/profile-employee-info.component';
import { ProfileGeneralComponent } from './panel/profile-general.component';
import { ProfileMetadataComponent } from './panel/profile-metadata.component';
import { ProfilePasswordComponent } from './panel/profile-password.component';
import { ProfileSettingsComponent } from './panel/profile-settings.component';
import { ProfileImageComponent } from './profile-image.component';
import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileComponent } from './profile.component';

@NgModule({
  declarations: [
    ProfileComponent,
    ProfileAddressComponent,
    ProfileEmployeeInfoComponent,
    ProfileGeneralComponent,
    ProfilePasswordComponent,
    ProfileImageComponent,
    ProfileSettingsComponent,
    ProfileMetadataComponent,
  ],
  exports: [ProfileImageComponent],
  imports: [CommonModule, ProfileRoutingModule, SharedModule],
})
export class ProfileModule {}
