import { ContentChild, Directive, TemplateRef } from '@angular/core';
import { CardFooterTemplateDirective } from './card-footer-template.directive';

@Directive({ selector: 'nappx-card-footer' })
export class CardFooterDirective {
  @ContentChild(CardFooterTemplateDirective, {
    read: TemplateRef,
  })
  template!: TemplateRef<any>;
}
