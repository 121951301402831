import { Component, Input } from '@angular/core';
import { Profile } from '~/app/core/account/profile/models/profile';
import { ProfileAddress } from '~/app/core/account/profile/models/profile-address';
import { ProfileConfig } from '~/app/core/account/profile/models/profile-config';
import { ProfileEmployeeInfo } from '~/app/core/account/profile/models/profile-employee-info';
import { ProfileMetadata } from '~/app/core/account/profile/models/profile-metadata';
import { ProfilePassword } from '~/app/core/account/profile/models/profile-password';
import { ProfileAddressService } from '~/app/core/account/profile/services/profile-address.service';
import { ProfileConfigService } from '~/app/core/account/profile/services/profile-config.service';
import { ProfileEmployeeInfoService } from '~/app/core/account/profile/services/profile-employee-info.service';
import { ProfileMetadataService } from '~/app/core/account/profile/services/profile-metadata.service';
import { ProfilePasswordService } from '~/app/core/account/profile/services/profile-password.service';
import { ProfileService } from '~/app/core/account/profile/services/profile.service';
import { BaseAccordionPanel } from './base-accordion-panel';

@Component({
  selector: 'app-base-accordion-panel',
  templateUrl: './base-accordion-panel.component.html',
})
export class BaseAccordionPanelComponent {
  @Input() public title?: string;
  @Input() public panel!:
    | BaseAccordionPanel<Profile, ProfileService>
    | BaseAccordionPanel<ProfileAddress, ProfileAddressService>
    | BaseAccordionPanel<ProfileEmployeeInfo, ProfileEmployeeInfoService>
    | BaseAccordionPanel<ProfileMetadata, ProfileMetadataService>
    | BaseAccordionPanel<ProfilePassword, ProfilePasswordService>
    | BaseAccordionPanel<ProfileConfig, ProfileConfigService>;
  @Input() public isEditable?: boolean;
  @Input() public showCancelButton?: boolean;

  constructor() {}
}
