import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '~/app/core/services/base/base.service';
import { ProfileConfig } from '../models/profile-config';

@Injectable({
  providedIn: 'root',
})
export class ProfileConfigService extends BaseService<ProfileConfig> {
  constructor(public override http: HttpClient) {
    super(http, ProfileConfig);
  }

  protected getServiceUrl(): string {
    const url = this.generateServiceURL('profile/config');
    return this.apiStrategy.getServiceUrl(url);
  }
}
