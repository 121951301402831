import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { flatMap } from 'rxjs/operators';
import { BaseService, IServiceUrlConfig } from '~/app/core/services/base/base.service';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService<User> {
  constructor(public override http: HttpClient) {
    super(http, User);
  }

  protected getServiceUrl(entityId?: string, serviceUrlConfig?: IServiceUrlConfig): string {
    const url = this.generateServiceURL('user', entityId, serviceUrlConfig);
    return this.apiStrategy.getServiceUrl(url);
  }

  public updatePassword(userId: string, body: object) {
    const url = this.getServiceUrl(userId, {
      mgmt: true,
      path: ['update-password'],
    });

    return this.apiStrategy.getHeaders('application/json', true, false).pipe(
      flatMap((headers) => {
        return this.http.patch(url, body, {
          headers,
        });
      })
    );
  }

  public getUsersWithAccessToResource(resourceType: 'feed', resourceId?: string) {
    return this.fetchAll({
      serviceUrlConfig: {
        mgmt: false,
        path: ['with-access-to-resource', resourceType, ...(typeof resourceId === 'string' ? [resourceId] : [])],
      },
    });
  }

  public getUserInfo(userId: string) {
    return this.getEntityById({
      entityId: userId,
      expansions: ['contexts', 'roles/contexts'],
      serviceUrlConfig: {
        customPath: ['user', 'info'],
      },
    });
  }
}
