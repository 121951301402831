import { TranslatableEntity } from '~/app/core/models/translatable-entity';
import { AttributeLanguage } from './attribute-language';

export class AttributeValue extends TranslatableEntity<AttributeValue, AttributeLanguage> {
  public translatedValue = '';

  constructor(/* public */ languages?: AttributeLanguage[], public occurred?: Date, public comment?: string) {
    super(languages ?? []);
  }

  public updateTranslations(): void {
    const translation = this.getPropertyTranslation(this.translatedEntities, 'value');
    this.translatedValue = translation?.value.toString() ?? '';
  }
}
