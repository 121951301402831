import { AppTranslation } from '../../app-app-translation';
import { BaseEntity } from './base-entity.model';
import { Language } from './language.interface';
import { TranslatedEntity } from './translated-entity';

export abstract class TranslatableEntity<
  T extends BaseEntity<T>,
  LT extends TranslatedEntity<LT>
> extends BaseEntity<T> {
  public languages: LT[] = [];
  constructor(languages: LT[]) {
    super();
    this.translatedEntities = languages;
    this.updateTranslations();
    AppTranslation.languageChanged.subscribe(() => {
      this.updateTranslations();
    });
  }

  public abstract updateTranslations(): void;

  public get translatedEntities(): LT[] {
    return this.languages;
  }

  public set translatedEntities(translatedEntities: LT[]) {
    this.languages =
      translatedEntities?.filter(
        (l) => AppTranslation.getStoredLanguages().findIndex((sl) => sl.id === l.language?.id) !== -1
      ) ?? [];
    this.updateTranslations();
  }

  public override deserialize(object: T): this {
    const des = super.deserialize(object);
    if (des === this) {
      this.updateTranslations();
    }
    return des;
  }

  public getPropertyTranslation<
    TE extends TranslatedEntity<TE>,
    PropKeys extends ReadonlyArray<keyof Omit<TE, 'id' | 'language' | 'deserialize'>>
  >(
    languagesData: Array<TranslatedEntity<TE>>,
    ...propertyKeys: PropKeys
  ): Record<(typeof propertyKeys)[number], string | Language | ((object: TE) => TranslatedEntity<TE>)> | null {
    let translation: TranslatedEntity<TE>;
    const currentLanguage = this.getCurrentLanguage();

    if (!Array.isArray(languagesData) || !currentLanguage) {
      return null;
    }

    translation = languagesData.filter((item) => {
      return item.language?.id === currentLanguage.id;
    })[0];

    if (typeof translation === 'undefined') {
      const defaultLang = AppTranslation.getDefaultLanguage();
      if (defaultLang && defaultLang.id !== currentLanguage.id) {
        translation = languagesData.filter((item) => {
          return item.language?.id === defaultLang.id;
        })[0];
      }

      if (typeof translation === 'undefined') {
        translation = languagesData[0];
      }
    }

    if (translation && propertyKeys.length > 0) {
      return propertyKeys.reduce((carry, key) => {
        if (!(key as keyof typeof translation in translation)) return carry;
        const translationValue = translation[key as keyof typeof translation];
        if (!translationValue) return carry;
        carry[key] = translationValue;
        return carry;
      }, {} as unknown as Record<(typeof propertyKeys)[number], string | Language | ((object: TE) => TranslatedEntity<TE>)>);
    }
    return null;
  }

  protected getCurrentLanguage(): Language {
    return AppTranslation.getCurrentLanguage();
  }
}
