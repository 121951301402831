<div class="pt-2">
  <ng-container *ngIf="!(success || pending); else completed">
    <h3 class="title">{{ 'login.title' | translate }}</h3>
    <p class="caption" *ngIf="caption">{{ caption }}</p>
    <dx-form *appReloadOnLangChange (onEditorEnterKey)="login()">
      <dxi-item dataField="email" [editorOptions]="{ mode: 'email', disabled: disableKeyBoardFix }">
        <dxo-label text="{{ 'labels.email' | translate }}"></dxo-label>
        <dxi-validation-rule type="required" message="{{ 'error.input_required' | translate }}"> </dxi-validation-rule>
        <dxi-validation-rule type="email" message="{{ 'error.email_format' | translate }}"> </dxi-validation-rule>
      </dxi-item>
    </dx-form>
    <app-button [type]="'default'" [stylingMode]="'contained'" (buttonClick)="login()">{{
      'login.domain.button' | translate
    }}</app-button>
  </ng-container>
  <ng-template #completed>
    <div *ngIf="!pending; else pendingView" class="registration-completed">
      <h3>{{ 'login.success.title' | translate }}</h3>
      <p>{{ 'login.success.mailSend' | translate }}</p>
    </div>
  </ng-template>
  <ng-template #pendingView>
    <div class="registration-completed">
      <h3>{{ 'login.pending.title' | translate }}</h3>
      <p>{{ 'login.pending.text' | translate }}</p>
    </div>
  </ng-template>
</div>
