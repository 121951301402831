import { TranslatableEntity } from '~/app/core/models/translatable-entity';
import { CMSSiteContentsLanguage } from './cms-site-contents-language';
import { Language } from '~/app/core/models/language.interface';
import { TranslatedEntity } from '~/app/core/models/translated-entity';

export enum CMSContentTypes {
  CMS = 'a3474778-db61-4840-8e4d-91d3f4d696c0',
}

export class CMSSiteContents extends TranslatableEntity<CMSSiteContents, CMSSiteContentsLanguage> {
  public contentTranslated?: string;

  constructor(
    /* public */ languages: CMSSiteContentsLanguage[] = [],
    public type: {
      id: string;
      name: string;
    } = {
      id: CMSContentTypes.CMS,
      name: '',
    }
  ) {
    super(languages);
  }

  public updateTranslations(): void {
    const translation: Record<"data", string | Language | ((object: CMSSiteContentsLanguage) => TranslatedEntity<CMSSiteContentsLanguage>)
      | Record<"content", string | Language | ((object: CMSSiteContentsLanguage) => TranslatedEntity<CMSSiteContentsLanguage>)>> | null =
      this.getPropertyTranslation(this.translatedEntities, 'data');

    if (!translation || !translation.data) {
      this.contentTranslated = '';
      return;
    }

    if (typeof translation.data === 'string') {
      this.contentTranslated = translation.data;
      return;
    }

    if ('content' in translation.data) {
      this.contentTranslated = translation.data.content as string;
      return;
    }

    this.contentTranslated = '';
  }
}
