import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { clone } from 'lodash';
import { IHttpParameters, IServiceUrlConfig } from '~/app/core/services/base/base.service';
import { AttributeValue } from '~/app/features/mgmt/attribute/models/attribute-value';
import { AttributeService } from '~/app/features/mgmt/attribute/services/attribute.service';
import { BaseAccordionPanel, SafeType } from '~/app/shared/components/accordion/base-accordion-panel';
import { ProfileEmployeeInfo } from '../models/profile-employee-info';
import { ProfileEmployeeInfoService } from '../services/profile-employee-info.service';

@Component({
  selector: 'app-profile-employee-info',
  templateUrl: './profile-employee-info.component.html',
})
export class ProfileEmployeeInfoComponent
  extends BaseAccordionPanel<ProfileEmployeeInfo, ProfileEmployeeInfoService>
  implements OnInit
{
  public formData: ProfileEmployeeInfo = new ProfileEmployeeInfo();
  public locations: AttributeValue[] = [];
  public departments: AttributeValue[] = [];
  public functionalities: AttributeValue[] = [];

  constructor(
    protected override translateService: TranslateService,
    protected profileEmployeeInfoService: ProfileEmployeeInfoService,
    private attributeService: AttributeService
  ) {
    super(profileEmployeeInfoService, translateService);
  }

  public override ngOnInit() {
    const serviceUrlConfig: IServiceUrlConfig = { showMessage: false };
    this.profileEmployeeInfoService.fetchAll({ serviceUrlConfig }).subscribe({
      next: (result: ProfileEmployeeInfo[]) => {
        this.entity = result[0];
        if (this.entity.id) {
          this.safeType = SafeType.Patch;
        }
        this.createForm();
      },
      error: (error: HttpErrorResponse) => {
        if (error.status === 404) {
          // it's ok if user has no employee info
          this.entity = new ProfileEmployeeInfo();
          this.createForm();
        } else {
          throw error;
        }
      },
    });

    const httpParameters: IHttpParameters[] = [
      {
        key: 'key',
        value: 'in:location,department,functionality',
      },
    ];

    this.attributeService.fetchAll({ httpParameters }).subscribe((attributes) => {
      for (const attribute of attributes) {
        if (attribute.key === 'location') {
          this.locations = attribute.values ?? [];
        }
        if (attribute.key === 'department') {
          this.departments = attribute.values ?? [];
        }
        if (attribute.key === 'functionality') {
          this.functionalities = attribute.values ?? [];
        }
      }
    });
  }

  public createForm() {
    if (!this.entity) return;
    this.formData = clone(this.entity);
  }
}
