import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { StorageConfig } from '../../config/storage.config';
import { AuthService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(private authService: AuthService) {}

  public isResourceOwner(resourceId: string): Observable<boolean> {
    return this.authService.isLoggedIn().pipe(
      take(1),
      map((value) => {
        return value ? localStorage.getItem(StorageConfig.USER_ID) === resourceId : value;
      })
    );
  }

  public checkScopes(neededScopes: string[]): boolean {
    const userScopes: string[] = (localStorage.getItem(StorageConfig.SCOPES_KEY) || '').split(' ');
    for (const scope of neededScopes) {
      if (userScopes.indexOf(scope) > -1) {
        return true;
      }
    }
    return false;
  }
}
