import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FieldDataChangedEvent } from 'devextreme/ui/form';
import { clone } from 'lodash';
import { Language } from '~/app/core/models/language.interface';
import { MessageService, TOASTTYPE } from '~/app/core/services/message.service';
import { AppTranslationService } from '~/app/core/services/translation/app-translation.service';
import { BaseAccordionPanel, SafeType } from '~/app/shared/components/accordion/base-accordion-panel';
import { ProfileConfig } from '../models/profile-config';
import { ProfileConfigService } from '../services/profile-config.service';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
})
export class ProfileSettingsComponent
  extends BaseAccordionPanel<ProfileConfig, ProfileConfigService>
  implements OnInit
{
  public formData: ProfileConfig = new ProfileConfig();
  public languages: Language[] = [];

  private selectedLang?: Language;

  constructor(
    protected override translateService: TranslateService,
    protected userConfigService: ProfileConfigService,
    private appTranslationService: AppTranslationService,
    private messageService: MessageService
  ) {
    super(userConfigService, translateService);
  }

  public override ngOnInit() {
    if (this.entity?.id) {
      this.safeType = SafeType.Patch;
    }
    this.createForm();

    this.appTranslationService.getStoredLanguages().forEach((items) => {
      items.iso = items.iso.toUpperCase();
      this.languages.push(items);
    });

    this.saved.subscribe(() => {
      if (this.selectedLang) {
        this.appTranslationService.setAppTranslation(this.selectedLang.iso.toLowerCase()).subscribe(() => {
          this.messageService.showToast(TOASTTYPE.SUCCESS, this.translateService.instant('general.langs.changed'));
        });
        this.selectedLang = undefined;
      }
    });
  }

  public createForm() {
    if (!this.entity) return;
    this.formData = clone(this.entity);
  }

  public onFieldChange(event: FieldDataChangedEvent): void {
    if (event.dataField === 'language.id') {
      const selectedLanguage = this.languages.find((x) => x.id === event.value);
      if (selectedLanguage) {
        this.selectedLang = selectedLanguage;
      }
    }
  }
}
