import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT_CONFIGURATION } from '@softwarehaus/util-configuration';
import { NappEnvironmentConfiguration, NappTheme } from '~/app/config/environments-configuration';

const STYLE_ELEMENT_ID = 'napp-style';
const ICON_ELEMENT_ID = 'napp-icon';

@Injectable()
export class ThemeService {
  private head?: HTMLHeadElement;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(ENVIRONMENT_CONFIGURATION) private config: NappEnvironmentConfiguration
  ) {}

  loadTheme(theme: NappTheme) {
    this.head = this.document.getElementsByTagName('head')[0];

    this.loadStyles(theme);
    this.loadIcon(theme);
    this.loadTitle();
  }

  private loadStyles(theme: NappTheme) {
    const styleSrc = `${theme}.css`;

    const styleElement = this.document.getElementById(STYLE_ELEMENT_ID) as HTMLLinkElement;

    if (styleElement) {
      styleElement.href = styleSrc;
    } else {
      const style = this.document.createElement('link');
      style.id = STYLE_ELEMENT_ID;
      style.rel = 'stylesheet';
      style.href = styleSrc;

      this.head?.appendChild(style);
    }
  }

  private loadIcon(theme: NappTheme) {
    const iconSrc = `assets/themes/${theme}/images/favicon.ico`;

    const iconElement = this.document.getElementById(ICON_ELEMENT_ID) as HTMLLinkElement;

    if (iconElement) {
      iconElement.href = iconSrc;
    } else {
      const icon = this.document.createElement('link');
      icon.id = ICON_ELEMENT_ID;
      icon.type = 'image/x-icon';
      icon.rel = 'icon';
      icon.href = iconSrc;

      this.head?.appendChild(icon);
    }
  }

  private loadTitle() {
    const title = this.config.title;
    const titleElement = this.document.getElementsByTagName('title')[0];
    titleElement.innerText = title;
  }
}
