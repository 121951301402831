import { NappEnvironmentConfiguration } from '~/app/config/environments-configuration';

export const GOTTHILFT_STAGE_NAPLEON_ENVIRONMENT_CONFIGURATION: NappEnvironmentConfiguration = {
  environmentName: 'gotthilft',
  appBaseUrl: 'gotthilft-app-test.08eins-preview.ch',
  title: 'SGh Mitanand',
  theme: 'gotthilft',
  defaultLangISO: 'de',
  api: {
    clientId: 'AulX7u8l4M',
    clientSecret: 'd9dc852e6cf8726c77e0e91d3015d5b760aab6a2bf39a519e8ea11145feb5411',
    baseServiceUrl: 'https://gotthilft-app-test.08eins-preview.ch/api',
  },
  captchaSiteKey: '6LdLQ30iAAAAAPECMKIccuOMV1XPVms8U7DcbMf3',
  appUrl: 'https://gotthilft-app-test.08eins-preview.ch/',
  footer: {
    customer: 'Stiftung Gott hilft',
    phoneNr: '+41 81 307 38 00',
    website: 'www.stiftung-gotthilft.ch',
    email: 'info@stiftung-gotthilft.ch',
    instagram: '',
    facebook: '',
    linkedIn: 'https://www.linkedin.com/company/stiftung-gott-hilft/',
    type: 'default',
  },
  availableModules: {
    feed: true,
    document: true,
    shortener: false,
    magazine: false,
    event: false,
    agenda: false,
    employees: false,
  },
  availableFeatures: {
    feed: {
      location: false,
      showPostType: false,
      approve: false,
      allowVideoUpload: true,
      mention: true,
      report: true,
    },
    header: {
      logoRedirectMyFeed: false,
    },
    push: {
      autoEnable: true,
      unregisteredDevices: false,
    },
    employee: {
      showFirstname: false,
      showLastname: false,
      showLocation: false,
      showDepartment: false,
      showFunctionality: false,
    },
  },
  cloudinary: {
    cloudName: 'cloud-08eins',
    uploadPreset: 'b3pbvfj4',
    prefix: 'stiftung-gotthilft-test',
  },
  appleAppID: '6444047176',
};
