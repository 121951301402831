import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { flatMap, tap } from 'rxjs/operators';
import { BaseService, IServiceUrlConfig } from '../../../core/services/base/base.service';
import { CMSSite } from '../models/cms-site';

@Injectable({
  providedIn: 'root',
})
export class CMSService extends BaseService<CMSSite> {
  constructor(public override http: HttpClient) {
    super(http, CMSSite);
  }

  public arrange(order: Array<string>) {
    return this.apiStrategy.getHeaders().pipe(
      flatMap((headers) => {
        return this.http
          .post<void>(
            this.getServiceUrl('', {
              mgmt: true,
              path: ['arrange'],
            }),
            { order },
            {
              headers,
            }
          )
          .pipe(
            tap(() => {
              this.triggerReload();
            })
          );
      })
    );
  }

  protected getServiceUrl(entityId: string, serviceUrlConfig?: IServiceUrlConfig): string {
    const url = this.generateServiceURL('cms-site', entityId, serviceUrlConfig);
    return this.apiStrategy.getServiceUrl(url);
  }
}
