import { NappEnvironmentConfiguration } from '~/app/config/environments-configuration';

export const KSGR_PROD_NAPLEON_ENVIRONMENT_CONFIGURATION: NappEnvironmentConfiguration = {
  environmentName: 'ksgr',
  appBaseUrl: 'ksgr-inside.napp.ch',
  title: 'KSGR Inside',
  theme: 'ksgr',
  defaultLangISO: 'de',
  api: {
    clientId: 'AulX7u8l4M',
    clientSecret: 'd9dc852e6cf8726c77e0e91d3015d5b760aab6a2bf39a519e8ea11145feb5411',
    baseServiceUrl: 'https://ksgr-inside.napp.ch/api',
  },
  captchaSiteKey: '6LcBwwAaAAAAACH1ps_5VTejkI6peVVDVu_8efUz',
  appUrl: 'https://ksgr-inside.napp.ch',
  footer: {
    customer: 'Kantonsspital Graubünden',
    phoneNr: '+41 81 256 70 14',
    website: 'www.ksgr.ch',
    email: 'webmaster@ksgr.ch',
    instagram: 'https://www.instagram.com/kantonsspitalgr',
    facebook: 'https://www.facebook.com/KantonsspitalGR',
    linkedIn: 'https://www.linkedin.com/company/kantonsspital-graubünden/',
    type: 'default',
  },
  availableModules: {
    feed: true,
    document: false,
    shortener: false,
    magazine: false,
    event: false,
    agenda: false,
    employees: false,
  },
  availableFeatures: {
    feed: {
      location: false,
      showPostType: false,
      approve: false,
      allowVideoUpload: true,
      mention: true,
      report: false,
    },
    header: {
      logoRedirectMyFeed: false,
    },
    push: {
      autoEnable: true,
      unregisteredDevices: false,
    },
    employee: {
      showFirstname: false,
      showLastname: false,
      showLocation: false,
      showDepartment: false,
      showFunctionality: false,
    },
  },
  cloudinary: {
    cloudName: 'ksgr-08eins',
    uploadPreset: 'q5zzliws',
  },
  appleAppID: '1624448774',
};
