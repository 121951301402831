<app-scroll-view-with-bottom-bar>
  <div class="row">
    <div class="col-12">
      <ul class="file-browser">
        <li *ngFor="let file of files">
          <app-external-file-chooser-item
            [file]="file"
            [selectedFiles]="selectedFiles"
            [editMode]="editMode"
            (itemClick)="setSelection($event)"
            (itemRemoved)="removeItemFromList($event)"
          >
          </app-external-file-chooser-item>
        </li>
      </ul>
    </div>
  </div>
  <div class="bottom-bar">
    <dx-button
      class="small"
      [type]="'default'"
      [stylingMode]="'text'"
      (onClick)="toggleEditMode()"
      [text]="'general.buttons.' + (editMode ? 'done' : 'edit') | translate"
    ></dx-button>
    <dx-button
      [type]="selectedFiles.length === 0 || editMode ? 'normal' : 'default'"
      [disabled]="selectedFiles.length === 0 || editMode"
      (onClick)="apply()"
      >{{ 'filemanager.apply' | translate }}</dx-button
    >
  </div>
</app-scroll-view-with-bottom-bar>
