import { TranslatableEntity } from '~/app/core/models/translatable-entity';
import { ExternalFile } from '../../../shared/components/external-file/types';
import { convertMentionHTML } from '../../../shared/helpers/dom';
import { StripTagsPipe } from '../../../shared/pipes/strip-tags.pipe';
import { TruncatePipe } from '../../../shared/pipes/truncate.pipe';
import { Context } from '../../mgmt/context/models/context';
import { User } from '../../mgmt/user/models/user';
import { Tag } from '../feed-tag/models/tag';
import { Author } from './author';
import { FeedPostType } from './feed-post-type';
import { PostLanguage } from './post-language';

export class FeedPost extends TranslatableEntity<FeedPost, PostLanguage> {
  public titleTranslated?: string;
  public descriptionTranslated?: string;
  public teaser?: string;

  constructor(
    public to?: string,
    public from?: string,
    public author: Author = new Author('', '', '', '', { id: '' }),
    /* public */ languages: PostLanguage[] = [],
    public tags: Tag[] = [],
    public type: FeedPostType = new FeedPostType('', ''),
    public bookmarked: boolean = false,
    public likeCount: number = 0,
    public liked: boolean = false,
    public enableLikes: boolean = true,
    public enableComments: boolean = true,
    public video: string = '',
    public views: number = 0,
    public approved: boolean = true,
    public teaserPicture?: {
      id?: string;
    },
    public picture?: ExternalFile,
    public coordinates?: {
      longitude?: number;
      latitude?: number;
    },
    public contexts: Context[] = [],
    public videoTagCloudinary?: string,
    public mentionedUsers?: Array<User>,
    public isTeaserShortened: boolean = false
  ) {
    super(languages);
  }

  public override deserialize(object: typeof this): typeof this {
    object.tags = object.tags ? object.tags.map((item) => new Tag().deserialize(item)) : [];
    object.languages = object.languages ? object.languages.map((item) => new PostLanguage().deserialize(item)) : [];
    object.contexts = object.contexts ? object.contexts.map((item) => new Context().deserialize(item)) : [];
    object.mentionedUsers = object.mentionedUsers
      ? object.mentionedUsers.map((item) => new User().deserialize(item))
      : [];
    return super.deserialize(object);
  }

  public updateTranslations(): void {
    const translation = this.getPropertyTranslation(this.translatedEntities, 'title', 'teaser', 'description');
    this.titleTranslated = translation?.title?.toString() ?? '';
    this.descriptionTranslated = convertMentionHTML(
      this.mentionedUsers ?? [],
      translation?.description?.toString() ?? ''
    );
    this.teaser = translation?.teaser
      ? translation.teaser.toString()
      : translation?.description
      ? new TruncatePipe().transform(
          new StripTagsPipe().transform(translation.description.toString(), ['img', 'video']),
          300
        )
      : '';
  }

  public getTeaser(maxLength: number) {
    if (this.teaser?.length && this.teaser.length <= maxLength) return this.teaser;
    else {
      this.isTeaserShortened = true;
      const lastspace = this.teaser?.lastIndexOf(' ', maxLength);
      if (lastspace != -1) {
        this.teaser = this.teaser?.slice(0, lastspace);
      }
      this.teaser += '...';
      return this.teaser;
    }
  }
}
