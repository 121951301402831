type CSSVariables<T extends ReadonlyArray<string>> = {
  [x in T[number]]: string;
};

type NumericCSSVariables<T extends ReadonlyArray<string>> = {
  [x in T[number]]: number;
};

export function getCssVariables<T extends ReadonlyArray<string>>(
  w: Window,
  document: Document,
  keys: T
): CSSVariables<T> {
  const returnObject: CSSVariables<T> = {} as unknown as CSSVariables<T>;
  // tslint:disable-next-line: no-unbound-method
  if (w && typeof w.getComputedStyle === 'function') {
    const computedStyle = w.getComputedStyle(document.documentElement);
    for (const key of keys) {
      returnObject[key as keyof typeof returnObject] = computedStyle.getPropertyValue(`--${key}`);
    }
  } else {
    // fill with empty values
    for (const key of keys) {
      returnObject[key as keyof typeof returnObject] = '';
    }
  }
  return returnObject;
}

export function getNumericCssVariables<T extends ReadonlyArray<string>>(
  w: Window,
  document: Document,
  keys: T
): NumericCSSVariables<T> {
  const returnObject: NumericCSSVariables<T> = {} as unknown as NumericCSSVariables<T>;
  const strValuesObject = getCssVariables(w, document, keys);
  for (const key of keys) {
    returnObject[key as keyof typeof returnObject] = parseInt(strValuesObject[key as keyof typeof strValuesObject], 10);
    // safe is NaN? https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NaN#testing_against_nan
    if (returnObject[key as keyof typeof returnObject] !== returnObject[key as keyof typeof returnObject]) {
      returnObject[key as keyof typeof returnObject] = 0;
    }
  }
  return returnObject;
}
