import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { Inject, Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { ENVIRONMENT_CONFIGURATION } from '@softwarehaus/util-configuration';
import { BehaviorSubject, Observable, Subject, fromEventPattern, interval } from 'rxjs';
import { debounce, takeUntil } from 'rxjs/operators';
import { NappEnvironmentConfiguration } from '~/app/config/environments-configuration';
import { StorageConfig } from '../../../config/storage.config';
import { IFirebaseConfig } from '../../auth/interfaces/IFirebaseConfig';
import { ReportContent } from '../../models/report-content';

declare global {
  interface Window {
    GoogleAnalyticsObject: string;
    ga: {
      (...args: unknown[]): void;
      l: number;
      q: unknown[];
    };
  }
}

export interface DxLayoutEvent {
  type: 'resize' | 'orientationChange' | 'dx';
}

@Injectable({
  providedIn: 'root',
})
export class LayoutService implements OnDestroy {
  public triggerLeftMenuReload = new BehaviorSubject<boolean>(false);
  public reloadLeftMenu = this.triggerLeftMenuReload.asObservable();
  public showLeftSidebarSubject = new BehaviorSubject<boolean>(false);
  public showUserSidebarSubject = new BehaviorSubject<boolean>(false);
  public isReportDialogShown = new BehaviorSubject<boolean>(false);
  public reportDialogContentReport = new BehaviorSubject<ReportContent | null>(null);
  public isFeedbackShown = new BehaviorSubject<boolean>(false);
  public isDamageReportShown = new BehaviorSubject<boolean>(false);
  public isMediaManagerShown = new BehaviorSubject<boolean>(false);
  public isActionSheetShown = new BehaviorSubject<boolean>(false);
  public actionSheetUsePopover: Observable<boolean>;
  public actionSheetPopoverWidth: Observable<string | null>;
  public appInLoadingStateSubject = new BehaviorSubject<boolean>(false);
  public subscriptionsUpdated = new BehaviorSubject<boolean>(false);
  public onDxLayoutChanged: Observable<DxLayoutEvent>;

  private onLayoutChanged = new Subject<DxLayoutEvent>();
  private debounceTimes: {
    [x in DxLayoutEvent['type']]: Observable<number>;
  } = {
    dx: interval(50),
    orientationChange: interval(100),
    resize: interval(100),
  };
  private initialInnerWidth?: number;
  private initialInnerHeight?: number;
  private initialScreenHeight?: number;
  private initialScreenOrientation?: OrientationType;
  private renderer: Renderer2;
  private loadingLocked = false;
  private analyticsEnabled: {
    [x in 'firebase' | 'googleAnalytics']: false | string;
  } = {
    firebase: false,
    googleAnalytics: false,
  };
  private _actionSheetUsePopover = new BehaviorSubject<boolean>(false);
  private _actionSheetPopoverWidth = new BehaviorSubject<string | null>(null);
  private _destroy$ = new Subject();

  constructor(
    rendererFactory: RendererFactory2,
    private metaService: Meta,
    private breakpointObserver: BreakpointObserver,
    @Inject(ENVIRONMENT_CONFIGURATION) private config: NappEnvironmentConfiguration
  ) {
    setTimeout(() => {
      this.resetWindowMeasurements();
    });
    this.renderer = rendererFactory.createRenderer(null, null);
    this.actionSheetUsePopover = this._actionSheetUsePopover.asObservable();
    this.actionSheetPopoverWidth = this._actionSheetPopoverWidth.asObservable();
    this.breakpointObserver
      .observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge, Breakpoints.Tablet, Breakpoints.Web])
      .subscribe((state: BreakpointState) => {
        this._actionSheetUsePopover.next(state.matches);
      });
    this.breakpointObserver.observe([Breakpoints.Large, Breakpoints.XLarge]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this._actionSheetPopoverWidth.next('25vw');
      } else {
        this._actionSheetPopoverWidth.next('50vw');
      }
    });

    window.screen?.orientation?.addEventListener('change', () => {
      if (this.initialScreenOrientation !== window.screen?.orientation?.type) {
        this.resetWindowMeasurements();
        this.onLayoutChanged.next({
          type: 'orientationChange',
        });
      }
    });
    this.onDxLayoutChanged = this.onLayoutChanged.pipe(debounce((event) => this.debounceTimes[event.type]));
    const events: ReadonlyArray<keyof Omit<{ [x in DxLayoutEvent['type']]: void }, 'dx'>> = [
      'orientationChange',
      'resize',
    ] as const;
    events.map((eventName) => {
      this.createWindowEventObservable(eventName).subscribe(() => {
        if (eventName === 'resize') {
          if (
            this.initialInnerWidth === window.innerWidth &&
            this.initialInnerHeight !== window.innerHeight &&
            this.initialScreenHeight === window.screen?.height
          ) {
            this.initialInnerHeight = window.innerHeight;
            return;
          }
        }
        this.resetWindowMeasurements();
        this.onLayoutChanged.next({
          type: eventName,
        });
      });
    });
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public deliverDxOnLayoutChangedEvent() {
    this.onLayoutChanged.next({
      type: 'dx',
    });
  }

  public getLoadingLocked(): boolean {
    return this.loadingLocked;
  }

  public setLoadingLocked(locked: boolean) {
    this.loadingLocked = locked;
  }

  public scrollHandling() {
    if (this.showUserSidebarSubject.getValue() || this.showLeftSidebarSubject.getValue()) {
      this.renderer.addClass(document.body, 'sidebar-open');
    } else {
      this.renderer.removeClass(document.body, 'sidebar-open');
    }
  }

  public toggleLeftSidebar(state?: boolean) {
    if (typeof state !== 'undefined') {
      this.showLeftSidebarSubject.next(state);
    } else {
      this.showLeftSidebarSubject.next(!this.showLeftSidebarSubject.getValue());
    }

    if (this.showLeftSidebarSubject.getValue() && this.showUserSidebarSubject.getValue()) {
      this.showUserSidebarSubject.next(false);
    }

    this.scrollHandling();
  }

  public toggleUserSidebar(state?: boolean) {
    if (typeof state !== 'undefined') {
      this.showUserSidebarSubject.next(state);
    } else {
      this.showUserSidebarSubject.next(!this.showUserSidebarSubject.getValue());
    }

    if (this.showUserSidebarSubject.getValue() && this.showLeftSidebarSubject.getValue()) {
      this.showLeftSidebarSubject.next(false);
    }

    this.scrollHandling();
  }

  public enableAnalytics(uaToken: string | null = null, firebaseConfig: IFirebaseConfig | null = null) {
    // prefer Firebase over Google Analytics, so try load that first if no arguments given
    // Store or load Firebase config
    if (firebaseConfig !== null && typeof firebaseConfig === 'object') {
      localStorage.setItem(StorageConfig.FIREBASE_ANALYTICS, JSON.stringify(firebaseConfig));
    } else {
      try {
        const config = localStorage.getItem(StorageConfig.FIREBASE_ANALYTICS);
        firebaseConfig = config ? JSON.parse(config) : null;
      } catch (e) {
        firebaseConfig = null;
      }
    }
    if (firebaseConfig !== null && typeof firebaseConfig === 'object') {
      // TODO: Add Firebase support (configuration now has also a firebase data object. needs changes to /frontend/init call)
    } else {
      // Store or load Google analytics token
      if (uaToken !== null && uaToken !== 'null') {
        localStorage.setItem(StorageConfig.GOOGLE_ANALYTICS, uaToken);
      } else {
        uaToken = localStorage.getItem(StorageConfig.GOOGLE_ANALYTICS);
      }

      if (uaToken !== null && uaToken !== '' && uaToken !== 'null') {
        // do not enable GoogleAnalytics multiple times for same uaToken
        if (this.analyticsEnabled.googleAnalytics !== uaToken) {
          window['GoogleAnalyticsObject'] = 'ga';
          window['ga'] =
            window['ga'] ||
            function () {
              (window['ga'].q = window['ga'].q || []).push();
            };
          window['ga'].l = new Date().getTime();

          const analyticsScript = document.createElement('script');
          const firstScript = document.getElementsByTagName('script')[0];

          analyticsScript.async = true;
          analyticsScript.src = 'https://www.google-analytics.com/analytics.js';

          firstScript.parentNode?.insertBefore(analyticsScript, firstScript);

          window['ga']('create', uaToken, 'auto');
          window['ga']('send', 'pageview');
          this.analyticsEnabled.googleAnalytics = uaToken;
        }
      }
    }
  }

  public setMetaTags(title?: string, imageId?: string, description?: string) {
    const metaTags: MetaDefinition[] = [];

    if (title) {
      metaTags.push({ property: 'og:title', content: title });
    }
    if (imageId) {
      metaTags.push({
        property: 'og:image',
        content: this.config.api.baseServiceUrl + '/media-file/' + imageId + '/data',
      });
    }
    if (description) {
      metaTags.push({
        property: 'og:description',
        content: description.substr(0, 200),
      });
    }

    this.metaService.addTags(metaTags);
  }

  public removeMetaTags() {
    this.metaService.removeTag('property="og:title"');
    this.metaService.removeTag('property="og:image"');
    this.metaService.removeTag('property="og:description"');
  }

  private createWindowEventObservable(eventName: 'resize' | 'orientationChange') {
    let removeListener: () => void;
    const createListener = (handler: (e: Event) => boolean | void) => {
      removeListener = this.renderer.listen('window', eventName, handler);
    };

    return fromEventPattern<Event>(createListener, () => removeListener()).pipe(takeUntil(this._destroy$));
  }

  private resetWindowMeasurements() {
    this.initialInnerWidth = window.innerWidth;
    this.initialInnerHeight = window.innerHeight;
    this.initialScreenHeight = window.screen?.height;
    this.initialScreenOrientation = window.screen?.orientation?.type;
  }
}
