import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ENVIRONMENT_CONFIGURATION } from '@softwarehaus/util-configuration';
import { from, Observable, of, OperatorFunction, PartialObserver, ReplaySubject, throwError } from 'rxjs';
import { catchError, concatMap, finalize, map, mergeMap, shareReplay, take, tap } from 'rxjs/operators';
import { AppApiStrategy } from '~/app/app-api-strategy';
import { NappEnvironmentConfiguration } from '~/app/config/environments-configuration';
import { LOGINTYPE, StorageConfig } from '~/app/config/storage.config';
import { IConfigResult } from '~/app/core/auth/interfaces/IConfigResult';
import { IInitResult } from '~/app/core/auth/interfaces/IInitResult';
import { IIntrospectResult } from '~/app/core/auth/interfaces/IIntrospectResult';
import { ITokenResult } from '~/app/core/auth/interfaces/ITokenResult';
import { Language } from '~/app/core/models/language.interface';
import { AngularBridgeService } from '~/app/core/services/angular-bridge.service';
import {
  BridgeCallbackMethod,
  BridgeError,
  BridgeNativeErrors,
  DefaultSubscriber,
  Platform,
} from '~/app/core/services/angular-bridge.types';
import { MessageService, TOASTTYPE } from '~/app/core/services/message.service';
import { LayoutService } from '~/app/core/services/store/layout.service';
import { AppTranslationService } from '~/app/core/services/translation/app-translation.service';

enum PushStatus {
  ENABLED = 'on',
  DISABLED = 'off',
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public registrateUser = false;
  public resetPasswordUser = false;
  public confirmed = false;
  public loginPending = new EventEmitter<boolean>();
  public refreshProfileImage = new EventEmitter<void>();

  private bridgeTokenIntrospect: string | undefined;
  private appAuthShared$?: Observable<ITokenResult>;
  private isUserLoggedInSubject = new ReplaySubject<boolean>(1);
  private isAppAuthSubject = new ReplaySubject<boolean>(1);
  private errorCodes = [BridgeNativeErrors.NoLoginDataFound, 400, 401, 403];
  private lastBridgeGetTokenError: BridgeError | null = null;

  constructor(
    public http: HttpClient,
    private appTranslationService: AppTranslationService,
    private router: Router,
    private messageService: MessageService,
    private translate: TranslateService,
    private layoutService: LayoutService,
    private bridge: AngularBridgeService,
    @Inject(ENVIRONMENT_CONFIGURATION) private config: NappEnvironmentConfiguration
  ) {
    this.bridge.setDefaultReceiver(BridgeCallbackMethod.updateToken, this.setTokenFromBridge);
    this.bridge.setDefaultReceiver(BridgeCallbackMethod.loggedIn, this.setTokenFromBridge);
    this.bridge.setDefaultReceiver(BridgeCallbackMethod.loginPending, this.setLoginPendingFromBridge);

    if (this.bridge.isNative()) {
      // check if there is a token stored in the native app
      this.getToken(LOGINTYPE.USER, false)
        .pipe(take(1))
        .subscribe((token) => {
          if (token) {
            // we got a token, so let's do the introspection on it and prepare applications loggedin state
            this.setTokenFromBridge(token);
          }
        });
      if (config.availableFeatures.push.unregisteredDevices && !this.pushStatus) {
        this.bridge.enablePush().subscribe((value) => {
          this.pushStatus = value ?? false;
        });
      }
    }
  }

  public getAuthServiceUrl(endpoint: string[]): string {
    return AppApiStrategy.getServiceUrl(endpoint);
  }

  public authenticateApp(): Observable<ITokenResult> {
    const body = new HttpParams()
      .set('grant_type', 'client_credentials')
      .set('client_id', this.config.api.clientId)
      .set('client_secret', this.config.api.clientSecret);
    if (!this.appAuthShared$) {
      this.appAuthShared$ = AppApiStrategy.headersUrlEncoded(false).pipe(
        mergeMap((headers) => {
          return this.http
            .post<IInitResult>(this.getAuthServiceUrl(['frontend', 'init']), body.toString(), {
              headers,
            })
            .pipe(
              mergeMap((result) => {
                return this.storeIntrospectData(result.introspect).pipe(map(() => result));
              }),
              map((result) => {
                const tokenResult = this.storeTokenInfo(result.token, LOGINTYPE.APP);
                this.storeConfigs(result.config);
                this.appTranslationService.storeLanguages(result.languages);
                this.isAppAuthSubject.next(true);
                return tokenResult;
              }),
              tap(() => {
                setTimeout(() =>
                  this.appTranslationService.setAppTranslationUserPreferredLang().pipe(take(1)).subscribe()
                );
              })
            );
        }),
        shareReplay({
          refCount: true,
          bufferSize: 1,
        })
      );
    }
    return this.appAuthShared$;
  }

  public refreshLoginStatus(): void {
    this.isLoggedIn().pipe(take(1)).subscribe();
  }

  public isLoggedIn(requestedLoginType = LOGINTYPE.USER): Observable<boolean> {
    const subject = requestedLoginType === LOGINTYPE.USER ? this.isUserLoggedInSubject : this.isAppAuthSubject;
    const subscriber: PartialObserver<string | null> = {
      next: (token) => {
        subject.next(Boolean(token));
      },
      error: () => {
        subject.next(false);
      },
    };
    if (this.bridge.isNative()) {
      this.getTokenIfNoBridgeErrorStored(requestedLoginType).subscribe(subscriber);
    } else {
      this.hasLocalToken(requestedLoginType).subscribe(subscriber);
    }
    return subject;
  }

  public login(username: string, password: string): Observable<IIntrospectResult> {
    if (this.bridge.isNative()) {
      return this.addLoginPipe(
        this.bridge.setUserCredentials(username, password).pipe(map((token) => ({ access_token: token as string })))
      ).pipe(
        tap(() => {
          this.bridge.getPushStatus().subscribe((status) => {
            this.pushStatus = status ?? false;
            if (this.config.availableFeatures.push.autoEnable && !this.pushStatus) {
              this.bridge.enablePush().subscribe((value) => {
                this.pushStatus = value ?? false;
              });
            }
          });
        })
      );
    } else {
      const body = new HttpParams()
        .set('grant_type', 'password')
        .set('username', encodeURIComponent(username))
        .set('password', encodeURIComponent(password))
        .set('client_id', this.config.api.clientId)
        .set('client_secret', this.config.api.clientSecret) // TODO: darf nicht mitgegeben werden müssen
        .set('accountId', localStorage.getItem(StorageConfig.ACCOUNT_ID_KEY) ?? '');

      return AppApiStrategy.headersUrlEncoded(false).pipe(
        mergeMap((headers) => {
          return this.addLoginPipe(
            this.http.post<ITokenResult>(this.getAuthServiceUrl(['auth', 'token']), body.toString(), {
              headers,
            })
          );
        })
      );
    }
  }

  public domainLogin(data: { username: string; timezone: string; isApp: Platform }): Observable<unknown> {
    return AppApiStrategy.getHeaders().pipe(
      mergeMap((headers) => {
        return this.http.post<unknown>(this.getAuthServiceUrl(['user', 'domain-login']), data, {
          headers,
        });
      })
    );
  }

  public introspect(token: string): Observable<IIntrospectResult> {
    const body = new HttpParams().set('token', token);
    return AppApiStrategy.headersUrlEncoded(true).pipe(
      mergeMap((headers) => {
        return this.http
          .post<IIntrospectResult>(this.getAuthServiceUrl(['auth', 'introspect']), body, {
            headers,
          })
          .pipe(
            map((result: IIntrospectResult) => {
              return result;
            })
          );
      })
    );
  }

  public logout(error: { type: 'invalid_token' | 'expired_token' } | null = null): Observable<unknown> {
    const storedLoginType = localStorage.getItem(StorageConfig.LOGGEDIN_TYPE);
    if (storedLoginType !== LOGINTYPE.USER && this.config.availableFeatures.push.unregisteredDevices) {
      error = null;
    }
    if (this.bridge.isNative()) {
      return this.bridge.logout().pipe(
        tap(() => {
          this.doUserLogout(false, error);
          if (this.config.availableFeatures.push.unregisteredDevices) {
            this.bridge.enablePush().subscribe((value) => {
              this.pushStatus = value ?? false;
            });
          }
        })
      );
    } else {
      return this.appLogout(error);
    }
  }

  public handleInvalidToken(showErrorMessage = true, forceErrorBridge = false): Observable<string> {
    const storedLoginType = localStorage.getItem(StorageConfig.LOGGEDIN_TYPE);
    if (storedLoginType !== LOGINTYPE.USER && this.config.availableFeatures.push.unregisteredDevices) {
      showErrorMessage = false;
    }
    if (this.bridge.isNative()) {
      return this.bridge.getToken(true, showErrorMessage).pipe(
        take(1) as OperatorFunction<string | null, string>,
        catchError((err) => {
          if (!forceErrorBridge && storedLoginType !== LOGINTYPE.USER) {
            return this.authenticateApp().pipe(map((tokenResult) => tokenResult.access_token));
          } else {
            return throwError(err);
          }
        })
      );
    } else {
      if (storedLoginType === LOGINTYPE.USER) {
        return throwError('invalid_token');
      } else {
        return this.authenticateApp().pipe(map((tokenResult) => tokenResult.access_token));
      }
    }
  }

  public registration(data: unknown): Observable<unknown> {
    return AppApiStrategy.getHeaders().pipe(
      mergeMap((headers) => {
        return this.http.post<unknown>(this.getAuthServiceUrl(['user', 'register']), data, {
          headers,
        });
      })
    );
  }

  public registrationWithConfig(data: {
    username: string;
    timezone: string;
    language?: Language;
  }): Observable<unknown> {
    return AppApiStrategy.getHeaders().pipe(
      mergeMap((headers) => {
        return this.http.post<unknown>(this.getAuthServiceUrl(['user', 'register-with-config']), data, {
          headers,
        });
      })
    );
  }

  public confirmation(session: string): Observable<unknown> {
    return AppApiStrategy.getHeaders().pipe(
      mergeMap((headers) => {
        return this.http.post<unknown>(
          this.getAuthServiceUrl(['user', 'register-confirmation']),
          { session },
          {
            headers,
          }
        );
      })
    );
  }

  public domainConfirmation(session: string): Observable<IIntrospectResult> {
    const body = new HttpParams()
      .set('session', session)
      .set('isApp', this.bridge.getPlatform().toString())
      .set('client_id', this.config.api.clientId)
      .set('client_secret', this.config.api.clientSecret);
    return AppApiStrategy.headersUrlEncoded(true).pipe(
      mergeMap((headers) => {
        return this.addLoginPipe(
          this.http.post<ITokenResult>(this.getAuthServiceUrl(['user', 'domain-login-confirmation']), body.toString(), {
            headers,
          })
        );
      })
    );
  }

  public getTokenExpiration(token: string): Observable<number> {
    if (this.bridge.isNative()) {
      if (this.bridgeTokenIntrospect === token) {
        return of(parseInt(localStorage.getItem(StorageConfig.ACCESS_TOKEN_EXPIRATION) ?? '', 10));
      } else {
        return this.addLoginPipe(from([{ access_token: token }])).pipe(
          map(() => {
            return parseInt(localStorage.getItem(StorageConfig.ACCESS_TOKEN_EXPIRATION) ?? '', 10);
          })
        );
      }
    } else {
      return from([parseInt(localStorage.getItem(StorageConfig.ACCESS_TOKEN_EXPIRATION) ?? '', 10)]);
    }
  }

  public getToken(loginType = LOGINTYPE.USER, showErrorMessage = true): Observable<string | null> {
    if (this.bridge.isNative()) {
      return this.bridge.getToken(false, showErrorMessage).pipe(
        catchError((err) => {
          if (loginType === LOGINTYPE.USER && 'code' in err && this.errorCodes.indexOf(err.code) >= 0) {
            this.lastBridgeGetTokenError = err;
            return this.handleInvalidToken(showErrorMessage, true).pipe(catchError(() => this.noToken(loginType)));
          }
          return this.noToken(loginType);
        })
      );
    } else {
      return this.hasLocalToken(loginType);
    }
  }

  public getAppToken(showErrorMessage = false): Observable<string | null> {
    return this.getTokenIfNoBridgeErrorStored(LOGINTYPE.APP, showErrorMessage).pipe(take(1));
  }

  public doUserLogout(
    returning: true,
    error?: { type: 'invalid_token' | 'expired_token' } | null
  ): Observable<ITokenResult>;
  public doUserLogout(returning: false, error?: { type: 'invalid_token' | 'expired_token' } | null): void;
  public doUserLogout(
    returning: boolean,
    error: { type: 'invalid_token' | 'expired_token' } | null = null
  ): Observable<ITokenResult> | void {
    const storedLoginType = localStorage.getItem(StorageConfig.LOGGEDIN_TYPE);
    this.clearSessionInfo();
    const result = this.authenticateApp()?.pipe(
      take(1),
      tap(() => {
        this.loginPending.emit(false);
        this.isUserLoggedInSubject.next(false);
        this.refreshProfileImage.emit();
        this.router.navigate(['/']);
        if (typeof error?.type === 'string' && storedLoginType === LOGINTYPE.USER) {
          this.messageService.showToast(TOASTTYPE.ERROR, this.translate.instant(`message.error.${error.type}`));
        }
      })
    );
    if (returning) {
      return result;
    } else {
      result.subscribe();
    }
  }

  public logoutUserIfNeeded(): Observable<boolean> {
    const loginType = localStorage.getItem(StorageConfig.LOGGEDIN_TYPE);
    if (loginType === LOGINTYPE.USER) {
      return this.doUserLogout(true).pipe(map(() => true));
    } else {
      return from([true]);
    }
  }

  private appLogout(error: { type: 'invalid_token' | 'expired_token' } | null = null): Observable<unknown> {
    if (typeof error?.type === 'string') {
      // we already know, that the token is invalid, so skip the revokation step
      return this.doUserLogout(true, error);
    }
    const body = new HttpParams().set('token', localStorage.getItem(StorageConfig.ACCESS_TOKEN_KEY) ?? '');
    return AppApiStrategy.getHeaders('application/x-www-form-urlencoded', true, false).pipe(
      mergeMap((headers) => {
        return this.http
          .post<void>(this.getAuthServiceUrl(['auth', 'revoke']), body, {
            headers,
          })
          .pipe(finalize(() => this.doUserLogout(false)));
      })
    );
  }

  private getTokenIfNoBridgeErrorStored(
    loginType = LOGINTYPE.USER,
    showErrorMessage = true
  ): Observable<string | null> {
    if (!this.lastBridgeGetTokenError) {
      return this.getToken(loginType, showErrorMessage);
    } else {
      return this.noToken(loginType);
    }
  }

  private hasLocalToken(requestedLoginType: LOGINTYPE): Observable<string | null> {
    const authToken = localStorage.getItem(StorageConfig.ACCESS_TOKEN_KEY);
    const storedLoginType = localStorage.getItem(StorageConfig.LOGGEDIN_TYPE);

    if (!storedLoginType) return of(null);

    if (!authToken && requestedLoginType === LOGINTYPE.APP) {
      return this.authenticateApp().pipe(
        take(1),
        map((tokenResult) => tokenResult.access_token)
      );
    }

    if (requestedLoginType === storedLoginType || requestedLoginType === LOGINTYPE.APP) return of(authToken);
    return of(null);
  }

  private setTokenFromBridge: DefaultSubscriber<string> = (token?: string, error?: BridgeError) => {
    if (error && this.errorCodes.indexOf(error.code) >= 0) {
      this.handleInvalidToken().pipe(take(1)).subscribe();
    } else {
      this.addLoginPipe(of({ access_token: token } as ITokenResult)).subscribe();
    }
  };

  private setLoginPendingFromBridge: DefaultSubscriber<boolean> = (pending?: boolean, error?: unknown) => {
    if (error) {
      pending = false;
    }
    this.loginPending.emit(pending);
  };

  private noToken(loginType: LOGINTYPE): Observable<string | null> {
    if (loginType !== LOGINTYPE.APP) return of(null);
    return this.hasLocalToken(loginType);
  }

  public get pushStatus(): boolean {
    const status = localStorage.getItem(StorageConfig.PUSH_STATUS);
    return status === PushStatus.ENABLED;
  }

  public set pushStatus(value: boolean) {
    localStorage.setItem(StorageConfig.PUSH_STATUS, value ? PushStatus.ENABLED : PushStatus.DISABLED);
  }

  private addLoginPipe(httpRequest: Observable<ITokenResult>) {
    return httpRequest.pipe(
      map((result) => {
        return this.storeTokenInfo(result, LOGINTYPE.USER);
      }),
      concatMap(() => {
        return this.introspect(
          (this.bridge.isNative()
            ? this.bridgeTokenIntrospect
            : localStorage.getItem(StorageConfig.ACCESS_TOKEN_KEY)) ?? ''
        );
      }),
      mergeMap((result) => this.storeIntrospectData(result)),
      tap(() => {
        this.loginPending.emit(false);
        this.isUserLoggedInSubject.next(true);
        this.refreshProfileImage.emit();
      })
    );
  }

  private storeConfigs(data: IConfigResult) {
    if (data.frontend) {
      if (typeof data.frontend.firebase === 'object') {
        this.layoutService.enableAnalytics(undefined, data.frontend.firebase);
      } else if (data.frontend.googleAnalyticsKey && data.frontend.googleAnalyticsKey !== '') {
        this.layoutService.enableAnalytics(data.frontend.googleAnalyticsKey);
      }
    }
  }

  private storeIntrospectData(data: IIntrospectResult) {
    if (data.active) {
      localStorage.setItem(StorageConfig.ACCESS_TOKEN_EXPIRATION, data.exp.toString());
      localStorage.setItem(StorageConfig.SCOPES_KEY, data.scope);
      localStorage.setItem(StorageConfig.USER_NAME, data.username);

      if (data.sub) {
        localStorage.setItem(StorageConfig.USER_ID, data.sub);
      }

      if (data.application) {
        localStorage.setItem(StorageConfig.ACCOUNT_ID_KEY, data.application.active_account);
        localStorage.setItem(StorageConfig.USER_FIRSTNAME, data.application.user_firstname);
        localStorage.setItem(StorageConfig.USER_LASTNAME, data.application.user_lastname);
      }
      return from([data]);
    } else {
      return throwError('invalid_token');
    }
  }

  private storeTokenInfo(data: ITokenResult, loginType: LOGINTYPE) {
    localStorage.setItem(StorageConfig.LOGGEDIN_TYPE, loginType);
    if (this.bridge.isNative() && loginType === LOGINTYPE.USER) {
      this.bridgeTokenIntrospect = data.access_token;
      this.lastBridgeGetTokenError = null;
    } else {
      localStorage.setItem(StorageConfig.ACCESS_TOKEN_KEY, data.access_token);
    }
    return data;
  }

  private clearSessionInfo() {
    localStorage.removeItem(StorageConfig.ACCESS_TOKEN_KEY);
    localStorage.removeItem(StorageConfig.ACCOUNT_ID_KEY);
    localStorage.removeItem(StorageConfig.ACCESS_TOKEN_EXPIRATION);
    localStorage.removeItem(StorageConfig.SCOPES_KEY);
    localStorage.removeItem(StorageConfig.USER_IDENTIFICATION);
    localStorage.removeItem(StorageConfig.USER_ID);
    localStorage.removeItem(StorageConfig.USER_NAME);
    localStorage.removeItem(StorageConfig.USER_FIRSTNAME);
    localStorage.removeItem(StorageConfig.USER_LASTNAME);
    localStorage.removeItem(StorageConfig.COMPANY_NAME);
    localStorage.removeItem(StorageConfig.LOGGEDIN_TYPE);

    sessionStorage.clear();
  }
}
