import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DxValidationGroupComponent } from 'devextreme-angular';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { AuthService } from '~/app/core/auth/authentication.service';
import { ReportContent } from '../../core/models/report-content';
import { ReportContentService } from '../../core/services/backend/report-content.service';
import { LayoutService } from '../../core/services/store/layout.service';
import { stripAllTags } from '../helpers/dom';

@Component({
  selector: 'app-content-report',
  templateUrl: './content-report.component.html',
})
export class ContentReportComponent implements OnInit, OnDestroy {
  @ViewChild('contentReportVG', { static: false }) public contentReportVG?: DxValidationGroupComponent;

  @Output() public closeContentReport = new EventEmitter();
  public report?: ReportContent;
  public visible?: boolean;

  public isLoggedIn = false;

  public stripAllTags = stripAllTags;
  private subscriptions: Array<Subscription> = [];

  constructor(
    public translateService: TranslateService,
    public reportContentService: ReportContentService,
    private authService: AuthService,
    private layoutService: LayoutService
  ) {}

  public ngOnInit() {
    this.subscriptions = [
      this.layoutService.isReportDialogShown.subscribe((visible) => {
        this.visible = visible;
      }),
      this.layoutService.reportDialogContentReport.subscribe((report) => {
        this.report = report || undefined;
      }),
      this.authService
        .isLoggedIn()
        .pipe(distinctUntilChanged())
        .subscribe((isLoggedIn) => {
          this.isLoggedIn = isLoggedIn;
        }),
    ];
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.subscriptions = [];
  }

  public send() {
    if (this.contentReportVG?.instance.validate().isValid) {
      if (!this.report) return;
      this.reportContentService.post(this.report).subscribe(() => {
        this.closeContentReport.emit();
        this.reset();
      });
    }
  }

  public close() {
    this.reset();
    this.closeContentReport.emit();
  }

  public reset() {
    // must create dummy Object, so that reset call can set a meaningless value on something.
    // Despite the ngIf taking the node out of dom, the handler is still gets called and tries to set a property on null object
    this.report = new ReportContent();
    this.contentReportVG?.instance.reset();
    this.report = undefined;
  }
}
