import { ContentChild, Directive, TemplateRef } from '@angular/core';
import { CardHeaderTemplateDirective } from './card-header-template.directive';

@Directive({ selector: 'nappx-card-header' })
export class CardHeaderDirective {
  @ContentChild(CardHeaderTemplateDirective, {
    read: TemplateRef,
  })
  template!: TemplateRef<any>;
}
