import { NappEnvironmentConfiguration } from '~/app/config/environments-configuration';

export const SEESPITAL_PROD_NAPLEON_ENVIRONMENT_CONFIGURATION: NappEnvironmentConfiguration = {
  environmentName: 'see-spital',
  appBaseUrl: 'see-spital.napp.ch',
  title: 'See-Spital Horgen',
  theme: 'seespital',
  defaultLangISO: 'de',
  api: {
    clientId: 'AulX7u8l4M',
    clientSecret: 'd9dc852e6cf8726c77e0e91d3015d5b760aab6a2bf39a519e8ea11145feb5411',
    baseServiceUrl: 'https://see-spital.napp.ch/api',
  },
  captchaSiteKey: '6LcBwwAaAAAAACH1ps_5VTejkI6peVVDVu_8efUz',
  analyticsUrl: 'https://console.firebase.google.com/project/napoleon-seenews/overview',
  appUrl: 'https://see-spital.napp.ch',
  footer: {
    customer: 'See-Spital Horgen',
    phoneNr: '+41 44 728 11 11',
    website: 'see-spital.ch/',
    email: 'kommunikation@see-spital.ch',
    instagram: 'https://www.instagram.com/seespital/',
    facebook: 'https://www.facebook.com/SeeSpital',
    linkedIn: 'https://www.linkedin.com/company/see-spital-ag/',
    type: 'default',
  },
  availableModules: {
    feed: true,
    document: true,
    shortener: false,
    magazine: false,
    event: false,
    agenda: false,
    employees: true,
  },
  availableFeatures: {
    feed: {
      location: false,
      showPostType: false,
      approve: false,
      allowVideoUpload: true,
      mention: true,
      report: false,
    },
    header: {
      logoRedirectMyFeed: false,
    },
    push: {
      autoEnable: true,
      unregisteredDevices: false,
    },
    employee: {
      showFirstname: true,
      showLastname: true,
      showLocation: true,
      showDepartment: true,
      showFunctionality: true,
    },
  },
  cloudinary: {
    cloudName: 'cloud-08eins',
    uploadPreset: 'pd6qdf3k',
    prefix: 'seespital-horgen',
  },
  appleAppID: '6463801218',
};
