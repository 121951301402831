import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { PopupComponent } from '../popup.component';
import { MediaFile } from './models/media-file';
import { FILECATEGORYTYPES } from './services/filemanager.service';

enum TabType {
  FILEUPLOAD = 'fileupload',
  FILEBROWSER = 'filebrowser',
}
@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
})
export class FileManagerComponent implements OnInit, OnChanges, OnDestroy {
  tabType = TabType;

  @ViewChild('popup') public popup?: PopupComponent;

  @Output() public selected = new EventEmitter<MediaFile[]>();
  @Output() public visibleChange = new EventEmitter<boolean>();
  @Output() public videoUploaded = new EventEmitter<string>();
  @Output() public imageUploaded = new EventEmitter<string>();
  @Output() public fileUploaded = new EventEmitter<string>();

  @Input() public get visible() {
    return this._visible;
  }
  public set visible(visible: boolean) {
    if (visible !== this._visible) {
      this._visible = visible;
      this.visibleChange.emit(this._visible);
    }
  }

  @Input() public enableCrop = false;
  @Input() public cropAspectRatio = 1 / 1;
  @Input() public fileCategoryType: FILECATEGORYTYPES = FILECATEGORYTYPES.MAGAZINE;
  @Input() public acceptedFileTypes?: string | null;
  @Input() public updateFile = false;
  @Input() public fileIdToUpdate?: string;
  @Input() public fileDirectoryId?: string;

  public tabs = [TabType.FILEUPLOAD];
  public tabTitles = {
    [TabType.FILEUPLOAD]: this.translate.instant('filemanager.uploadFile'),
    [TabType.FILEBROWSER]: this.translate.instant('filemanager.mediathek'),
  };
  public resetInput = true; // === !this._visible;

  public activeTab = new EventEmitter();

  private resetInputSubscription?: Subscription;
  private _visible = !this.resetInput; // === false;

  constructor(private translate: TranslateService) {}

  public ngOnInit() {
    this.resetInputSubscription = this.visibleChange.subscribe((visible: boolean) => {
      // speed up UI, so resetInput on child component in next angular cycle
      setTimeout(() => (this.resetInput = !visible), 0);
    });
  }

  public ngOnChanges(sc: SimpleChanges) {
    for (const key of Object.keys(sc)) {
      if (key === 'fileCategoryType') {
        const fileCategoryTypeValue = sc[key].currentValue;
        if (fileCategoryTypeValue && fileCategoryTypeValue !== FILECATEGORYTYPES._CLOUDINARY_) {
          this.tabs = [TabType.FILEUPLOAD, TabType.FILEBROWSER];
        } else {
          this.tabs = [TabType.FILEUPLOAD];
        }

        setTimeout(() => this.popup?.repaint(), 100);
      }
    }
  }

  public ngOnDestroy() {
    this.resetInputSubscription?.unsubscribe();
  }

  public changeTab(tab: { itemData: Event }) {
    this.activeTab.emit(tab.itemData);
    this.popup?.repaint();
  }

  public onSelect(selection: MediaFile[]) {
    this.selected.emit(selection);
  }

  public onVideoSelect(fileId: string) {
    this.videoUploaded.emit(fileId);
  }

  public onImageUploaded(fileId: string) {
    this.imageUploaded.emit(fileId);
  }

  public onFileUploaded(publicId: string) {
    this.fileUploaded.emit(publicId);
  }
}
