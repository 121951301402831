import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'strip-tags',
})
export class StripTagsPipe implements PipeTransform {
  public transform(value: string, removeTags: Array<string> = []) {
    if (typeof value !== 'string') {
      return value;
    }
    return removeTags
      .map((tagName) => new RegExp(`</?${tagName}[^><]*>`, 'ig'))
      .reduce((str, regexp) => str.replace(regexp, ''), value);
  }
}
