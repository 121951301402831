import { Component, HostBinding, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ENVIRONMENT_CONFIGURATION } from '@softwarehaus/util-configuration';
import { DxFormComponent } from 'devextreme-angular';
import { EditorEnterKeyEvent } from 'devextreme/ui/form';
import { Subscription } from 'rxjs';
import { AppAngularBridge } from '~/app/app-bridge';
import { NappEnvironmentConfiguration } from '~/app/config/environments-configuration';
import { AuthService } from '~/app/core/auth/authentication.service';
import { Platform } from '~/app/core/services/angular-bridge.types';
import { LayoutService } from '~/app/core/services/store/layout.service';
import { AppTranslationService } from '~/app/core/services/translation/app-translation.service';
import { KeyboardNavigableComponent } from '~/app/shared/components/keyboard-navigable.component';
import { getTimezoneOffsetInValidPattern } from '~/app/shared/helpers/timezone';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
})
export class RegistrationComponent extends KeyboardNavigableComponent implements OnInit, OnDestroy {
  @ViewChild(DxFormComponent) public form?: DxFormComponent;

  @HostBinding('class.right-menu') public addClass = false;

  public success = false;
  public disableKeyBoardFix = false;
  public acceptToS = false;
  public showToS = false;

  private layoutSubscription?: Subscription;
  private tosEnabled = this.config.availableFeatures.feed.report;

  constructor(
    public authService: AuthService,
    private appTranslationService: AppTranslationService,
    private layoutService: LayoutService,
    @Inject(ENVIRONMENT_CONFIGURATION) private config: NappEnvironmentConfiguration
  ) {
    super();
  }

  public ngOnInit() {
    this.addClass = true;
    if (AppAngularBridge.isNative()) {
      this.layoutSubscription = this.layoutService.showUserSidebarSubject.subscribe((isOpen) => {
        if (isOpen) {
          this.disableKeyBoardFix = true;
        }
        AppAngularBridge.sideBarStatus(isOpen).subscribe({
          next: () => {
            if (isOpen) {
              this.disableKeyBoardFix = false;
            }
          },
          error: () => {
            this.disableKeyBoardFix = false;
          },
        });
      });
      if (this.tosEnabled && AppAngularBridge.getPlatform() === Platform.Android) {
        AppAngularBridge.tosEnabled().subscribe({
          next: (enabled) => {
            this.showToS = enabled ?? false;
          },
          error: () => {
            this.showToS = false;
          },
        });
      }
    }
  }

  public ngOnDestroy() {
    this.layoutSubscription?.unsubscribe();
  }

  public passwordComparison = () => {
    return this.form?.instance.option('formData').password;
  };

  public registrateUser(event?: EditorEnterKeyEvent) {
    if (!this.form) return;

    if (this.form.instance.validate().isValid) {
      this.blurAllFields(event);
      this.authService
        .registrationWithConfig({
          ...this.form.formData,
          username: this.form.instance.option('formData').email,
          timezone: getTimezoneOffsetInValidPattern(),
          language: this.appTranslationService.getCurrentLanguage(),
        })
        .subscribe(() => {
          this.success = true;
        });
    } else {
      if (!event) return;
      this.focusNextField(event, this.form.instance);
    }
  }

  public acceptToSValueChanged() {
    this.acceptToS = !this.acceptToS;
  }

  public readToS() {
    if (AppAngularBridge.isNative()) {
      AppAngularBridge.showToS();
    } else {
      // TODO: Later show ToS on Web?
    }
  }
}
