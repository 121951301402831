import { ContentChild, Directive, TemplateRef } from '@angular/core';
import { CardBodyTemplateDirective } from './card-body-template.directive';

@Directive({ selector: 'nappx-card-body' })
export class CardBodyDirective {
  @ContentChild(CardBodyTemplateDirective, {
    read: TemplateRef,
  })
  template!: TemplateRef<any>;
}
