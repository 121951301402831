import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { PermissionService } from '../../core/auth/permission.service';
import { AngularBridgeService } from '../../core/services/angular-bridge.service';
import { BridgeCallbackMethod, BridgeError, SelectionInfo } from '../../core/services/angular-bridge.types';
import { LayoutService } from '../../core/services/store/layout.service';
import { assignUniqueIdToDOMElement } from '../helpers/dom';

type CallbackExtendedSelectionInfo = SelectionInfo & {
  callback: () => void;
};

@Injectable({
  providedIn: 'root',
})
export class FeedCreateService {
  public titleText: BehaviorSubject<string> = new BehaviorSubject('');
  public cancelText: BehaviorSubject<string> = new BehaviorSubject('');
  public commands: BehaviorSubject<Array<CallbackExtendedSelectionInfo>> = new BehaviorSubject<
    Array<CallbackExtendedSelectionInfo>
  >([]);
  public target = new BehaviorSubject<Element | undefined>(undefined);

  private actionSheetCallback!: (selectionNumber: number) => Observable<void>;

  constructor(
    private permissionService: PermissionService,
    private bridge: AngularBridgeService,
    private layoutService: LayoutService,
    private translate: TranslateService,
    private router: Router
  ) {
    bridge.setDefaultReceiver(BridgeCallbackMethod.createPost, this.showSelectionForPostCreationBridge.bind(this));
  }

  public actionSheetSelectedCallback(selection: number): void {
    let done: Observable<void> = from([]);
    let handled = false;
    const cleanup = () => {
      if (!handled) {
        handled = true;
        this.layoutService.isActionSheetShown?.next(false);
        // reset ActionSheet
        this.resetActionSheet();
      }
    };
    if (typeof this.actionSheetCallback === 'function') {
      done = this.actionSheetCallback(selection);
      this.actionSheetCallback;
    }
    done.subscribe({ next: cleanup, error: cleanup, complete: cleanup });
  }

  public showSelectionForPostCreation(target?: EventTarget) {
    this._showSelectionForPostCreation(undefined, undefined, target);
    if (this.commands.value.length === 1) {
      this.actionSheetCallback(1);
    }
  }

  private showSelectionForPostCreationBridge(_videoUrlOrId: string | undefined, err?: BridgeError) {
    this._showSelectionForPostCreation(_videoUrlOrId, err, undefined);
  }

  private _showSelectionForPostCreation(_videoUrlOrId: string | undefined, err?: BridgeError, target?: EventTarget) {
    if (typeof err !== 'undefined' && null !== err) {
      return;
    }
    if (this.permissionService.checkScopes(['mgmt.feedpost.create', 'feedpost.create'])) {
      // TODO handle _videoUrlOrId parameter
      const translationKeys = {
        titleText: 'feed.creation.title',
        cancelText: 'feed.creation.cancel',
        addPost: 'feed.creation.addPost',
      };
      this.translate.get(Object.values(translationKeys)).subscribe((data: { [x: string]: string }) => {
        this.titleText.next(data[translationKeys.titleText]);
        this.cancelText.next(data[translationKeys.cancelText]);
        this.target.next(target as Element);
        this.commands.next([
          {
            id: 1,
            default: true,
            name: data[translationKeys.addPost],
            callback: () => {
              this.router.navigate(['feed', 'add'], {
                queryParams: {
                  type: 'news',
                },
              });
            },
          },
          // TODO add Lifehack
          /*
            {
              id: 2,
              default: false,
              name: data[translationKeys.addLifehack],
              callback: () => {
                this.router.navigate(['feed', 'add'], {
                  queryParams: {
                    type: 'lifehack',
                  },
                });
              },
            },
            */
        ]);
        this.actionSheetCallback = (selection) => {
          // cancel => selection === 0
          if (selection > 0) {
            this.commands.value.find((s) => s.id === selection)?.callback();
          }
          return from([]);
        };

        if (this.commands.value.length === 1) return;

        if (this.bridge.isNative()) {
          const uniqueId = assignUniqueIdToDOMElement(this.target.value as Element);
          this.bridge
            .showSelection(this.titleText.value, this.cancelText.value, this.commands.value, uniqueId)
            .subscribe({
              next: (selection: number | null) => this.actionSheetSelectedCallback(selection as number),
              error: this.resetActionSheet.bind(this),
            });
        } else {
          // Show web Selections;
          this.layoutService.isActionSheetShown.next(true);
        }
      });
    }
  }

  private resetActionSheet() {
    this.titleText.next('');
    this.cancelText.next('');
    this.commands.next(new Array<CallbackExtendedSelectionInfo>());
    this.target.next(new Element());
  }
}
