// https://stackoverflow.com/a/47138918

import { AngularBridgeService } from './core/services/angular-bridge.service';

export let AppAngularBridge: AngularBridgeService;

/**
 * Helper to access the exported {@link AppAngularBridge}, needed as ES6 modules export
 * immutable bindings; see http://2ality.com/2015/07/es6-module-exports.html
 */
export function setAppAngularBridge(angularBridge: AngularBridgeService) {
  if (AppAngularBridge) {
    // Should not happen
    console?.error('Programming error: AppAngularBridge was already set');
  }
  else {
    AppAngularBridge = angularBridge;
  }
}
