import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Observable, Subject } from 'rxjs';
import { ExternalFileService } from './external-file.service';
import { ExternalFile, FilePurpose } from './types';

@Component({
  selector: 'app-external-file-uploader',
  templateUrl: './external-file-uploader.component.html',
})
export class ExternalFileUploaderComponent {
  @Input() public resetInput = false;
  @Input() public purpose!: FilePurpose;

  @Input() public enableCrop = false;
  @Input() public cropAspectRatio: number = 1 / 1;
  // @Input() public config?: UploadConfig;

  @Output() public fileUploaded = new EventEmitter<ExternalFile>();

  public isLoading = false;
  public imageChangedEvent?: ImageCroppedEvent | Event;
  public file?: File;
  public croppedImage?: Blob;
  public cropMode = false;
  public canvasRotation = 0;

  constructor(private cdRef: ChangeDetectorRef, private externalFileService: ExternalFileService) {}

  public fileChangeEvent(event: Event): void {
    const target = event.target as HTMLInputElement;
    const file = target.files ? target.files[0] : null;

    if (!file) return;

    this.file = file;
    this.isLoading = true;

    if (this.enableCrop) {
      // this.imageChangedEvent = event;
      this.cropMode = true;
    } else {
      this.uploadFile(file);
    }
  }

  public imageCropped(blob: Blob) {
    this.croppedImage = blob;
  }

  public uploadFile(blob?: Blob) {
    console.log(blob);
    if (!blob) return;
    this.isLoading = true;
    this.externalFileService.uploadExternalFile(blob, this.purpose).subscribe((result) => {
      this.isLoading = false;
      this.fileUploaded.emit(result);
    });
  }

  public getAcceptedFileTypes(): string {
    if (this.purpose.includes('VIDEO')) return 'video/*';
    else if (this.purpose.includes('PICTURE')) return 'image/*';
    else if (this.purpose.includes('DOCUMENT')) return 'application/pdf';
    else return '*/*';
  }

  public cancel() {
    this.triggerResetInput().subscribe({
      next: () => {
        this.imageChangedEvent = undefined;
        this.cropMode = false;
      },
    });
  }

  public triggerResetInput(): Observable<void> {
    return this._triggerResetInput(true);
  }

  private _triggerResetInput(reset: boolean, obs?: Subject<void>): Observable<void> {
    this.resetInput = reset;
    this.cdRef.detectChanges();
    if (reset) {
      obs = new Subject<void>();
      setTimeout(() => this._triggerResetInput(false, obs), 0);
    } else {
      if (obs) {
        obs.next();
        obs.complete();
      }
    }
    if (!obs) return new Observable<void>();
    return obs.asObservable();
  }
}
