import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, finalize, mergeMap, tap } from 'rxjs/operators';
import {
  HTTPErrorCode,
  HTTPSuccessCode,
  RESTApiErrorCode,
  RESTApiErrorField,
  httpStatusCodes,
} from '../../../shared/constants/HttpStatusCodes';
import { AuthService } from '../../auth/authentication.service';
import { HttpStateService } from '../backend/httpstate.service';
import { HttpProgressState } from '../base/base.service';
import { MessageService, TOASTTYPE } from '../message.service';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  private exceptions: string[] = ['login'];

  private isRefreshingToken = false;

  private tokenRefreshed = new Subject<string>();
  private tokenRefreshFailed = new Subject<void>();
  private hasRefreshedToken = this.tokenRefreshed.asObservable();

  constructor(
    private router: Router,
    private translate: TranslateService,
    private messageService: MessageService,
    private httpStateService: HttpStateService,
    private authService: AuthService
  ) {}

  public intercept(req: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<HttpStatusCode>> {
    let parsedReq = this.parseRequest(req);

    // remove custom headers
    const showMessage = parsedReq.headers.get('app_showMessage') !== 'false';
    parsedReq = parsedReq.clone({ headers: parsedReq.headers.delete('app_showMessage') });

    this.httpStateService.state.next({
      state: HttpProgressState.start,
      url: req.url,
    });

    return next.handle(parsedReq).pipe(
      tap(this.handleResponse(parsedReq, { showMessage })),
      catchError(this.handleErrorResponse(next, parsedReq, { showMessage })),
      finalize(() => {
        // set next value in the next event loop to prevent ExpressionChangedAfterItHasBeenCheckedError
        setTimeout(
          () =>
            this.httpStateService.state.next({
              state: HttpProgressState.end,
              url: req.url,
            }),
          0
        );
      })
    );
  }

  private parseRequest(req: HttpRequest<object>): HttpRequest<object> {
    const updateOpts: Record<string, object> = {};
    switch (req.method) {
      case 'POST':
      case 'PUT':
      case 'PATCH':
        if (req.body === null) {
          break;
        }

        updateOpts['body'] = req.body;
        break;
    }

    return req.clone(updateOpts);
  }

  private handleResponse(req: HttpRequest<object>, options: { showMessage: boolean }) {
    return (event: HttpEvent<HttpStatusCode>) => {
      if (event instanceof HttpResponse) {
        // possible way of showing toast with success messages

        if (options && options.showMessage) {
          const successObject = httpStatusCodes.success[event.status.toString() as unknown as HTTPSuccessCode];
          this.translate.get(successObject.message).subscribe((res: string) => {
            if (successObject.showToast) {
              this.messageService.showToast(
                TOASTTYPE.SUCCESS,
                res,
                (successObject as { displayTime?: number }).displayTime ?? undefined
              );
            }
          });
        }
        switch (req.method) {
          case 'POST':
          case 'PUT':
          case 'PATCH':
            // todo
            break;
        }
      }
    };
  }

  private handleErrorResponse(next: HttpHandler, req: HttpRequest<object>, options: { showMessage: boolean }) {
    return (error: Error, caught: Observable<HttpEvent<HttpStatusCode>>): Observable<HttpEvent<HttpStatusCode>> => {
      if (error instanceof HttpErrorResponse) {
        const errCode: string | undefined = error.error?.code;

        type ErrorObject =
          | {
              showToast: boolean;
              showModal: boolean;
              log: boolean;
              message: string;
              logout: boolean;
            }
          | {
              reason: string;
              showToast: boolean;
              showModal: boolean;
              log: boolean;
              message: string;
            };
        let errorObject: ErrorObject = httpStatusCodes.error[error.status.toString() as unknown as HTTPErrorCode];

        if (errCode && httpStatusCodes.error.code[errCode as unknown as RESTApiErrorCode]) {
          errorObject = httpStatusCodes.error.code[errCode as unknown as RESTApiErrorCode];
        }

        if (error.status === 422 && errCode === 'model_validation_error' && Array.isArray(error.error?.detail)) {
          for (const detail of error.error.detail) {
            if (detail.field && httpStatusCodes.error.field[detail.field as unknown as RESTApiErrorField]) {
              errorObject = httpStatusCodes.error.field[detail.field as unknown as RESTApiErrorField];
              break;
            }
          }
        }

        // (error.error instanceof Blob) === true happens when a linked file exists, but no data is available
        if (!(error.error instanceof Blob)) {
          if (error.status === 401 && errCode === 'invalid_token') {
            let errType: 'invalid_token' | 'expired_token' = errCode;
            const message = error.error?.message;
            if (typeof message === 'string' && message.indexOf('expired')) {
              errType = 'expired_token';
            }
            const refreshTokenFailed = () => {
              this.authService.logout({ type: errType }).subscribe();
              return throwError(error);
            };
            return this.authService.handleInvalidToken(options && options.showMessage).pipe(
              mergeMap((token) => {
                const newToken = `Bearer ${token}`;
                if (token && req.headers.get('Authorization') !== newToken) {
                  // if native bridge got us a new token or user is not logged-in and the app did request new token
                  const newReq = req.clone({ headers: req.headers.set('Authorization', newToken) });
                  return next.handle(newReq);
                } else {
                  return refreshTokenFailed();
                }
              }),
              catchError(() => {
                return refreshTokenFailed();
              })
            );
          } else {
            if (typeof errorObject === 'undefined') {
              // this.messageService.showToast(TOASTTYPE.ERROR, this.translate.instant('message.error.default'));
            } else if (options && options.showMessage) {
              if (errorObject.showToast) {
                this.translate.get(errorObject.message).subscribe((res: string) => {
                  this.messageService.showToast(TOASTTYPE.ERROR, res);
                });
              } else if (errorObject.log) {
                console?.error(errorObject);
              }
            }
          }
        }
        return throwError(error);
      }
      return caught;
    };
  }

  // private refreshToken(showMessage: boolean): Observable<any> {
  //   if (this.isRefreshingToken) {
  //     return new Observable(observer => {
  //       this.hasRefreshedToken.subscribe(token => {
  //         observer.next(token);
  //         observer.complete();
  //       });
  //       this.tokenRefreshFailed.subscribe(_ => {
  //         observer.error();
  //       });
  //     });
  //   } else {
  //     this.isRefreshingToken = true;

  //     return .pipe(
  //       timeout(15_000),
  //       tap({
  //         next: (token) => {
  //           this.isRefreshingToken = false;
  //           this.tokenRefreshed.next(token);
  //         },
  //         error: (_error) => {
  //           this.isRefreshingToken = false;
  //           this.tokenRefreshFailed.next();
  //         },
  //         complete: () => {
  //           this.isRefreshingToken = false;
  //           // causes no harm if observer did complete already after a successful next() call
  //           this.tokenRefreshFailed.next();
  //         }
  //       }),
  //     );
  //   }
  // }
}
