import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { LayoutService } from '~/app/core/services/store/layout.service';
import { HttpStateService } from '../../core/services/backend/httpstate.service';
import { HttpProgressState } from '../../core/services/base/base.service';

@Component({
  selector: 'app-loading-bar',
  template: ` <dx-progress-bar [value]="false" width="100%" [class.d-none]="!this.loading"></dx-progress-bar> `,
})
export class LoadingBarComponent implements OnInit {
  public loading = false;
  @Input() public filterBy: string | null = null;
  constructor(
    private httpStateService: HttpStateService,
    private layoutService: LayoutService,
    private cdRef: ChangeDetectorRef
  ) {}

  /**
   * receives all HTTP requests and filters them by the filterBy
   * values provided
   */
  public ngOnInit() {
    this.httpStateService.state.subscribe((progress) => {
      if (progress && progress.url) {
        if (!this.filterBy) {
          this.loading = progress.state === HttpProgressState.start;
        } else if (progress.url.indexOf(this.filterBy) !== -1) {
          this.loading = progress.state === HttpProgressState.start;
        }
        this.layoutService.appInLoadingStateSubject.next(this.loading);
        this.cdRef.detectChanges();
      }
    });
  }
}
