import { Component, HostBinding, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DxFormComponent } from 'devextreme-angular';
import { EditorEnterKeyEvent } from 'devextreme/ui/form';
import { Subscription } from 'rxjs';
import { AppAngularBridge } from '../../../app-bridge';
import { FeedService } from '../../../features/feed/services/feed.service';
import { KeyboardNavigableComponent } from '../../../shared/components/keyboard-navigable.component';
import { MessageService, TOASTTYPE } from '../../services/message.service';
import { LayoutService } from '../../services/store/layout.service';
import { AuthService } from '../authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent extends KeyboardNavigableComponent implements OnInit, OnDestroy {
  @Input() public isVisible = false;
  @ViewChild(DxFormComponent) public form?: DxFormComponent;

  @HostBinding('class.right-menu') public addClass = false;
  public pending = false;
  public disableKeyBoardFix = false;
  public caption: string | null = null;

  public enteredData: Record<string, string> = {};

  private layoutSubscription?: Subscription;
  private loginPendingSubscription?: Subscription;

  constructor(
    public authService: AuthService,
    private router: Router,
    private layoutService: LayoutService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private feedService: FeedService
  ) {
    super();
  }

  public ngOnInit() {
    this.addClass = true;
    this.translateService.get('login.caption').subscribe((r) => {
      this.caption = r;
    });

    // if confirmation link exist
    const confirmationSession = this.route.snapshot.queryParamMap.get('s');
    if (confirmationSession !== null && !this.authService.confirmed) {
      this.authService.confirmation(confirmationSession).subscribe(() => {
        // TODO: translate
        this.messageService.showToast(TOASTTYPE.SUCCESS, this.translateService.instant('register.accountActivated'));
      });
      this.authService.confirmed = true;
      this.router.navigate(['/']);
    }

    this.loginPendingSubscription = this.authService.loginPending.subscribe((pending) => (this.pending = pending));
    if (AppAngularBridge.isNative()) {
      this.layoutSubscription = this.layoutService.showUserSidebarSubject.subscribe((isOpen) => {
        if (this.isVisible) {
          if (isOpen) {
            this.disableKeyBoardFix = true;
          } else {
            this.enteredData = {};
          }
          AppAngularBridge.sideBarStatus(isOpen).subscribe({
            next: () => {
              if (isOpen) {
                this.disableKeyBoardFix = false;
              }
            },
            error: () => {
              this.disableKeyBoardFix = false;
            },
          });
        }
      });
    }
  }

  public ngOnDestroy() {
    this.loginPendingSubscription?.unsubscribe();
    this.layoutSubscription?.unsubscribe();
  }

  public login(event: EditorEnterKeyEvent) {
    if (!this.form) return;

    if (this.form.instance.validate().isValid) {
      this.blurAllFields(event);
      const email = this.form.instance.option('formData').email;
      const password = this.form.instance.option('formData').password;
      this.authService.login(email, password).subscribe({
        next: () => {
          this.messageService.showToast(TOASTTYPE.SUCCESS, this.translateService.instant('login.success.message'));
          this.layoutService.toggleUserSidebar(false);
          const shared = this.route.snapshot.queryParamMap.get('shared');
          if (!shared) {
            this.router.navigate(['feed']);
            this.feedService.triggerReload();
          }
        },
        error: () => {
          this.messageService.showToast(
            TOASTTYPE.ERROR,
            this.translateService.instant('message.error.invalid_credential')
          );
        },
      });
    } else {
      this.focusNextField(event, this.form.instance);
    }
  }
}
