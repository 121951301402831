<div id="bottom-navigations">
  <div class="bottom-navigations-background row container p-0">
    <div class="link col-6">
      <a routerLink="/feed" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
        <span class="nav-icon"><i class="napp-logo napp-logo-brand"></i></span>
        <span class="nav-text">{{ 'feed.navLabel' | translate }}</span>
      </a>
    </div>
    <div class="link col-6" *ngIf="isLoggedIn && hasAnySubscriptions">
      <a
        routerLink="/feed"
        [queryParams]="{ feedtype: 'subscribed' }"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: false }"
      >
        <span class="nav-icon"><i class="fas fa-tags va-middle"></i></span>
        <span class="nav-text">{{ 'feed.myFeed' | translate }}</span>
      </a>
    </div>
    <div class="link col-6" *ngIf="isLoggedIn && !hasAnySubscriptions">
      <a routerLink="/feed/subscriptions" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
        <span class="nav-icon"><i class="fas fa-tags va-middle"></i></span>
        <span class="nav-text">{{ 'feed.myFeed' | translate }}</span>
      </a>
    </div>
    <div class="link col-6" *ngIf="!isLoggedIn">
      <a routerLink="/feed/subscriptions">
        <span class="nav-icon"><i class="fas fa-tags va-middle"></i></span>
        <span class="nav-text">{{ 'feed.myFeed' | translate }}</span>
      </a>
    </div>
  </div>
</div>
