import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AppTranslation } from './app-app-translation';
import { AppAngularBridge } from './app-bridge';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
})
export class ErrorPageComponent implements OnInit, AfterViewInit {

  constructor(
    private router: Router,
  ) { }

  public ngOnInit() {
    AppTranslation.setAppTranslationUserPreferredLang().pipe(take(1)).subscribe();
  }

  public ngAfterViewInit() {
    setTimeout(() => {
      AppAngularBridge.webReady();
    }, 0);
  }

  public reload() {
    this.router.navigate(['/']);
  }

}
