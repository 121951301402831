<app-base-accordion-panel [panel]="this">
  <div class="row">
    <div class="col-lg-8">
      <div *ngIf="this.entity">
        <dx-form
          *appReloadOnLangChange
          [readOnly]="panelMode === modeType.Read"
          [showColonAfterLabel]="true"
          [(formData)]="this.formData"
          (onEditorEnterKey)="save(safeType, $event)"
        >
          <dxi-item
            dataField="location.id"
            editorType="dxSelectBox"
            [editorOptions]="{
              dataSource: locations,
              displayExpr: 'translatedValue',
              valueExpr: 'id',
              selectionMode: 'single',
              showSelectionControls: true,
              showTitle: false,
              searchEnabled: true,
              placeholder: 'general.select' | translate
            }"
          >
            <dxo-label [text]="'fields.employeeinfo.location' | translate"></dxo-label>
          </dxi-item>
          <dxi-item
            dataField="department.id"
            editorType="dxSelectBox"
            [editorOptions]="{
              dataSource: departments,
              displayExpr: 'translatedValue',
              valueExpr: 'id',
              selectionMode: 'single',
              showSelectionControls: true,
              showTitle: false,
              searchEnabled: true,
              placeholder: 'general.select' | translate
            }"
          >
            <dxo-label [text]="'fields.employeeinfo.department' | translate"></dxo-label>
          </dxi-item>
          <dxi-item
            dataField="functionality.id"
            editorType="dxSelectBox"
            [editorOptions]="{
              dataSource: functionalities,
              displayExpr: 'translatedValue',
              valueExpr: 'id',
              selectionMode: 'single',
              showSelectionControls: true,
              showTitle: false,
              searchEnabled: true,
              placeholder: 'general.select' | translate
            }"
          >
            <dxo-label [text]="'fields.employeeinfo.functionality' | translate"></dxo-label>
          </dxi-item>
        </dx-form>
      </div>
    </div>
  </div>
</app-base-accordion-panel>
