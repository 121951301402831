<div class="sidebar-right-content">
  <ng-container *ngIf="!pending; else pendingView">
    <h3 class="title">{{ 'login.title' | translate }}</h3>
    <p class="caption" *ngIf="caption">{{ caption }}</p>
    <dx-form *appReloadOnLangChange (onEditorEnterKey)="login($event)" [(formData)]="this.enteredData">
      <dxi-item dataField="email" [editorOptions]="{ mode: 'email', disabled: disableKeyBoardFix }">
        <dxo-label text="{{ 'labels.email' | translate }}"></dxo-label>
        <dxi-validation-rule type="required" message="{{ 'error.input_required' | translate }}"> </dxi-validation-rule>
        <dxi-validation-rule type="email" message="{{ 'error.email_format' | translate }}"> </dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="password" [editorOptions]="{ mode: 'password', disabled: disableKeyBoardFix }">
        <dxo-label text="{{ 'labels.password' | translate }}"></dxo-label>
        <dxi-validation-rule type="required" message="{{ 'error.input_required' | translate }}"> </dxi-validation-rule>
      </dxi-item>
    </dx-form>
    <dx-button
      [type]="'default'"
      [stylingMode]="'text'"
      class="float-start"
      (click)="authService.resetPasswordUser = true"
      ><span>{{ 'login.link.reset_password' | translate }}</span>
    </dx-button>
    <dx-button
      [type]="'default'"
      [stylingMode]="'text'"
      class="float-start"
      (click)="authService.registrateUser = true"
    >
      <span>{{ 'login.link.register' | translate }}</span>
    </dx-button>
    <app-button [type]="'default'" [stylingMode]="'contained'" (buttonClick)="login($event)">{{
      'login.button' | translate
    }}</app-button>
  </ng-container>
  <ng-template #pendingView>
    <div class="registration-completed">
      <h3>{{ 'login.pending.title' | translate }}</h3>
      <p>{{ 'login.pending.text' | translate }}</p>
    </div>
  </ng-template>
</div>
