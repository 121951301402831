import { Injectable, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import jsSHA from 'jssha/dist/sha256';
import { Observable, Subject, noop, of } from 'rxjs';
import { MediaFile } from '~/app/shared/components/filemanager/models/media-file';
import { pseudoRandomBytes } from '~/app/shared/helpers/random';
import {
  HTTPErrorCode,
  RESTApiErrorCode,
  RESTApiErrorField,
  httpStatusCodes,
} from '../../shared/constants/HttpStatusCodes';
import {
  BridgeCallbackMethod,
  BridgeError,
  BridgeMethod,
  BridgeMethodParameter,
  BridgeMethodResponse,
  BridgeNativeErrors,
  DefaultSubscriber,
  Platform,
  SelectionInfo,
  VideoType,
} from './angular-bridge.types';
import { MessageService, TOASTTYPE } from './message.service';

declare global {
  interface Window {
    angularBridge: {
      [key in BridgeCallbackMethod]: (unparsedResponse: string) => void;
    };
    AndroidWebInterface?: {
      [key in BridgeMethod]: (payload?: string) => void;
    };
    webkit?: {
      messageHandlers: {
        [key in BridgeMethod]: {
          postMessage: (payload: string) => void;
        };
      };
    };
  }
}

const nativePlatforms = [Platform.iOS, Platform.Android];

type KnownTypeSubscribers = {
  updateToken?: DefaultSubscriber<string>;
  loggedIn?: DefaultSubscriber<string>;
  loginPending?: DefaultSubscriber<boolean>;
  goBack?: DefaultSubscriber<string>;
};
type AnyTypeDefaultSubscriberKeys = Exclude<keyof typeof BridgeCallbackMethod, keyof KnownTypeSubscribers>;
type DefaultSubscribers = KnownTypeSubscribers & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key in AnyTypeDefaultSubscriberKeys]?: DefaultSubscriber<any>;
};
type AllDefaultSubscriberKeys = keyof DefaultSubscribers;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SubscriberResultSubject = boolean | number | string | MediaFile | void | null | any;

type SubscriberResult<T extends SubscriberResultSubject> = { subject: Subject<T>; showErrorMessage: boolean };

const updateTokenRequestIdForce = {
  true: 'getToken_f:true',
  false: 'getToken_f:false',
};

@Injectable({
  providedIn: 'root',
})
export class AngularBridgeService {
  private subscribers: {
    [id: string]: SubscriberResult<SubscriberResultSubject>;
  } = {};
  private defaultSubscribers: DefaultSubscribers = {};
  private logError: (...args: unknown[]) => void = noop;
  private _platform: Platform;
  private _isNative = false;
  private isHandlingGoBack = false;

  public constructor(
    private zone: NgZone,
    private translateService: TranslateService,
    private messageService: MessageService
  ) {
    this._platform =
      'AndroidWebInterface' in window ? Platform.Android : 'webkit' in window ? Platform.iOS : Platform.Browser;
    this._isNative = nativePlatforms.indexOf(this._platform) !== -1;
    if (console) {
      const ck = Object.keys(console);
      if (ck.indexOf('error') !== -1) {
        this.logError = console.error.bind(console);
      } else if (ck.indexOf('log') !== -1) {
        this.logError = console.log.bind(console);
      }
    }
  }

  public createAngularBridge() {
    window['angularBridge'] = window['angularBridge'] || {};
    const allMethods = Object.values(BridgeCallbackMethod);
    for (const method of allMethods) {
      window['angularBridge'][method] = this.zoneRunner(method);
    }
  }

  public init() {
    // init Angular Bridge
    this.createAngularBridge();

    return Promise.resolve();
  }

  public isNative(): boolean {
    return this._isNative;
  }

  public getPlatform(): Platform {
    return this._platform;
  }

  public setDefaultReceiver(
    method: BridgeCallbackMethod.updateToken | BridgeCallbackMethod.loggedIn | BridgeCallbackMethod.goBack,
    receiver: DefaultSubscriber<string>
  ): void;
  public setDefaultReceiver(
    method: BridgeCallbackMethod.createPost,
    receiver: DefaultSubscriber<string | undefined>
  ): void;
  public setDefaultReceiver(method: BridgeCallbackMethod.loginPending, receiver: DefaultSubscriber<boolean>): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public setDefaultReceiver(method: AnyTypeDefaultSubscriberKeys, receiver: DefaultSubscriber<any>): void;
  public setDefaultReceiver(
    method: AllDefaultSubscriberKeys,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    receiver: DefaultSubscriber<string | boolean | any>
  ): void {
    this.defaultSubscribers[method] = receiver;
  }

  public setUserCredentials(email: string, password: string, showErrorMessage = true) {
    const paramsHash = new jsSHA('SHA-256', 'TEXT');
    paramsHash.update(email);
    paramsHash.update(password);
    const params: BridgeMethodParameter = {
      params: {
        email,
        password,
      },
      callbackName: BridgeCallbackMethod.loggedIn,
      requestId: `setUserCredentials_${paramsHash.getHash('HEX')}`,
    };

    return this.callNativeMethod<string>(BridgeMethod.setUserCredentials, params, showErrorMessage);
  }

  public getToken(force = false, showErrorMessage = true): Observable<string | null> {
    const params: BridgeMethodParameter = {
      params: {
        force, // renew token always if true
      },
      callbackName: BridgeCallbackMethod.updateToken,
      requestId: updateTokenRequestIdForce[force ? 'true' : 'false'],
    };
    if (!force) {
      const pending = this.hasPendingCall(updateTokenRequestIdForce['true']);
      if (pending) {
        // we still have a native Call pending, return this Observable.
        return pending;
      }
    }

    return this.callNativeMethod<string>(BridgeMethod.getToken, params, showErrorMessage);
  }

  public getPushStatus(showErrorMessage = true) {
    const params: BridgeMethodParameter = {
      callbackName: BridgeCallbackMethod.pushStatusCallback,
      requestId: pseudoRandomBytes(32),
    };

    return this.callNativeMethod<boolean>(BridgeMethod.pushGetStatus, params, showErrorMessage);
  }

  public enablePush(showErrorMessage = true) {
    return this.pushChangeStatus(BridgeMethod.pushEnable, showErrorMessage);
  }

  public disablePush(showErrorMessage = true) {
    return this.pushChangeStatus(BridgeMethod.pushDisable, showErrorMessage);
  }

  public logout(showErrorMessage = true) {
    const params: BridgeMethodParameter = {
      callbackName: BridgeCallbackMethod.loggedOut,
      requestId: pseudoRandomBytes(32),
    };
    return this.callNativeMethod<boolean>(BridgeMethod.logout, params, showErrorMessage);
  }

  public openURL(url: string | URL, showErrorMessage = true) {
    if (typeof url !== 'string') {
      url = url.href;
    }
    const params: BridgeMethodParameter = {
      callbackName: BridgeCallbackMethod.openUrlCallback,
      requestId: pseudoRandomBytes(32),
      params: {
        url,
      },
    };
    return this.callNativeMethod<boolean>(BridgeMethod.openUrl, params, showErrorMessage);
  }

  public shareText(elementId: string, text: string, url?: string, subject?: string, showErrorMessage = true) {
    const params: BridgeMethodParameter = {
      callbackName: BridgeCallbackMethod.shareTextCallback,
      requestId: pseudoRandomBytes(32),
      params: {
        url,
        text,
        subject,
        elementId,
      },
    };
    return this.callNativeMethod<boolean>(BridgeMethod.shareText, params, showErrorMessage);
  }

  public showSelection(
    titleText: string,
    cancelText: string,
    selections: Array<SelectionInfo>,
    elementId?: string,
    showErrorMessage = true
  ) {
    const verifySelections = selections.map((s, i) => ({
      selection: s,
      index: i,
    }));
    const validatedSelections: Array<SelectionInfo> = [];
    if (selections.length < 1) {
      throw new Error('Selections must at least include 1 element.');
    }
    for (const { selection, index } of verifySelections) {
      if (selection.id < 1) {
        throw new Error('Selection index must start at 1');
      }
      const prev = selections.slice(0, index);
      if (prev.some((s) => s.id === selection.id)) {
        throw new Error('Selection index must be unique.');
      }
      validatedSelections.push({
        id: selection.id,
        // multiple defaults, just honor first.
        default: selection.default && !prev.some((s) => s.default),
        name: selection.name,
      });
    }
    const params: BridgeMethodParameter = {
      callbackName: BridgeCallbackMethod.showSelectionCallback,
      requestId: pseudoRandomBytes(32),
      params: {
        titleText,
        cancelText,
        selections: validatedSelections,
        elementId,
      },
    };
    return this.callNativeMethod<number>(BridgeMethod.showSelection, params, showErrorMessage);
  }

  public playVideo(type: VideoType, videoId: string, showErrorMessage = true) {
    const params: BridgeMethodParameter = {
      callbackName: BridgeCallbackMethod.playVideoCallback,
      requestId: pseudoRandomBytes(32),
      params: {
        type,
        videoId,
      },
    };
    return this.callNativeMethod<boolean>(BridgeMethod.playVideo, params, showErrorMessage);
  }

  public chooseFile(
    mimeTypes: string | Array<string>,
    uploadPath: string,
    fileIdToUpdate?: string,
    fileCategoryType?: string,
    fileDirectoryId?: string,
    showErrorMessage = true
  ) {
    if (typeof mimeTypes === 'string') {
      mimeTypes = mimeTypes.split(',');
    }
    const params: BridgeMethodParameter = {
      callbackName: BridgeCallbackMethod.chooseFileCallback,
      requestId: pseudoRandomBytes(32),
      params: {
        mimeTypes,
        fileIdToUpdate,
        fileCategoryType,
        fileDirectoryId,
        uploadPath,
      },
    };
    return this.callNativeMethod<MediaFile>(BridgeMethod.chooseFile, params, showErrorMessage);
  }

  public webReady(showErrorMessage = true): Observable<null> {
    return this.callNativeMethod<null>(BridgeMethod.webReady, null, showErrorMessage);
  }

  public didGoBack(requestId: string, didHandle: boolean, showErrorMessage = true) {
    const params: BridgeMethodParameter = {
      params: {
        didHandle,
      },
      requestId,
    };
    const data = this.callNativeMethod<null>(BridgeMethod.didGoBack, params, showErrorMessage);
    this.isHandlingGoBack = false;
    return data;
  }

  public sideBarStatus(isOpen: boolean, showErrorMessage = true) {
    const params: BridgeMethodParameter = {
      params: {
        isOpen,
      },
      callbackName: BridgeCallbackMethod.sideBarStatusCallback,
      requestId: pseudoRandomBytes(32),
    };
    return this.callNativeMethod<void>(BridgeMethod.sideBarStatus, params, showErrorMessage);
  }

  public showToS(showErrorMessage = true) {
    return this.callNativeMethod<null>(BridgeMethod.showToS, null, showErrorMessage);
  }

  public tosEnabled(showErrorMessage = true) {
    const params: BridgeMethodParameter = {
      callbackName: BridgeCallbackMethod.tosEnabledCallback,
      requestId: pseudoRandomBytes(32),
    };
    return this.callNativeMethod<boolean>(BridgeMethod.tosEnabled, params, showErrorMessage);
  }

  /** ANGULAR BRIDGE METHODS */

  public zoneRunner(method: BridgeCallbackMethod) {
    const methodName = `${method}Handler`;
    return (unparsedResponse: string) =>
      this.zone.run(() => {
        let response: BridgeMethodResponse;
        try {
          response = JSON.parse(unparsedResponse);
          if (response) {
            let hasError = !!response.error;
            if (!hasError) {
              try {
                // eslint-disable-next-line @typescript-eslint/no-this-alias
                const that = this as this & { [key in string]: (response: BridgeMethodResponse) => boolean };
                if (methodName in that) {
                  hasError = !!that[methodName](response);
                }
              } catch (e) {
                response.error = response.error || {
                  message:
                    typeof e === 'object' && e != null && 'message' in e
                      ? ((e as { message: string }).message as string)
                      : 'Unknown error',
                  code: 0,
                };
                response.error.nativeError = e;
                hasError = true;
              }
            }
            if (hasError) {
              this.errorHandler(
                response,
                true,
                this.defaultSubscribers[method] as DefaultSubscriber<string> | undefined
              );
            }
          }
        } catch (e) {
          console.error(e);
        }
      });
  }

  // Callbacks

  public errorHandler<T>(
    response: BridgeMethodResponse,
    showErrorMessage = true,
    defaultSubscriber?: DefaultSubscriber<T>
  ) {
    const error = response.error;
    let errorObject;
    if (error) {
      if (error.code >= 100 && httpStatusCodes.error[error.code.toString() as unknown as HTTPErrorCode]) {
        errorObject = httpStatusCodes.error[error.code as unknown as HTTPErrorCode];
      }
      if (error.restApiError) {
        if (error.restApiError.code && httpStatusCodes.error.code[error.restApiError.code as RESTApiErrorCode]) {
          errorObject = httpStatusCodes.error.code[error.restApiError.code as unknown as RESTApiErrorCode];
        }
        if (error.restApiError.code === 'model_validation_error' && Array.isArray(error.restApiError.detail)) {
          for (const detail of error.restApiError.detail) {
            if (detail.field && httpStatusCodes.error.field[detail.field as unknown as RESTApiErrorField]) {
              errorObject = httpStatusCodes.error.field[detail.field as unknown as RESTApiErrorField];
              break;
            }
          }
        }
      }
    }

    showErrorMessage = showErrorMessage && this.showErrorForSubscriber(response.requestId ?? '');

    if (typeof errorObject === 'undefined') {
      this.logError(error);
    } else {
      if (showErrorMessage && errorObject.showToast) {
        this.translateService.get(errorObject.message).subscribe((res: string) => {
          this.messageService.showToast(TOASTTYPE.ERROR, res);
        });
      } else if (errorObject.log) {
        this.logError(errorObject);
      }
    }

    this.notifySubscriberWithError(response.requestId, error, defaultSubscriber);
  }

  protected pushChangeCallbackHandler(response: BridgeMethodResponse) {
    this.pushStatusUpdate(response);
  }

  protected pushStatusCallbackHandler(response: BridgeMethodResponse) {
    this.pushStatusUpdate(response);
  }

  protected tosEnabledCallbackHandler(response: BridgeMethodResponse) {
    this.notifySubscriberWithValue(response.requestId, response.params?.['status'] ?? false);
  }

  protected loggedInHandler(response: BridgeMethodResponse) {
    return this.newToken(response);
  }

  protected loggedOutHandler(response: BridgeMethodResponse) {
    this.notifySubscriberWithValue(response.requestId, true);
  }

  protected updateTokenHandler(response: BridgeMethodResponse) {
    return this.newToken(response);
  }

  protected goBackHandler(response: BridgeMethodResponse) {
    if (!this.isHandlingGoBack) {
      this.isHandlingGoBack = true;
      return this.notifySubscriberWithValue(
        response.requestId,
        response.requestId ?? '',
        this.defaultSubscribers.goBack
      );
    } else {
      return true; // error
    }
  }

  protected loginPendingHandler(response: BridgeMethodResponse) {
    this.notifySubscriberWithValue(
      response.requestId,
      (response.params?.['status'] as boolean) || false,
      this.defaultSubscribers.loginPending
    );
  }

  protected openUrlCallbackHandler(response: BridgeMethodResponse) {
    this.notifySubscriberWithValue(response.requestId, true);
  }

  protected chooseFileCallbackHandler(response: BridgeMethodResponse) {
    if (response.params) {
      this.notifySubscriberWithValue(response.requestId, response.params as unknown);
      return;
    } else {
      return true; // error
    }
  }

  protected shareTextCallbackHandler(response: BridgeMethodResponse) {
    this.notifySubscriberWithValue(response.requestId, response.params?.['success'] || false);
  }

  protected sideBarStatusCallbackHandler(response: BridgeMethodResponse) {
    this.notifySubscriberWithValue(response.requestId, true);
  }

  protected createPostHandler(response: BridgeMethodResponse) {
    // TODO: Register default handler, since only called from app
    this.notifySubscriberWithValue(
      response.requestId,
      response.params?.['videoUrlOrId'],
      this.defaultSubscribers.createPost
    );
  }

  protected showSelectionCallbackHandler(response: BridgeMethodResponse) {
    this.notifySubscriberWithValue(
      response.requestId,
      response.params?.['selectedEntry'] ?? 0 // 0 (zero) is for cancel
    );
  }

  protected playVideoCallbackHandler(response: BridgeMethodResponse) {
    this.notifySubscriberWithValue(response.requestId, response.params?.['success'] || false);
  }

  private newToken(response: BridgeMethodResponse) {
    if (response.params?.['token']) {
      this.notifySubscriberWithValue(
        response.requestId,
        response.params['token'] as string,
        this.defaultSubscribers.updateToken
      );
      return;
    } else {
      return true; // error
    }
  }

  private pushStatusUpdate(response: BridgeMethodResponse) {
    this.notifySubscriberWithValue(response.requestId, response.params?.['status'] ?? false);
  }

  private pushChangeStatus(method: BridgeMethod.pushEnable | BridgeMethod.pushDisable, showErrorMessage = true) {
    const params = {
      callbackName: BridgeCallbackMethod.pushChangeCallback,
      requestId: pseudoRandomBytes(32),
    };

    return this.callNativeMethod<boolean>(method, params, showErrorMessage);
  }

  private callNativeMethod<T extends SubscriberResultSubject>(
    methodName: BridgeMethod,
    params: BridgeMethodParameter | null,
    showErrorMessage: boolean
  ): Observable<T | null> {
    if (!this.isNative()) return of(null);

    let inexistentMethod = false;
    let errorString: string | null = null;
    let payload: string | null = null;

    if (params) {
      if ('callbackName' in params) {
        const pending = this.hasPendingCall(params.requestId);
        if (pending) {
          // we still have a native Call pending, return this Observable.
          return pending;
        }
      }
      payload = JSON.stringify(params);
    }
    if ('AndroidWebInterface' in window && window['AndroidWebInterface']) {
      if (
        window['AndroidWebInterface'][methodName] &&
        typeof window['AndroidWebInterface'][methodName] === 'function'
      ) {
        if (payload) {
          window['AndroidWebInterface'][methodName](payload);
        } else {
          window['AndroidWebInterface'][methodName]();
        }
      } else {
        inexistentMethod = true;
      }
    } else if ('webkit' in window && window['webkit']) {
      if (
        window['webkit'].messageHandlers[methodName] &&
        typeof window['webkit'].messageHandlers[methodName].postMessage === 'function'
      ) {
        window['webkit'].messageHandlers[methodName].postMessage(payload ? payload : '');
      } else {
        inexistentMethod = true;
      }
    } else {
      inexistentMethod = true;
    }

    if (inexistentMethod) {
      errorString = `Method '${methodName}' not found in native Bridge!`;
      this.logError(errorString);
    }
    if (params && 'callbackName' in params) {
      this.subscribers[params.requestId] = {
        subject: new Subject<SubscriberResultSubject>(),
        showErrorMessage,
      };
      if (inexistentMethod) {
        const requestId = params.requestId;
        const error = {
          message: errorString,
          code: BridgeNativeErrors.UnknownError,
        };
        setTimeout(() => this.notifySubscriberWithError(requestId, error as BridgeError), 0);
      }
      return this.subscribers[params.requestId].subject as unknown as Subject<T>;
    }
    return of(null);
  }

  private hasPendingCall(requestId: string): Observable<null> {
    if (
      requestId in this.subscribers &&
      !(
        this.subscribers[requestId].subject.isStopped ||
        this.subscribers[requestId].subject.hasError ||
        this.subscribers[requestId].subject.closed
      )
    ) {
      // we still have a native Call pending, return this Observable.
      return this.subscribers[requestId].subject as Observable<null>;
    }
    return of(null);
  }

  private showErrorForSubscriber(requestId: string) {
    if (requestId && requestId in this.subscribers) {
      return this.subscribers[requestId].showErrorMessage;
    }
    return false;
  }

  private notifySubscriberWithError<T>(
    requestId: string | undefined,
    error?: BridgeError,
    defaultReceiver?: DefaultSubscriber<T>
  ) {
    if (requestId && requestId in this.subscribers) {
      const sub = this.subscribers[requestId];
      delete this.subscribers[requestId];
      sub.subject.error(error);
    } else if (defaultReceiver) {
      defaultReceiver(undefined, error);
    }
  }

  private notifySubscriberWithValue<T extends SubscriberResultSubject>(
    requestId: string | undefined,
    value: T,
    defaultReceiver?: DefaultSubscriber<T>
  ) {
    if (requestId && requestId in this.subscribers) {
      const sub = this.subscribers[requestId];
      delete this.subscribers[requestId];
      sub.subject.next(value);
      sub.subject.complete();
      if (requestId === updateTokenRequestIdForce['true']) {
        if (this.hasPendingCall(updateTokenRequestIdForce['false'])) {
          // we still have a native Call pending for `getToken_f:false`, also notify that Observable.
          this.notifySubscriberWithValue(updateTokenRequestIdForce['false'], value, defaultReceiver);
        }
      }
    } else if (defaultReceiver) {
      defaultReceiver(value);
    } else {
      this.logError('notify with no subscribers');
    }
  }
}
