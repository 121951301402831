<app-scroll-view-with-bottom-bar>
  <div class="row" *ngIf="this.formData">
    <div class="col-lg-8 mt-3 pe-4 pr-md-3">
      <div class="dx-field">
        <div class="dx-field-label">{{'feedback.rating' | translate}}</div>
        <div class="dx-field-value">
          <ngx-stars class="mt-2 d-block" [readonly]="false" [color]="'#EDE381'" (ratingOutput)="rated($event)" [size]="1"
                     [initialStars]="this.formData.rating"></ngx-stars>
        </div>
      </div>
      <div class="dx-field">
        <div class="dx-field-label">{{'feedback.concern' | translate}}</div>
        <div class="dx-field-value">
          <dx-select-box
                         text="Kategorie"
                         [items]="this.availableReasons"
                         valueExpr="value" displayExpr="label" [(value)]="formData.reason" fieldTemplate="field">
            <div *dxTemplate="let data of 'field'">
              <div class="custom-item">
                <dx-text-box
                             [value]="translateService.instant('feedback.' + data.label)"
                             [readOnly]="true"></dx-text-box>
              </div>
            </div>
            <div *dxTemplate="let item of 'item'">
              {{ 'feedback.' + item.label | translate}}
            </div>
          </dx-select-box>
        </div>
      </div>
      <div class="dx-field">
        <div class="dx-field-label">{{'feedback.description' | translate}}</div>
        <div class="dx-field-value">
          <dx-text-area [(value)]="formData.message" height="150"></dx-text-area>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom-bar">
    <dx-button class="small" [type]="'normal'" [stylingMode]="'text'" (onClick)="close()">{{'general.buttons.cancel' | translate}}</dx-button>
    <app-button (buttonClick)="send()">{{'general.buttons.send' | translate}}</app-button>
  </div>
</app-scroll-view-with-bottom-bar>
