import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '~/app/core/services/message.service';
import { LinkHandlingDirective } from '../directives/link-handling.directive';

@Component({
  selector: 'app-renderer',
  template: `
    <ng-container [ngSwitch]="templateTag">
      <span *ngSwitchCase="'span'" [ngClass]="templateClass" [innerHTML]="body"></span>
      <p *ngSwitchCase="'p'" [ngClass]="templateClass" [innerHTML]="body"></p>
      <li *ngSwitchCase="'li'" [ngClass]="templateClass" [innerHTML]="body"></li>
      <div *ngSwitchDefault [ngClass]="templateClass" [innerHTML]="body"></div>
    </ng-container>
  `,
})
export class InnerHTMLRendererComponent implements OnInit, OnDestroy {
  // tslint:disable-next-line:no-input-rename
  @Input() public body?: string | SafeHtml;
  // tslint:disable-next-line:no-input-rename
  @Input() public templateTag?: string;
  // tslint:disable-next-line:no-input-rename
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Input() public templateClass?: string | Array<string> | Set<string> | { [klass: string]: {} };

  public removeEventListener?: () => void;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private messageService: MessageService,
    private translate: TranslateService,
    private sanitizer: DomSanitizer
  ) {}

  public ngOnInit() {
    if (typeof this.body === 'string') {
      this.body = this.sanitizer.bypassSecurityTrustHtml(this.body);
    }
    this.removeEventListener = this.renderer.listen(this.elementRef.nativeElement, 'click', (event) => {
      let target: HTMLElement = event.target as HTMLElement; // Add type assertion
      let previousTarget: HTMLElement;
      do {
        previousTarget = target;
        if (target instanceof HTMLAnchorElement) {
          LinkHandlingDirective.handleClick(event, target, this.messageService, this.translate);
          break;
        }
      } while ((target = target.parentElement as HTMLElement) && target !== previousTarget); // Add type assertion
    });
  }

  public ngOnDestroy() {
    if (!this.removeEventListener) return;
    this.removeEventListener();
  }
}
