<app-base-accordion-panel [panel]="this">
  <div class="row">
    <div class="col-lg-8">
      <div *ngIf="this.entity">
        <dx-form
          *appReloadOnLangChange
          [readOnly]="panelMode === modeType.Read"
          [showColonAfterLabel]="true"
          [(formData)]="this.formData"
          (onEditorEnterKey)="save(safeType, $event)"
        >
          <dxi-item dataField="streetname">
            <dxo-label [text]="'fields.address.streetname' | translate"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'error.input_required' | translate }}">
            </dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="zipcode">
            <dxo-label [text]="'fields.address.zipcode' | translate"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'error.input_required' | translate }}">
            </dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="city">
            <dxo-label [text]="'fields.address.city' | translate"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'error.input_required' | translate }}">
            </dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="country">
            <dxo-label [text]="'fields.address.country' | translate"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'error.input_required' | translate }}">
            </dxi-validation-rule>
          </dxi-item>
        </dx-form>
      </div>
    </div>
  </div>
</app-base-accordion-panel>
