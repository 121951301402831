import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { flatMap } from 'rxjs/operators';
import { ReportContent } from '../../models/report-content';
import { BaseService, IServiceUrlConfig } from '../base/base.service';

@Injectable({
  providedIn: 'root',
})
export class ReportContentService extends BaseService<ReportContent> {
  constructor(public override http: HttpClient) {
    super(http, ReportContent);
  }

  public getContentReportCount() {
    return this.apiStrategy.getHeaders('application/json', true, false).pipe(
      flatMap((headers) => {
        return this.http.get<{ count: number }>(this.getServiceUrl('open-report-count', { mgmt: true }), {
          headers,
        });
      })
    );
  }

  protected getServiceUrl(entityId?: string, serviceUrlConfig?: IServiceUrlConfig): string {
    const url = this.generateServiceURL('report-content', entityId, serviceUrlConfig);
    return this.apiStrategy.getServiceUrl(url);
  }
}
