import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ENVIRONMENT_CONFIGURATION } from '@softwarehaus/util-configuration';
import { loadMessages, locale } from 'devextreme/localization';
import * as deMessages from 'devextreme/localization/messages/de.json';
import * as enMessages from 'devextreme/localization/messages/en.json';
import * as zhMessages from 'devextreme/localization/messages/zh.json';
import { merge as mergeObjects } from 'lodash';
import navigatorLanguages from 'navigator-languages';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, mergeMap } from 'rxjs/operators';
import { NappEnvironmentConfiguration } from '~/app/config/environments-configuration';
import { AppApiStrategy } from '../../../app-api-strategy';
import { StorageConfig } from '../../../config/storage.config';
import { JSON } from '../../JSON.interface';
import { Language } from '../../models/language.interface';

@Injectable({
  providedIn: 'root',
})
export class AppTranslationService {
  public languageChanged: EventEmitter<void> = new EventEmitter<void>();
  public hasLanguagesStored: EventEmitter<boolean> = new EventEmitter<boolean>();

  private loadISO?: {
    id: number;
    iso: string;
    loading: Observable<JSON>;
  } = undefined;
  private nextLoadId = 0;

  constructor(
    private translate: TranslateService,
    private http: HttpClient,
    @Inject(ENVIRONMENT_CONFIGURATION) private config: NappEnvironmentConfiguration
  ) {
    loadMessages(deMessages);
    loadMessages(zhMessages);
    loadMessages(enMessages);
    this.hasLanguagesStored.emit(this.hasStoredLanguages());
  }

  public storeLanguages(langs: Language[]) {
    localStorage.setItem(StorageConfig.LANGUAGES, JSON.stringify(langs));
    this.hasLanguagesStored.emit(true);
    this.loadDevExtremeLanguageData(this.getDefaultLanguage()?.iso);
    // this.languageChanged.emit();
  }

  public getStoredLanguages(): Language[] {
    const langs = localStorage.getItem(StorageConfig.LANGUAGES);
    if (!langs) {
      return [];
    }
    // return only active languages
    const languages: Language[] = JSON.parse(langs);
    return languages.filter((item) => item.active);
  }

  public hasStoredLanguages(): boolean {
    const langs = this.getStoredLanguages();
    return Array.isArray(langs) && langs.length > 0;
  }

  public getDefaultLanguage(): Language {
    const lang = this.getStoredLanguages().filter((item) => item.isDefault);
    return lang[0];
  }

  public getCurrentLanguage(): Language {
    let lang = this._getCurrentLanguage();
    if (!lang) {
      lang = this.getDefaultLanguage();
    }
    return lang;
  }

  public storeCurrentLanguage(language: Language) {
    this.loadDevExtremeLanguageData(language.iso);
    localStorage.setItem(StorageConfig.CURRENT_LANGUAGE, JSON.stringify(language));
    // this.languageChanged.emit();
  }

  public getLanguageByIso(iso: string): Language | undefined {
    const lang = this.getStoredLanguages().filter((item) => item.iso === iso);
    return lang.length ? lang[0] : undefined;
  }

  public getLanguageById(id: string): Language | undefined {
    const lang = this.getStoredLanguages().filter((item) => item.id === id);
    return lang.length ? lang[0] : undefined;
  }

  public setAppTranslationUserPreferredLang(): Observable<JSON> {
    let lang: string;

    const currentLang = this._getCurrentLanguage();
    if (currentLang) {
      // user has made a choice in the past, respect that choice
      lang = currentLang.iso;
    } else {
      // check browser language
      for (let browserLang of navigatorLanguages()) {
        if (browserLang.indexOf('-') !== -1) {
          browserLang = browserLang.split('-')[0];
        }
        if (browserLang.indexOf('_') !== -1) {
          browserLang = browserLang.split('_')[0];
        }

        const compLang = browserLang.toLowerCase();
        if (this.getLanguageByIso(compLang)) {
          lang = compLang;
          break;
        }
      }
      lang = this.getDefaultLanguage()?.iso ?? this.config.defaultLangISO;
    }
    return this.setAppTranslation(lang);
  }

  public setAppTranslation(iso: string): Observable<JSON> {
    if (!this.loadISO || this.loadISO.iso !== iso) {
      const lang = this.getLanguageByIso(iso);
      const localResourceURL = 'assets/i18n/' + iso + '.json';
      let serverResourceURL = '';

      if (lang) {
        this.storeCurrentLanguage(lang);
        serverResourceURL = AppApiStrategy.getServiceUrl(['translation', lang.id]);
      }

      let localResource: JSON = {};
      const mergeServerResource = (serverResource: JSON) => {
        mergeObjects(localResource, serverResource);
        this.translate.setTranslation(iso, localResource || {});
        this.translate.use(iso);
        this.languageChanged.emit();
        return 'login';
      };
      const id = this.getLoadId();
      this.loadISO = {
        id,
        iso,
        loading: this.http.get(localResourceURL).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error?.error?.error instanceof SyntaxError) {
              // can not parse JSON
              return of({});
            } else {
              return throwError(error);
            }
          }),
          mergeMap((localRes) => {
            localResource = localRes as JSON;
            return AppApiStrategy.getHeaders(undefined, true, false).pipe(
              catchError(() => {
                return of(null);
              }),
              mergeMap((headers) => {
                if (headers === null) {
                  return of(mergeServerResource({}));
                } else {
                  return this.http.get<JSON>(serverResourceURL, { headers }).pipe(
                    catchError(() => {
                      return of({});
                    }),
                    map(mergeServerResource)
                  );
                }
              })
            );
          }),
          catchError(() => {
            return of(mergeServerResource({}));
          }),
          finalize(() => {
            if (this.loadISO && this.loadISO.id === id) {
              this.loadISO = undefined;
            }
          })
        ),
      };
    }
    return this.loadISO.loading;
  }

  private loadDevExtremeLanguageData(iso?: string) {
    if (!iso) {
      iso = this.config.defaultLangISO;
    }
    switch (iso) {
      case 'cn':
        locale('zh');
        break;
      case 'sk':
        locale('en');
        break;
      default:
        locale(iso);
        break;
    }
  }

  private _getCurrentLanguage(): Language | null {
    const currentLanguageJSON = localStorage.getItem(StorageConfig.CURRENT_LANGUAGE);
    if (!currentLanguageJSON) return null;
    return JSON.parse(currentLanguageJSON);
  }

  private getLoadId(): number {
    this.nextLoadId++;
    if (this.nextLoadId > 2147483647) {
      this.nextLoadId = 0;
    }
    return this.nextLoadId;
  }
}
